import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fontSizes } from 'style/vars';
import HeaderProfile from '../headerProfile/HeaderProfile';
import HomeButton from './HomeButton';

function Header(props) {
  const { middleItems } = props;
  const onWorkspace = window.location.pathname.startsWith('/app/workspace');
  const tenants = useSelector(rs => rs.tenantsList.tenants);
  const currentProject = useSelector(rs => rs.shared.currentProject);
  const currentTenant = useSelector(rs => rs.shared.currentTenant);
  const showTenantProjectLabel = !!currentTenant && !!currentProject && onWorkspace;
  const showTenantsButton = !onWorkspace && tenants && tenants.length > 1;
  const isAdmin = useSelector(rs => rs.currentUser.isAdmin);
  const planTypeInfo = useSelector(rs => rs.planTypeInfo);

  const itemStyle = {
    padding:'3px',
    display: 'flex',
    flexDirection:'column',
    justifyContent: 'center',
    fontSize: fontSizes.r,
  };
  return (
    <>
    <div style={{padding:'5px 10px', borderBottom:'0.5px lightgray solid'}} className="header-container">
      <div className="logo-title-container">
        <div style={itemStyle}>
          <img style={{height:'40px'}} src="/govinvest_logo_no_subtitle_small.png" alt="GovInvest, Inc. Logo"/>
        </div>
        <div style={itemStyle}>
          <div style={{fontWeight:1.2, fontSize:fontSizes.l, marginLeft: '10px'}}>Total Liability Calculator</div>
        </div>
      </div>
      <div style={{display:'flex', alignItems:'center'}}>
        {middleItems}
      </div>
      <div style={{display:'flex'}}>
        {
          showTenantsButton && (
            <div style={{...itemStyle, marginRight: '20px', fontSize:'small'}}>
              <Link to="/app/tenants">Tenants</Link>
            </div>
          )
        }
        {
          showTenantProjectLabel && (
            <div style={{display:'flex', flexDirection:'column', alignItems:'flex-end', marginRight:'5px'}}>
              <div style={{fontSize:fontSizes.s, height:'100%', textAlign:'right', maxWidth:'350px', display:'flex', alignItems:'center', marginBottom:'1px'}}>
                {currentTenant.name}
              </div>
              <div title={isAdmin ? planTypeInfo.planGroupLabel : ''}
                style={{fontSize:fontSizes.s, height:'100%', textAlign:'right', maxWidth:'350px', display:'flex', alignItems:'center', 
                        marginBottom:'1px'}}>
                {currentProject.name}
              </div>
            </div>
          )
        }
        <div style={itemStyle}>
          <HomeButton />
        </div>
        <div style={itemStyle}>
          <HeaderProfile />
        </div>
      </div>
    </div>
    </>
  );
}

export default Header;