// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import { postJSON } from 'lib/network.js';
import saveAs from 'client/lib/saveAs.js';

export function downloadXLS(where, props, what) {
  postJSON(where, props, 'arrayBuffer').then(response => {
    let blob = new Blob([response]);
    saveAs(blob, what);
  });
}

export function downloadPDF(where, props, what) {
  postJSON(where, props, 'arrayBuffer').then(response => {
    let blob = new Blob([response]);
    saveAs(blob, what);
  });
}

export function downloadPNG(where, props, what) {
  postJSON(where, props, 'arrayBuffer').then(response => {
    let blob = new Blob([response]);
    saveAs(blob, what);
  });
}
