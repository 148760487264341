// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import prettyPrint from 'common/pretty_print';
import { getValues, getBaselineValues } from './getValues';
import { findWidth as barWidth } from './barChart';

const findWidth = function(scales) {
  return barWidth(scales, 1);
};

//areacharts stack, but go all the way to the bottom
const stackedBar = (obj, info, already, negAlready, baseAlready, baseNegAlready, scales) => {
  let width = findWidth(scales);
  let values = getValues(obj, info);
  let baselineValues = getBaselineValues(obj, info);

  return values.map(function(val, i) {
    let year = info.valuationYear + i;
    let bottom = scales.y(already[i] || 0); //scales.y.range()[0];
    let baseBottom = scales.y(baseAlready[i] || 0);
    let reversed = null;
    let y = 0;
    let baseY = 0;
    let baseValue = baselineValues[i];

    // viewing
    if (val >= 0) {
      already[i] = already[i] || 0;
      y = scales.y(val + already[i]);
      already[i] += val;
    } else {
      negAlready[i] = negAlready[i] || 0;
      y = scales.y(val + negAlready[i]);
      //reverse bottom & y so svg is right-side-up
      bottom = y;
      y = scales.y(0);
      negAlready[i] += val;
    }

    //baseline
    if (baseValue >= 0) {
      baseAlready[i] = baseAlready[i] || 0;
      baseY = scales.y(baseValue + baseAlready[i]);
      baseAlready[i] += baseValue;
    } else {
      baseNegAlready[i] = baseNegAlready[i] || 0;
      baseY = scales.y(baseValue + baseNegAlready[i]);
      //reverse bottom & y so svg is right-side-up
      baseBottom = baseY;
      baseY = scales.y(0);
      baseNegAlready[i] += baseValue;
    }

    let r = {
      value: val,
      x: scales.x(year),
      y: y,
      baseY,
      width: width,
      bottom: bottom,
      baseBottom,
      year: year,
      label: prettyPrint(val, obj.format),
      already: already[i],
      baseAlready: baseAlready[i],
      reversed: reversed
    };
    return r;
  });
}

export default stackedBar;
