// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { line } from 'd3-shape';

export default class BarLine extends React.Component {
  render() {
    const { chart, cmp, isComparing, renderBaselineComparison } = this.props;
    const comparingWithBaseline = chart.view === 'stackedBar' && isComparing && chart.compareWithBaseline;
    const strokeWidth = 4;
    let opacity = renderBaselineComparison ? 0.7 : 0.9;
    let barLines = [];
    let baselineBarLines = [];

    chart.points.forEach(function(c, i) {
      if (cmp.state.pinYear && cmp.state.onYear !== c.year) {
        opacity = 0.4;
      }
      let margin = c.width * 0.025;
      let halfWidth = c.width * 0.5;
      let barStart = comparingWithBaseline ? c.x + margin : c.x - halfWidth;
      let barEnd = comparingWithBaseline ? c.x + halfWidth + margin : c.x + halfWidth;
      let baselineBarStart = c.x - margin - halfWidth;
      let baselineBarEnd = c.x - margin;

      if (renderBaselineComparison) {
        baselineBarLines.push(
          <line
            x1={baselineBarStart}
            y1={c.baseY}
            x2={baselineBarEnd}
            y2={c.baseY}
            stroke={chart.color}
            strokeWidth={strokeWidth}
            fill="none"
            opacity={opacity}
            key={`bar-line-${i}`}
            shapeRendering="auto"
          />
        );
      } else {
        barLines.push(
          <line
            x1={barStart}
            y1={c.y}
            x2={barEnd}
            y2={c.y}
            stroke={chart.color}
            strokeWidth={strokeWidth}
            fill="none"
            opacity={opacity}
            key={i}
            shapeRendering="auto"
          />
        );
      }
    });

    if (renderBaselineComparison) {
      return <g>{baselineBarLines}</g>;
    }

    return <g>{barLines}</g>;
  }
}
