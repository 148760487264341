// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Glyphicon } from 'react-bootstrap';
import classNames from 'classnames';
import { getOveriddenAdjustmentGroupsInfo, getCategoryItemInfo } from '../selectors';
import AdjustmentOverride from './AdjustmentOverride';
import SideBarAdjustmentGroup from './SideBarAdjustmentGroup';
import './CategoryItem.css';

class CategoryItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true,
    };
  }

  handleSidebarCategoryClick = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  getCategoryItemLabelCssClass = anyDeviateFromScenario => {
    let result = 'category-header';
    if (anyDeviateFromScenario) {
      result = classNames(result, 'changed');
    }
    return result;
  };

  getCategoryItemHeaderCssClass = anyAdjustmentsOverridden => {
    let result = 'sideBar-adjustment-category-item-header';
    if (!anyAdjustmentsOverridden) {
      result = classNames(result, 'noOverrides');
    }
    return result;
  };

  render() {
    const { item, Icon, overiddenPlanAdjustmentGroupsInfo, categoryName, categoryItemInfo } = this.props;
    const { anyDeviateFromScenario, anyAdjustmentsOverridden } = categoryItemInfo;
    const { expanded } = this.state;
    return (
      <div className="sideBar-adjustment-category-item">
        <div className={this.getCategoryItemHeaderCssClass(anyAdjustmentsOverridden)}>
          <div className={this.getCategoryItemLabelCssClass(anyDeviateFromScenario)} onClick={this.handleSidebarCategoryClick}>
            {anyAdjustmentsOverridden && (
              <span className="toggle sideBar-toggle">
                <Glyphicon glyph={expanded ? 'menu-down' : 'menu-right'} style={{ fontSize: 8, fontWeight: 900, color: '#a4aabb' }} />
              </span>
            )}
            <span style={{ marginLeft: '10px' }}>{item.label}</span>
          </div>
          <div className="sideBar-adjustment-category-item-actions">
            <AdjustmentOverride categoryName={categoryName} itemName={item.name} />
            <span className="sideBar-adjustment-category-item-actions-collapse" onClick={this.handleSidebarCategoryClick}>
              {Icon}
            </span>
          </div>
        </div>
        {expanded &&
          overiddenPlanAdjustmentGroupsInfo.length > 0 &&
          overiddenPlanAdjustmentGroupsInfo.map(e => (
            <SideBarAdjustmentGroup adjustmentGroup={e.adjustmentGroup} adjustmentGroupName={e.adjustmentGroupName} Icon={Icon} key={e.adjustmentGroupName} />
          ))}
      </div>
    );
  }
}

const stateToProps = (state, ownProps) => ({
  overiddenPlanAdjustmentGroupsInfo: getOveriddenAdjustmentGroupsInfo(state, ownProps),
  categoryItemInfo: getCategoryItemInfo(state, ownProps),
});

export default connect(stateToProps)(CategoryItem);
