const _enabledProjects = (state) => {
  const currentUser = state.currentUser;
  if(!currentUser)
    return null;

  const tenants = state.tenantsList.tenants;
  if(!tenants)
    return null;

  if(tenants.length > 1)
    return false;

  const projects = state.projectsList.projects;
  if(!projects)
    return null;

  const isAdmin = currentUser.isAdmin;

  const filterEnabledProjects = (p) => {
    if (p.disabled && !isAdmin) //hide disabled projects from users
      return false;
    return true;
  };

  return projects.filter(filterEnabledProjects);
}
export const onlyOneProject = (state) => {
  const enabledProjects = _enabledProjects(state);
  if(enabledProjects === null)
    return null;
  return _enabledProjects(state).length === 1;
};

export const selectFirstProject = (state) => {
  const enabledProjects = _enabledProjects(state);
  if(enabledProjects === null || enabledProjects.length === 0)
    return null;
  return enabledProjects[0];
};
