// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getPayoffPriority, getBases } from '../selectorsAmortizationBases';
import { setPayoffPriorityApplied } from '../actionCreators';
import { List, arrayMove } from 'react-movable';
import { Form, Modal, Checkbox, FormGroup, Button, Glyphicon } from 'react-bootstrap';
import styled from 'styled-components';

const ModalTitleStyled = styled(Modal.Title)`
  text-align: left;
`;

const FooterButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
`;

const ApplyButton = styled(Button)`
  width: 65px;
`;

const InlineDiv = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-column-gap: 15px;
  align-items: center;
  margin-bottom: 10px;
`;

const StyledForm = styled(Form)`
  margin: 0 150px 0 0;
`;

const MoveButton = styled(Button)`
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
  height: 22px;
  width: 28px;
  font-size: 9px;
`;

const MoveTopIcon = styled(Glyphicon)`
  transform: rotate(-90deg);
`;

const MoveBottomIcon = styled(Glyphicon)`
  transform: rotate(90deg);
`;

const SelectStyled = styled.select`
  color: ${props => (props.disabled ? 'gray' : '')};
  padding: 2.5px;
  border-radius: 2.5px;
`;

const CheckboxStyled = styled(Checkbox)`
  line-height: 19px;
`;

const RowButtons = styled.div`
  display: grid;
  grid-auto-flow: column;
  column-gap: 5px;
`;

const ModalStyled = styled(Modal)`
  & .modal-dialog {
    transform: none !important;
  }
  & .modal-content {
    max-height: 80%;
    overflow-y: hidden;
  }
`;

const TableStyled = styled.table`
  width: 100%;
  border-spacing: 0;
  & td,
  th {
    padding: 5px;
  }
`;

const HeaderCell = styled.th`
  text-align: ${props => (props.right ? 'right' : 'left')};
`;

const CellStyled = styled.td`
  text-align: ${props => (props.right ? 'right' : 'left')};
`;

const RowStyled = styled.tr`
  &:hover {
    background: #ddd;
  }
`;

const moveTop = (arr, itemIndex) => {
  if (itemIndex === 0) return arr;
  let movedItem = arr.splice(itemIndex, 1)[0];
  arr.unshift(movedItem);
  return arr;
};

const moveBottom = (arr, itemIndex) => {
  if (itemIndex === arr.length - 1) return arr;
  let movedItem = arr.splice(itemIndex, 1)[0];
  arr.push(movedItem);
  return arr;
};

const moveUp = (arr, itemIndex) => {
  if (itemIndex === 0) return arr;
  let movedItem = arr.splice(itemIndex, 1)[0];
  arr.splice(itemIndex - 1, 0, movedItem);
  return arr;
};

const moveDown = (arr, itemIndex) => {
  if (itemIndex === arr.length - 1) return arr;
  let movedItem = arr.splice(itemIndex, 1)[0];
  arr.splice(itemIndex + 1, 0, movedItem);
  return arr;
};

class PayoffPriorityModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bases: this.props.payoffPriority.applied.bases,
      strategy: this.props.payoffPriority.applied.strategy || 'MaximizeTotalCashSavings',
      manual: this.props.payoffPriority.applied.manual || false,
      isDragging: false,
      widths: [],
    };
    this.onBeforeDragStart = () => {
      this.setState({
        isDragging: true,
      });
    };

    this.onDragEnd = result => {
      this.setState(state => {
        // dropped outside the list
        if (!result.destination || result.destination.index === result.source.index) {
          return;
        }
        // no movement
        if (result.destination.index === result.source.index) {
          return;
        }
        const reorderedBases = this.reorder(this.state.bases, result.source.index, result.destination.index);

        return {
          isDragging: false,
          bases: reorderedBases,
        };
      });
    };

    this.toggleTableLayout = () => {
      this.setState(state => ({ layout: state.layout === 'auto' ? 'fixed' : 'auto', }));
    };
  }

  handleStrategySelect = e => {
    this.setState({
      strategy: e.target.value,
    });
  };

  handleMove = (location, itemIndex) => {
    this.setState(state => {
      let basesModified;
      if (location === 'up') basesModified = moveUp([...state.bases], itemIndex);
      if (location === 'down') basesModified = moveDown([...state.bases], itemIndex);
      if (location === 'top') basesModified = moveTop([...state.bases], itemIndex);
      if (location === 'bottom') basesModified = moveBottom([...state.bases], itemIndex);

      return {
        bases: basesModified,
      };
    });
  };

  handleBasesApply = () => {
    const bases = this.state.bases.map(base => base.id);
    const basesOriginalOrder = this.props.bases.map(base => base.id);
    const payload = {
      payoffPriority: {
        strategy: this.state.strategy,
        manual: this.state.manual,
        bases: this.state.manual ? bases : basesOriginalOrder,
      },
      adjustmentName: this.props.adjustmentName,
    };
    this.props.payoffPriorityApply(payload);
    this.props.handleShow('payoffPriorityModalShow');
  };

  handleCheck = () => {
    this.setState(state => {
      return { manual: !state.manual }
    });
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  render() {
    return (
      <ModalStyled
        show={this.props.show}
        onHide={() =>
          this.props.handleShowWithConfirm(
            'payoffPriorityModalShow',
            { strategy: this.state.strategy, bases: this.state.bases, manual: this.state.manual },
            this.props.payoffPriority.applied,
          )
        }
        bsSize={this.state.manual ? 'large' : undefined}
        centered="true"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <ModalTitleStyled id="contained-modal-title-vcenter">Amortization Payoff Priority</ModalTitleStyled>
        </Modal.Header>
        <Modal.Body>
          <StyledForm>
            <FormGroup controlId="formRadioButtons">
              <InlineDiv>
                <div>Payoff Strategy:</div>
                <SelectStyled value={this.state.strategy} disabled={this.state.manual} onChange={e => this.setState({ strategy: e.target.value })}>
                  <option value="MaximizeTotalCashSavings">Maximize total cash savings</option>
                  <option value="MaximizeInitialSavings">Maximize initial savings</option>
                  <option value="SpreadAcrossAllBases">Spread across all bases</option>
                </SelectStyled>
              </InlineDiv>
              <CheckboxStyled inline id="payoff-priority-manual" checked={this.state.manual} onChange={this.handleCheck}>
                Specify Manual Payoff Priority
              </CheckboxStyled>
            </FormGroup>
          </StyledForm>
          {this.state.manual &&
            this.state.bases &&
            this.state.bases.length > 0 && (
              <div style={{ maxHeight: '480px', overflowY: 'auto' }}>
                <List
                  beforeDrag={({ elements, index }) => {
                    const cells = Array.from(elements[index].children);
                    const widths = cells.map(cell => window.getComputedStyle(cell).width);
                    this.setState({ widths });
                  }}
                  values={this.state.bases}
                  onChange={({ oldIndex, newIndex }) => {
                    this.setState(prevState => ({
                      bases: arrayMove(prevState.bases, oldIndex, newIndex),
                    }));
                  }}
                  renderList={({ children, props, isDragged }) => (
                    <TableStyled style={{ zIndex: 2000 }}>
                      <thead>
                        <RowStyled>
                          <HeaderCell>Bases</HeaderCell>
                          <HeaderCell right>Date Est</HeaderCell>
                          <HeaderCell right>Rem Pmt</HeaderCell>
                          <HeaderCell right>Payment</HeaderCell>
                          <HeaderCell right>Balance</HeaderCell>
                          <HeaderCell right />
                        </RowStyled>
                      </thead>

                      <tbody {...props}>{children}</tbody>
                    </TableStyled>
                  )}
                  renderItem={({ value, props, isDragged, isSelected }) => {
                    const widths = isDragged ? this.state.widths : [];
                    const row = (
                      <RowStyled
                        {...props}
                        style={{
                          ...props.style,
                          cursor: isDragged ? 'grabbing' : 'grab',
                          backgroundColor: isDragged || isSelected ? '#ddd' : '',
                        }}
                      >
                        <CellStyled style={{ width: widths[1] }}>{value.source}</CellStyled>
                        <CellStyled style={{ width: widths[2] }} right>
                          {value.dateEstablished}
                        </CellStyled>
                        <CellStyled style={{ width: widths[3] }} right>
                          {value.yearsRemaining}
                        </CellStyled>
                        <CellStyled style={{ width: widths[4] }} right>
                          {value.payment.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                        </CellStyled>
                        <CellStyled style={{ width: widths[5] }} right>
                          {value.balanceBegin.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                        </CellStyled>
                        <CellStyled style={{ width: widths[6] }} right>
                          <RowButtons>
                            <MoveButton bsSize="small" onClick={() => this.handleMove('up', props.key)}>
                              <Glyphicon glyph="triangle-top" />
                            </MoveButton>
                            <MoveButton bsSize="small" onClick={() => this.handleMove('down', props.key)}>
                              <Glyphicon glyph="triangle-bottom" />
                            </MoveButton>
                            <MoveButton bsSize="small" onClick={() => this.handleMove('top', props.key)}>
                              <MoveTopIcon glyph="step-forward" />
                            </MoveButton>
                            <MoveButton bsSize="small" onClick={() => this.handleMove('bottom', props.key)}>
                              <MoveBottomIcon glyph="step-forward" />
                            </MoveButton>
                          </RowButtons>
                        </CellStyled>
                      </RowStyled>
                    );
                    return isDragged ? (
                      <TableStyled style={{ ...props.style, zIndex: 2000 }}>
                        <tbody>{row}</tbody>
                      </TableStyled>
                    ) : (
                      row
                    );
                  }}
                />
              </div>
            )}

          <FooterButtons>
            <ApplyButton bsStyle="success" bsSize="small" onClick={this.handleBasesApply}>
              OK
            </ApplyButton>
          </FooterButtons>
        </Modal.Body>
      </ModalStyled>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  bases: getBases(state, ownProps.adjustmentName),
  payoffPriority: getPayoffPriority(state, ownProps),
  scenario: state.sideBar.scenarios[state.sideBar.viewingScenario],
});

const mapDispatchToProps = dispatch => ({
  payoffPriorityApply: payoffPriority => dispatch(setPayoffPriorityApplied(payoffPriority)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PayoffPriorityModal);
