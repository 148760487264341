// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { Table } from 'react-bootstrap';
import prettyPrint from 'common/pretty_print';

const detailFormat = 'amortizationDetail';

export default class AmortizationDetailsTable extends React.Component {
  makeTable(id, headings, rows) {
    return (
      <Table key={id} className="metrics-data-table table tablecontent table-striped">
        <thead>
          <tr>{headings}</tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    );
  }

  render() {
    const { info } = this.props;
    const { projectionStart, onYear, series } = info;

    const yearIndex = onYear - projectionStart;
    const details = series.find(s => s.id === detailFormat);

    const showDetailsTable =
      details.adjustments.length > 0 &&
      details.adjustments[0].values.length > 0 &&
      details.adjustments[0].values[0].plans.length > 0 &&
      details.adjustments[0].values[0].plans[0].details.length > 0 &&
      details.adjustments[0].values[0].plans[0].details[0].source;

    if (!showDetailsTable) return null;

    const headingNames = [
      { source: 'Type' },
      { dateEstablished: 'Date Established' },
      { yearsInitial: 'Initial Years' },
      { balanceInitial: 'Initial Amount' },
      { balanceBegin: 'Outstanding Balance' },
      { yearsRemaining: 'Years Remaining' },
      { payment: 'Annual Payment' },
      { method: 'Amortization Method' },
    ];

    const includeInitialBalance = !!details.adjustments[0].values[0].plans[0].details[0].balanceInitial;
    if (!includeInitialBalance) headingNames.splice(3, 1); // remove the balanceInitial column

    const headingCss = {
      borderBottom: '3px solid black',
    };
    const columnRightCss = {
      textAlign: 'right',
    };
    const columnStyles = {
      source: {},
      dateEstablished: columnRightCss,
      yearsInitial: columnRightCss,
      balanceInitial: columnRightCss,
      balanceBegin: columnRightCss,
      yearsRemaining: columnRightCss,
      payment: columnRightCss,
      method: {},
    };
    const classificationRowCss = {
      background: 'none',
    };
    const classificationColCss = {
      fontWeight: 'bold',
      borderTop: 'none',
      borderBottom: '1px solid grey',
    };
    const totalRowCss = {
      fontWeight: 'bold',
      borderTop: '1px solid black',
    };
    const spacerRowCss = {
      background: 'none',
      border: 'none',
    };

    const headingKeys = headingNames.map(entry => Object.keys(entry)[0]);

    const dollarKeys = ['balanceInitial', 'balanceBegin', 'payment'];

    const headings = [
      headingNames.map(entry => {
        let key = Object.keys(entry)[0];
        return (
          <th key={key} style={{ ...headingCss, ...columnStyles[key] }}>
            {entry[key]}
          </th>
        );
      }),
    ];

    const formatValue = (key, value, dollarFormat = detailFormat) => {
      if (dollarKeys.includes(key)) {
        return prettyPrint(value, dollarFormat);
      } else if (key === 'method') {
        switch (value) {
          case 'PercentPay': {
            return 'Percent Pay';
          }
          case 'Ramp30':
          case '30 Year Ramp 5': {
            return '5 Year Ramp';
          }
          case 'LevelDollar': {
            return 'Level Dollar';
          }
          default: {
            return value;
          }
        }
      }

      return value;
    };

    const makeTotalCols = (obj, id, rowName, extraCss = {}) =>
      headingKeys.map(k => {
        const isType = k === 'source';
        const isBalance = k === 'balanceBegin';
        const isPayment = k === 'payment';
        return (
          <td key={id + k} style={{ ...columnStyles[k], ...totalRowCss, ...extraCss }}>
            {isType && rowName}
            {isBalance && formatValue(k, obj.totalBalanceBegin, detailFormat)}
            {isPayment && formatValue(k, obj.totalPayment, detailFormat)}
          </td>
        );
      });

    const makeAdjustmentsTable = adjustments => {
      const tableName = onYear + ' ' + details.title + (details.adjustments.length > 1 ? ' - ' + adjustments.label : '');

      const values = adjustments.values[yearIndex];
      let rows = [];
      for (let p of values.plans) {
        let pp = p.plan + '_';
        const planLabel = p.planLabel ? p.planLabel : p.plan;
        rows.push(
          <tr key={pp + 'tr'} style={classificationRowCss}>
            <td key={pp + 'td'} colSpan="8" style={classificationColCss}>
              {planLabel}
            </td>
          </tr>,
        );

        // add bases rows
        for (let i = 0; i < p.details.length; i++) {
          let base = p.details[i];
          let cols = headingKeys.map(k => {
            return (
              <td key={pp + k} style={columnStyles[k]}>
                {formatValue(k, base[k])}
              </td>
            );
          });
          let source = base.source.replace(/\s/g, '') + '_' + i;
          rows.push(<tr key={pp + source}>{cols}</tr>);
        }
        rows.push(<tr key={pp + 'total'}>{makeTotalCols(p, pp + 't', 'Total')}</tr>);
        rows.push(
          <tr key={pp + 'spacer'} style={spacerRowCss}>
            <td key={pp + 'spacerBlank'} colSpan="8" style={spacerRowCss} />
          </tr>,
        );
      }
      rows.push(
        <tr key={'gt' + adjustments.id + 'spacer'}>
          {makeTotalCols(values, 'adj' + adjustments.id + 'gt', 'Grand Total', { borderTop: '5px double black' })}
        </tr>,
      );

      return (
        <div key={'s' + adjustments.id} className="bot-table-container container-fluid">
          <br />
          <h3 key={'h' + adjustments.id}>{tableName}</h3>
          <br />
          <div key={'d' + adjustments.id} className="table-responsive btc-table-bot">
            {this.makeTable('t' + adjustments.id, headings, rows)}
          </div>
        </div>
      );
    };

    return <section>{details.adjustments.map(makeAdjustmentsTable)}</section>;
  }
}
