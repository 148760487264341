// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';

const css = {
  cursor: 'pointer'
};

export default class Hovers extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleHover = this.handleHover.bind(this);
  }
  handleClick(year) {
    const { callbacks } = this.props;
    callbacks.clickYear(year);
    callbacks.changeYear(year);
  }
  handleHover(year) {
    this.props.callbacks.hoverYear(year);
  }
  render() {
    const { charts, scales } = this.props;
    if (!charts || charts.length === 0) {
      return null;
    }
    const firstYear = scales.x.domain()[0];
    const height = scales.y.range()[0];
    const hovers = charts[0].points.map((o, i) => {
      let width = scales.x(firstYear + 1) - scales.x(firstYear);
      let x = scales.x(o.year);
      if (i === 0) {
        width = width / 2;
      } else {
        x -= width / 2; //align center (move them half-a-unit back)
      }
      return (
        <rect
          key={i}
          onClick={() => this.handleClick(o.year)}
          onMouseEnter={() => this.handleHover(o.year)}
          x={x}
          y={0}
          width={width - 5}
          height={height}
          fill={'black'}
          opacity={0}
          style={css}
        />
      );
    });
    return <g>{hovers}</g>;
  }
}
