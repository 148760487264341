// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import prettyPrint from 'common/pretty_print';
import { getValues, getBaselineValues } from './getValues';

export function findWidth(scales, n) {
  let yearOne = scales.x.range()[0];
  let width = scales.x(yearOne + 1) - scales.x(yearOne);
  let margin = width / 5;
  width = width - margin;
  return width / n;
}

export const barChartWidth = (info, scales) => {
  const barWidth = findWidth(scales, 1);
  const barWidthHalf = barWidth * 0.5;
  const margin = barWidth * 0.025;
  let result = scales.x(info.endYear) + barWidthHalf;

  if (info.isComparing && info.compareWithBaseline) {
    result = result + margin;
  }

  return result;
};

function numBarSeries(info) {
  return info.series.filter(s => s.view === 'bar').length;
}

//areacharts stack, but go all the way to the bottom
const barChart = (obj, info, already, negAlready, scales, index) => {
  let n = numBarSeries(info);
  let width = findWidth(scales, n);
  let overBy = width * index - width * (n - 1) * 0.5;
  let bottom = scales.y(0);
  let values = getValues(obj, info);
  let baselineValues = getBaselineValues(obj, info);
  return values.map(function(val, i) {
    let year = info.valuationYear + i;
    let y = scales.y(val) || 0;
    let baseY = scales.y(baselineValues[i]) || 0;
    let r = {
      value: val,
      baseValue: baselineValues[i],
      x: scales.x(year) + overBy,
      y: Math.min(y, bottom),
      baseY: Math.min(baseY, bottom),
      width: width,
      bottom: Math.max(y, bottom),
      baseBottom: Math.max(baseY, bottom),
      year: year,
      label: prettyPrint(val, obj.format),
      compareWithBaseline: info.compareWithBaseline
    };
    return r;
  });
}

export default barChart;
