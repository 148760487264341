// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import { keyFromArray } from 'client/lib/app-general.js';
import { getXlsView } from 'client/lib/app-general.js';

export function getDownloadableCharts(planType, availCharts) {
  // TODO: KPL this should be simplified, we should just put a property on the charts themselves that defines whether they are downloadable.
  const allTopics = availCharts[planType];
  // topics with chart keys are filtered by chart, otherwise they are filtered at the topic level
  const noDownloadable = {
    Dashboard: true,
    'Amortization Bases': {
      Details: true,
    },
  };
  return Object.keys(allTopics).reduce((topics, topicName) => {
    // this topic is in noDownloadable
    if (noDownloadable[topicName]) {
      const nd = noDownloadable[topicName];
      // filtered at the chart level
      if (Object.keys(nd).length) {
        return {
          ...topics,
          [topicName]: Object.keys(allTopics[topicName]).reduce((a, t) => (nd[t] ? a : { ...a, [t]: allTopics[topicName][t] }), {}),
        };
      }
      //filtered at the topic level
      return topics;
    }
    return { ...topics, [topicName]: allTopics[topicName] };
  }, {});
}

/** Get the charts for this topic, populated with useful details
 **/
export function getTopicCharts(planType, topic, availCharts) {
  const charts = availCharts[planType][topic];
  return Object.keys(charts).reduce((all, chartName) => {
    const chart = charts[chartName];
    const view = getXlsView(chart);
    const series = Object.keys(chart.views[view].series);
    return { ...all, [chartName]: { series, chart, view } };
  }, {});
}

// return true if any element of [what] is true
export function unchecked(state, ...what) {
  console.assert(what[0] === 'pension' || what[0] === 'opeb', 'missing planType', what[0]);
  const a = what.reduce((all, a) => (a ? [...all, a] : all), []);
  return !!a.find((v, i) => {
    const key = keyFromArray(a.slice(0, i + 1));
    return state.viewSummary.downloadUnchecked[key];
  });
}
