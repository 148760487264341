// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import { find } from 'common/colorMachine';
import prettyPrint from 'common/pretty_print';

const linechart = function(adj, info, scales, o, baselineValues) {
  let values = adj.values.slice(0, info.endIndex + 1);
  let series = values.map(function(val, i) {
    const baselineValue = baselineValues.length > i ? baselineValues[i] : 0;
    let baselineDifference = val - baselineValue;
    let baselineDifferenceLabel = prettyPrint(baselineDifference, o.format).replace(/[()]/g, '');
    let baselineDiffDirection = 'none';
    if(baselineDifference > 10e-6) {
      baselineDiffDirection = 'up';
    } else if (baselineDifference < -10e-6) {
      baselineDiffDirection = 'down';
    }
    let year = info.valuationYear + i;
    return {
      value: val,
      x: scales.x(year),
      y: scales.y(val),
      baseY: scales.y(baselineValue),
      baselineLabel: prettyPrint(baselineValue, o.format),
      baselineDiffLabel: baselineDifferenceLabel,
      baselineDiffDirection: baselineDiffDirection,
      year: year,
      label: prettyPrint(val, o.format),
      already: 0
    };
  });
  return series;
};

const getBaselineValues = (adjustments, info) => {
  const baselineAdjustment  = adjustments.filter(x => x.isBaseline)[0];
  if(!baselineAdjustment) {
    console.error('Baseline adjustment not found');
    return null;
  }
  return baselineAdjustment.values.slice(0, info.endIndex + 1);
}

//add a 'points' field, with the calculated x & y's
const compute = (s, info, scales) => {
  let charts = [];

  const baselineValues = getBaselineValues(s.adjustments, info);
  s.adjustments.forEach(function(a) {
    if (!a.values) {
      return;
    }
    let points = linechart(a, info, scales, s, baselineValues);
    let type = 'comparison';
    //baseline is never-dotted
    if (a.isBaseline) {
      type = 'line';
    }
    charts.push({
      id: a.id,
      title: a.label,
      isCurrent: a.isCurrent,
      type: type,
      points: points,
      color: find(a.id)
    });
  });
  return charts;
}

export default compute;
