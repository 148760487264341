// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Glyphicon } from 'react-bootstrap';
import ScenarioTools from './ScenarioTools';
import ComparedScenarioList from './ComparedScenarioList';
import SavedScenarioList from './SavedScenarioList';
import ScenarioEditModal from './ScenarioEditModal';
import ConfirmationModal from './ConfirmationModal';
import './ScenarioComparison.css';

import { deleteScenario2 } from '../apiActions.js';
import { getIsEditingScenario } from '../selectors';
import { getPlanType } from 'client/selectors/page';

class ScenarioComparison extends React.Component {
  constructor(props) {
    super(props);
    this.state = { collapsed: false, confirmModalOpen: false, scenarioToDelete: '' };
  }

  onToggleCollapse = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  onDeleteScenario = () => {
    const { workspaceInfo } = this.props;
    this.props.deleteScenario2(workspaceInfo, this.state.scenarioToDelete);
    this.toggleConfirmationDialog();
  };

  confirmDeleteScenario = scenarioId => {
    this.setState({ confirmModalOpen: !this.state.confirmModalOpen, scenarioToDelete: scenarioId });
  };

  toggleConfirmationDialog = () => {
    this.setState({ confirmModalOpen: !this.state.confirmModalOpen });
  };

  render() {
    const { collapsed } = this.state;
    const { isEditingScenario } = this.props;

    return (
      <div className="sideBar-adjustment-groups scenario-comparison" style={{ margin: 0 }}>
        <div className="sideBar-adjustment-category-header" onClick={this.onToggleCollapse}>
          <span className="toggle sideBar-toggle">
            <Glyphicon glyph={!collapsed ? 'menu-down' : 'menu-right'} style={{ fontSize: 11, fontWeight: 900, color: '#a4aabb' }} />
          </span>
          <h1>Scenario Comparison</h1>
        </div>
        {!collapsed && (
          <div>
            <ScenarioTools isEditingScenario={isEditingScenario} />
            {!isEditingScenario && <ComparedScenarioList />}
            <SavedScenarioList confirmDeleteScenario={this.confirmDeleteScenario} />
            <ScenarioEditModal />
            <ConfirmationModal show={this.state.confirmModalOpen} onConfirm={this.onDeleteScenario} onClose={this.toggleConfirmationDialog} />
          </div>
        )}
      </div>
    );
  }
}

const stateToProps = (state, ownProps) => ({
  isEditingScenario: getIsEditingScenario(state),
  planType: getPlanType(state, ownProps),
  workspaceInfo: state.workspace.info,
});
const dispatchToProps = { deleteScenario2 };

export default withRouter(
  connect(
    stateToProps,
    dispatchToProps,
  )(ScenarioComparison),
);
