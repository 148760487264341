// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Glyphicon } from 'react-bootstrap';
import classNames from 'classnames';
import CategoryItem from './CategoryItem';
import { getCategoryItems, getCategoryInfo } from 'client/sideBar/selectors.js';

// svg icon components
import Equalizer from 'img/giEqualizer.js';

const getCategoryHeaderCssClass = anyDeviateFromScenario => {
  let result = 'category-header';
  if (anyDeviateFromScenario) {
    result = classNames(result, 'changed');
  }
  return result;
};

class NonGlobalCategorry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
    };
  }

  handleCollapseToggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    const { categoryItems, label, name, categoryInfo } = this.props;
    const { anyDeviateFromScenario } = categoryInfo;
    const { collapsed } = this.state;
    return (
      <div className="sideBar-adjustment-groups">
        <div onClick={this.handleCollapseToggle} className="sideBar-adjustment-category-header">
          <span className="toggle sideBar-toggle">
            <Glyphicon glyph={!collapsed ? 'menu-down' : 'menu-right'} style={{ fontSize: 11, fontWeight: 900, color: '#a4aabb' }} />
          </span>
          <h1 className={getCategoryHeaderCssClass(anyDeviateFromScenario)}>{label}</h1>
        </div>
        {!collapsed && categoryItems.length > 0 && categoryItems.map((item, i) => <CategoryItem categoryName={name} item={item} key={i} itemName={item.name} Icon={<Equalizer />} />)
        }
      </div>
    );
  }
}


const stateToProps = (state, ownProps) => ({
  categoryItems: getCategoryItems(state, ownProps),
  categoryInfo: getCategoryInfo(state, ownProps),
});

export default connect(stateToProps)(NonGlobalCategorry);
