// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import moment from 'moment';

export const getValuationYear = (state, ownProps) => {
  return moment(state.sideBar.planGroupsInfo.valuationDate).year();
}
