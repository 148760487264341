// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import { findByName } from 'common/colorMachine';
import stackedBarLine from './stackedBarLine';
import stackedBar from './stackedBar';

const diffBar = (info, scales) => {
  let already = [];
  let negAlready = [];
  let arr = scales.y.domain();
  scales.y = scales.y.domain([arr[0], arr[1]]);

  let diffBorder = info.series.find(s => s.diffBorder);
  let diffArea = info.series.find(s => !s.diffCurve && !s.diffBorder);

  let diffBorderPoints = stackedBarLine(diffBorder, info, already, negAlready, scales);
  let diffBorderChart = {
    id: diffBorder.id,
    title: diffBorder.title,
    type: 'barLine',
    view: 'stackedBar',
    points: diffBorderPoints,
    color: findByName(diffBorder.color) || 'lightgrey',
    compareWithBaseline: info.compareWithBaseline,
  };

  let diffAreaPoints = makeDiffArea(diffArea, info, scales);

  let diffAreaChart = {
    id: diffArea.id,
    title: diffArea.title,
    type: 'stackedBar',
    view: 'stackedBar',
    points: diffAreaPoints,
    color: findByName(diffArea.color) || 'lightgrey',
    compareWithBaseline: info.compareWithBaseline,
  };

  let result = [];

  if (!diffBorder.hidden) {
    result.push(diffBorderChart);
  }

  if (!diffArea.hidden) {
    result.push(diffAreaChart);
  }

  return result;
}

const makeDiffArea = (diffArea, info, scales) => {
  let already = [];
  let baseAlready = [];
  let negAlready = [];
  let baseNegAlready = [];
  let stackedDiffArea = stackedBar(diffArea, info, already, negAlready, baseAlready, baseNegAlready, scales);
  stackedDiffArea = stackedDiffArea.map(chart => {
    const reversed = chart.value < 0 ? true : false;
    return {
      ...chart,
      reversed,
    }
  })
  return stackedDiffArea;
};

export default diffBar;
