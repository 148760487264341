// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
//FIXME this should be merged with utils.format.
// this is just a wrapper for the 'numeral' library, so that charts can all do something super-easy
// it is used by the axis and the tooltips
import numeral from 'numeral';

const formats = {
  percentage: '(0%.00)',
  percentageDecimal: '(0.00%)',
  dollars: '$(0.00a)',
  dollarsTable: '$(0,0.000am)',
  amortizationDetail: '$(0,0)',
  percentTable: '(0.00%)',
  rough_dollars: '$(0.0a)',
  rough_percent: '(0.0%)',
  rough_number: '(0.0a)',
  integer: '(0a)',
  integerTable: '(0,0)',
  number: '(0.00a)',
  number_thousands: '(0,0.00)',
  comma: '0,0',
};
//some aliases
formats.dollar = formats.dollars;
formats.percent = formats.percentage;

export default function pretty_print(value, unit) {
  let format = formats.integer;
  if (formats.hasOwnProperty(unit)) {
    format = formats[unit];
  } else if (formats.hasOwnProperty(unit.replace('Table', ''))) {
    format = formats[unit.replace('Table', '')];
  }
  if (!value && value !== 0) {
    console.warn('weird value', value, 'substituting 0');
    value = 0;
  }
  let str = numeral(value).format(format);
  //make some rough_dollars rougher
  if (unit === 'rough_dollars' && str.length > 5) {
    str = numeral(value).format('$(0a)');
  }

  str = str.toUpperCase();
  if (unit.startsWith('rough_')) {
    //remove trailing 0's
    //trailing 0's
    str = str.replace(/\.0+([MKB%]\)?)?$/, '$1');
    //trailing 0.5s
    str = str.replace(/\.50+([MKB%]\)?)?$/, '.5$1');
  }

  if (str.includes('NAN')) {
    return pretty_print(0, unit);
  }
  return str;
}
