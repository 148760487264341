// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';

import * as actionTypes from './actionTypes';
import { DATA_RETRIEVAL } from '../actions';
import * as constants from './constants';
import { findColorKey } from './selectors';
import { mapPaymentsToBackendAdjustmentValue } from './components/customAdp/customAdpAdjustmentsMapper';

const defaultState = {
  chartRules: {},
  calcRequests: {},
  adjustmentStructure: {},
  planGroupsInfo: {},
  categories: [],
  adjustmentGroups: {},
  adjustmentsMadeByUser: {},
  adjustmentControls: {},
  filterCategories: [],
  filters: {},
  filtersApplied: {},
  planGroupsLoaded: false,
  projectedNumYears: 5,
  show30YearModal: false,
  year30ModalAdjustmentName: constants.YEAR_30_MODAL_ADJUSTMENT_NAME,
  showWillLoseDataModal: false,
  willLoseDataModalAdjustmentName: undefined,
  userSelectedControl: constants.CONTROL_5YEAR,
  scenarios: {},
  payoffPriorityDefault: {},
  baselineScenario: 'default',
  editingScenario: 'default',
  viewingScenario: 'default',
  scenarioLabelEditModal: '',
  comparedScenarios: ['default'],
  unComparedScenarios: [],
  gaugeMetrics: {},
  firstTime: true,
};

function updatedScenarios2FromAdjustments(result) {
  const { adjustmentsMadeByUser: adjustments, scenarios: currentScenarios, comparedScenarios } = result;
  const found = Object.keys(currentScenarios).find(s => _isEqual(currentScenarios[s].adjustments, adjustments));
  let viewingScenario, scenarios;
  if (found) {
    viewingScenario = found;
    scenarios = currentScenarios;
  } else {
    let sequence = 1;
    while (currentScenarios[`Scenario ${sequence}`]) {
      sequence++;
    }
    const label = `Scenario ${sequence}`;
    viewingScenario = label;
    scenarios = {
      ...currentScenarios,
      [label]: {
        name: label,
        label,
        adjustments,
        hasBeenSaved: false,
      },
    };
    comparedScenarios.push(label);
  }
  return { viewingScenario, comparedScenarios, scenarios };
}

const sideBar = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.SET_ADJUSTMENT_1YEAR: {
      const result = _cloneDeep(state);
      const { adjustmentName, index, value } = action.payload;
      result.adjustmentsMadeByUser[adjustmentName][index] = value;
      return result;
    }
    case actionTypes.SET_ADJUSTMENT_SLIDER: {
      const result = _cloneDeep(state);
      const { adjustmentName, values } = action.payload;
      result.adjustmentsMadeByUser[adjustmentName] = values;
      return result;
    }
    case actionTypes.SET_PERCENT_ADJUSTMENT_1YEAR:
    case actionTypes.SET_DOLLAR_ADJUSTMENT_1YEAR: {
      const result = _cloneDeep(state);
      const { adjustmentName, adjustmentYearRateOptions, index, value } = action.payload;
      result.adjustmentsMadeByUser[adjustmentName][index] = value;
      if (adjustmentYearRateOptions && adjustmentYearRateOptions.valueShouldRepeatFromIndexToEnd) {
        for (let i = index; i < result.adjustmentsMadeByUser[adjustmentName].length; ++i) {
          result.adjustmentsMadeByUser[adjustmentName][i] = value;
        }
      }

      return result;
    }
    case actionTypes.SET_PERCENT_ADJUSTMENT_SLIDER: {
      const result = _cloneDeep(state);
      const { adjustmentName, values } = action.payload;
      result.adjustmentsMadeByUser[adjustmentName] = values;
      return result;
    }
    case actionTypes.SET_CONTROL_5YEAR: {
      const result = _cloneDeep(state);
      const { adjustmentName } = action.payload;
      result.adjustmentControls[adjustmentName].control = constants.CONTROL_5YEAR;
      result.showWillLoseDataModal = false;
      return result;
    }
    case actionTypes.SET_CONTROL_10YEAR: {
      const result = _cloneDeep(state);
      const { adjustmentName } = action.payload;
      result.adjustmentControls[adjustmentName].control = constants.CONTROL_10YEAR;
      result.showWillLoseDataModal = false;
      return result;
    }
    case actionTypes.SET_CONTROL_30YEAR: {
      const result = _cloneDeep(state);
      const { adjustmentName, values } = action.payload;
      result.adjustmentControls[adjustmentName].control = constants.CONTROL_30YEAR;
      result.show30YearModal = false;
      // Set AdjustmentName back to original value to maintain a valid state to avoid component errors.
      result.year30ModalAdjustmentName = constants.YEAR_30_MODAL_ADJUSTMENT_NAME;
      result.adjustmentsMadeByUser[adjustmentName] = values;
      result.showWillLoseDataModal = false;
      return result;
    }
    case actionTypes.SET_CONTROL_SLIDER: {
      const result = _cloneDeep(state);
      const { adjustmentName } = action.payload;
      result.adjustmentControls[adjustmentName].control = constants.CONTROL_SLIDER;
      result.showWillLoseDataModal = false;
      return result;
    }
    case actionTypes.OPEN_WILL_LOSE_DATA_MODAL: {
      const { adjustmentName, userSelectedControl } = action.payload;
      const result = _cloneDeep(state);
      result.willLoseDataModalAdjustmentName = adjustmentName;
      result.showWillLoseDataModal = true;
      result.userSelectedControl = userSelectedControl;
      return result;
    }
    case actionTypes.PROCEED_CONTROL_CHANGE: {
      const adjustmentName = action.payload;
      const result = _cloneDeep(state);
      result.adjustmentControls[adjustmentName].control = _cloneDeep(result.userSelectedControl);
      result.showWillLoseDataModal = false;
      return result;
    }
    case actionTypes.CANCEL_CONTROL_CHANGE: {
      const result = _cloneDeep(state);
      result.showWillLoseDataModal = false;
      return result;
    }
    case actionTypes.TOGGLE_ADJUSTMENTGROUP_COLLAPSED: {
      const result = _cloneDeep(state);
      const { adjustmentGroupName } = action.payload;
      result.adjustmentGroups[adjustmentGroupName].collapsed = _cloneDeep(!result.adjustmentGroups[adjustmentGroupName].collapsed);
      return result;
    }
    case DATA_RETRIEVAL: {
      if (action.step !== 'done') {
        return state;
      }
      const result = _cloneDeep(state);
      const { key, calcRequest } = action;

      if (action.result.gaugeMetrics) {
        result.gaugeMetrics[key] = action.result.gaugeMetrics;
      }
      if (key === 'default' && result.firstTime) {
        if(action.result.amortizationDetail) {
          result.payoffPriorityDefault = {};
          action.result.amortizationDetail[0].plans.forEach(plan => {
            result.payoffPriorityDefault[plan.plan] = {
              bases: plan.details.map(detail => detail.id),
              manual: false,
              strategy: 'MaximizeTotalCashSavings',
            };
          });
          result.amortizationDetail = action.result.amortizationDetail;
        }
        result.firstTime = false;
      }
      return {
        ...result,
        chartRules: action.result.chartRules || result.chartRules,
        calcRequests: {
          ...result.calcRequests,
          [key]: calcRequest,
        },
      };
    }
    case actionTypes.PLAN_GROUPS_RECEIVED: {
      const result = { ...state };
      const { payload } = action;
      result.planGroupsInfo = payload.planGroupsInfo;
      result.adjustmentStructure = payload.adjustmentStructure;
      result.adjustmentGroups = payload.adjustmentGroups;
      result.categories = payload.categories;
      result.adjustmentsMadeByUser = payload.adjustmentsMadeByUser;
      result.adjustmentControls = payload.adjustmentControls;
      result.filterCategories = payload.filterCategories;
      for(let filterCategory of payload.filterCategories) {
        result.filters[filterCategory.name] = [];
        result.filtersApplied[filterCategory.name] = [];
      }
      result.projectedNumYears = 5;
      result.planGroupsLoaded = true;
      result.year30ModalAdjustmentName = constants.YEAR_30_MODAL_ADJUSTMENT_NAME;
      return result;
    }
    case actionTypes.TOGGLE_30YEAR_MODAL: {
      const result = { ...state };
      result.show30YearModal = !state.show30YearModal;
      if (result.show30YearModal) {
        result.year30ModalAdjustmentName = action.payload;
      } else {
        // Set AdjustmentName back to original value if no value provided to avoid component error.
        result.year30ModalAdjustmentName = constants.YEAR_30_MODAL_ADJUSTMENT_NAME;
      }
      return result;
    }
    case actionTypes.SET_ADJUSTMENT_DROPDOWN: {
      const result = _cloneDeep(state);
      const { adjustmentName, selectedChoiceName } = action.payload;
      result.adjustmentsMadeByUser[adjustmentName] = [selectedChoiceName];
      return result;
    }
    case actionTypes.SET_PROJECTED_YEARS: {
      const result = _cloneDeep(state);
      result.projectedNumYears = action.payload.value;
      return result;
    }
    case actionTypes.RESET_ADJUSTMENT_TO_DEFAULT: {
      const result = _cloneDeep(state);
      const { adjustmentName, values } = action.payload;
      result.adjustmentsMadeByUser[adjustmentName] = values;
      return result;
    }
    case actionTypes.OVERRIDE_ADJUSTMENT: {
      const result = _cloneDeep(state);
      const { adjustmentName, values } = action.payload;
      result.adjustmentsMadeByUser[adjustmentName] = values;
      return result;
    }
    case actionTypes.REMOVE_OVERRIDE: {
      const result = _cloneDeep(state);
      const { adjustmentName } = action.payload;
      delete result.adjustmentsMadeByUser[adjustmentName];
      result.adjustmentControls[adjustmentName].control = constants.CONTROL_DEFAULT;
      return result;
    }
    case actionTypes.RESET_ADJUSTMENT_TO_SCENARIO: {
      const result = _cloneDeep(state);
      const { adjustmentName, viewingScenarioId } = action.payload;

      if (result.scenarios[viewingScenarioId].adjustments[adjustmentName]) {
        result.adjustmentsMadeByUser[adjustmentName] = _cloneDeep(result.scenarios[viewingScenarioId].adjustments[adjustmentName]);
      } else {
        delete result.adjustmentsMadeByUser[adjustmentName];
      }
      return result;
    }
    case actionTypes.DELETE_SCENARIO: {
      const result = _cloneDeep(state);
      const { scenarioId } = action.payload;
      delete result.scenarios[scenarioId];
      result.unComparedScenarios = result.unComparedScenarios.filter(scenario => scenario !== scenarioId);
      return result;
    }
    case actionTypes.RUN_SCENARIO: {
      const { scenarioId, scenarioLabel, hasBeenSaved } = action.payload;
      const result = _cloneDeep(state);
      const colorKey = findColorKey(result.scenarios);
      let scenarioAdjustments = _cloneDeep(result.adjustmentsMadeByUser);

      result.scenarios[scenarioId] = {
        scenarioId: scenarioId,
        label: scenarioLabel,
        colorKey,
        hasBeenSaved,
        adjustments: _cloneDeep(scenarioAdjustments),
      };

      result.adjustmentsMadeByUser = scenarioAdjustments;
      result.comparedScenarios = [...result.comparedScenarios, scenarioId];
      result.viewingScenario = scenarioId;
      return result;
    }
    case actionTypes.SAVE_SCENARIO: {
      const result = _cloneDeep(state);
      const { scenarioId } = action.payload;
      result.scenarios[scenarioId].hasBeenSaved = true;
      return result;
    }
    case actionTypes.SET_SCENARIO_LABEL_EDIT_MODAL: {
      const result = _cloneDeep(state);
      const { scenarioId } = action.payload;
      result.scenarioLabelEditModal = scenarioId;
      return result;
    }
    case actionTypes.UNSET_SCENARIO_LABEL_EDIT_MODAL: {
      const result = _cloneDeep(state);
      result.scenarioLabelEditModal = '';
      return result;
    }
    case actionTypes.SET_VIEWING_SCENARIO: {
      const result = _cloneDeep(state);
      const { scenarioId } = action.payload;

      result.adjustmentsMadeByUser = _cloneDeep(result.scenarios[scenarioId].adjustments);
      result.viewingScenario = scenarioId;
      return result;
    }
    case actionTypes.SET_EDITING_SCENARIO: {
      const result = _cloneDeep(state);
      const { scenarioId } = action.payload;
      result.adjustmentsMadeByUser = _cloneDeep(result.scenarios[scenarioId].adjustments);

      result.viewingScenario = scenarioId;
      result.editingScenario = scenarioId;
      return result;
    }
    case actionTypes.UNSET_EDITING_SCENARIO: {
      const result = _cloneDeep(state);
      result.editingScenario = 'default';
      return result;
    }
    case actionTypes.SET_SCENARIO_AS_UNCOMPARED: {
      const result = _cloneDeep(state);
      const { scenarioId } = action.payload;

      if (scenarioId === constants.SCENARIO_DEFAULT_ID) {
        const scenario = {
          adjustments: {},
          hasBeenSaved: true,
          label: 'Default',
          scenarioId: constants.SCENARIO_DEFAULT_ID,
        }
        result.scenarios[scenarioId] = scenario;
        result.comparedScenarios = result.comparedScenarios.filter(scenario => scenario !== scenarioId);
        result.unComparedScenarios = [scenarioId, ...result.unComparedScenarios];
      } else {
        const scenario = result.scenarios[scenarioId];
        delete scenario.colorKey;
        result.scenarios[scenarioId] = scenario;
        result.comparedScenarios = result.comparedScenarios.filter(scenario => scenario !== scenarioId);
        result.unComparedScenarios = [...result.unComparedScenarios, scenarioId];
      }

      return result;
    }
    case actionTypes.SET_SCENARIO_AS_COMPARED: {
      const { scenarioId } = action.payload;
      const result = _cloneDeep(state);
      const colorKey = findColorKey(result.scenarios);
      const scenario = result.scenarios[scenarioId];

      scenario.colorKey = colorKey;
      result.scenarios[scenarioId] = scenario;
      result.unComparedScenarios = result.unComparedScenarios.filter(scenario => scenario !== scenarioId);

      if (scenario.scenarioId === constants.SCENARIO_DEFAULT_ID) {
        result.comparedScenarios = [scenarioId, ...result.comparedScenarios];
      } else {
        result.comparedScenarios = [...result.comparedScenarios, scenarioId];
        result.adjustmentsMadeByUser = _cloneDeep(result.scenarios[scenarioId].adjustments);
      }

      result.viewingScenario = scenarioId;

      return result;
    }
    case actionTypes.SET_BASELINE_SCENARIO: {
      const result = _cloneDeep(state);
      const { scenarioId } = action.payload;
      result.baselineScenario = scenarioId;
      return result;
    }
    case actionTypes.UPDATE_SCENARIO_LABEL: {
      const result = _cloneDeep(state);
      const { scenarioId, label } = action.payload;
      result.scenarios[scenarioId].label = label;
      result.scenarioLabelEditModal = '';
      return result;
    }
    case actionTypes.UPDATE_SCENARIO: {
      const result = _cloneDeep(state);
      const { scenarioId } = action.payload;

      result.editingScenario = 'default';
      result.scenarios[scenarioId].adjustments = _cloneDeep(result.adjustmentsMadeByUser);

      // force re-request
      delete result.calcRequests[scenarioId];
      return result;
    }
    case actionTypes.SET_VIEWING_SCENARIO_TO_DEFAULT: {
      const result = _cloneDeep(state);

      result.adjustmentsMadeByUser = action.payload.adjustmentsMadeByUser;
      result.viewingScenario = action.payload.scenarioId;
      return result;
    }
    case actionTypes.REMOVE_FROM_COMPARED: {
      const result = _cloneDeep(state);
      const { scenarioId } = action.payload;
      result.comparedScenarios = result.comparedScenarios.filter(scenario => scenario !== scenarioId);
      delete result.scenarios[scenarioId];
      return result;
    }
    case actionTypes.SCENARIOS2_RECEIVED: {
      const { payload } = action;
      const receivedScenarios = {};
      for(let s of payload) {
        s.hasBeenSaved = true;
        receivedScenarios[s.scenarioId] = s;
      }
      const result = _cloneDeep(state);
      result.scenarios2Loaded = true;
      result.scenarioLabelEditModal = '';
      result.unComparedScenarios = Object.keys(receivedScenarios);
      result.scenarios = receivedScenarios;
      return result;
    }
    case actionTypes.UPDATE_SCENARIO2_FROM_ADJUSTMENTS: {
      const result = _cloneDeep(state);
      const { viewingScenario, comparedScenarios, scenarios } = updatedScenarios2FromAdjustments(result);

      return { ...result, viewingScenario, comparedScenarios, scenarios };
    }
    case actionTypes.SELECT_FILTER: {
      const newState = _cloneDeep(state);
      let { filters } = newState;
      const { categoryName, filterName } = action.payload;
      let categoryFilters = filters[categoryName];
      const shouldEnable = categoryFilters.indexOf(filterName) === -1;
      if(shouldEnable) {
        categoryFilters.push(filterName);
      } else {
        categoryFilters = categoryFilters.filter(x => x !== filterName);
      }
      filters[categoryName] = categoryFilters;
      return {
        ...newState,
        filters,
      };
    }
    case actionTypes.SET_FILTERS_APPLIED: {
      const result = _cloneDeep(state);
      return {
        ...result,
        filtersApplied: _cloneDeep(action.payload),
      };
    }
    case actionTypes.RESET_FILTERITEMS_SELECTED: {
      const result = _cloneDeep(state);
      for(let filterCategory of result.filterCategories) {
        result.filters[filterCategory.name] = [];
      }
      return result;
    }
    case actionTypes.TOGGLE_FILTERCATEGORY_COLLAPSED: {
      const result = _cloneDeep(state);
      const { filterCategoryName } = action.payload;
      result.filterCategories.filter(filterCategory => filterCategory.name === filterCategoryName)[0].collapsed = !result.filterCategories.filter(
        filterCategory => filterCategory.name === filterCategoryName,
      )[0].collapsed;
      return result;
    }
    case actionTypes.SET_FRESH_STARTS_APPLIED: {
      const result = _cloneDeep(state);
      let adjustments = _cloneDeep(result.adjustmentsMadeByUser);
      adjustments = { ...adjustments, [action.payload.adjustmentName]: action.payload.freshStarts };
      return {
        ...result,
        adjustmentsMadeByUser: adjustments,
      };
    }
    case actionTypes.SET_PAYOFF_PRIORITY_APPLIED: {
      const result = _cloneDeep(state);
      let adjustments = _cloneDeep(result.adjustmentsMadeByUser);
      adjustments = { ...adjustments, [action.payload.adjustmentName]: action.payload.payoffPriority}
      return {
        ...result,
        adjustmentsMadeByUser: adjustments,
      };
    }
    default: {
      return state;
    }
    case actionTypes.SET_CUSTOM_ADP_PAYMENTS_APPLIED: {
      const result = _cloneDeep(state);
      const { payments, plans, adjustmentName } = action.payload;
      let adjustments = _cloneDeep(result.adjustmentsMadeByUser);
      const mapped = mapPaymentsToBackendAdjustmentValue(payments, plans);
      adjustments = { ...adjustments, [adjustmentName]: mapped };
      return {
        ...result,
        adjustmentsMadeByUser: adjustments,
      };
    }
  }
};

export default sideBar;
