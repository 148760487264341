// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import styled, { keyframes } from 'styled-components';

const borderPulse = keyframes`
  0% {
    border-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    border-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    border-color: rgba(165, 165, 165, 0.1);
  }
`;

const barPulse = keyframes`
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
`;

const SSContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 220px;
  min-height: 220px;
  max-width: 220px;
  max-height: 220px;
  border-radius: 50%;
  border: 7px solid rgba(165, 165, 165, 0.1);
  animation: ${borderPulse} 2s infinite ease-in-out;
`;

const SSNumber = styled.div`
  width: ${props => props.width}px;
  height: 46px;
  background: rgba(165, 165, 165, 0.1);
  animation: ${barPulse} 2s infinite ease-in-out;
`;

export default class FakeGauge extends React.Component {
  render() {
    let { props } = this;
    let radius = props.radius || 110;
    let color = props.color || 'lightgrey';
    let thickness = props.thickness || 7;
    let length = radius * 2;
    let css = {
      container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: length,
        minHeight: length,
        maxWidth: length,
        maxHeight: length,
        overflow: 'show',
        borderRadius: '50%',
        border: thickness + 'px solid ' + color,
      },
      number: {
        color: color,
        fontSize: '2rem',
        cursor: 'pointer',
      },
      numberSecondary: {
        fontSize: '1.2rem',
      },
    };

    if (radius > 100) {
      css.number.fontSize = '2rem';
    }
    if (radius >= 110) {
      css.number.fontSize = '2.5rem';
    }
    let cb = props.onClick || function() {};
    return this.props.fetchingData ? (
      <SSContainer>
        <SSNumber width={Math.floor(Math.random() * (180 - 132 + 1) + 132)} />
      </SSContainer>
    ) : (
      <div style={css.container}>
        <div style={css.number} onClick={() => cb()}>
          {props.number || ''}
        </div>
        {props.numberSecondary && <div style={{ ...css.number, ...css.numberSecondary }}>{props.numberSecondary || ''}</div>}
      </div>
    );
  }
}
