// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { connect } from 'react-redux';
import { MenuItem, Glyphicon } from 'react-bootstrap';
import { getOverrideAdjustmentItems } from '../selectors';
import { overrideAdjustment } from '../actionCreators';
import GlyphDropdown from './GlyphDropdown';

const styles = {
  menuSubItem: {
    paddingLeft: '1rem',
  },
};

const getOverrideMenuItems = overrideAdjustments => {
  return overrideAdjustments.map(e => (
    <MenuItem href="http://" key={e.name} eventKey={e.name} disabled={e.isAdjustmentGroup} onClick={e => e.preventDefault()}>
      {e.isAdjustmentGroup ? `${e.label}` : <span style={styles.menuSubItem}>{e.label}</span>}
    </MenuItem>
  ));
};

const AdjustmentOverride = ({ overrideAdjustment, overrideAdjustmentItems, sideBar }) => {
  const handleSelect = adjustmentName => {
    const selectedItem = overrideAdjustmentItems.find(e => e.name === adjustmentName);
    overrideAdjustment(selectedItem, sideBar);
  };
  return (
    overrideAdjustmentItems.length > 0 && (
      <GlyphDropdown pullRight onSelect={handleSelect} Glyph={<Glyphicon glyph="option-vertical" />}>
        {getOverrideMenuItems(overrideAdjustmentItems)}
      </GlyphDropdown>
    )
  );
};

const mapStateToProps = (state, ownProps) => ({
  sideBar: state.sideBar,
  overrideAdjustmentItems: getOverrideAdjustmentItems(state, ownProps),
});

const dispatchToProps = {
  overrideAdjustment,
};
export default connect(
  mapStateToProps,
  dispatchToProps,
)(AdjustmentOverride);
