// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React, { Component } from 'react';

class Arrow extends Component {
  determinePosition = (centerline, order, year) => {
    if (!order && order !== 0) return centerline;

    const odd = order % 2 !== 0;
    const margin = 6;
    const orderIncrement = odd ? (order + 1) / 2 : order / 2;
    const staggeredOrder = margin * orderIncrement;

    if (year.start === year.current) {
      return centerline + margin * order;
    }

    if (year.end === year.current) {
      return centerline - margin * order;
    }

    return odd ? centerline - staggeredOrder : centerline + staggeredOrder;
  };

  render() {
    const { x, order, to, from, color, year } = this.props;
    const upArrow = to < from;
    const topModifier = 5;
    const bottomModifier = 3;
    const angleModifier = 3;
    const arrowHeight = upArrow ? to + topModifier : to - topModifier;
    const bottomMargin = upArrow ? from - bottomModifier : from + bottomModifier;
    const arrowAngle = upArrow ? arrowHeight + angleModifier : arrowHeight - angleModifier;
    const lineStrokeWidth = 2;
    const arrowStrokeWidth = 3;
    const strokeDash = 4;
    const position = this.determinePosition(x, order, year);

    return (
      <g>
        <line key="difference-line" x1={position} x2={position} y1={bottomMargin} y2={arrowHeight} stroke={color} strokeWidth={lineStrokeWidth} strokeDasharray={strokeDash} />
        <line
          key="difference-arrow-head-left"
          x1={position - angleModifier}
          x2={position}
          y1={arrowAngle}
          y2={arrowHeight}
          stroke={color}
          strokeWidth={arrowStrokeWidth}
          strokeLinecap="round"
        />
        <line
          key="difference-arrow-head-right"
          x1={position}
          x2={position + angleModifier}
          y1={arrowHeight}
          y2={arrowAngle}
          stroke={color}
          strokeWidth={arrowStrokeWidth}
          strokeLinecap="round"
        />
      </g>
    );
  }
}

export default Arrow;
