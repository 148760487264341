// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Glyphicon } from 'react-bootstrap';
import classNames from 'classnames';
import SidebarAdjustmentGroup from './SideBarAdjustmentGroup.jsx';
import { getGlobalAdjustmentGroupsForCategory, getCategoryInfo } from 'client/sideBar/selectors.js';

// svg icon components
import Equalizer from 'img/giEqualizer.js';

const stateToProps = (state, ownProps) => ({
  adjustmentGroups: getGlobalAdjustmentGroupsForCategory(state, ownProps),
  categoryInfo: getCategoryInfo(state, ownProps),
});

const dispatchToProps = (dispatch, ownProps) => ({});

const getCategoryHeaderCssClass = anyDeviateFromScenario => {
  let result = 'category-header';
  if (anyDeviateFromScenario) {
    result = classNames(result, 'changed');
  }
  return result;
};

class GlobalCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
    };
  }

  handleCollapseToggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    const { adjustmentGroups, label, categoryInfo } = this.props;
    const { anyDeviateFromScenario } = categoryInfo;
    const { collapsed } = this.state;

    return (
      <div className="sideBar-adjustment-groups">
        <div onClick={this.handleCollapseToggle} className="sideBar-adjustment-category-header">
          <span className="toggle sideBar-toggle">
            <Glyphicon glyph={!collapsed ? 'menu-down' : 'menu-right'} style={{ fontSize: 11, fontWeight: 900, color: '#a4aabb' }} />
          </span>
          <h1 className={getCategoryHeaderCssClass(anyDeviateFromScenario)}>{label}</h1>
        </div>
        {!collapsed &&
          adjustmentGroups.length &&
          adjustmentGroups.map((adjustmentGroup, i) => (
            <SidebarAdjustmentGroup adjustmentGroup={adjustmentGroup} key={i} adjustmentGroupName={adjustmentGroup.name} Icon={<Equalizer />} />
          ))}
      </div>
    );
  }
}

export default connect(stateToProps, dispatchToProps)(GlobalCategory);
