// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal, Button, Form, FormGroup, FormControl, ControlLabel, Col } from 'react-bootstrap';
import { unsetScenarioLabelEditModal } from '../actionCreators';
import { updateScenario2Label } from '../apiActions.js';
import { getScenarioEditModalInfo, } from '../selectors';

class ScenarioEditModal extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = { isValid: false, value: '' };
    this.state = this.initialState;
  }

  UNSAFE_componentWillUpdate(props) {
    return { ...this.state, value: props.scenarioLabel };
  }

  handleChange = e => {
    const isValid = e.target.value !== this.props.scenarioLabel || e.target.value > 3;
    this.setState({
      value: e.target.value,
      isValid: isValid,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
  };

  onClickConfirm = () => {
    const { workspaceInfo } = this.props;
    this.props.updateScenario2Label(workspaceInfo, this.props.scenarioId, this.state.value);
    this.setState(this.initialState);
  };

  onClickClose = () => {
    this.props.unsetScenarioLabelEditModal();
    this.setState(this.initialState);
  };

  render() {
    const { isValid, value } = this.state;
    const { show } = this.props;
    return (
      <Modal show={show} onHide={this.onClickClose} aria-labelledby="contained-modal-title-sm">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-sm">Save Scenario</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form horizontal onSubmit={this.handleSubmit}>
            <FormGroup>
              <Col componentClass={ControlLabel} sm={2}>
                Name:
              </Col>
              <Col sm={10}>
                <FormControl type="text" value={value} placeholder="Enter text" onChange={this.handleChange} />
              </Col>
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.onClickClose}>Cancel</Button>
          <Button disabled={!isValid} onClick={this.onClickConfirm}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const stateToProps = (state, ownProps) => {
  const { show, scenarioId, label } = getScenarioEditModalInfo(state);
  return {
    show: show,
    scenarioId: scenarioId,
    scenarioLabel: label,
    workspaceInfo: state.workspace.info,
  };
};

const dispatchToProps = { unsetScenarioLabelEditModal, updateScenario2Label };

export default withRouter(
  connect(
    stateToProps,
    dispatchToProps,
  )(ScenarioEditModal),
);
