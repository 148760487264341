import React from 'react';
import { connect } from 'react-redux';
import pieLoader from 'img/pie-loader.gif';

class CalcStatus extends React.Component {
  render() {
    const calculations = this.props.calcStatus.calculations;
    const activeCalculations = this.props.calcStatus.activeCalculations;
    const scenarios = this.props.scenarios;

    const scenarioLabel = (scenarioId) => {
      if (scenarioId === 'default') {
        return 'Default';
      }
      return scenarios[scenarioId].label;
    };

    const subTextStyle = {
      fontSize: '14px',
      marginTop: '5px',
    };

    if (!activeCalculations) {
      return <div/>;
    }

    //we don't want the z-index of this to be greater than the z-index of the bootstrap modal (1050) in order to show the disclaimer dialog
    return (
      <div className="modalDialog" style={{zIndex:'1000'}}>
        <div>
          <div>
            <strong>
              Calculating …{' '}
            </strong>
            {`  `}
            <img alt="loading" src={pieLoader}/>
          </div>
          {
            Object.keys(calculations).map(calcId => {
              const activeCalc = calculations[calcId];
              return (
                <div key={calcId} style={subTextStyle}>
                  {`${scenarioLabel(activeCalc.scenarioKey)}: ${activeCalc.message}`}
                </div>
              );
            })
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  calcStatus: state.calcStatus,
  scenarios: state.sideBar.scenarios,
});

export default connect(mapStateToProps)(CalcStatus);