// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import _intersection from 'lodash/intersection';

//SHARED HELPER FUNCTIONS SHARED BETWEEN SELECTORS
import { anyDeviateFromViewingScenario, getCategoryItemAdjustmentNames, getCategoryAdjustmentNames } from './selectorsSharedHelpers';

export const getCategoryInfo = ({ sideBar }, { name }) => {
  const adjustmentNamesWithin = getCategoryAdjustmentNames(sideBar, name);
  const deviateFromViewingScenario = anyDeviateFromViewingScenario(sideBar, adjustmentNamesWithin);
  return {
    anyDeviateFromScenario: deviateFromViewingScenario,
  };
};

export const getAdjustmentGroupInfo = ({ sideBar }, { adjustmentGroupName }) => {
  const adjustmentNamesWithin = sideBar.adjustmentGroups[adjustmentGroupName].adjustmentNames;
  return {
    anyDeviateFromScenario: anyDeviateFromViewingScenario(sideBar, adjustmentNamesWithin),
  };
};

export const getCategoryItemInfo = ({ sideBar }, { item, categoryName }) => {
  const adjustmentNamesWithin = getCategoryItemAdjustmentNames(sideBar, categoryName, item.name);
  const anyAdjustmentsOverridden = _intersection(Object.keys(sideBar.adjustmentsMadeByUser), adjustmentNamesWithin).length > 0;

  return {
    anyDeviateFromScenario: anyDeviateFromViewingScenario(sideBar, adjustmentNamesWithin),
    anyAdjustmentsOverridden,
  };
};

export const runScenarioButtonInfo = ({ sideBar }) => {
  const allAdjustmentNames = Object.keys(sideBar.adjustmentStructure);
  return {
    anyDeviateFromScenario: anyDeviateFromViewingScenario(sideBar, allAdjustmentNames),
  };
};
