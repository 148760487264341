export const isValidIsoDate = (dateString) => {
  if(dateString === undefined || dateString === '')
    return false;
  try{
    const y = dateString.split('-')[0];
    const parsedY = Number.parseInt(y);
    if(parsedY < 1950 || parsedY > new Date().getFullYear() + 50)
      return false;
  } catch(e) {
    console.error('Error parsing date in isValidIsoDate: ', e);
    return false;
  }
  return true;
};