import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { colors } from '../../../../style/colors';
import { isValidIsoDate } from './customAdpHelpers';

const ModalTitleStyled = styled(Modal.Title)`
  text-align: left;
`;

const DescriptionText = styled.div`
  margin-top: 5px;
  font-style: italic;
  margin-bottom: 30px;
`;

const FooterButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 25px;
`;

const ApplyButton = styled(Button)`
  height: 36px;
  display: flex;
`;

const CancelButton = styled(Button)`
  height: 36px;
  margin-right: 15px;
  font-size: 18px;
`;

const IconText = styled.div`
  margin: auto;
  font-size: 18px;
`;

const ModalStyled = styled(Modal)`
  & .modal-content {
    max-height: 80%;
    overflow-y: hidden;
  }

  & .modal-dialog {
    transform: none !important;
    width: 500px;
  }

  & .open {
  }

  & .modal-body {
    padding: 10px 25px 20px 25px
  }
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
`;

const LabelsColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 220px;
`;

const InputsColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 120px;
`;

const LabelCell = styled.div`
  height: 40px;
`;
const InputCell = styled.div`
  height: 40px;
`;

const StyledDatePicker = styled.input`
  height: 28px;
  text-align: right;
  background: ${(p) => p.isError ? colors.redtransparent : ''};
  padding: 2px;
  border-style: solid;
  border-width: 0.5px;
  width: 200px;
  font-size: smaller;
  font-family: monospace;
`;

const StyledInput = styled.input`
  text-align: right;
  height: 28px;
  background: ${(p) => p.isError ? colors.redtransparent : ''};
  font-family: monospace;
  padding: 2px;
  border-style: solid;
  border-width: 0.5px;
  width: 200px;
  font-size: smaller;
`;

const DatePickerControl = ({ value, onUpdate }) => {
  const [val, setVal] = useState(value);
  const handleOnChange = (e) => {
    setVal(e.target.value);
    onUpdate(e.target.value);
  };
  let isError = !isValidIsoDate(val);
  return <InputCell>
    <StyledDatePicker
      type='date'
      style={{ fontSize: 'smaller', padding: '2px' }}
      value={val}
      isError={isError}
      max="2050-01-01"
      onChange={e => handleOnChange(e)}
    />
  </InputCell>;
};

const InputCellControl = ({ value, onUpdate, isValid }) => {
  const [val, setVal] = useState(value.toString());
  const handleOnChange = (e) => {
    let newValue = e.target.value;
    setVal(newValue);
    onUpdate(newValue);
  };
  const handleOnBlur = (e) => {
    setVal(e.target.value);
  };
  return <InputCell>
    <StyledInput
      type='text'
      value={val}
      isError={!isValid}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
    />
  </InputCell>;
};

const CustomAdpTimingAddEditDialog = () => {
  const dispatch = useDispatch();
  const adjustmentStructure = useSelector(rs => rs.customAdp.adjustmentStructure);
  const plans = !!adjustmentStructure.extended && adjustmentStructure.extended.plans;
  const currentPayment = useSelector(rs => rs.customAdp.currentPayment);
  const isEditing = useSelector(rs => rs.customAdp.mode === 'edit');
  if (!plans) {
    throw new Error('No plans in adjustmentStructure in CustomTimingDialog');
  }

  const handleDatePickerUpdate = (date) => {
    dispatch({type: 'CUSTOM_ADP_ADD_EDIT_DATE_UPDATED', payload: { date } })
  };
  const handleInputValidationUpdate = (planId, isValid) => {
    dispatch({type: 'CUSTOM_ADP_ADD_EDIT_INPUT_VALIDATION_UPDATE', payload: { planId, isValid } })
  };
  const handleInputUpdate = (planId, amount) => {
    dispatch({type: 'CUSTOM_ADP_ADD_EDIT_INPUT_UPDATED', payload: { planId, amount } })
  };
  const allValid = () => {
    for(let p of plans) {
      if(!paymentValid(p.id))
        return false;
    }
    if(currentPayment.date === null || currentPayment.date === '')
      return false;
    return true;
  };
  const paymentValid = planId => {
    const v = currentPayment.planPaymentAmounts[planId];
    let stripped = v.replaceAll(',', '');
    let containsValidChars = /^[0-9,.]*$/.test(v);
    let isError = isNaN(stripped) || v < 0;
    isError = isError || !containsValidChars;
    return !isError;
  };
  const handleAddEditClicked = () => {
    if(isEditing)
      dispatch({ type: 'CUSTOM_ADP_PAYMENT_EDITED' });
    else
      dispatch({ type: 'CUSTOM_ADP_PAYMENT_ADDED' });
  };
  const handleCancel = () => {
    dispatch({ type: 'CUSTOM_ADP_CANCEL_ADD_EDIT' });
  };
  const disableSave = !allValid();
  const title = `${ isEditing ? 'Edit' : 'Add' } Additional Timed Contribution`;

  return (
    <ModalStyled
      show={true}
      onHide={handleCancel}
      centered='true'
      backdrop='static'
      aria-labelledby='contained-modal-title-vcenter'
    >
      <Modal.Header closeButton>
        <ModalTitleStyled id='contained-modal-title-vcenter'>{title}</ModalTitleStyled>
      </Modal.Header>
      <Modal.Body>
        <DescriptionText>
          Choose a date for the additional contribution payment, and the dollar amounts to pay to each plan.
        </DescriptionText>

        <InputContainer>
          <LabelsColumn>
            <LabelCell>Date of Payment</LabelCell>
            {
              plans.map(p => {
                return <LabelCell key={p.id}>{p.description}</LabelCell>;
              })
            }
          </LabelsColumn>
          <InputsColumn>
            <DatePickerControl value={currentPayment.date} onUpdate={handleDatePickerUpdate} />
            {
              plans.map(p => {
                return <InputCellControl
                  key={p.id}
                  value={currentPayment.planPaymentAmounts[p.id]}
                  onValidate={v => handleInputValidationUpdate(p.id, v)}
                  onUpdate={v => handleInputUpdate(p.id, v)}
                  isValid={paymentValid(p.id)}
                />;
              })
            }
          </InputsColumn>
        </InputContainer>

        <FooterButtons>
          <CancelButton bsSize='small' onClick={handleCancel}>
            Cancel
          </CancelButton>
          <ApplyButton bsStyle='success' bsSize='small' onClick={handleAddEditClicked} disabled={disableSave}>
            <IconText>{isEditing ? 'Update' : 'Add'}</IconText>
          </ApplyButton>
        </FooterButtons>

      </Modal.Body>
    </ModalStyled>
  );
};
export default CustomAdpTimingAddEditDialog;
