import { DateTime } from 'luxon';
import { v4 as uuid } from 'uuid'

export const parsePaymentsFromString = (inputString, plans) => {
  if(inputString === '') {
    return { err: { error: 'Empty string'} }
  }
  const lines = inputString.split('\n');
  const headerLine = lines[0];
  const colIdxToPlanMap = {};
  const headerCells = headerLine.split('\t');
  if(headerCells[0].trim().toUpperCase() !== 'DATE') {
    return { err: { error: '\'Date\' must be in the first cell'}};
  }
  for(let hx = 1; hx < headerCells.length; hx++) {
    const h = headerCells[hx];
    if(h.trim() === '')
      break;
    const plan = plans.find(x => x.description.toUpperCase() === h.trim().toUpperCase());
    if(!plan)
      return { err: { error: 'Unknown plan: ' + h }};
    colIdxToPlanMap[hx] = plan;
  }
  const payments = [];
  for(let lx = 1; lx < lines.length; lx++) {
    const line = lines[lx];
    const cells = line.split('\t');
    if(cells.length <= 1)
      break;
    const dateString = cells[0];
    const date = DateTime.fromFormat(dateString, 'M/d/yyyy');
    if(date.year < 1950 || date.year > new Date().getFullYear() + 100)
      return { err: { error: 'Out of range date on line ' + (lx + 1) }}
    if(date.invalid)
      return { err: { error: 'Invalid date format on line ' + (lx + 1) }}
    const formattedDate = date.toISODate();
    const pmt = { id: uuid(), date: formattedDate, planPaymentAmountsParsed: {} };
    payments.push(pmt);
    for(let cx = 1; cx < headerCells.length; cx++) {
      if(headerCells[cx].trim() === '')
        break;
      const cell = cells[cx];
      const plan = colIdxToPlanMap[cx];
      let strippedCell = cell.replace(/,/g, '').trim();
      if(strippedCell === '')
        strippedCell = '0';
      const parsedAmount = Number.parseFloat(strippedCell);
      if(isNaN(parsedAmount))
        return { err: { error: `Could not parse value on row: ${lx + 1}, column: ${cx + 1}.`}}
      pmt.planPaymentAmountsParsed[plan.id] = parsedAmount;
    }
  }
  return { payments };
};

export const convertPaymentsToString = (payments, plans) => {
  let ret = 'Date';
  for(let p of plans) {
    ret += `\t${p.description}`;
  }
  ret += '\n';
  for(let pmt of payments) {
    ret += DateTime.fromISO(pmt.date).toLocaleString();
    for(let p of plans) {
      ret += `\t${pmt.planPaymentAmountsParsed[p.id]}`;
    }
    ret += '\n';
  }
  return ret;
};
