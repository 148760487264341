import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { ClipboardIcon } from '../../../../components/Icons';
import { convertPaymentsToString, parsePaymentsFromString } from './customAdpTextAdapter';
import pretty_print from '../../../../common/pretty_print';
import { DateTime } from 'luxon';


const ModalTitleStyled = styled(Modal.Title)`
  text-align: left;
`;

const DescriptionText = styled.div`
  margin-top: 5px;
  font-style: italic;
  margin-bottom: 30px;
`;

const FooterButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 25px;
`;

const ApplyButton = styled(Button)`
  height: 36px;
  display: flex;
`;

const CancelButton = styled(Button)`
  height: 36px;
  margin-right: 15px;
  font-size: 18px;
`;

const IconText = styled.div`
  margin: auto;
  font-size: 18px;
`;

const ModalStyled = styled(Modal)`
  & .modal-content {
    width: 80vw;
    max-height: 80%;
    margin: auto;
    overflow-y: hidden;
  }

  & .modal-dialog {
    width: initial;
    transform: none !important;
  }

  & .open {
  }

  & .modal-body {
    padding: 10px 25px 20px 25px
  }
`;

const StyledTextArea = styled.textarea`
  font-family: monospace;
  font-size: smaller;
  width: 100%;
  margin-top: 5px;
  height: 150px;
  resize: none;
  border: 0.75px solid lightgray;
`;

const ErrorTextArea = styled.textarea`
  font-family: monospace;
  font-size: smaller;
  width: 100%;
  margin-top: 5px;
  height: 150px;
  resize: none;
`;

const TextAreaButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TextAreaTitle = styled.div`
  padding-top: 4px;
`;

const TextAreaButtons = styled.div`
  display: flex;
`;

const IconButtonContainer = styled.div`
  margin-right: 10px;
`;

const IconButton = styled.div`
  height: 36px;
  display: flex;
  margin-left: 20px;
  color: #5cb85c;
  fill: #5cb85c;
  background: white;
  border: 0px;
  cursor: pointer;
  border-radius: 3px;
  padding: 3px 6px 3px 6px;

  &:hover {
    fill: white;
    color: white;
    background: #5cb85c;
  }
`;

const TableContainer = styled.div`
  overflow-y: auto;
  height: 300px;
  margin-top: 10px;
  margin-bottom: 30px;
  border: 0.25px solid lightgray;
`;

const TableStyled = styled.table`
  width: 100%;
  border-spacing: 5px;

  & td,
  th {
    padding: 5px;
  }
`;

const HeaderCell = styled.th`
  border: 0.75px solid lightgray;
  text-align: ${props => (props.right ? 'right' : props.center ? 'center' : 'left')};
  width: 120px;
  font-size: 9pt;
  border-top: 0;
  border-left: 0;
`;

const CellStyled = styled.td`
  border: 0.75px solid lightgray;
  text-align: ${props => (props.right ? 'right' : 'left')};
  font-family: monospace;
  font-size: 9pt;
  border-left: 0;
`;

const RowStyled = styled.tr`
  &:hover {
    background: #ddd;
  }
`;


const CustomAdpCopyPasteFromSpreadsheetDialog = () => {
  const dispatch = useDispatch();
  const adjustmentStructure = useSelector(rs => rs.customAdp.adjustmentStructure);
  const payments = useSelector(rs => rs.customAdp.payments);
  const paymentsString = convertPaymentsToString(payments, adjustmentStructure.extended.plans);
  const [textAreaValue, setTextAreaValue] = useState(paymentsString);
  const [previewPayments, setPreviewPayments] = useState(payments);
  const [error, setError] = useState('');

  useEffect(() => {
    document.addEventListener('copy', handleCopyEvent);
    document.addEventListener('paste', handlePasteEvent);
    return () => {
      document.removeEventListener('copy', handleCopyEvent);
      document.removeEventListener('paste', handlePasteEvent);
    };
  });

  const plans = !!adjustmentStructure.extended && adjustmentStructure.extended.plans;
  if (!plans) {
    throw new Error('No plans in adjustmentStructure in CustomTimingDialog');
  }

  const handleOk = () => {
    dispatch({ type: 'CUSTOM_ADP_COPY_PASTE_OK_CLICKED', payload: { payments: previewPayments } });
  };
  const handleCancel = () => {
    dispatch({ type: 'CUSTOM_ADP_CANCEL_COPY_PASTE' });
  };

  const title = `Copy/Paste from Spreadsheet`;

  const copyToClipboard = async e => {
    await navigator.clipboard.writeText(textAreaValue);
  };

  const handleCopyEvent = async e => {
    console.log('handling copy event')
    e.preventDefault();
    await navigator.clipboard.writeText(textAreaValue);
  };

  const handlePasteEvent = e => {
    console.log('handling paste event')
    e.preventDefault();
    let pastedText = ''
    if (e.clipboardData && e.clipboardData.getData) {
      pastedText = e.clipboardData.getData('text');
    }
    parseInputTextArea(pastedText);
  };

  const parseInputTextArea = inputText => {
    const { payments, err } = parsePaymentsFromString(inputText, plans);
    if(!!err) {
      setTextAreaValue(inputText);
      setError('There was an error reading the text: ' + err.error);
      return;
    }
    setTextAreaValue(inputText);
    setError('');
    setPreviewPayments(payments);
  };

  const handleTextAreaChange = e => {
    parseInputTextArea(e.target.value);
  };

  const formatAmount = val => {
    if(val === undefined)
      return 0;
    return pretty_print(val, 'number_thousands');
  };

  const formatDate = d => {
    return DateTime.fromISO(d).toLocaleString();
  };

  const disableOk = paymentsString === textAreaValue; //no changes

  return (
    <ModalStyled
      show={true}
      onHide={handleCancel}
      centered='true'
      backdrop='static'
      aria-labelledby='contained-modal-title-vcenter'
    >
      <Modal.Header closeButton>
        <ModalTitleStyled id='contained-modal-title-vcenter'>{title}</ModalTitleStyled>
      </Modal.Header>
      <Modal.Body>
        <DescriptionText>
          Use <b>Ctrl-C</b> and <b>Ctrl-V</b> to copy and paste to and from a spreadsheet.
        </DescriptionText>

        <TextAreaButtonRow>
          <TextAreaTitle>Tab-Delimited Text</TextAreaTitle>
          <TextAreaButtons>
            <IconButton bsStyle='success' bsSize='small' onClick={copyToClipboard}>
              <IconButtonContainer>
                <ClipboardIcon height={'24px'} width={'24px'} />
              </IconButtonContainer>
              <IconText>Copy</IconText>
            </IconButton>
          </TextAreaButtons>
        </TextAreaButtonRow>

        <StyledTextArea value={textAreaValue} onChange={handleTextAreaChange} />

        <TextAreaTitle>Preview</TextAreaTitle>

        {
          error !== '' &&
            <ErrorTextArea value={error} readOnly={true} />
        }
        {
          error === '' &&
            <TableContainer>
              <TableStyled style={{ zIndex: 2000 }}>
                <thead>
                <tr>
                  <HeaderCell>Date</HeaderCell>
                  {
                    plans.map(p => {
                      return <HeaderCell key={p.id} center>{p.description}</HeaderCell>;
                    })
                  }
                </tr>
                </thead>

                <tbody>
                {
                  previewPayments.length === 0 &&
                  <RowStyled>
                    <td style={{ fontSize: '9pt' }}>No payments</td>
                  </RowStyled>
                }
                {
                  previewPayments.map(pmt => {
                    return <RowStyled key={pmt.id}>
                      <CellStyled>{formatDate(pmt.date)}</CellStyled>
                      {
                        plans.map(p => {
                          return <CellStyled key={p.id} right>
                            {formatAmount(pmt.planPaymentAmountsParsed[p.id])}
                          </CellStyled>;
                        })
                      }
                    </RowStyled>;
                  })
                }
                </tbody>
              </TableStyled>
            </TableContainer>
        }

        <FooterButtons>
          <CancelButton bsSize='small' onClick={handleCancel}>
            Cancel
          </CancelButton>
          <ApplyButton bsStyle='success' bsSize='small' onClick={handleOk} disabled={disableOk}>
            <IconText>OK</IconText>
          </ApplyButton>
        </FooterButtons>

      </Modal.Body>
    </ModalStyled>
  );
};
export default CustomAdpCopyPasteFromSpreadsheetDialog;
