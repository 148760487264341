// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import css from './axisCss';
// import { startCase } from 'lodash'; // commented out alongside lable()
import { uniq } from 'lodash';
import prettyPrint from 'common/pretty_print';

const X0 = 50; // hard coded left edge of the chart display for now (matches what's used in makeScales.js)

export default class YAxis extends React.Component {
  ticks(left) {
    const { scales, info, barChartWidth } = this.props;
    const range = scales.x.range();

    let format = 'rough_dollars';
    if (info && info.series && info.series[0]) {
      format = info.series[0].format;
    }
    switch (format) {
      case 'dollars':
      case 'percent':
      case 'number':
        format = 'rough_' + format;
        break;
      default: break;
    }

    let values = scales.y.ticks(5);
    values = uniq(values);

    let x2 = range[1];

    if (info.view === 'bar' || info.view === 'diffBar' || info.view === 'stackedBar') {
      x2 = barChartWidth;
    }

    return values.map((val, i) => {
      let y = scales.y(val);
      return (
        <g key={i}>
          <text x={left} y={y} style={css.labelY}>
            {prettyPrint(val, format)}
          </text>
          <line style={css.line} x1={X0} x2={x2} y1={y} y2={y} />
        </g>
      );
    });
  }
  zeroLine() {
    let { scales } = this.props;
    let top = scales.y.domain()[1];
    let bottom = scales.y.domain()[0];
    if (bottom < 0 && top > 0) {
      let left = X0;
      let right = scales.x.range()[1];
      let y = scales.y(0);
      return <line key={'line'} x1={left} x2={right} y1={y} y2={y} style={css.line} />;
    }
  }
  // Temporarily commented out label method and removed {this.label()} from returned JSX element

  // label() {
  //   let { info, scales } = this.props;
  //   if (!info || !info.series[0]) {
  //     return null;
  //   }
  //   let label = info.series[0].format;
  //   let arr = scales.y.range();
  //   let middle = (arr[1] - arr[0]) / 2;
  //   return (
  //     <g key={'label'}>
  //       <text x={middle - 20} y={-10} transform="rotate(270 0,0)" style={css.labelY}>
  //         {startCase(label)}
  //       </text>
  //     </g>
  //   );
  // }
  render() {
    // let { scales } = this.props;
    let left = X0; // scales.x.range()[0];
    // let top = scales.y.range()[0];
    // let bottom = scales.y.range()[1];
    return (
      <g key="yaxis">
        {this.ticks(left - 27)}
        {this.zeroLine()}
        {/* <line key={'line'} x1={left} x2={left} y1={top} y2={bottom} style={css.line} strokeDasharray={'7 7'}/> */}
      </g>
    );
  }
}
