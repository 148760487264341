// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { connect } from 'react-redux';

import TopicChart from './TopicChart';
import { Panel, Glyphicon } from 'react-bootstrap';
import { toggleDownloadSelection } from 'client/actions';
import { getTopicCharts, unchecked } from 'client/selectors/downloadSelector.js';

class TopicRow extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      open: false,
    };
  }
  render(){
    const { id, planType, topic, downloadableCharts, charts, unChecked, toggleDownloadSelection } = this.props;
    const expanded = this.state.open ? 'close' : 'open';
    const icon = this.state.open ? 'default' : 'rotated';
    return (
      <div className="topic-row">
        <Panel expanded={this.state.open} onToggle={() => {}}>
          <Panel.Heading>
            <Panel.Title componentClass="h3">
              <div className="topic-row-content">
                <span>
                  <input id={id} checked={!unChecked} onChange={toggleDownloadSelection} type="checkbox" />
                  <label htmlFor={id}> {topic}</label>
                </span>
                <span className="expand-toggle" onClick={() => this.setState({ open: !this.state.open })}>
                  <span>{expanded}</span>
                  <Glyphicon glyph="triangle-bottom" className={icon} />
                </span>
              </div>
            </Panel.Title>
          </Panel.Heading>
          <Panel.Collapse>
            <Panel.Body>
              {Object.keys(downloadableCharts).map(name => (
                <TopicChart id={`cb/${topic}/${name}`} key={name} planType={planType} topic={topic} chart={name} details={charts[name]} />
              ))}{' '}
            </Panel.Body>
          </Panel.Collapse>
        </Panel>
      </div>
    )
  }
}

const topicStateToProps = (state, { allCharts, planType, topic }) => ({
  charts: getTopicCharts(planType, topic, state.charts.availCharts),
  unChecked: unchecked(state, planType, topic),
});

const topicDispatchToProps = (dispatch, { planType, topic }) => ({
  toggleDownloadSelection: () => dispatch(toggleDownloadSelection(planType, topic)),
});

export default connect(topicStateToProps, topicDispatchToProps)(TopicRow);
