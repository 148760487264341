// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { connect } from 'react-redux';
import { toggleDownloadSelection } from 'client/actions';
import { getTopicCharts, unchecked } from 'client/selectors/downloadSelector.js';

// ChartSeries

const ChartSeries = ({ series, id, title, unChecked, toggleDownloadSelection }) => (
  <li className="chart-series">
    <input id={id} checked={!unChecked} onChange={toggleDownloadSelection} type="checkbox" /> <label htmlFor={id}>{title}</label>
  </li>
);

const seriesStateToProps = (state, { allCharts, planType, topic, chart, series }) => ({
  charts: getTopicCharts(planType, topic, state.charts.availCharts),
  unChecked: unchecked(state, planType, topic, chart, series),
});

const seriesDispatchToProps = (dispatch, { planType, topic, chart, series }) => ({
  toggleDownloadSelection: () => dispatch(toggleDownloadSelection(planType, topic, chart, series)),
});

export default connect(seriesStateToProps, seriesDispatchToProps)(ChartSeries);
