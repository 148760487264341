// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
/*

# ChartSelect

*/

import React from 'react';
import { connect } from 'react-redux';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { setChart } from '../actions/chartsActions';
import styled from 'styled-components';

const DropdownSelect = styled.div`
  padding: 0 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  user-select: none;
  cursor: pointer;
  &:hover {
    border-color: #b5bbcc;
  }
`;

class ChartSelect extends React.Component {
  render() {
    const { dispatch, chartList, selectedChart } = this.props;
    return (
      <div className="dropdown-parent" id="select-chart" style={{ verticalAlign: 'middle' }}>
        {chartList.length > 1 && (
          <Dropdown id="topic-select">
            <DropdownSelect bsRole="toggle">
              {selectedChart} <span className="caret" />
            </DropdownSelect>
            <Dropdown.Menu>
              {chartList.map(chart => (
                <MenuItem
                  href="http://" onClick={e => e.preventDefault()}
                  key={chart}
                  onSelect={() => {
                    dispatch(setChart(chart));
                  }}
                >
                  {chart}
                </MenuItem>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  chartList: state.charts.charts,
  selectedChart: state.charts.selectedView.chart,
});

export default connect(mapStateToProps)(ChartSelect);
