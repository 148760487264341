import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { getTenant, getProject } from 'lib/sharedActions';
import { getProjects } from '../../components/projectsList/projectListActions';
import * as routeBuilder from 'lib/routeBuilder';
import TenantImage from '../tenantImage/TenantImage';
import Spinner from '../Spinner';
import { colors } from '../../style/colors';
import Button from '../Button'
import { validateName } from 'lib/validators';
import { postCommand } from 'lib/commandClient';
import ErrorDialog from 'components/ErrorDialog';
import { fontSizes } from '../../style/vars';
import * as constants from 'common/constants.js';

function AddEditProject() {
  const dispatch = useDispatch();
  const history = useHistory();
  const prms = useParams();
  const tenantId = prms.tenantId;
  const projectId = prms.projectId;
  const [readyToRender, setReadyToRender] = useState(false);
  const projectsList = useSelector(rs => rs.projectsList);
  const projects = projectsList.projects;
  const currentTenant = useSelector(rs => rs.shared.currentTenant);
  const currentTenantId = !!currentTenant ? currentTenant.id : null;
  const currentProject = useSelector(rs => rs.shared.currentProject);
  const currentProjectId = !!currentProject ? currentProject.projectId : null;
  const [errorDialogState, setErrorDialogState] = useState({ visible: false });
  const [saving, setSaving] = useState(false);
  const [fieldName, setFieldName] = useState('');
  const [fieldNameError, setFieldNameError] = useState('');
  const [projectType, setProjectType] = useState(constants.projectTypes.pensionOpeb);

  const [fieldOpebPlanGroup, setFieldOpebPlanGroup] = useState('');
  const [fieldOpebPlanGroupError, setFieldOpebPlanGroupError] = useState('');
  const [fieldPensionPlanGroup, setFieldPensionPlanGroup] = useState('');
  const [fieldPensionPlanGroupError, setFieldPensionPlanGroupError] = useState('');

  const [fieldMersVersion, setFieldMersVersion] = useState('');
  const [fieldMersVersionError, setFieldMersVersionError] = useState('');
  const [fieldMersEmployerId, setFieldMersEmployerId] = useState('');
  const [fieldMersEmployerIdError, setFieldMersEmployerIdError] = useState('');

  const mode = !!projectId ? 'edit' : 'add';

  useEffect(() => {
    document.title = mode === 'add' ? 'GovInvest - Add Project' : 'GovInvest - Edit Project';
    dispatch(getTenant(tenantId));
    dispatch(getProjects(tenantId));
    if (!projectId) {
      setReadyToRender(true);
      return;
    }
  }, [dispatch, tenantId, currentTenantId, projectId, mode]);

  useEffect(() => {
    if (!!projectId)
      dispatch(getProject(projectId));
  }, [dispatch, projectId]);

  useEffect(() => {
    if (!!currentProject && currentProjectId === projectId) {
      setFieldName(currentProject.name);
      setFieldOpebPlanGroup(currentProject.opebPlanGroup);
      setFieldPensionPlanGroup(currentProject.pensionPlanGroup);
      if (!currentProject.projectType) {
        setProjectType(constants.projectTypes.pensionOpeb);
      } else {
        setProjectType(currentProject.projectType);
      }
      setFieldMersVersion(currentProject.mersVersion);
      setFieldMersEmployerId(currentProject.mersEmployerId);
      setReadyToRender(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProjectId]);

  const handleSave = async () => {
    const cmdType = mode === 'add' ? 'CREATE_PROJECT' : 'UPDATE_PROJECT';
    setSaving(true);
    const cmd = {
      type: cmdType,
      payload:
      {
        projectId: projectId,
        tenantId: tenantId,
        name: fieldName,
        projectType: projectType,
      }
    };
    if (projectType === constants.projectTypes.pensionOpeb) {
      cmd.payload.pensionPlanGroup = fieldPensionPlanGroup;
      cmd.payload.opebPlanGroup = fieldOpebPlanGroup;
    } else { //mers
      cmd.payload.mersVersion = fieldMersVersion;
      cmd.payload.mersEmployerId = fieldMersEmployerId;
    }
    const result = await postCommand(cmd);
    if (result.error) {
      setSaving(false);
      setErrorDialogState({ visible: true, error: result.error, errorDescription: result.errorDescription });
    } else {
      dispatch(getProjects(tenantId)).then(() => {
        setSaving(false);
        setReadyToRender(false);
        dispatch({ type: 'CLEAR_PROJECT' });
        history.push(routeBuilder.buildTenantDetail(currentTenantId));
      });
    }
  };

  const handleCancel = () => {
    setReadyToRender(false);
    dispatch({ type: 'CLEAR_PROJECT' });
    history.push(routeBuilder.buildTenantDetail(currentTenantId));
  };

  const handleNameChange = (e) => {
    setFieldName(e.target.value);
    setTimeout(() => validateNameState(e.target.value), 800);
  };

  const handlePensionPlanGroupChange = (e) => {
    setFieldPensionPlanGroup(e.target.value);
    setTimeout(() => validatePensionPlanGroup(e.target.value), 800);
  };

  const handleOpebPlanGroupChange = (e) => {
    setFieldOpebPlanGroup(e.target.value);
    setTimeout(() => validateOpebPlanGroup(e.target.value), 800);
  };

  const handleMersVersionChange = (e) => {
    setFieldMersVersion(e.target.value);
    setTimeout(() => validateMersVersion(e.target.value), 800);
  };

  const handleMersEmployerIdChange = (e) => {
    setFieldMersEmployerId(e.target.value);
    setTimeout(() => validateMersEmployerId(e.target.value), 800);
  };

  const validateNameState = (value) => {
    if (value === '') {
      setFieldNameError('Please provide a name');
      return;
    }
    if (!validateName(value)) {
      setFieldNameError('Invalid name');
      return;
    }
    const allowedExisting = mode === 'add' ? 0 : 1;
    const existing = projects.filter(x => x.name === value);
    if (existing.length > allowedExisting) {
      setFieldNameError('A project with that name already exists in this tenant');
      return;
    }
    setFieldNameError('');
  };

  const validatePensionPlanGroup = (value) => {
    if (value === '') {
      setFieldPensionPlanGroupError('');
      return;
    }
    if (!validateName(value)) {
      setFieldPensionPlanGroupError('Invalid value');
      return;
    }
    setFieldPensionPlanGroupError('');
  };

  const validateOpebPlanGroup = (value) => {
    if (value === '') {
      setFieldOpebPlanGroupError('');
      return;
    }
    if (!validateName(value)) {
      setFieldOpebPlanGroupError('Invalid value');
      return;
    }
    setFieldOpebPlanGroupError('');
  };

  const validateMersVersion = (value) => {
    if (value === '') {
      setFieldMersVersionError('You must provide a value');
      return;
    }
    if (!validateName(value)) {
      setFieldMersVersionError('Invalid value');
      return;
    }
    setFieldMersVersionError('');
  };

  const validateMersEmployerId = (value) => {
    if (value === '') {
      setFieldMersEmployerIdError('You must provide a value');
      return;
    }
    if (!validateName(value)) {
      setFieldMersEmployerIdError('Invalid value');
      return;
    }
    setFieldMersEmployerIdError('');
  };

  const handleCloseErrorDialog = () => {
    setErrorDialogState({ visible: false });
  };

  const handleProjectTypeChange = (e) => {
    setProjectType(e.target.value);
  };

  const isDirty = () => {
    if (mode === 'add')
      return true;
    if (!currentProject)
      return false;
    if(currentProject.projectType !== projectType) {
      return true;
    }
    if (currentProject.projectType === constants.projectTypes.pensionOpeb) {
      return currentProject.name !== fieldName
        || currentProject.pensionPlanGroup !== fieldPensionPlanGroup
        || currentProject.opebPlanGroup !== fieldOpebPlanGroup;
    } else {
      return currentProject.name !== fieldName
        || currentProject.mersVersion !== fieldMersVersion
        || currentProject.mersEmployerId !== fieldMersEmployerId;
    }
  };

  if (!readyToRender)
    return <Spinner />;

  if (!projects)
    return <Spinner />;

  if (tenantId !== projectsList.tenantId)
    return <Spinner />;

  if (tenantId !== currentTenantId)
    return <Spinner />;

  if (!!projectId && projectId !== currentProjectId)
    return <Spinner />;

  let saveEnabled = false;

  if (projectType === constants.projectTypes.pensionOpeb) {
    saveEnabled = !saving
      && !!fieldName
      && (!!fieldPensionPlanGroup || !!fieldOpebPlanGroup)
      && !fieldNameError
      && !fieldPensionPlanGroupError
      && !fieldOpebPlanGroupError
      && isDirty();
  } else {
    saveEnabled = !saving
      && !!fieldName
      && (!!fieldMersVersion && !!fieldMersEmployerId)
      && !fieldNameError
      && !fieldMersVersionError
      && !fieldMersEmployerIdError
      && isDirty();
  }

  const addOrEditTitle = mode === 'add' ? 'Add' : 'Edit';

  return (
    <PageWrapper>
      <TitleRow>
        <ImageContainer>
          <TenantImage tenantId={tenantId} width='32px' />
        </ImageContainer>
        <TitleText>{addOrEditTitle} Project</TitleText>
        <SubTitle>
          {mode === 'edit' && <>Edit project {currentProject.name} (in {currentTenant.name})</>}
          {mode === 'add' && <>Add project (in {currentTenant.name})</>}
        </SubTitle>

      </TitleRow>

      <InputSection>

        <InputSectionRow>
          <InputSectionLabel className="col-3" style={{ fontSize: fontSizes.m }}>Project Information</InputSectionLabel>
        </InputSectionRow>

        <InputRow>
          <InputLabel className="col-2">Name</InputLabel>
          <InputWrapper className="col-6">
            <StyledInput isError={!!fieldNameError} type="text" disabled={saving}
              value={fieldName} onChange={handleNameChange} ></StyledInput>
          </InputWrapper>
          {!!fieldNameError && <ErrorLabel className="col-3">{fieldNameError}</ErrorLabel>}
        </InputRow>

        {
          mode === 'add' && (
            <InputRow>
              <InputLabel className="col-2">Project Type</InputLabel>
              <div className="col-6">
                <StyledSelect name="projectType" value={projectType} onChange={handleProjectTypeChange} disabled={saving}>
                  <option value="pensionOpeb">Pension/OPEB</option>
                  <option value="mers">MERS</option>
                </StyledSelect>
              </div>
            </InputRow>
          )
        }

        {
          mode === 'edit' && (
            <InputRow>
              <InputLabel className="col-2">Project Type:</InputLabel>
              <InputLabel className="col-2" style={{ justifyContent:'left', marginLeft:'20px' }}>{projectType === 'mers' ? 'MERS' : 'Pension/OPEB'}</InputLabel>
            </InputRow>
          )
        }

        <InputSectionRow>
          <InputSectionLabel className="col-3" style={{ fontSize: fontSizes.m }}>Project Configuration</InputSectionLabel>
        </InputSectionRow>

        {
          projectType === 'pensionOpeb' && (
            <>
              <InputRow>
                <InputLabel className="col-2">Pension Plan Group</InputLabel>
                <InputWrapper className="col-6">
                  <StyledInput isError={!!fieldPensionPlanGroupError} type="text" disabled={saving}
                    value={fieldPensionPlanGroup} onChange={handlePensionPlanGroupChange}></StyledInput>
                </InputWrapper>
                {!!fieldPensionPlanGroupError && <ErrorLabel className="col-3">{fieldPensionPlanGroupError}</ErrorLabel>}
              </InputRow>

              <InputRow>
                <InputLabel className="col-2">OPEB Plan Group</InputLabel>
                <InputWrapper className="col-6">
                  <StyledInput isError={!!fieldOpebPlanGroupError} type="text" disabled={saving}
                    value={fieldOpebPlanGroup} onChange={handleOpebPlanGroupChange}></StyledInput>
                </InputWrapper>
                {!!fieldOpebPlanGroupError && <ErrorLabel className="col-3">{fieldOpebPlanGroupError}</ErrorLabel>}
              </InputRow>
            </>
          )
        }

        {
          projectType === 'mers' && (
            <>
              <InputRow>
                <InputLabel className="col-2">MERS Version</InputLabel>
                <InputWrapper className="col-6">
                  <StyledInput isError={!!fieldMersVersionError} type="text" disabled={saving}
                    value={fieldMersVersion} onChange={handleMersVersionChange}></StyledInput>
                </InputWrapper>
                {!!fieldMersVersionError && <ErrorLabel className="col-3">{fieldMersVersionError}</ErrorLabel>}
              </InputRow>

              <InputRow>
                <InputLabel className="col-2">MERS Employer ID</InputLabel>
                <InputWrapper className="col-6">
                  <StyledInput isError={!!fieldMersEmployerIdError} type="text" disabled={saving}
                    value={fieldMersEmployerId} onChange={handleMersEmployerIdChange}></StyledInput>
                </InputWrapper>
                {!!fieldMersEmployerIdError && <ErrorLabel className="col-3">{fieldMersEmployerIdError}</ErrorLabel>}
              </InputRow>
            </>
          )
        }

        {saving && <SavingLabel>Saving...</SavingLabel>}

        <div>
          <ButtonsWrapper className="col-9">
            <StyledButton primary disabled={!saveEnabled} onClick={handleSave}>Save</StyledButton>
            <StyledButton onClick={handleCancel}>Cancel</StyledButton>
          </ButtonsWrapper>
        </div>

      </InputSection>
      {errorDialogState.visible &&
        <ErrorDialog dialogState={errorDialogState} open={errorDialogState.visible} onClose={handleCloseErrorDialog} />}
    </PageWrapper>
  )
}

const PageWrapper = styled.div`
  display:flex;
  flex-direction:column;
  padding: 10px;
  padding-left: 15px;
  margin-bottom: 0px;
`;

const TitleRow = styled.div`
  display:flex;
  flex-direction:row;
  padding: 10px;
  padding-left: 15px;
  margin-bottom: 0px;
`;

const SubTitle = styled.div`
  display:flex;
  align-items:center;
  font-size: var(--font-m);
  font-style: italic;
  padding-left:15px;
  padding-top:5px;
`;

const ImageContainer = styled.div`
  width: 32px;
  display: flex;
  align-items: center;
`;

const TitleText = styled.div`
  font-size: var(--font-l);
  padding: 15px;
`;

const InputSection = styled.div`
  font-size: var(--font-r);
  paadding: 10px;
  width: 900px;
  display: flex;
  flex-direction: column;
`;

const InputRow = styled.div`
`;

const InputSectionRow = styled.div`
  padding-top:10px;
  padding-bottom:10px;
`;

const InputSectionLabel = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 0px;
`;

const InputLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  text-align: right;
  height: 60px;
  padding:0px;
`;

const InputWrapper = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
`;

const StyledInput = styled.input`
  font-size: var(--font-r);
  padding: 8px;
  width: 100%;
  background: ${(p) => p.isError ? colors.redtransparent : ''};
`;

const ErrorLabel = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  paadding: 8px;
  colors: var(--color-red);
  font-size: var(--font-r);
`;

const SavingLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  padding: 5px;
  padding-left: 20px;
  font-style: italic;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  font-size: var(--font-m);
  margin-top: 10px
`;

const StyledButton = styled(Button)`
  margin: 5px;
  padding: 5px;
`;

const StyledSelect = styled.select`
  padding: 5px 2.5px;
  border-radius: 2.5px;
  margin-top:5px;
`;

export default AddEditProject;