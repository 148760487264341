// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
const createStatus = (all, s) => ({ ...all, [s]: () => s });
const status = ['failed', 'created', 'check', 'updated', 'deleted', 'targetExists', 'noSource', 'exists'].reduce(createStatus, {});
const activationStatus = ['active', 'inactive'].reduce(createStatus, {});

export const Constants = {
  status,
  activationStatus,
  changedStatus: ['created', 'updated', 'deleted'],
  formats: {
    PERCENT: 'percent',
    DOLLARS: 'dollars',
    SMDOLLARS: 'smdollars',
    MONEY: 'money',
    NUMBER: 'number',
    INTEGER: 'integer',
    ROUNDED: 'rounded',
    COMMA: 'comma',
    JSON: 'json',
  },
};

export const PLAN_GROUP_OPEB = 'opebPlanGroup';
export const PLAN_GROUP_PENSION = 'pensionPlanGroup';

export const projectTypes = {
  pensionOpeb: 'pensionOpeb',
  mers: 'mers',
};