// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
/*
* # Utility functions
* Grab bag of front and back end
*/

import numeral from 'numeral';
import validator from 'validator';
import _ from 'lodash';

import { Constants } from './constants.js';

const displayFormats = {
  [Constants.formats.PERCENT]: '(0.00%)',
  [Constants.formats.DOLLARS]: '$(0.00a)',
  [Constants.formats.SMDOLLARS]: '$(0.0a)',
  [Constants.formats.MONEY]: '$0,0.00',
  [Constants.formats.ROUND_DOLLARS]: '$(0a)',
  [Constants.formats.NUMBER]: '(0.0a)',
  [Constants.formats.INTEGER]: '(0,0)',
  [Constants.formats.ROUNDED]: '0.00',
  [Constants.formats.JSON]: 1,
};

// get a display format (uses numeral lib)
export function getFormat(t) {
  return displayFormats[t];
}

export function format(f, value) {
  if (!f || !displayFormats[f]) {
    console.error('undefined format', f);
    return 'NOFORMAT';
  }
  if (f === Constants.formats.JSON) {
    return JSON.stringify(value);
  }
  if (!f || !isNumber(value)) {
    return '';
  }

  return numeral(value)
    .format(displayFormats[f])
    .toUpperCase();
}

export function formatNamespace(v) {
  let namespace = v || '/';
  if (!namespace.match(/^\//)) {
    namespace = '/' + namespace;
  }
  if (!namespace.match(/\/$/)) {
    namespace += '/';
  }
  return namespace;
}

export function isNumber(x) {
  return x !== undefined && x !== null && x.toString().match(/^-?[.|\d]+(e-?\d+)?$/);
}

export function escape(html) {
  return validator.escape(html);
}

export function statusMessage(failed, entity, status) {
  return _.assign(
    {
      status: status || Constants.status.check(),
      failedValidation: failed,
      timestamp: Date.now(),
    },
    entity,
  );
}


// Project IDs
export function idStr(loc) {
  return loc && loc.name && loc.state ? loc.name.toLowerCase().replace(/ /g, '-') + '-' + loc.state.toLowerCase() : null;
}

export function displayObject(v, d) {
  if (typeof v === 'object') {
    if (Object.keys(v).length < 1) {
      process.stdout.write('{}');
    } else {
      Object.keys(v).forEach(k => {
        process.stdout.write(
          '\n' +
          Array(d)
            .fill('  ')
            .join('') +
          JSON.stringify(k) +
          ': ',
        );
        displayObject(v[k], d + 1);
      });
    }
  } else {
    process.stdout.write(JSON.stringify(v));
  }
}

export function scenarioLabel(seq) {
  return 'Scenario ' + seq;
}
