// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { range } from 'lodash';
import { connect } from 'react-redux';
import { toggle30YearModal } from '../actionCreators';

import './AdjustmentYearRates30.css';

const AdjustmentYearRates30 = ({ yearLabels, adjustmentYearData, toggle30YearModal, adjustmentName }) => {
  const handleEditClick = () => {
    toggle30YearModal(adjustmentName);
  }

  return (
    <div className="adjustment-year-rates-30">
      <table>
        <tbody>
        <tr>
          {range(0, 5).map(i => (
            <td key={i}>
              <div>{yearLabels[i]}</div>
              {adjustmentYearData.values[i].displayValue}
            </td>
          ))}
        </tr>
        </tbody>
      </table>
      <div>
        <span>30 years of input</span>
        <span className="pull-right" style={{ textDecoration: 'underline', paddingRight: '0.5em', cursor: 'pointer' }} onClick={handleEditClick}>
          Edit
        </span>
      </div>
    </div>
  );
};

export default connect(null, {toggle30YearModal})(AdjustmentYearRates30);
