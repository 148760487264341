// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { Alert, Modal, Button } from 'react-bootstrap';
import ReactDataSheet from 'react-datasheet';
import { connect } from 'react-redux';
import { show30YearModal, get30YearModalAdjustmentStructure, getModalRows, getModalInfo } from 'client/sideBar/selectors.js';
import { toggle30YearModal, setControlTo30Year } from '../actionCreators';
import _last from 'lodash/last';
import copy from 'copy-to-clipboard';
import numeral from 'numeral';
import './AdjustmentYearRates30.css';

const constructGrid = (rows, info, adjustmentStructure) => {
  const result = rows.map(e => [
    {
      value: e[0],
      disableEvents: true,
      readOnly: true,
    },
    {
      data: e[1].dataValue,
      value: e[1].displayValue,
    },
  ]);
  result.unshift([
    {
      value: info.yearColumnLabel,
      disableEvents: true,
      readOnly: true,
    },
    {
      value: adjustmentStructure.label,
    },
  ]);

  return result;
};

const gridContainsValidData = (grid, info) => {
  const rows = grid.map(e => info.validationFunc(e[1].data));
  // header row removed from validation checks
  rows.shift();
  return rows.every(e => e === true);
};

class YearRates30Modal extends React.Component {
  constructor() {
    super();
    this.state = {
      valid: true,
      grid: [
        [
          {
            value: 'As of July 12',
            disableEvents: true,
            readOnly: true,
          },
          {
            value: 'asdf',
          },
        ],
        [
          {
            value: 2017,
            disableEvents: true,
            readOnly: true,
          },
          {
            data: 0,
            value: 0,
          },
        ],
      ],
      errors: {},
    };

    // for e2e testing and debugging
    this.CancelButton.displayName = 'CancelButton';
    this.ApplyButton.displayName = 'ApplyButton';
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { rows, info, adjustmentStructure } = nextProps;
    if (rows && info && adjustmentStructure) {
      const { prevRows, prevInfo, prevAdjustmentStructure } = this.props;
      if (prevRows && prevInfo && prevAdjustmentStructure) {
        if (rows === prevRows && info === prevInfo && adjustmentStructure === prevAdjustmentStructure) {
          return;
        }
      }
      const grid = constructGrid(rows, info, adjustmentStructure);

      this.setState({
        grid,
        errors: {},
        valid: gridContainsValidData(grid, info),
      });
    }
  }

  handleClose = () => {
    this.setState({
      valid: true,
    });

    this.props.toggle30YearModal();
  };

  handleSetTo30Year = () => {
    const { setControlTo30Year, adjustmentStructure } = this.props;
    setControlTo30Year({ adjustmentName: adjustmentStructure.name, values: this.convertGridToAdjustmentValues() });
  };

  handleDataSheetChange = (cell, rowI, colJ, value) => {
    const result = this.onGridChange(cell, rowI, colJ, value, this.state.grid);

    this.setState({
      grid: result.grid,
      valid: result.valid,
    });
  };

  onGridChange = (cell, rowI, colJ, value, grid) => {
    const newGrid = grid.map(col =>
      col.map(rowCell => {
        if (rowCell !== cell) {
          return rowCell;
        }

        return this.props.info.cellValueAndDataFunc(value);
      }),
    );

    const valid = gridContainsValidData(newGrid, this.props.info);

    return {
      grid: newGrid,
      valid,
    };
  };

  handleParsePaste = clipboardData => {
    let result = clipboardData
      .split('\n')
      .filter(e => e !== '' && Number.isFinite(numeral(e).value()))
      .map(e => [e]);

    if (result.length >= 30) {
      return result;
    }
    for (let i = result.length; i < 30; ++i) {
      result.push(_last(result));
    }
    return result;
  };

  handleCopyToClipboard = () => {
    const copyValue = this.convertGridToExcelValues();
    const copyHandler = e => {
      e.clipboardData.setData('text/plain', copyValue);
      e.preventDefault();
    };
    document.addEventListener('copy', copyHandler);
    copy(copyValue);
    document.removeEventListener('copy', copyHandler);
  };

  convertGridToAdjustmentValues = () => {
    const values = this.state.grid.map(e => e[1].data);
    // header row removed from results
    values.shift();
    return values;
  }

  convertGridToExcelValues = () => {
    return this.convertGridToAdjustmentValues().join('\n');
  };

  // doing this so e2e test can tell between cancel and apply buttons via react component name
  CancelButton = () => (
    <Button onClick={this.handleClose}>Cancel</Button>
  );

  ApplyButton = () => (
    <span>
      <Button disabled={!this.state.valid} onClick={this.handleSetTo30Year} bsStyle="prom_apply">Apply</Button>
    </span>
  );

  render() {
    const { adjustmentStructure, show, info } = this.props;
    if (!(adjustmentStructure && show && info)) return <Modal/>;
    return (
      <Modal show={show} onHide={this.handleClose} aria-labelledby="Year rates input">
        <Modal.Header closeButton>
          <Modal.Title>30 years of {adjustmentStructure.label}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            <p>
              {info.inputMessage}
            </p>
            <p>
              <Button onClick={this.handleCopyToClipboard}>Copy all data to clipboard</Button>
            </p>
            <div className="modal-data-sheet">
              <ReactDataSheet
                data={this.state.grid}
                valueRenderer={cell => cell.value}
                onChange={this.handleDataSheetChange}
                dataRenderer={cell => cell.data}
                parsePaste={this.handleParsePaste}
              />
            </div>
            {!this.state.valid ? <Alert bsStyle="danger">Some values are invalid. {adjustmentStructure.errorMessage}</Alert> : null}
          </span>
        </Modal.Body>

        <Modal.Footer>
          <this.CancelButton />
          <this.ApplyButton />
        </Modal.Footer>
      </Modal>
    );
  }
}

const stateToProps = (state, ownProps) => {
  return {
    show: show30YearModal(state),
    adjustmentStructure: get30YearModalAdjustmentStructure(state),
    rows: getModalRows(state),
    info: getModalInfo(state),
  };
};

const dispatchToProps = {
  toggle30YearModal,
  setControlTo30Year,
};

export default connect(stateToProps, dispatchToProps)(YearRates30Modal);
