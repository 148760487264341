// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { connect } from 'react-redux';
import { Glyphicon, MenuItem } from 'react-bootstrap';
import GlyphDropdown from './GlyphDropdown';
import './ResetDropdown.css';
import * as constants from '../constants';
import { getViewingScenarioId } from '../selectors';
import { resetUserAdjustmentToDefaultValues, resetUserAdjustmentToScenario } from '../actionCreators';

const ResetDropdown = ({
  adjustmentName,
  resetUserAdjustmentToDefaultValues,
  resetUserAdjustmentToScenario,
  adjustmentStructure,
  viewingScenarioId,
  sideBar
}) => {
  const resetToDefault = () => {
    resetUserAdjustmentToDefaultValues(adjustmentStructure, sideBar);
  };
  const resetToViewingScenario = () => {
    resetUserAdjustmentToScenario({ viewingScenarioId, adjustmentStructure }, sideBar);
  };

  return (
    <GlyphDropdown pullRight dropdownCss={'reset-dropdown'} Glyph={<Glyphicon glyph="repeat" />}>
      <MenuItem href="http://"  eventKey={constants.SCENARIO_DEFAULT_ID} onSelect={resetToDefault} onClick={e => e.preventDefault()}>
        Reset to Default
      </MenuItem>
      <MenuItem href="http://" onClick={e => e.preventDefault()} eventKey="scenario" onSelect={resetToViewingScenario}>
        Reset to Scenario
      </MenuItem>
    </GlyphDropdown>
  );
};

const stateToProps = (state, ownProps) => ({
  sideBar: state.sideBar,
  viewingScenarioId: getViewingScenarioId(state),
});
const dispatchToProps = { resetUserAdjustmentToDefaultValues, resetUserAdjustmentToScenario };

export default connect(stateToProps, dispatchToProps)(ResetDropdown);
