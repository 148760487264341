import React from 'react';

function Footer() {
  return (
    <div id="footer">
      <div style={{width:'100%', textAlign: 'right', paddingRight: '10px', background: 'white'}}>
        Confidential&copy; {new Date().getFullYear()} GovInvest, Inc.
      </div>
    </div>
  );
}

export default Footer;