// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import { runScenarioButtonInfo } from './selectors';
import _isEqual from 'lodash/isEqual';

export const getFilterCategories = state => state.sideBar.filterCategories && state.sideBar.filterCategories;

export const getShowFilter = state => {
  const filterCategories = getFilterCategories(state);
  if (filterCategories && filterCategories.length > 0) {
    return filterCategories.some(category => category.items.length > 1);
  }
  return false;
};

export const getFilterApplyEnabled = state => {
  const hasDeviated = runScenarioButtonInfo(state).anyDeviateFromScenario;
  if (hasDeviated) return false;
  const { filters, filtersApplied } = state.sideBar;
  for(let filterKey of Object.keys(filters)) {
    const filter = filters[filterKey];
    const applied = filtersApplied[filterKey];
    if(!_isEqual(filter, applied))
      return true;
  }
  return false;
};
