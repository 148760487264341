// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import classNames from 'classnames';
import styled from 'styled-components';
import './GlyphDropdown.css';

const GlyphStyled = styled.a`
  color: ${props => props.dark ? '#253846 !important' : ''};
`;

class CustomToggle extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();

    this.props.onClick(e);
  }

  render() {
    return (
      <GlyphStyled onClick={this.handleClick} dark={this.props.dark}>
        {this.props.children}
      </GlyphStyled>
    );
  }
}

const GlyphDropdown = ({ onSelect, children, Glyph, dropdownCss, pullRight, dropup, dark }) => {
  return (
    <Dropdown
      style={{ verticalAlign: 'top' }}
      id="control-select"
      pullRight={pullRight}
      dropup={dropup}
      className={classNames('control-select', dropdownCss)}
      onSelect={onSelect}
    >
      <CustomToggle bsRole={'toggle'} dark={dark}>{Glyph}</CustomToggle>
      <Dropdown.Menu>{children}</Dropdown.Menu>
    </Dropdown>
  );
};

export default GlyphDropdown;
