// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import { rgb, hsl } from 'd3-color';
//yes! it's the one-and-only!
// import { scaleLinear } from 'd3-scale';

//caravan palette from https://slack-files.com/files-pri-safe/T0434N2DB-F0ASUA96D/palette-more.pdf?
export const c = {
  orange: '#F0A965',
  green: '#5BB372',
  blue: '#63B4D5',
  turquoise: '#6ACFE6',
  navy: '#566EA6', //'#566FA7'
  purple: '#695492',
  lightPurple: '#AD84D4',
  red: '#D66D6A',
  black: '#393E4D',
  yellow: '#F2EB66',
  brown: '#B5885B',
  lime: '#aacc3d', //'#A7FF00',
  grey: '#D9DDE5',
  medGrey: '#aaaaaa',
  darkGrey: '#222222',
  lightGrey: '#f2f2f2',
  mersBlue: '#3A3F56',
  mersGreen: '#00C89D',
  mersLightBlue: '#39a0bb',
  mersLightGreen: '#4c8587',
  scenario0: '#0099ff',
  scenario1: '#00cc00',
  scenario2: '#ff0000',
  scenario3: '#ffcc00',
  scenario4: '#ff66cc',
  scenario5: '#911ebf',
  scenario6: '#9999ff',
};

export function findByName(name) {
  return c[name];
}
// const scales = {
//   yellowBlue: scaleLinear().domain([0, 10]).range([c.turqoise, c.red])
// };

const series_mapping = {
  //comparisons (need to look different)
  0: '#0099ff',
  1: '#00cc00',
  2: '#ff0000 ',
  3: '#ffcc00 ',
  4: '#ff66cc ',
  5: '#911ebf ',
  6: '#9999ff',
  // 1: '#ff9933',
  // 2: '#993399',
  // 3: '#ff0000',
  // 4: '#00cc00',
  // 5: '#6758CB',
  // 6: '#C941C9'
  // 7: '#C76957',
  // 8: '#C7575E',
};

//series-id -> color
export function find(id) {
  if (series_mapping.hasOwnProperty(id)) {
    return series_mapping[id];
  }
  console.warn('missing color for ' + id);
  return c.grey;
}

//generate a new color for each new comparison
// export function adjustment(num) {
//   let colors = ['#668fda', '#9a93c9', '#8ce7d1', '#c78ec7', '#ece75f', '#e69660'];
//   if (colors[num]) {
//     return colors[num];
//   }
//   //repeat after a while
//   num = num % colors.length;
//   return colors[num];
// }

//use d3's magic to lighten the color, instead of using opacity
export function lighten(color) {
  let c = rgb(color);
  let obj = hsl(c);
  obj.l = obj.l * 1.27;
  if (obj.l > 1) {
    obj.l = 0.99;
  }
  return obj.toString();
}

// convert color hex codes to RGB with optional alpha
export const hexToRGB = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
};
