// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import EditScenario from './EditScenario';
import RunScenario from './RunScenario';

const ScenarioTools = props => {
  const { isEditingScenario } = props;
  return (
    <div>
      <div className="container-fluid">
        {!isEditingScenario && <RunScenario />}
        <br />
        {isEditingScenario && <EditScenario />}
      </div>
    </div>
  );
};

export default ScenarioTools;
