// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import * as constants from './constants';
import units from './units';
import { calculateDefaultValues } from './adjustmentDefaults';
import _flatten from 'lodash/flatten';
import _isEqual from 'lodash/isEqual';
import _find from 'lodash/find';
// SHARED FUNCTIONS
export const getViewingScenario = sideBar => sideBar.scenarios[sideBar.viewingScenario];

export const getViewingScenarioValues = (sideBar, adjustmentName) => {
  if (!sideBar.viewingScenario || sideBar.viewingScenario === constants.SCENARIO_DEFAULT_ID) {
    const adjustmentStructure = sideBar.adjustmentStructure[adjustmentName];
    return calculateDefaultValues(adjustmentStructure, sideBar);
  }

  if (sideBar.viewingScenario && sideBar.viewingScenario !== constants.SCENARIO_DEFAULT_ID) {
    const viewingScenario = getViewingScenario(sideBar);
    if (!viewingScenario.adjustments[adjustmentName]) {
      // start fix
      const adjustmentStructure = sideBar.adjustmentStructure[adjustmentName];
      const calulatedValues = calculateDefaultValues(adjustmentStructure, sideBar);
      return calulatedValues;
      // end fix
      // return true;
    }
  }

  return getViewingScenario(sideBar).adjustments[adjustmentName];
};

export const isAdjustmentAssumption = (sideBar, adjustmentName) => {
  const structure = sideBar.adjustmentStructure[adjustmentName];
  const defaults = structure.defaults;
  return Array.isArray(defaults) && defaults.length > 0;
};

export const getGlobalCategories = sideBar => sideBar.categories.filter(e => !e.categoryItems || e.categoryItems.length === 0);

export const constructValidationFunc = (sideBar, adjustmentName) => {
  const structure = sideBar.adjustmentStructure[adjustmentName];
  const result = inputValue => {
    let isValid = false;

    // assumptions do not allow empty values, experiences do
    if (isAdjustmentAssumption(sideBar, adjustmentName) && inputValue === null) {
      return false;
    }

    if (inputValue === null) {
      return true;
    }

    if (Number.isFinite(inputValue) && inputValue >= structure.min && inputValue <= structure.max) {
      isValid = true;
    }
    return isValid;
  };

  return result;
};

export const determineAdjustmentYearDataUnit = (sideBar, adjustmentName) => {
  const structure = sideBar.adjustmentStructure[adjustmentName];
  let result = units['%'];
  if (structure && structure.type === constants.TYPE_YEAR_DOLLAR) {
    const dollarScale = sideBar.planGroupsInfo.dollarScale;
    result = units[dollarScale];
  }
  if (result === undefined)
    console.log('returning null', structure, sideBar.planGroupsInfo);
  return result;
};

export const getControlValue = (sideBar, adjustmentName) => {
  const showSlider = sideBar.adjustmentStructure[adjustmentName].showSlider;
  const data = sideBar.adjustmentsMadeByUser[adjustmentName];

  if (data === undefined) return null;

  if (data.length > 0) {
    const allValuesEqualToFirstOrNull = data.every(e => e === null || e === data[0]);
    if (
      !sideBar.adjustmentControls[adjustmentName] ||
      !sideBar.adjustmentControls[adjustmentName].control ||
      sideBar.adjustmentControls[adjustmentName].control === 'default'
    ) {
      return showSlider && allValuesEqualToFirstOrNull ? constants.CONTROL_SLIDER : constants.CONTROL_5YEAR;
    }
  }

  return sideBar.adjustmentControls[adjustmentName].control;
};

export const getGlobalAdjustmentNames = sideBar => {
  const categories = getGlobalCategories(sideBar);
  return getAdjustmentNamesForCategories(sideBar, categories);
};

export const getGlobalAdjustmentNamesForCategory = (sideBar, categoryName) => {
  const categories = getGlobalCategories(sideBar).filter(x => x.name === categoryName);
  return getAdjustmentNamesForCategories(sideBar, categories);
};

const getAdjustmentNamesForCategories = (sideBar, categories) => {
  const adjustmentNames = [];
  for(let category of categories) {
    if (!category.adjustmentGroupNames) {
      continue;
    }
    const result = category.adjustmentGroupNames.map(adjustmentGroupName => sideBar.adjustmentGroups[adjustmentGroupName].adjustmentNames);
    const flattened = _flatten(result);
    adjustmentNames.push(...flattened);
  }

  return adjustmentNames;
};

export const anyDeviateFromDefault = (sideBar, adjustmentNamesWithin) => {
  return adjustmentNamesWithin.some(adjustmentName => {
    const userValues = sideBar.adjustmentsMadeByUser[adjustmentName];
    // no user adjustments implies no deviation
    if (!userValues) {
      return false;
    }
    const adjustmentStructure = sideBar.adjustmentStructure[adjustmentName];
    const defaultValues = calculateDefaultValues(adjustmentStructure);
    return !_isEqual(userValues, defaultValues);
  });
};

export const anyDeviateFromViewingScenario = (sideBar, adjustmentNamesWithin) => {
  return adjustmentNamesWithin.some(adjustmentName => {
    const userValues = sideBar.adjustmentsMadeByUser[adjustmentName];
    // no user adjustments implies no deviation, unless you are an advanced control like fresh starts or custom adp
    const adjustmentType = sideBar.adjustmentStructure[adjustmentName].type;
    if (
      !userValues &&
      adjustmentName &&
      adjustmentType !== constants.TYPE_FRESH_START &&
      adjustmentType !== constants.TYPE_CUSTOM_ADP_TIMING) {
      return false;
    }
    const viewingScenarioValues = getViewingScenarioValues(sideBar, adjustmentName);
    return !_isEqual(userValues, viewingScenarioValues);
  });
};

export const getCategoryItemAdjustmentNames = (sideBar, categoryName, categoryItemName) => {
  const category = _find(sideBar.categories, e => e.name === categoryName);
  const categoryItem = _find(category.categoryItems, e => e.name === categoryItemName);
  const result = categoryItem.adjustmentGroupNames.map(adjustmentGroupName => sideBar.adjustmentGroups[adjustmentGroupName].adjustmentNames);
  return _flatten(result);
};

export const getCategoryAdjustmentNames = (sideBar, categoryName) => {
  const globalCategoryNames = sideBar.categories.filter(x => !x.categoryItems || x.categoryItems.length === 0)
    .filter(x => x.name === categoryName)
    .map(x => x.name);
  if (globalCategoryNames.indexOf(categoryName) !== -1) {
    return getGlobalAdjustmentNamesForCategory(sideBar, categoryName);
  }

  const category = _find(sideBar.categories, e => e.name === categoryName);
  if (!category) {
    return [];
  }
  const result = category.categoryItems.map(e => getCategoryItemAdjustmentNames(sideBar, categoryName, e.name));
  return _flatten(result);
};
