// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import _pick from 'lodash/pick';
import * as constants from './constants';
import numeral from 'numeral';
import units from './units';

//SHARED HELPER FUNCTIONS SHARED BETWEEN SELECTORS
import { constructValidationFunc, determineAdjustmentYearDataUnit, getControlValue } from './selectorsSharedHelpers';

export const show30YearModal = ({ sideBar }) => {
  return sideBar.show30YearModal;
};

export const showWillLoseDataModal = ({ sideBar }) => {
  return sideBar.showWillLoseDataModal;
};

export const getWillLoseDataModalAdjustmentStructure = ({ sideBar }) => {
  const rawStructure = sideBar.adjustmentStructure[sideBar.willLoseDataModalAdjustmentName];
  return _pick(rawStructure, ['name', 'label']);
};

export const getModalInfo = state => {
  if (!state.sideBar.year30ModalAdjustmentName) return null;
  const props = { adjustmentName: state.sideBar.year30ModalAdjustmentName };
  const result = {
    validationFunc: constructValidationFunc(state.sideBar, props.adjustmentName),
    yearColumnLabel: getYearColumnLabel(state, props),
    cellValueAndDataFunc: constructCellValueAndDataFunc(state.sideBar, props.adjustmentName),
    inputMessage: constructInputMessage(state.sideBar, props.adjustmentName),
  };

  return result;
};

export const willLoseDataOnControlChange = ({ sideBar }, { adjustmentName }) => {
  const currentControl = getControlValue(sideBar, adjustmentName);
  const result = eventKey => {
    if (currentControl === constants.CONTROL_SLIDER || currentControl === constants.CONTROL_5YEAR) {
      return false;
    }
    if (currentControl === constants.CONTROL_30YEAR) {
      return true;
    }
    if (currentControl === constants.CONTROL_10YEAR && eventKey === constants.CONTROL_30YEAR) {
      return false;
    }
    return true;
  };

  return result;
};

export const getGlobalCategories = ({ sideBar }) => {
  if(!sideBar.categories || Object.entries(sideBar.categories).length === 0)
    return {};
  return sideBar.categories.filter(e => !e.categoryItems || e.categoryItems.length === 0);
};

export const getNonGlobalCategories = ({ sideBar }) => {
  if(!sideBar.categories || Object.entries(sideBar.categories).length === 0)
    return {};
  return sideBar.categories.filter(e => e.categoryItems && e.categoryItems.length > 0);
};

export const getCategoryItems = ({ sideBar }, { name }) => {
  if(!sideBar.categories)
    return {};
  const category = sideBar.categories.find(e => e.name === name);
  if (!category) {
    return [];
  }
  return category.categoryItems;
};

export const getScenarioEditModalInfo = ({ sideBar }) => {
  let scenario = sideBar.scenarios[sideBar.scenarioLabelEditModal];
  if (scenario) {
    return { show: true, scenarioId: scenario.scenarioId, label: scenario.label };
  } else {
    return { show: false, scenarioId: null, label: '' };
  }
};

// PRIVATE FUNCTIONS

const getCellPercentValueAndData = value => {
  const dataToDisplay = units['%'].dataToDisplay;
  if (!value) {
    return { value: '', data: null };
  }

  // need to use numeral to convert inputs with % correctly
  let valueNumeral = numeral(value);
  //numeraljs documentation is wrong - check for null rather than NaN
  if (value !== '' && valueNumeral.value() === null) {
    return { value, data: null };
  }

  let newValue = dataToDisplay(valueNumeral.value());
  return { value: newValue, data: valueNumeral.value() };
};

const getCellDollarValueAndData = (value, displayToData) => {
  if (!value) {
    return { value: '', data: null };
  }

  // need to use numeral to convert inputs with % correctly
  let valueNumeral = numeral(value);
  //numeraljs documentation is wrong - check for null rather than NaN
  if (value !== '' && valueNumeral.value() === null) {
    return { value, data: null };
  }

  let newValue = displayToData(valueNumeral.value());
  return { value: valueNumeral.value(), data: newValue };
};

const constructInputMessage = (sideBar, adjustmentName) => {
  const percentMessage =
    'Double-click to input values for individual years.  To copy and paste from Excel, or to repeat a copied value for the remaining input period, click once on the starting value and paste using the keyboard (Ctrl-V).';
  const dollarMessage = 'To paste values from Excel, or to repeat a copied value for the remaining input period, click on the first value and paste using the keyboard (Ctrl-V).';

  return sideBar.adjustmentStructure[adjustmentName].type === constants.TYPE_YEAR_PERCENT ? percentMessage : dollarMessage;
};

const constructCellValueAndDataFunc = (sideBar, adjustmentName) => {
  const displayToData = determineAdjustmentYearDataUnit(sideBar, adjustmentName).displayToData;
  let result;
  if (sideBar.adjustmentStructure[adjustmentName].type === constants.TYPE_YEAR_PERCENT) {
    result = inputValue => getCellPercentValueAndData(inputValue);
  } else {
    result = inputValue => getCellDollarValueAndData(inputValue, displayToData);
  }

  return result;
};

const getYearColumnLabel = ({ sideBar }, { adjustmentName }) => {
  // simplify the logic for now, as below may not be correct. Approved by Kelly to use static label. Do not remove this method as requirements are fluid.
  // const structure = sideBar.adjustmentStructure[adjustmentName];
  // const startDate =
  //   structure.indexType === constants.INDEX_TYPE_FISCALYEAR ? sideBar.planGroupsInfo.fiscalYear1Start : sideBar.planGroupsInfo.valuationYear1Start;
  //
  // return `As of ${moment(startDate).format('MMMM D')}`;
  return 'Year';
};
