// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import prettyPrint from 'common/pretty_print';
import { getValues, getBaselineValues } from './getValues';

const areaChart = (obj, info, already, negAlready, baseAlready, baseNegAlready, scales, cmp, order) => {
  let values = getValues(obj, info);
  const baselineValues = getBaselineValues(obj, info);
  return values.map(function(val, i) {
    const baselineValue = baselineValues[i];
    already[i] = already[i] || 0;
    baseAlready[i] = baseAlready[i] || 0;
    const bottom = already[i];
    const baseBottom = baseAlready[i];
    const year = info.valuationYear + i;
    const yScaled = scales.y(val + bottom);
    const baseYScaled = scales.y(baselineValue + baseBottom);
    const showArrow = Math.abs(yScaled - baseYScaled) > 7;
    let orderResult;
    if (cmp && year === cmp.state.onYear && showArrow) {
      orderResult = order;
    } else {  
      orderResult = null;
    }
 
    let r = {
      value: val,
      baseValue: baselineValue,
      x: scales.x(year),
      y: yScaled,
      baseY: scales.y(baselineValue + baseBottom),
      bottom: scales.y(bottom),
      baseBottom: scales.y(baseBottom),
      year: year,
      label: prettyPrint(val, obj.format),
      order: orderResult,
    };

    already[i] += val;
    baseAlready[i] += baselineValue;

    return r;
  });
}

export default areaChart;
