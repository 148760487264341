// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
//
export const getValues = (obj, info) => {
  let values = obj.adjustments.find(a => a.isCurrent === true).values;
  values = values.slice(0, info.endIndex + 1);
  values = values.map(val => {
    val = val || 0;
    if (isNaN(val)) {
      val = 0;
    }
    return val;
  });
  return values;
};

export const getBaselineValues = (obj, info) => {
  let baselineAdjustments = obj.adjustments.find(a => a.isBaseline === true);
  // baselineAdjustments temporarily undefined during calculations where years projected were increased while in compare mode
  // return getValues to prevent null from cascading into baseline compute values
  if (!baselineAdjustments) {
    return getValues(obj, info);
  }
  let values = baselineAdjustments.values;
  values = values.slice(0, info.endIndex + 1);
  values = values.map(val => {
    val = val || 0;
    if (isNaN(val)) {
      val = 0;
    }
    return val;
  });
  return values;
};
