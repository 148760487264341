// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';

export default function giEqualizer(props) {
  return (
  	<svg
  		xmlns="http://www.w3.org/2000/svg"
  		version="1.1"
  		id="Layer_1"
  		x="0px"
  		y="0px"
      viewBox="0 0 20 20"
      style={{ enableBackground: "0 0 25 25" }}
  		width={25}
      height={25}
  		{...props}
  	>
      <path
    		d="M13,7.998h-1v-1h-1v1H3c-0.276,0-0.5,0.225-0.5,0.5c0,0.276,0.224,0.501,0.5,0.501h8v0.999h1V8.999h1   c0.275,0,0.5-0.225,0.5-0.501C13.5,8.223,13.275,7.998,13,7.998z M13,11.999H5v-1.001H4v1.001H3c-0.276,0-0.5,0.224-0.5,0.499   c0,0.276,0.224,0.501,0.5,0.501h1v0.999h1v-0.999h8c0.275,0,0.5-0.225,0.5-0.501C13.5,12.223,13.275,11.999,13,11.999z M13,3.999H8   V2.998H7v1.001H3c-0.276,0-0.5,0.224-0.5,0.5c0,0.275,0.224,0.5,0.5,0.5h4v1h1v-1h5c0.275,0,0.5-0.225,0.5-0.5   C13.5,4.223,13.275,3.999,13,3.999z M14.5-0.001h-13C0.671-0.001,0,0.67,0,1.498v13c0,0.829,0.671,1.501,1.5,1.501h13   c0.828,0,1.5-0.672,1.5-1.501v-13C16,0.67,15.328-0.001,14.5-0.001z M15,14.498c0,0.276-0.225,0.501-0.5,0.501h-13   c-0.276,0-0.5-0.225-0.5-0.501v-13c0-0.275,0.224-0.499,0.5-0.499h13c0.275,0,0.5,0.224,0.5,0.499V14.498z"
    	/>
    </svg>
  );
}
