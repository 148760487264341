// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import urlJoin from 'url-join';
import { keyFromArray } from 'client/lib/app-general.js';
import { getJSON } from 'lib/network.js';
import config from '../../lib/client-config';

export const NAV = 'NAV';
export const PROJECT_DETAILS = 'PROJECT_DETAILS';
export const PROJECT_PLAN_TYPE_DETAILS = 'PROJECT_PLAN_TYPE_DETAILS';
export const DATA_RETRIEVAL = 'DATA_RETRIEVAL';
export const DATA_REQUEST_STATUS = 'DATA_REQUEST_STATUS';

export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const SET_YEAR_PARAMS = 'SET_YEAR_PARAMS';

export const TOGGLE = 'TOGGLE';

export const DOWNLOAD_SELECTION = 'DOWNLOAD_SELECTION';

// profile and account
export const CHANGE_PASSWORD_RESULT = 'CHANGE_PASSWORD_RESULT';

export function setErrorMessage(result) {
  return { type: SET_ERROR_MESSAGE, result };
}

export function requestProject(planType) {
  return (dispatch, getState) => {
    return getJSON('project').then(project => {
      dispatch(projectDetails(project));
    });
  };
}

export function requestProjectPlanTypeDetails(planType) {
  return (dispatch, getState) => {
    const workspaceInfo = getState().workspace.info;
    const uri = urlJoin(config.baseUrl, `/webapi/projects/${workspaceInfo.projectId}`);
    return getJSON(uri)
      .catch(err => {
        console.log('error', err);
        const errorMessage = 'Error in requesting project details.';
        window.appInsights.trackException(new Error(errorMessage), 'app.js');
        dispatch(setErrorMessage(errorMessage));
      })
      .then(project => {
        dispatch(projectPlanTypeDetails(project, planType));
        return project;
      });
  };
}

export function projectDetails(project) {
  return { type: PROJECT_DETAILS, result: { project } };
}

export function projectPlanTypeDetails(project, planType) {
  return { type: PROJECT_PLAN_TYPE_DETAILS, result: { project, planType } };
}

export function setYearParams(name, value) {
  return (dispatch, getState) => {
    if (name === 'y') {
      return dispatch({
        type: SET_YEAR_PARAMS,
        result: {
          name,
          value: parseInt(value, 10),
        },
      });
    }
    if (name === 'pinned') {
      return dispatch({
        type: SET_YEAR_PARAMS,
        result: {
          name,
          value,
        },
      });
    }
    ;
  }
}

export function toggleDownloadSelection(...what) {
  const key = keyFromArray(what.reduce((all, a) => (a !== undefined ? [...all, a] : all), []));
  return (dispatch, getState) => {
    const downloadUnchecked = { ...getState().viewSummary.downloadUnchecked };
    if (downloadUnchecked[key]) {
      delete downloadUnchecked[key];
    } else {
      downloadUnchecked[key] = true;
    }
    dispatch({
      type: DOWNLOAD_SELECTION,
      result: downloadUnchecked,
    });
  };
}