// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import moment from 'moment';

export const getValuationYear2 = state => {
  return moment(state.sideBar.planGroupsInfo.fiscalYear1Start).year();
};

export const getFiscalYear1StartLabel2 = state => {
  const fiscalYear1Start = state.sideBar.planGroupsInfo.fiscalYear1Start;
  return moment(fiscalYear1Start).format('MMMM D');
};

export const getValuationYear1StartLabel2 = state => {
  const valuationYear1Start = state.sideBar.planGroupsInfo.valuationYear1Start;
  return moment(valuationYear1Start).format('MMMM D');
};

export const getFiscalYear1StartDifferentYearThanEnd2 = state => {
  const fiscalYear1Start = state.sideBar.planGroupsInfo.fiscalYear1Start;
  const fiscalYear1End = state.sideBar.planGroupsInfo.fiscalYear1End;
  return moment(fiscalYear1Start).year() !== moment(fiscalYear1End).year();
};

export const getValuationYear1StartDifferentYearThanEnd2 = state => {
  const valuationYear1Start = state.sideBar.planGroupsInfo.valuationYear1Start;
  const valuationYear1End = state.sideBar.planGroupsInfo.valuationYear1End;
  return moment(valuationYear1Start).year() !== moment(valuationYear1End).year();
};

export const getFiscalYear1EndLabel2 = state => {
  const fiscalYear1End = state.sideBar.planGroupsInfo.fiscalYear1End;
  return moment(fiscalYear1End).format('MMMM D');
};

export const getValuationYear1EndLabel2 = state => {
  const valuationYear1End = state.sideBar.planGroupsInfo.valuationYear1End;
  return moment(valuationYear1End).format('MMMM D');
};