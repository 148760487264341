import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { colors } from '../style/colors';

const styles = theme => ({
  progress: {
    color: colors.themePrimary,
    margin: theme.spacing(2),
  },
});

function Spinner(props) {
  const { classes } = props;
  return (
      <div className="row">
        <div style={{ color: 'red', display: 'flex', marginBottom: '10px', width: '100%', alignItems:'center' }}>
            <CircularProgress
              className={classes.progress}
              size={60}
              thickness={props.thickness}
              style={{ margin:'auto', marginTop:'20%', color: `${props.color}` }}
            />
        </div>
      </div>
  );
}
Spinner.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Spinner);
