// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import { baseCharts } from '../lib/chart-specs';
import { SCENARIO_DEFAULT_ID } from '../sideBar/constants';
import cloneDeep from 'lodash/cloneDeep';
import { PROJECT_PLAN_TYPE_DETAILS } from "../actions";

const defaultState = {
  initialized: false,
  selectedView: {
    planType: '',
    topic: '',
    chart: '',
    view: '',
    tab: 'current',
  },
  topics: [],
  charts: [],
  views: [],
  allCharts: baseCharts,
  availCharts: {},
  chartRulesCache: {},
  baselineScenario: SCENARIO_DEFAULT_ID,
  chartSize: {},
  compareWithBaseline: true,
  hiddenTopicChartSeries: [],
};

const charts = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_PLAN_TYPE': {
      let newState = {
        ...state,
        selectedView: {
          ...state.selectedView,
          planType: action.payload,
          tab: 'current',
        }
      };
      return updateStateFromNewState(newState);
    }
    case PROJECT_PLAN_TYPE_DETAILS: {
      return { ...state, initialized: true };
    }
    case 'TOGGLE_DATA_SERIES': {
      const { topic, chart, series } = action.payload;
      let newHiddenTopicChartSeries = [...state.hiddenTopicChartSeries];

      const seriesIsHidden = newHiddenTopicChartSeries.find(hiddenSeries => {
        const topicSelected = hiddenSeries.topic === topic;
        const chartSelected = hiddenSeries.chart === chart;
        const seriesSelected = hiddenSeries.series === series;

        return (topicSelected && chartSelected && seriesSelected);
      });

      if (seriesIsHidden) {
        newHiddenTopicChartSeries = newHiddenTopicChartSeries.filter(hiddenSeries => {
          const topicSelected = hiddenSeries.topic === topic;
          const chartSelected = hiddenSeries.chart === chart;
          const seriesSelected = hiddenSeries.series === series;

          return !(topicSelected && chartSelected && seriesSelected);
        });
      } else {
        newHiddenTopicChartSeries.push({
          topic: topic,
          chart: chart,
          series: series,
        })
      }

      return {
        ...state,
        hiddenTopicChartSeries: newHiddenTopicChartSeries,
      }
    }
    case 'RESET_HIDDEN_DATA_SERIES': {
      return {
        ...state,
        hiddenTopicChartSeries: [],
      }
    }
    case 'SET_VIEWING_SCENARIO': {
      return {
        ...state,
      }
    }
    case 'CALCULATION_COMPLETED': {
      let newState = {
        ...state,
        chartRulesCache: {
          ...state.chartRulesCache,
          default: action.payload.calcResponse.chartRules || {},
        },
      };
      return updateStateFromNewState(newState);
    }
    case 'SET_BASELINE_SCENARIO': {
      let newState = {
        ...state,
        baselineScenario: action.payload.scenarioId,
      };
      return updateStateFromNewState(newState);
    }
    case 'SET_TOPIC': {
      let newState = {
        ...state,
        selectedView: {
          ...state.selectedView,
          topic: action.payload,
          tab: 'current',
        },
      };
      return updateStateFromNewState(newState);
    }
    case 'SET_CHART': {
      let newState = {
        ...state,
        selectedView: {
          ...state.selectedView,
          chart: action.payload,
          tab: 'current',
          chartXType: chartXType(state, state.selectedView.topic, action.payload),
        },
        hiddenTopicChartSeries: [],
      };
      return updateStateFromNewState(newState);
    }
    case 'SET_TOPIC_AND_CHART': {
      let newState = {
        ...state,
        selectedView: {
          ...state.selectedView,
          topic: action.payload.topic,
          chart: action.payload.chart,
          tab: 'current',
          chartXType: chartXType(state, action.payload.topic, action.payload.chart),
        },
        hiddenTopicChartSeries: [],
      };
      return updateStateFromNewState(newState);
    }
    case 'SET_VIEW': {
      let newState = {
        ...state,
        selectedView: {
          ...state.selectedView,
          view: action.payload,
        },
      };
      return updateStateFromNewState(newState);
    }
    case 'SET_TAB': {
      let newState = {
        ...state,
        selectedView: {
          ...state.selectedView,
          tab: action.payload,
        },
        hiddenTopicChartSeries: [],
      };
      return updateStateFromNewState(newState);
    }
    case 'SET_CHART_SIZE': {
      return {
        ...state,
        chartSize: action.payload,
      };
    }
    case 'SET_LEGEND_SIZE': {
      return {
        ...state,
        legendSize: action.payload,
      };
    }
    case 'TOGGLE_COMPARE_WITH_BASELINE': {
      return {
        ...state,
        compareWithBaseline: !state.compareWithBaseline,
      }
    }
    default:
      return state;
  }
};

export default charts;

function chartXType(state, topic, chartName) {
  const planType = state.selectedView.planType;
  const chart = state.availCharts[planType][topic][chartName];
  return chart.xType;
}

function updateStateFromNewState(clonedState) {
  const planType = clonedState.selectedView.planType;
  const allCharts = clonedState.allCharts;
  const chartRules = clonedState.chartRulesCache['default'] || {};
  const adjustedCharts = adjustCharts(allCharts, chartRules); // renames and the like...
  const availCharts = filterCharts(adjustedCharts, chartRules);

  // do I have a valid topic?
  clonedState.topics = Object.keys(availCharts[planType]);
  if (!clonedState.topics.includes(clonedState.selectedView.topic)) {
    clonedState.selectedView = {
      ...clonedState.selectedView,
      topic: clonedState.topics[0]
    };
  }
  let { topic: resolvedTopic } = clonedState.selectedView;

  // do I have a valid chart?
  clonedState.charts = Object.keys(availCharts[planType][resolvedTopic]);
  if (!clonedState.charts.includes(clonedState.selectedView.chart)) {
    clonedState.selectedView = {
      ...clonedState.selectedView,
      chart: clonedState.charts[0]
    };
  }
  let { chart: resolvedChart } = clonedState.selectedView;

  // do I have a valid view?
  clonedState.views = Object.keys(availCharts[planType][resolvedTopic][resolvedChart].views);
  if (!clonedState.views.includes(clonedState.selectedView.view)) {
    clonedState.selectedView = {
      ...clonedState.selectedView,
      view: clonedState.views[0]
    };
  }

  clonedState.availCharts = availCharts;
  clonedState.viewButtons = buildViewButtons(clonedState.selectedView, clonedState.views);

  return clonedState;
}

function getTestValue(ruleObject) {
  if (ruleObject === true)
    return true;
  else if (ruleObject === false)
    return false;
  else if (ruleObject.value === undefined)
    return true;
  else
    return ruleObject.value;
}

function getDefaultValue(ruleObject) {
  if (isOverrideRule(ruleObject))
    return undefined;
  if (ruleObject === true)
    return false;
  else if (ruleObject === false)
    return false;
  else if (ruleObject.defaultValue === undefined)
    if (ruleObject.value === undefined)
      return true;
    else
      return ruleObject.value;
  else
    return ruleObject.defaultValue;
}

function isOverrideRule(ruleObject) {
  return ruleObject.isOverride === true;
}

function matchesChartRules(objectChartRules, planChartRules) {
  let objectKeys = Object.keys(objectChartRules);
  let nonOverrideResult = true;
  let overrideResult = null;
  for (let r of objectKeys) {
    let ro = objectChartRules[r];
    let testValue = getTestValue(ro);
    let defaultValue = getDefaultValue(ro);
    let isOverride = isOverrideRule(ro);

    let v = planChartRules[r];
    if (v === undefined)
      v = defaultValue;

    // if after applying the default to undefined things, it is still undefined...  don't test this rule.
    if (v === undefined)
      continue;

    if (testValue === true) {
      // positive rule
      // if chartRules from calc has either undefined or 'true', let it pass.
      if (v === false) {
        nonOverrideResult = false;
        if (isOverride)
          overrideResult = false;
      } else if (isOverride && overrideResult === null) {
        overrideResult = true;
      }
    } else if (testValue === false) {
      // negative rule
      // if chartRules from calc has either undefined or 'false', let it pass.
      if (v === true) {
        nonOverrideResult = false;
        if (isOverride)
          overrideResult = false;
      } else if (isOverride && overrideResult === null) {
        overrideResult = true;
      }
    }
  }
  return (overrideResult === null && nonOverrideResult) || (overrideResult === true);
}

function renameKey(dictionary, keyBefore, keyAfter) {
  let renamed = null;
  Object.keys(dictionary).forEach((k) => {
    let entry = dictionary[k];
    delete dictionary[k];
    if (k === keyBefore) {
      dictionary[keyAfter] = entry;
      renamed = entry;
    }
    else
      dictionary[k] = entry;
  });
  return renamed;
}

function renameSeries(chart, titleBefore, titleAfter) {
  Object.keys(chart.defaultSeries).forEach(series => {
    let seriesSpec = chart.defaultSeries[series];
    if (seriesSpec.title === titleBefore)
      seriesSpec.title = titleAfter;
  })
}

function adjustCharts(allCharts, chartRules) {
  // so far... only calPERS has special rules.
  if (!chartRules.isCalPERS)
    return allCharts;
  let result = cloneDeep(allCharts);
  let cost = result['pension']['Cost'];

  let arc = renameKey(cost, 'Actuarial Recommended Contribution', 'Required Employer Contribution');
  if (arc) {
    renameSeries(arc, 'Total Employer Recommended Contribution', 'Total Employer Required Contribution');
  }

  let arcPct = renameKey(cost, 'Actuarial Recommended Contribution - Percent of Pay', 'Required Employer Contribution - Percent of Pay');
  if (arcPct) {
    renameSeries(arcPct, 'Total Employer Recommended Contribution', 'Total Employer Required Contribution');
  }

  return result;
}

function filterCharts(allCharts, chartRules) {
  let result = cloneDeep(allCharts);
  Object.keys(result).forEach(planType => {
    let planTypeSpec = result[planType];
    Object.keys(planTypeSpec).forEach(topic => {
      let topicSpec = planTypeSpec[topic];
      Object.keys(topicSpec).forEach(chart => {
        let chartSpec = topicSpec[chart];
        if (chartSpec.chartRules && !matchesChartRules(chartSpec.chartRules, chartRules)) {
          delete topicSpec[chart];
        } else {
          Object.keys(chartSpec.defaultSeries).forEach(series => {
            let seriesSpec = chartSpec.defaultSeries[series];
            if (seriesSpec.chartRules && !matchesChartRules(seriesSpec.chartRules, chartRules)) {
              delete chartSpec.defaultSeries[series];
            }
          });
          if (Object.keys(chartSpec.defaultSeries).length === 0) {
            delete topicSpec[chart];
          } else {
            Object.keys(chartSpec.views).forEach(v => chartSpec.views[v].series = chartSpec.defaultSeries);
          }
        }
      });
      if (Object.keys(topicSpec).length === 0) {
        delete planTypeSpec[topic];
      }
    });
  });
  return result;
}

function buildViewButtons(selectedView, views) {
  // what should view buttons be?  There's some magic here if we're comparing.
  let viewButtons = views.map(v => {
    return { view: v, iconToShow: v }
  });
  if (selectedView.tab !== 'current') {
    viewButtons = [];
    let view = selectedView.view;
    // 1. make sure there's at least a 'line' view - either first non-table, or current.
    if (view === 'table') {
      const firstNonTableView = views.find(v => v !== 'table');
      if (firstNonTableView) {
        viewButtons.push({
          view: firstNonTableView,
          iconToShow: 'line'
        });
      }
    } else {
      const currentView = views.find(v => v === view);
      if (currentView) {
        viewButtons.push({
          view: currentView,
          iconToShow: 'line'
        });
      }
    }
    // 2. next, add table if applicable.
    if (views.includes('table')) {
      viewButtons.push({
        view: 'table',
        iconToShow: 'table',
      });
    }
  }
  return viewButtons;
}
