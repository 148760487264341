import _ from 'lodash';

const initialState = {
  projects: null,
  tenantId: null,
};

export const projectsList = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PROJECTS_SUCCESS':
      const newState = _.cloneDeep(state);
      newState.projects = _.cloneDeep(action.payload.projects);
      newState.tenantId = _.cloneDeep(action.payload.tenantId);
      return newState;
    default:
      return state;
  }
};