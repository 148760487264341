// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************

export const CHART_SET = 'CHART_SET';
const SET_SELECTED_VIEW = 'SET_SELECTED_VIEW';
const SET_TOPIC = 'SET_TOPIC';
const SET_CHART = 'SET_CHART';
const SET_PLAN_TYPE = 'SET_PLAN_TYPE';
const SET_VIEW = 'SET_VIEW';
const SET_TAB = 'SET_TAB';
const CALCULATION_COMPLETED = 'CALCULATION_COMPLETED';
const SET_TOPIC_AND_CHART = 'SET_TOPIC_AND_CHART';
const SET_CHART_SIZE = 'SET_CHART_SIZE';
const TOGGLE_COMPARE_WITH_BASELINE = 'TOGGLE_COMPARE_WITH_BASELINE';

export const setSelectedView = metrics => {
  return {
    type: SET_SELECTED_VIEW,
    payload: metrics,
  };
};

export const setTopicAndChart = ({ topic, chart }) => {
  return {
    type: SET_TOPIC_AND_CHART,
    payload: { topic, chart },
  };
};

export const setTopic = topic => {
  return {
    type: SET_TOPIC,
    payload: topic,
  };
};

export const setChart = chart => {
  return (dispatch, getState) => {
    dispatch({
        type: SET_CHART,
        payload: chart,
    });
    const state = getState();
    const selectedView = state.charts.selectedView;
    dispatch({
      type: CHART_SET,
      payload: selectedView
    });
  }
};

export const setPlanType = planType => {
  return {
    type: SET_PLAN_TYPE,
    payload: planType,
  };
};

export const setView = view => {
  return {
    type: SET_VIEW,
    payload: view,
  };
};

export const setTab = tab => {
  return {
    type: SET_TAB,
    payload: tab,
  };
};

export const calculationCompleted = (calcResponse, planType) => {
  return {
    type: CALCULATION_COMPLETED,
    payload: {
      calcResponse,
      planType,
    },
  };
};

export const setChartSize = chartSize => ({
  type: SET_CHART_SIZE,
  payload: chartSize,
});

export const setLegendSize = legendSize => ({
  type: 'SET_LEGEND_SIZE',
  payload: legendSize,
});

export const toggleCompareWithBaseline = () => ({
  type: TOGGLE_COMPARE_WITH_BASELINE,
});