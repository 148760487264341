// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import SelectorBar from './SelectorBar';

import './SelectionBar.css';

const SelectionBar = (props) => {
  const {leftItems} = props.selections;
  return (
    <div className="selection-bar">
      {leftItems}
      <span className="line" style={{height:'0.5px', background:'#cccccc'}}></span>
      <SelectorBar />
    </div>
  )
}

export default SelectionBar;
