import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import _ from 'lodash';
import CustomAdpTimingDialog from './CustomAdpTimingDialog';
import CustomAdpTimingAddEditDialog from './CustomAdpTimingAddEditDialog';
import CustomAdpCopyPasteFromSpreadsheetDialog from './CustomAdpCopyPasteFromSpreadsheetDialog';
import {
  mapPaymentsFromBackendAdjustmentValue
} from './customAdpAdjustmentsMapper';

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px;
`;

const StyledButton = styled(Button)`
  width: 80%;
  padding: 5px 15px;
  cursor: pointer;
`;

const selectDoesSidebarDeviateFromScenario = adjustmentName => state => {
  const { scenarios, viewingScenario, adjustmentsMadeByUser } = state.sideBar;
  const sidebarAdjustmentValue = adjustmentsMadeByUser[adjustmentName] ?? {};
  const scenarioAdjustmentValue = viewingScenario !== 'default'
    ? scenarios[viewingScenario].adjustments[adjustmentName] ?? {}
    : {};
  return !_.isEqual(scenarioAdjustmentValue, sidebarAdjustmentValue);
};

const selectAdjustmentValuePayments = adjustmentName => state => {
  const sidebarAdjustmentValue = state.sideBar.adjustmentsMadeByUser[adjustmentName] ?? {};
  const adjustmentStructure = state.sideBar.adjustmentStructure[adjustmentName];
  const plans = adjustmentStructure.extended.plans;
  const adjustmentValue = sidebarAdjustmentValue ?? {};
  return mapPaymentsFromBackendAdjustmentValue(adjustmentValue, plans);
};

const CustomAdpTimingAdjustment = ({ adjustmentName }) => {
  const dispatch = useDispatch();
  const mode = useSelector(rs => rs.customAdp.mode);
  const adjustmentStructure = useSelector(rs => rs.sideBar.adjustmentStructure[adjustmentName]);
  const payments = useSelector(selectAdjustmentValuePayments(adjustmentName));
  const doesDeviateFromScenario = useSelector(selectDoesSidebarDeviateFromScenario(adjustmentName));

  const handleSetupPaymentsClicked = id => {
    dispatch(
      {
        type: 'CUSTOM_ADP_SETUP_PAYMENTS_CLICKED',
        payload: { adjustmentStructure, adjustmentName, payments }
      });
  };

  let buttonTitle = 'Manage Payments';
  if(payments.length > 0)
    buttonTitle += ` (${payments.length})`

  return <Container>
    <StyledButton bsStyle={doesDeviateFromScenario ? 'success' : 'default'}
                  onClick={handleSetupPaymentsClicked}>{buttonTitle}</StyledButton>
    {
      mode === 'list' &&
        <CustomAdpTimingDialog />
    }
    {
      (mode === 'add' || mode === 'edit') &&
        <CustomAdpTimingAddEditDialog />
    }
    {
      mode === 'copy_paste' &&
      <CustomAdpCopyPasteFromSpreadsheetDialog />
    }
  </Container>
};
export default CustomAdpTimingAdjustment;
