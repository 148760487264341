import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import {
  SaveIcon,
  PlusIcon,
  TableIcon,
  TrashIcon,
  EditIcon,
} from '../../../../components/Icons';
import pretty_print from '../../../../common/pretty_print';

const ModalTitleStyled = styled(Modal.Title)`
  text-align: left;
`;

const DescriptionText = styled.div`
  margin-top: 2px;
  margin-bottom: 10px;
  font-style: italic;
`;

const FooterButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
`;

const ApplyButton = styled(Button)`
  height: 36px;
  display: flex;
`;

const SaveIconContainerStyled = styled.div`
  margin-right: 10px;
  fill: white;
`;

const CancelButton = styled(Button)`
  height: 36px;
  margin-right: 15px;
  font-size: 18px;
`;

const IconButton = styled(Button)`
  height: 36px;
  display: flex;
  margin-left: 20px;
  color: #5cb85c;
  fill: #5cb85c;
  background: white;
  border: 0px;

  &:hover {
    fill: white;
  }
`;

const IconButtonContainer = styled.div`
  margin-right: 10px;
`;

const IconText = styled.div`
  margin: auto;
  font-size: 18px;
`;

const ModalStyled = styled(Modal)`
  & .modal-content {
    width: 80vw;
    max-width: 90vw;
    margin: auto;
    max-height: 80%;
    overflow-y: hidden;
  }

  & .modal-dialog {
    width: initial;
    transform: none !important;
  }
`;

const TableContainer = styled.div`
  overflow-y: auto;
  height: ${props => props.big === true ? '600px' : '300px'};
  border: 0.25px solid lightgray;
  margin-top: 10px;
  margin-bottom: 30px;
`;

const TableStyled = styled.table`
  width: 100%;

  & td,
  th {
    padding: 5px;
  }
`;

const HeaderCell = styled.th`
  border: 0.75px solid lightgray;
  text-align: ${props => (props.right ? 'right' : props.center ? 'center' : 'left')};
  width: 120px;
  border-top: 0;
  border-left: 0;
`;

const ActionHeaderCell = styled.th`
  border: 0.75px solid lightgray;
  width: 80px;
  border-top: 0;
  border-left: 0;
`;

const CellStyled = styled.td`
  border: 0.75px solid lightgray;
  text-align: ${props => (props.right ? 'right' : 'left')};
  font-family: monospace;
  font-size: smaller;
  border-left: 0;
`;

const ActionCellStyled = styled.td`
  border: 0.75px solid lightgray;
  text-align: left;
  font-size: smaller;
`;

const ActionIconButton = styled.div`
  height: 36px;
  display: flex;
  color: #5cb85c;
  fill: #5cb85c;
  background: transparent;
  border: 0px;
  padding-top: 5px;
  padding-right: 3px;
  padding-left: 7px;

  cursor: pointer;

  &:hover {
    fill: #278827;
  }
`;

const ActionCellContainer = styled.div`
  display: flex;
`;

const RowStyled = styled.tr`
  &:hover {
    background: #ddd;
  }
`;

const ButtonsRow = styled.div`
  display: flex;
  justify-content: end;
`;

const CustomAdpTimingDialog = () => {
  const dispatch = useDispatch();
  const payments = useSelector(rs => rs.customAdp.payments);
  const adjustmentStructure = useSelector(rs => rs.customAdp.adjustmentStructure);
  const adjustmentName = useSelector(rs => rs.customAdp.adjustmentName);
  const isDirty = useSelector(rs => rs.customAdp.isDirty);
  const plans = !!adjustmentStructure.extended && adjustmentStructure.extended.plans;
  if (!plans) {
    throw new Error('No plans in adjustmentStructure in CustomTimingDialog');
  }
  const handleAdd = () => {
    dispatch({ type: 'CUSTOM_ADP_ADD_PAYMENT'})
  };
  const handleEdit = id => {
    dispatch({ type: 'CUSTOM_ADP_EDIT_PAYMENT', payload: { id }})
  };
  const handleDelete = id => {
    dispatch({ type: 'CUSTOM_ADP_DELETE_PAYMENT', payload: { id } });
  };
  const handleCancel = () => {
    dispatch({ type: 'CUSTOM_ADP_SETUP_CANCELLED'})
  };
  const handleSave = () => {
    dispatch({ type: 'SET_CUSTOM_ADP_PAYMENTS_APPLIED',
      payload: { adjustmentName, payments, plans }});
  };
  const handleCopyPasteFromSpreadsheetClicked = () => {
    dispatch({ type: 'CUSTOM_ADP_COPY_PASTE_FROM_SPREADSHEET_CLICKED' });
  };
  const formatAmount = val => {
    return pretty_print(val, 'number_thousands');
  };
  const formatDate = d => {
    return DateTime.fromISO(d).toLocaleString();
  };

  return (
    <>
      <ModalStyled
        show={true}
        onHide={handleCancel}
        centered='true'
        aria-labelledby='contained-modal-title-vcenter'
        bsSize='large'
      >
        <Modal.Header closeButton>
          <ModalTitleStyled id='contained-modal-title-vcenter'>Additional Timed Contributions</ModalTitleStyled>
        </Modal.Header>
        <Modal.Body>
          <DescriptionText>
            You can use this dialog to manage custom timed additional contributions.
            Each row in the table below shows a date and the contribution payments made to each plan on that date.
            To add a new payment, click the <b>Add</b> button.
          </DescriptionText>

          <ButtonsRow>
            <IconButton bsStyle='success' bsSize='small' onClick={handleCopyPasteFromSpreadsheetClicked}>
              <IconButtonContainer>
                <TableIcon height={'24px'} width={'32px'} />
              </IconButtonContainer>
              <IconText>Copy/Paste from Spreadsheet</IconText>
            </IconButton>
            <IconButton bsStyle='success' bsSize='small' onClick={handleAdd}>
              <IconButtonContainer>
                <PlusIcon height={'24px'} width={'24px'} />
              </IconButtonContainer>
              <IconText>Add</IconText>
            </IconButton>
          </ButtonsRow>

          <TableContainer big={payments.length > 5}>
            <TableStyled style={{ zIndex: 2000 }}>
              <thead>
              <tr>
                <HeaderCell>Date</HeaderCell>
                {
                  plans.map(p => {
                    return <HeaderCell key={p.id} center>{p.description}</HeaderCell>;
                  })
                }
                <ActionHeaderCell></ActionHeaderCell>
              </tr>
              </thead>

              <tbody>
              {
                payments.length === 0 &&
                  <RowStyled>
                    <td>No payments</td>
                  </RowStyled>
              }
              {
                payments.map(pmt => {
                  return <RowStyled key={pmt.id}>
                    <CellStyled>{formatDate(pmt.date)}</CellStyled>
                    {
                      plans.map(p => {
                        return <CellStyled key={p.id} right>
                          {formatAmount(pmt.planPaymentAmountsParsed[p.id])}
                        </CellStyled>;
                      })
                    }
                    <ActionCellStyled>
                      <ActionCellContainer>
                        <ActionIconButton bsStyle='success' bsSize='small' onClick={() => handleEdit(pmt.id)}>
                          <EditIcon height={'24px'} width={'28px'} />
                        </ActionIconButton>
                        <ActionIconButton bsStyle='success' bsSize='small' onClick={() => handleDelete(pmt.id)}>
                          <TrashIcon height={'24px'} width={'24px'} />
                        </ActionIconButton>
                      </ActionCellContainer>
                    </ActionCellStyled>
                  </RowStyled>;
                })
              }
              </tbody>
            </TableStyled>
          </TableContainer>

          <FooterButtons>
            <CancelButton bsSize='small' onClick={handleCancel}>
              Cancel
            </CancelButton>
            <ApplyButton bsStyle='success' bsSize='small' disabled={!isDirty} onClick={handleSave}>
              <SaveIconContainerStyled>
                <SaveIcon height={'24px'} width={'24px'} />
              </SaveIconContainerStyled>
              <IconText>Save</IconText>
            </ApplyButton>
          </FooterButtons>

        </Modal.Body>
      </ModalStyled>
    </>
  );
};
export default CustomAdpTimingDialog;
