// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { connect } from 'react-redux';

import { Table } from 'react-bootstrap';
import ChartSeries from './ChartSeries';
import { toggleDownloadSelection } from 'client/actions';
import { unchecked } from 'client/selectors/downloadSelector.js';

// TopicChart

const TopicChart = ({ id, details, chart: name, planType, topic, unChecked, toggleDownloadSelection }) => {
  const { series, chart, view } = details;
  return (
    <Table className="topic-chart">
      <thead>
        <tr>
          <th>
            <input id={id} checked={!unChecked} onChange={toggleDownloadSelection} type="checkbox" /> <label htmlFor={id}>{name}</label>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <ul style={{ marginLeft: 12.5, listStyle: 'none' }}>
              {series.map(s => (
                <ChartSeries id={`cb/${name}/${s}`} key={s} planType={planType} topic={topic} chart={name} series={s} title={chart.views[view].series[s].title} />
              ))}
            </ul>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

const chartStateToProps = (state, { allCharts, planType, topic, chart }) => ({
  unChecked: unchecked(state, planType, topic, chart),
});

const chartDispatchToProps = (dispatch, { planType, topic, chart }) => ({
  toggleDownloadSelection: () => dispatch(toggleDownloadSelection(planType, topic, chart)),
});

export default connect(chartStateToProps, chartDispatchToProps)(TopicChart);
