// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _isEqual from 'lodash/isEqual';
import { Button } from 'react-bootstrap';
import FreshStartModal from './FreshStartModal.jsx';
import PayoffPriorityModal from './PayoffPriorityModal.jsx';
import { getFreshStartsDeviate, getPayoffPrioritiesDeviate, getFreshStarts } from '../selectorsAmortizationBases';
import styled from 'styled-components';

const AmortizationBasesContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px;
`;

const AmortizationButton = styled(Button)`
  width: 80%;
  padding: 5px 15px;
  cursor: pointer;
`;

class AmortizationBases extends Component {
  constructor(props) {
    super(props);
    this.state = {
      freshStartModalShow: false,
      payoffPriorityModalShow: false,
    };
  }

  handleShow = name => {
    this.setState({ [name]: !this.state[name] });
  };

  changesDeviate = (localState, reduxState) => !_isEqual(localState, reduxState);

  handleShowWithConfirm = (name, localState, reduxState) => {
    if (this.changesDeviate(localState, reduxState)) {
      if (window.confirm('Your unsaved changes will be lost.')) {
        this.handleShow(name);
      }
    } else {
      this.handleShow(name);
    }
  };

  render() {
    const { freshStart, payoffPriority, adjustmentName, adjustmentStructure } = this.props;
    const { freshStartModalShow, payoffPriorityModalShow } = this.state;
    return (
      <React.Fragment>
        <AmortizationBasesContainer>
          {freshStart && (
            <AmortizationButton bsStyle={this.props.freshStartsDeviate ? 'success' : 'default'} onClick={() => this.handleShow('freshStartModalShow')}>
              Manage Fresh Starts {this.props.freshStarts.applied.length > 0 && `(${this.props.freshStarts.applied.length})`}
            </AmortizationButton>
          )}
          {payoffPriority && (
            <AmortizationButton bsStyle={this.props.payoffPriorityDeviate ? 'success' : 'default'} onClick={() => this.handleShow('payoffPriorityModalShow')}>
              Change Payoff Priority
            </AmortizationButton>
          )}
        </AmortizationBasesContainer>

        {this.state.freshStartModalShow &&
          freshStart && <FreshStartModal show={freshStartModalShow} handleShowWithConfirm={this.handleShowWithConfirm} handleShow={this.handleShow} />}
        {this.state.payoffPriorityModalShow &&
          payoffPriority && (
            <PayoffPriorityModal adjustmentName={adjustmentName} adjustmentStructure={adjustmentStructure} show={payoffPriorityModalShow} handleShowWithConfirm={this.handleShowWithConfirm} handleShow={this.handleShow} />
          )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  freshStarts: ownProps.freshStart ? getFreshStarts(state) : {},
  freshStartsDeviate: ownProps.freshStart && getFreshStartsDeviate(state),
  payoffPriorityDeviate: ownProps.payoffPriority && getPayoffPrioritiesDeviate(state, ownProps),
});

export default connect(mapStateToProps)(AmortizationBases);
