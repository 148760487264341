export const postCommand = async (cmd) => {
  const bodyText = JSON.stringify(cmd);
  const result = await fetch(`/webapi/commands`, {
    method: 'POST',
    body: bodyText,
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });
  if (result.status >= 300) {
    try {
        console.log('Posting command: ' + JSON.stringify(cmd));
        const content = await result.json();
        return { error: content.error, errorDescription: content.errorDescription };
    }
    catch(e) {
      return { error: 'bad_http_status', errorDescription:'Unexpected status code result: ' + result.status};
    }
  }

  return {};
};