// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { getSidebarWidth } from 'client/selectors/page.js';
import Footer from 'components/footer/Footer';
import Header from 'components/header/Header.js';
import { DisclaimerDialog } from 'components/disclaimerDialog/DisclaimerDialog';

export class ClientMenu extends Component {
  render() {
    const { middleItems, generalError, alert, marginLeft, sidebar, children } = this.props;

    const mainContentStyles = {
      marginLeft: marginLeft,
      transition: '.75s ease',
      paddingTop:'10px',
      height: 'calc(100% - 84px)',
    };

    return (
      <div>
        <section className="page-wrapper" style={{ fontSize: '1.25rem', overflow: 'auto' }}>
          <div style={{marginLeft: marginLeft}}>
          <Header middleItems={middleItems} />
          </div>
          <section className="main-content" style={mainContentStyles}>
            {alert}
            {
              generalError ? 
                <Alert bsStyle="danger">{generalError}</Alert>
                : children
            }
          </section>
        </section>
        {sidebar}
        <Footer />
        { this.props.planTypeInfo.features.disclaimer && <DisclaimerDialog /> }
      </div>
    );
  }
}

function stateToProps(state, ownProps) {
  return {
    planTypeInfo: state.planTypeInfo,
    account: state.user.account,
    project: state.user.project,
    generalError: state.user.generalError,
    marginLeft: ownProps.sidebar ? getSidebarWidth(state, ownProps) : 0,
  };
}

export default withRouter(connect(stateToProps)(ClientMenu));
