import _ from 'lodash';

const initialState = {
  acknowledged: false,
};

export const disclaimer = (state = initialState, action) => {
  switch (action.type) {
    case 'ACKNOWLEDGE_DISCLAIMER':
      const newState = _.cloneDeep(state);
      newState.acknowledged = true;
      return newState;
    default:
      return state;
  }
};