// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
export const SET_ADJUSTMENT_1YEAR = 'SET_ADJUSTMENT_1YEAR';
export const SET_PERCENT_ADJUSTMENT_1YEAR = 'SET_PERCENT_ADJUSTMENT_1YEAR';
export const SET_DOLLAR_ADJUSTMENT_1YEAR = 'SET_DOLLAR_ADJUSTMENT_1YEAR';
export const SET_ADJUSTMENT_SLIDER = 'SET_ADJUSTMENT_SLIDER';
export const SET_PERCENT_ADJUSTMENT_SLIDER = 'SET_PERCENT_ADJUSTMENT_SLIDER';
export const SET_CONTROL_SLIDER = 'SET_CONTROL_SLIDER';
export const SET_CONTROL_5YEAR = 'SET_CONTROL_5YEAR';
export const OPEN_WILL_LOSE_DATA_MODAL = 'OPEN_WILL_LOSE_DATA_MODAL';
export const CANCEL_CONTROL_CHANGE = 'CANCEL_CONTROL_CHANGE';
export const PROCEED_CONTROL_CHANGE = 'PROCEED_CONTROL_CHANGE';
export const SET_CONTROL_10YEAR = 'SET_CONTROL_10YEAR';
export const SET_CONTROL_30YEAR = 'SET_CONTROL_30YEAR';

export const TOGGLE_ADJUSTMENTGROUP_COLLAPSED = 'TOGGLE_ADJUSTMENTGROUP_COLLAPSED';
export const RESET_ADJUSTMENT = 'RESET_ADJUSTMENT';

export const PLAN_GROUPS_RECEIVED = 'PLAN_GROUPS_RECEIVED';
export const SELECT_SCENARIO = 'SELECT_SCENARIO';

export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE_V2';
export const DATA_RETRIEVAL = 'DATA_RETRIEVAL';
export const DATA_REQUEST_STATUS = 'DATA_REQUEST_STATUS';

export const TOGGLE_30YEAR_MODAL = 'TOGGLE_30YEAR_MODAL';
export const SET_ADJUSTMENT_DROPDOWN = 'SET_ADJUSTMENT_DROPDOWN';

export const SET_PROJECTED_YEARS = 'SET_PROJECTED_YEARS';

export const RESET_ADJUSTMENT_TO_DEFAULT = 'RESET_ADJUSTMENT_TO_DEFAULT';
export const OVERRIDE_ADJUSTMENT = 'OVERRIDE_ADJUSTMENT';
export const REMOVE_OVERRIDE = 'REMOVE_OVERRIDE';
export const RESET_ADJUSTMENT_TO_SCENARIO = 'RESET_ADJUSTMENT_TO_SCENARIO';

export const DELETE_SCENARIO = 'DELETE_SCENARIO';
export const RUN_SCENARIO = 'RUN_SCENARIO';
export const SAVE_SCENARIO = 'SAVE_SCENARIO';
export const SET_SCENARIO_LABEL_EDIT_MODAL = 'SET_SCENARIO_LABEL_EDIT_MODAL';
export const UNSET_SCENARIO_LABEL_EDIT_MODAL = 'UNSET_SCENARIO_LABEL_EDIT_MODAL';
export const SET_VIEWING_SCENARIO = 'SET_VIEWING_SCENARIO';
export const SET_EDITING_SCENARIO = 'SET_EDITING_SCENARIO';
export const UNSET_EDITING_SCENARIO = 'UNSET_EDITING_SCENARIO';
export const SET_SCENARIO_AS_UNCOMPARED = 'SET_SCENARIO_AS_UNCOMPARED';
export const SET_SCENARIO_AS_COMPARED = 'SET_SCENARIO_AS_COMPARED';
export const SET_BASELINE_SCENARIO = 'SET_BASELINE_SCENARIO';
export const UPDATE_SCENARIO_LABEL = 'UPDATE_SCENARIO_LABEL';
export const UPDATE_SCENARIO = 'UPDATE_SCENARIO';

export const SET_VIEWING_SCENARIO_TO_DEFAULT = 'SET_VIEWING_SCENARIO_TO_DEFAULT';

export const REMOVE_FROM_COMPARED = 'REMOVE_FROM_COMPARED';

export const SCENARIOS2_RECEIVED = 'SCENARIOS2_RECEIVED';
export const UPDATE_SCENARIO2_FROM_ADJUSTMENTS = 'UPDATE_SCENARIO2_FROM_ADJUSTMENTS';

export const SELECT_CATEGORY = 'SELECT_CATEGORY';

export const TOGGLE_FILTERCATEGORY_COLLAPSED = 'TOGGLE_FILTERCATEGORY_COLLAPSED';
export const RESET_FILTERITEMS_SELECTED = 'RESET_FILTERITEMS_SELECTED';
export const SET_FILTERS_APPLIED = 'SET_FILTERS_APPLIED';
export const SELECT_FILTER = 'SELECT_FILTER';
export const SET_FRESH_STARTS_APPLIED = 'SET_FRESH_STARTS_APPLIED';
export const SET_CUSTOM_ADP_PAYMENTS_APPLIED = 'SET_CUSTOM_ADP_PAYMENTS_APPLIED';
export const SET_PAYOFF_PRIORITY_APPLIED = 'SET_PAYOFF_PRIORITY_APPLIED';
export const CALC_STATUS_RECEIVED = 'CALC_STATUS_RECEIVED';
