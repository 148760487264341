// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { useSelector } from 'react-redux';
import ComparedScenario from './ComparedScenario';
import { getComparedScenarioList } from '../selectors';

const ComparedScenarioList = () => {
  const scenarios = useSelector(state => getComparedScenarioList(state).scenarioIds);
  return <div>{scenarios.map(s => <ComparedScenario key={s} scenarioId={s} />)}</div>;
}

export default ComparedScenarioList;
