import { homeRedirect } from '../../lib/redirects';
import { errorOccurred } from '../../lib/sharedActions';

export const getProjects = (tenantId) => async dispatch => {
    const uri = `/webapi/projects?tenantId=${tenantId}`;
    const result = await fetch(uri, {
        method: 'GET',
    });
    if(result.status === 401) {
        console.log('401 - redirecting home');
        homeRedirect();
        return;
    }
    if(result.status !== 200) {
        dispatch(errorOccurred('Error Getting Projects', 'Unexpected status code: ' + result.status));
        return;
    }
    let items = [];
    try {
        items = await result.json();
        dispatch({ type: 'GET_PROJECTS_SUCCESS', payload: { projects: items, tenantId: tenantId } });
    }
    catch(e) {
        console.error(e);
        dispatch(errorOccurred('Error Getting Projects', 'Unable to read response'));
    }
};