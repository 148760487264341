// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';

export default function giTable(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 128 128"
      style={{ enableBackground: "new 0 0 128 128" }}
      id="svg2"
      width={128}
      height={128}
      {...props}
    >
      <defs id="defs14" />
      <rect y="36.868942" id="rect6" height="91.131058" width="36.599998" className="st0" x="-36.599998" transform="matrix(0,-1,1,0,0,0)" />
      <rect x={-128} id="rect8" height="92.063896" width="36.599998" className="st0" y="35.936104" transform="matrix(0,-1,1,0,0,0)" />
      <rect id="rect10" height="91.400002" width="36.599998" className="st0" y="36.599998" x="-82.300003" transform="matrix(0,-1,1,0,0,0)" />
      <rect y="3.5439031" id="rect6-5" height="28.60133" width="36.599998" className="st0" x="-36.43055" transform="matrix(0,-1,1,0,0,0)" />
      <rect y="3.6913795" id="rect6-5-7" height="28.60133" width="36.599998" className="st0" x="-82.295509" transform="matrix(0,-1,1,0,0,0)" />
      <rect y="3.6913793" id="rect6-5-5" height="28.60133" width="36.599998" className="st0" x="-127.71805" transform="matrix(0,-1,1,0,0,0)" />
    </svg>
  );
}
