// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { Alert, Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { showWillLoseDataModal, getWillLoseDataModalAdjustmentStructure } from 'client/sideBar/selectors.js';
import { cancelControlChange, proceedControlChange } from '../actionCreators';

const WillLoseDataModal = ({ adjustmentStructure, proceedControlChange, cancelControlChange, show }) => {
  const handleProceed = () => proceedControlChange(adjustmentStructure.name);
  const handleClose = () => cancelControlChange();

  const CancelButton = () => <Button onClick={handleClose}>Cancel</Button>;

  const ApplyButton = () => (
    <Button onClick={handleProceed} bsStyle="prom_apply">
      Apply
    </Button>
  );

  return (
    <Modal show={show} onHide={handleClose} aria-labelledby="Will lose data">
      <Modal.Header closeButton>
        <Modal.Title>Warning</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert bsStyle="warning">
          Changing input to fewer years will lose data. For example, when changing from 10 years of input to 5 years, amounts previously input for years 6
          through 10 will be lost. If you wish to change values for the first years, make those changes on the sidebar. To continue, hit apply. If you do not
          wish to lose your input, hit cancel.
        </Alert>
      </Modal.Body>
      <Modal.Footer>
        <CancelButton />
        <ApplyButton />
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state, ownProps) => ({
  show: showWillLoseDataModal(state),
  adjustmentStructure: getWillLoseDataModalAdjustmentStructure(state),
});

export default connect(mapStateToProps, {cancelControlChange, proceedControlChange})(WillLoseDataModal);
