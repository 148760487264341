// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';

const makeCss = function(state) {
  let css = {
    line: {
      stroke: 'grey',
      strokeWidth: 1
    }
  };
  if (state.pinYear) {
    css.line.stroke = 'slategrey';
    css.line.strokeWidth = 2;
  }
  return css;
};

export default class CrossHair extends React.Component {
  render() {
    let { scales, cmp } = this.props;
    const onYear = cmp.state.onYear;
    if (!onYear && onYear !== 0) {
      return null;
    }
    const css = makeCss(cmp.state);
    let x = scales.x(onYear);
    let top = scales.y.range()[0];
    let bottom = scales.y.range()[1];
    let dash = '7 7';
    if (cmp.state.pinYear === true) {
      dash = null;
    }
    return (
      <g key="xaxis">
        <line key={'line'} x1={x} x2={x} y1={top - 5} y2={bottom + 5} strokeDasharray={dash} style={css.line} />
      </g>
    );
  }
}
