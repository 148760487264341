// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';

export default function giBox(props) {
  return (
  	<svg
  		xmlns="http://www.w3.org/2000/svg"
  		version="1.1"
  		id="Layer_1"
  		x="0px"
  		y="0px"
  		width={25}
      height={25}
  		viewBox="0 0 20 20"
  		style={{ enableBackground: "0 0 25 25" }}
  		{...props}
  	>
      <path
  			d="M15,0.999H1c-0.552,0-1,0.447-1,1v4c0,0.553,0.448,1,1,1v8c0,0.552,0.448,1,1,1h12c0.553,0,1-0.448,1-1v-8   c0.553,0,1-0.447,1-1v-4C16,1.446,15.553,0.999,15,0.999z M14,14.499c0,0.276-0.225,0.5-0.5,0.5h-11c-0.276,0-0.5-0.224-0.5-0.5v-7   c0-0.276,0.224-0.5,0.5-0.5H5v2c0,0.553,0.448,1,1,1h4c0.553,0,1-0.447,1-1v-2h2.5c0.275,0,0.5,0.224,0.5,0.5V14.499z M6,8.499   v-0.5v-0.5v-0.5h0.5h3H10v0.5v0.5v0.5c0,0.276-0.225,0.5-0.5,0.5h-3C6.224,8.999,6,8.775,6,8.499z M15,5.499   c0,0.276-0.225,0.5-0.5,0.5H10H6H1.5c-0.276,0-0.5-0.224-0.5-0.5v-3c0-0.276,0.224-0.5,0.5-0.5h13c0.275,0,0.5,0.224,0.5,0.5V5.499   z"
  		/>
    </svg>
  );
}
