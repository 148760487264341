// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getDisplayDataPropsFromState2 } from '../lib/app-general.js';
import ClientMenu from './ClientMenu.jsx';
import Sidebar from '../sideBar/components/SideBar.jsx';
import Dashboard from './Dashboard.jsx';
import MiniDashboard from './MiniDashboard.jsx';
import SectionGraph2 from './SectionGraph2.jsx';
import SelectionBar from './SelectionBar.jsx';
import ChartSummary from './chartSummary/ChartSummary';
import './PlanInteraction.css';

const PlanInteraction = props => {
  const { wrapperProps } = props;
  const onYear = useSelector(state => state.viewSummary.yearParams.y);
  const view = useSelector(state => state.charts.selectedView.view);
  const displayDataPropsFromState2 = useSelector(state => getDisplayDataPropsFromState2(state, props));
  const profile = useSelector(state => state.currentUser.profile);
  const features = useSelector(state => state.planTypeInfo.features);

  useEffect(() => {
    document.title = 'GovInvest | Liability Calculator';
  }, []);

  let viewComp;
  if (view === 'overview') {
    viewComp = (
      <div style={{ width: '100%', textAlign: 'center' }}>
        <Dashboard onYear={onYear} />
      </div>
    );
  } else {
    const gridTemplateRows = features.miniDashboard ? 'auto auto auto auto 1fr' : 'auto auto auto 1fr';
    viewComp = (
      <div style={{ display: 'grid', gridTemplateRows: gridTemplateRows, height: '100%' }}>
        { features.miniDashboard && <MiniDashboard onYear={onYear} /> }

        <div style={{paddingTop:(!features.miniDashboard ? '30px' : null)}}>
          <SelectionBar selections={wrapperProps} />
        </div>

        <ChartSummary />

        <SectionGraph2 {...displayDataPropsFromState2} />
      </div>
    );
  }

  const newProps = {
    ...wrapperProps,
    sidebar: <Sidebar />,
    profile
  };
  return (
    <ClientMenu { ...newProps }>
      <section style={{ minHeight: '0.0em' }} id="overview" className="top-graph-container container-fluid">
        <div style={{ width: '100%', position: 'relative' }}>
          <div
            className="text-right"
            style={{
              width: '100%',
              position: 'absolute',
              top: 0,
              verticalAlign: 'bottom',
            }}
          />
        </div>
      </section>
      {viewComp && (
        <section style={{ clear: 'both', height: 'calc(100% - 29px)' }} className="inner-page-wrapper">
          <div className="relative-container" style={{ height: '100%' }}>
            <section id="graph-container" className="mid-graph-container" style={{height:'100%'}}>
              {viewComp}
            </section>
          </div>
        </section>
      )}
      <br style={{ clear: 'both' }} />
    </ClientMenu>
  );
}

export default withRouter(PlanInteraction);
