// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { area, line, curveMonotoneX } from 'd3-shape';
import tinycolor from 'tinycolor2';

export default class Area extends React.Component {
  render() {
    const { chart, renderCurrentComparison, renderBaselineComparison, isComparing } = this.props;

    const areaPath = area()
      .x(d => d.x)
      .y1(d => d.y)
      .y0(d => d.bottom)
      .curve(curveMonotoneX)(chart.points);
    const linePath = line()
      .x(d => d.x)
      .y(d => d.y)
      .curve(curveMonotoneX)(chart.points);
    const baselineLinePath = line()
      .x(d => d.x)
      .y(d => d.baseY)
      .curve(curveMonotoneX)(chart.points);
    const baselineAreaPath = area()
      .x(d => d.x)
      .y1(d => d.baseY)
      .y0(d => d.bottom)
      .curve(curveMonotoneX)(chart.points);
    const compareWithBaselineAreaPath = area()
      .x(d => d.x)
      .y1(d => d.baseY)
      .y0(d => d.y)
      .curve(curveMonotoneX)(chart.points);

    if (renderCurrentComparison) {
      return <path d={linePath} stroke={tinycolor(chart.color).darken(20)} strokeWidth={4} fill="none" opacity={0.9} shapeRendering="geometricPrecision" />;
    }

    if (renderBaselineComparison) {
      return (
        <React.Fragment>
          <path d={baselineLinePath} stroke={tinycolor(chart.color).darken(15)} strokeWidth={2} fill={"none"} opacity={0.9} shapeRendering="geometricPrecision" />
          <path d={compareWithBaselineAreaPath} strokeWidth={0} fill={chart.color} opacity={0.2} shapeRendering="geometricPrecision" />;
          <path d={baselineAreaPath} strokeWidth={0} fill={chart.color} opacity={0.9} shapeRendering="geometricPrecision" />;
        </React.Fragment>
      );
    }

    const opacity = isComparing && chart.compareWithBaseline ? 0.2 : 0.9;
    return <path d={areaPath} strokeWidth={0} fill={chart.color} opacity={opacity} shapeRendering="geometricPrecision" />;
  }
}
