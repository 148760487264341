// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { connect } from 'react-redux';
import { Glyphicon } from 'react-bootstrap';
import { removeOverride } from '../actionCreators';

const RemoveOverrideButton = ({ adjustmentName, removeOverride }) => {
  const handleClick = () => {
    removeOverride(adjustmentName);
  };
  return (
    <span onClick={handleClick} className="btn-group" style={{ verticalAlign: 'top' }}>
      <Glyphicon glyph={'remove-circle'} style={{ cursor: 'pointer', marginRight: '0.2em', color: '#a4aabb', backgroundColor:"red" }} />
    </span>
  );
};

export default connect(
  null,
  { removeOverride },
)(RemoveOverrideButton);
