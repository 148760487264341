import {v4 as uuid} from 'uuid';
export const mapPaymentsToBackendAdjustmentValue = (payments, plans) => {
  const ret = { dates: [], contributions: {} };
  for(let plan of plans) {
    const planId = plan.id;
    ret.contributions[planId] = [];
  }
  for(let pmt of payments) {
    ret.dates.push(pmt.date);
    for(let plan of plans) {
      const planId = plan.id;
      ret.contributions[planId].push(pmt.planPaymentAmountsParsed[planId] ?? 0);
    }
  }
  return ret;
}

export const mapPaymentsFromBackendAdjustmentValue = (adjustmentValue, plans) => {
  const ret = [];
  if(Object.keys(adjustmentValue).length === 0)
    return ret;
  for(let dx = 0; dx < adjustmentValue.dates.length; dx++) {
    const date = adjustmentValue.dates[dx];
    const pmt = { id: uuid(), date: date, planPaymentAmountsParsed: {} };
    ret.push(pmt);
    for(let plan of plans) {
      const planId = plan.id;
      pmt.planPaymentAmountsParsed[planId] = adjustmentValue.contributions[planId][dx] ?? 0;
    }
  }
  return ret;
};
