// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
/**
 * DownloadData
 *
 **/

import React from 'react';
import '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import urlJoin from 'url-join';
import { getDisplayDataPropsFromState2 } from 'client/lib/app-general.js';
import { downloadPDF, downloadPNG } from 'client/lib/download.js';
import { scenariosSelectDownloadDialogShow } from '../actions/uiActions.js';
import ScenariosSelectDownloadDialog from './ScenariosSelectDownloadDialog';
import './DownloadData.css';
import config from 'lib/client-config';

const DownloadData2 = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const displayProps = useSelector(state => ({
    ...getDisplayDataPropsFromState2(state, props),
    sideBar: state.sideBar,
    cache: state.cache,
    user: state.user,
  }));
  const workspaceInfo = useSelector(state => state.workspace.info);
  const showDialog = useSelector(state => state.ui.scenariosSelectDownloadDialogShow);
  const { topic, chart, planType } = displayProps.selectedView;
  const features = useSelector(state => state.planTypeInfo.features);

  const newProps = {
    ...props,
    displayProps,
    showDialog,
    workspaceInfo
  }

  const handleDownloadPng = () => {
    const uri = urlJoin(config.baseUrl, `webapi/workspace/${workspaceInfo.projectId}/${workspaceInfo.planType}/chartAsPNG`);
    downloadPNG(uri, newProps, topic + '-' + chart + '.png');
  };

  const handleDownloadPdf = () => {
    const uri = urlJoin(config.baseUrl, `webapi/workspace/${workspaceInfo.projectId}/${workspaceInfo.planType}/pdf2`);
    downloadPDF(uri, newProps, topic + '-' + chart + '.pdf');
  };

  const navigateToDownloadSelector = () => {
    const uri = urlJoin(`/app/workspace/${workspaceInfo.projectId}/${workspaceInfo.planType}/download`);
    history.push(uri);
  };

  return (
    <DropdownButton className="btn gi-btn-gr-default-o" title={<div><FontAwesomeIcon style={{marginBottom:'3px', color:"#777"}} icon="download" /></div>} pullRight id="split-button-pull-right" noCaret>
      <MenuItem href="http://" onClick={e => e.preventDefault()} eventKey="1" onSelect={() => dispatch(scenariosSelectDownloadDialogShow(!showDialog))}>
        Current chart as XLSX
      </MenuItem>
      <MenuItem href="http://" onClick={e => e.preventDefault()} eventKey="2" onSelect={handleDownloadPdf}>
        Current chart as PDF
      </MenuItem>
      <MenuItem href="http://" onClick={e => e.preventDefault()} eventKey="3" onSelect={handleDownloadPng}>
        Current chart as PNG
      </MenuItem>
      { features.downloadSelector && (
        <MenuItem href="http://" onClick={e => e.preventDefault()} eventKey="4" onSelect={navigateToDownloadSelector}>
          Download selector
        </MenuItem>
      )
      }
      {showDialog &&
        <ScenariosSelectDownloadDialog downloadProps={{...props, displayProps}} planType={planType} topic={topic} chart={chart} />
      }
    </DropdownButton>
  );
}

export default withRouter(DownloadData2);
