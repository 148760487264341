// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import prettyPrint from 'common/pretty_print';
import { getValues, getBaselineValues } from './getValues';

const lineChart = (obj, info, already, negAlready, scales, cmp, order) => {
  const values = getValues(obj, info);
  const baselineValues = getBaselineValues(obj, info);

  return values.map(function(val, i) {
    const year = info.valuationYear + i;
    const yScaled = scales.y(val);
    const baseYScaled = scales.y(baselineValues[i]);
    const showArrow = Math.abs(yScaled - baseYScaled) > 7;
    let orderResult;
    if (cmp && year === cmp.state.onYear && showArrow) {
      orderResult = order;
    } else {  
      orderResult = null;
    }

    return {
      value: val,
      x: scales.x(year),
      y: scales.y(val),
      baseY: scales.y(baselineValues[i]),
      year: year,
      label: prettyPrint(val, obj.format),
      already: 0,
      order: orderResult,
    };
  });
}

export default lineChart;
