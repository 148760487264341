// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import _isEqual from 'lodash/isEqual';
import _sortBy from 'lodash/sortBy';
import * as constants from './constants';
import moment from 'moment';

export const getAmortizationPlans = state => {
  const { amortizationDetail } = state.sideBar;

  if (amortizationDetail && amortizationDetail[0] && amortizationDetail[0].plans) {
    return amortizationDetail[0].plans.map(plan => ({
      ...plan,
      details: plan.details.map(base => ({ ...base, selected: false })),
    }));
  } else {
    return [];
  }
};

export const getAmortizationPlansWithoutAppliedFreshStarts = state => {
  const freshStarts = getFreshStarts(state);
  const amortizationPlans = getAmortizationPlans(state);

  let result = [];
  if (amortizationPlans) {
    result = amortizationPlans.filter(amortizationPlan => !freshStarts.applied.some(freshStart => freshStart.plan === amortizationPlan.plan));
  }
  return result;
};

export const getBases = (state, adjustmentName) => {
  const amortizationPlans = getAmortizationPlans(state);
  let basesAll = [];

  if (amortizationPlans) {
    amortizationPlans.forEach(amortizationPlan => {
      amortizationPlan.details.forEach(baseDetail => {
        const base = { ...baseDetail, plan: amortizationPlan.plan };
        basesAll.push(base);
      });
    });
  }
  if (adjustmentName) {
    const adjustmentStructureItem = state.sideBar.adjustmentStructure[adjustmentName];
    const planName = adjustmentStructureItem.extended.planName;
    basesAll = basesAll.filter(base => base.plan === planName);
  }
  return basesAll;
};

export const getFreshStartAdjustmentName = (state) => {
  const { adjustmentStructure } = state.sideBar;
  const adjustmentStructureNames = Object.keys(adjustmentStructure);
  for(let adjustmentStructureName of adjustmentStructureNames) {
    if(adjustmentStructure[adjustmentStructureName].type === constants.TYPE_FRESH_START) {
      return adjustmentStructureName;
    }
  }
  return null;
}

const getValuationYear = state => moment(state.sideBar.planGroupsInfo.valuationDate).year();

export const getFreshStartYears = state => {
  let { projectedNumYears } = state.sideBar;
  const valYear = getValuationYear(state);
  const result = [];
  for(let i=1; i < projectedNumYears; i++)
    result.push(valYear + i);
  return result;
}

// Fresh Start selectors
export const getFreshStarts = state => {
  const { scenarios, viewingScenario, adjustmentsMadeByUser } = state.sideBar;
  const basesData = getBases(state);
  const valYear = getValuationYear(state);

  let freshStartAdjustmentName = getFreshStartAdjustmentName(state);
  let freshStartAdjustment = viewingScenario !== 'default'
    ? scenarios[viewingScenario].adjustments[freshStartAdjustmentName]
    : [];

  const freshStartsUserChanges = adjustmentsMadeByUser[freshStartAdjustmentName] || [];

  const freshStartsFullBases = [...freshStartAdjustment].map(freshStart => ({
    ...freshStart,
    bases: freshStart.bases.map(scenarioBaseId => basesData.filter(base => base.id === scenarioBaseId)[0]),
    year: freshStart.year === undefined ? valYear+1 : freshStart.year,
    includeNewBases: freshStart.includeNewBases === undefined ? true : freshStart.includeNewBases,
  }));

  const freshStartsAppliedFullBases = [...freshStartsUserChanges].map(freshStart => ({
    ...freshStart,
    bases: freshStart.bases.map(scenarioBaseId => basesData.filter(base => base.id === scenarioBaseId)[0]),
    year: freshStart.year === undefined ? valYear+1 : freshStart.year,
    includeNewBases: freshStart.includeNewBases === undefined ? true : freshStart.includeNewBases,
  }));

  const result = {
    scenario: freshStartsFullBases,
    applied: freshStartsAppliedFullBases,
  };

  return result;
};

export const getFreshStartsDeviate = state => {
  const { scenarios, viewingScenario, adjustmentsMadeByUser } = state.sideBar;

  let freshStartAdjustmentName = getFreshStartAdjustmentName(state);
  const freshStarts =
    viewingScenario !== 'default'
      ? scenarios[viewingScenario].adjustments[freshStartAdjustmentName]
      : adjustmentsMadeByUser[freshStartAdjustmentName];

  const freshStartsUserChanges = viewingScenario !== 'default' ? adjustmentsMadeByUser[freshStartAdjustmentName] : [];

  return !_isEqual(_sortBy(freshStarts), _sortBy(freshStartsUserChanges));
};

// Payoff priority selectors

export const getPayoffPriority = (state, ownProps) => {
  const { viewingScenario, adjustmentStructure, scenarios, adjustmentsMadeByUser, payoffPriorityDefault } = state.sideBar;
  const { adjustmentName } = ownProps;
  if (!adjustmentName)
    throw Error('adjustmentName required for this function');

  const { extended: { planName } } = adjustmentStructure[adjustmentName];
  const basesData = getBases(state, ownProps.adjustmentName);

  const defaults = payoffPriorityDefault[planName];
  const scenario = scenarios[viewingScenario];

  const payoffPriorityScenario = viewingScenario === 'default' ? defaults : scenario.adjustments[adjustmentName] || defaults;
  const payoffPriorityUserChanges = adjustmentsMadeByUser[adjustmentName] || defaults;

  const populate = (bases) =>
    bases && bases.map(id => basesData.find(b => b.id === id)).filter(b => b);

  const scenarioResult = {
    ...payoffPriorityScenario,
    bases: populate(payoffPriorityScenario.bases),
  }

  const appliedResult = {
    ...payoffPriorityUserChanges,
    bases: populate(payoffPriorityUserChanges.bases),
  }

  return {
    scenario: scenarioResult,
    applied: appliedResult,
  };
};

export const getPayoffPrioritiesDeviate = (state, ownProps) => {
  let payoffPriority = getPayoffPriority(state, ownProps);
  return !_isEqual(payoffPriority.scenario, payoffPriority.applied);
};
