import _ from 'lodash';

const initialState = {
};

export const planTypeInfo = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PROJECT_SUCCESS': {
      const payload = action.payload;
      const newState = _.cloneDeep(state);
      newState.project = payload.project;
      setDerivedState(newState);
      return newState;
    }
    case 'RESET_WORKSPACE': {
      const payload = action.payload;
      const newState = _.cloneDeep(state);
      newState.planType = payload.planType;
      setDerivedState(newState);
      return newState;
    }
    default:
      return state;
  }
};

const setDerivedState = (state) => {
  if(!state.project || !state.planType)
    return;
  state.planGroupKeys = planGroupKeys(state.project, state.planType);
  state.features = availableFeatures(state.planType);
  state.availablePlanTypes = availablePlanTypes(state.project);
  state.planGroupLabel = planGroupLabel(state);
};

const availablePlanTypes = (project) => {
    const availablePlanTypes = [];
    if(project.pensionPlanGroup) {
      availablePlanTypes.push({ id: 'pension', label: 'Pension' });
    }
    if(project.opebPlanGroup) {
      availablePlanTypes.push({ id: 'opeb', label: 'OPEB' });
    }
    if(project.mersEmployerId) {
      availablePlanTypes.push({ id: 'mers-pension', label: 'Pension' });
      availablePlanTypes.push({ id: 'mers-peer-comparison', label: 'Peer Comparison' });
    }
    return availablePlanTypes;
};

const planGroupKeys = (project, planType) => {
  const keys = {};
  switch (planType) {
    case 'pension': {
      keys['planGroupId'] = project.pensionPlanGroup;
      break;
    }
    case 'opeb': {
      keys['planGroupId'] = project.opebPlanGroup;
      break;
    }
    case 'mers-peer-comparison':
    case 'mers-pension': {
      keys['mersEmployerId'] = project.mersEmployerId;
      keys['mersVersion'] = project.mersVersion;
      break;
    }
    default:
      throw new Error('Invalid planType');
  }
  return keys;
};

const availableFeatures = (planType) => {
  const features = {
    scenarios: true,
    yearSelector: true,
    downloadSelector: true,
    miniDashboard: true,
    changeYearsProjected: true,
    adjustments: true,
    disclaimer: false,
    adjustments30year: true,
  };
  if (planType === 'mers-peer-comparison') {
    features.scenarios = false;
    features.yearSelector = false;
    features.downloadSelector = false;
    features.miniDashboard = false;
    features.changeYearsProjected = false;
    features.adjustments = false;
    features.disclaimer = true;
  } else if (planType === 'mers-pension') {
    features.yearSelector = false;
    features.miniDashboard = false;
    features.changeYearsProjected = false;
    features.disclaimer = true;
    features.adjustments30year = false;
  }
  return features;
};

const planGroupLabel = (state) => {
  const planType = state.planType;
  if (planType === 'mers-peer-comparison' || planType === 'mers-pension') {
    return state.project.mersVersion + ' / ' + state.project.mersEmployerId
  } else if (state.planType === 'pension') {
    return state.project.pensionPlanGroup;
  }
  return state.project.opebPlanGroup;
};

export default planTypeInfo;