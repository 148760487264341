// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
const AxisCss = {
  line: {
    stroke: 'lightgrey',
    strokeWidth: 1,
  },
  labelY: {
    stroke: 'none',
    fill: 'grey',
    opacity: 1,
    shapeRendering: 'crispEdges',
    fontSize: 14,
    textAnchor: 'middle'
  }
};

export default AxisCss;
