// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import { findByName } from 'common/colorMachine';
import stackedBarLine from './stackedBarLine';
import prettyPrint from 'common/pretty_print';
import { getValues, getBaselineValues } from './getValues';
import { findWidth as barWidth } from './barChart';

const diffBar = (info, scales) => {
  let already = [];
  let negAlready = [];
  let arr = scales.y.domain();
  scales.y = scales.y.domain([0, arr[1]]);

  let diffCurve = info.series.find(s => s.diffCurve === true);
  let diffBorder = info.series.find(s => s.diffBorder === true);
  let diffArea = info.series.find(s => !s.diffCurve && !s.diffBorder);

  let diffBorderPoints = stackedBarLine(diffBorder, info, already, negAlready, scales);
  let diffBorderChart = {
    id: diffBorder.id,
    title: diffBorder.title,
    type: 'barLine',
    view: 'stackedBar',
    points: diffBorderPoints,
    color: findByName(diffBorder.color) || 'lightgrey',
    compareWithBaseline: info.compareWithBaseline,
  };

  let diffCurvePoints = makeDiffCurve(diffCurve, diffBorder, info, scales);
  let diffCurveChart = {
    id: diffCurve.id,
    title: diffCurve.title,
    type: 'stackedBar',
    view: 'stackedBar',
    points: diffCurvePoints,
    color: findByName(diffCurve.color) || 'lightgrey',
    compareWithBaseline: info.compareWithBaseline,
  };

  let diffAreaPoints = makeDiffArea(diffArea, diffCurve, diffBorder, info, scales);
  let diffAreaChart = {
    id: diffArea.id,
    title: diffArea.title,
    type: 'stackedBar',
    view: 'stackedBar',
    points: diffAreaPoints,
    color: findByName(diffArea.color) || 'lightgrey',
    compareWithBaseline: info.compareWithBaseline,
  };

  let result = [];

  if (!diffBorder.hidden) {
    result.push(diffBorderChart);
  }

  if (!diffArea.hidden) {
    result.push(diffAreaChart);
  }

  if (!diffCurve.hidden) {
    result.push(diffCurveChart);
  }

  return result;
}

const findWidth = function(scales) {
  return barWidth(scales, 1);
};

const makeDiffArea = (diffArea, diffCurve, diffBorder, info, scales) => {
  let width = findWidth(scales);
  let diffAreaValues = getValues(diffArea, info);
  let diffCurveValues = getValues(diffCurve, info);
  let diffBorderValues = getValues(diffBorder, info);
  let diffAreaBaselineValues = getBaselineValues(diffArea, info);
  let diffCurveBaselineValues = getBaselineValues(diffCurve, info);
  let diffBorderBaselineValues = getBaselineValues(diffBorder, info);

  return diffAreaValues.map(function(val, i) {
    let baselineValue = diffAreaBaselineValues[i];
    let year = info.valuationYear + i;
    let bottom = scales.y(diffCurveValues[i]);
    let baseBottom = scales.y(diffCurveBaselineValues[i]);
    let reversed = null;
    let baseReversed = null;
    let y = 0;
    let baseY = 0;

    if (val >= 0) {
      y = scales.y(val + diffCurveValues[i]);
    } else {
      bottom = scales.y(diffBorderValues[i]);
      y = scales.y(-val + diffBorderValues[i]);
      reversed = true;
    }
    if (baselineValue >= 0) {
      baseY = scales.y(baselineValue + diffCurveBaselineValues[i]);
    } else {
      baseBottom = scales.y(diffBorderBaselineValues[i]);
      baseY = scales.y(-baselineValue + diffBorderBaselineValues[i]);
      baseReversed = true;
    }

    let r = {
      value: val,
      baselineValue,
      x: scales.x(year),
      y: y,
      baseY,
      width: width,
      bottom: bottom,
      baseBottom,
      year: year,
      label: prettyPrint(val, diffArea.format),
      reversed: reversed,
      baseReversed,
    };

    return r;
  });
};

const makeDiffCurve = (diffCurve, diffBorder, info, scales) => {
  let width = findWidth(scales);
  let diffCurveValues = getValues(diffCurve, info);
  let diffBorderValues = getValues(diffBorder, info);
  let diffCurveBaselineValues = getBaselineValues(diffCurve, info);
  let diffBorderBaselineValues = getBaselineValues(diffBorder, info);

  return diffCurveValues.map(function(val, i) {
    let baselineValue = diffCurveBaselineValues[i];
    let year = info.valuationYear + i;
    let bottom = scales.y(0);
    let reversed = null;
    let y = scales.y(val);
    let baseY = scales.y(baselineValue);

    if (val > diffBorderValues[i]) {
      y = scales.y(diffBorderValues[i]);
    }
    if (baselineValue > diffBorderBaselineValues[i]) {
      baseY = scales.y(diffBorderBaselineValues[i]);
    }

    let r = {
      value: val,
      x: scales.x(year),
      y: y,
      baseY,
      width: width,
      bottom: bottom,
      baseBottom: bottom,
      year: year,
      label: prettyPrint(val, diffCurve.format),
      reversed: reversed,
    };

    return r;
  });
};

export default diffBar;
