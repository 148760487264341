// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { unsetEditingScenario, updateScenario } from '../actionCreators';
import { getEditingScenarioInfo } from '../selectors';
import { checkGetMetrics, saveScenario2 } from 'client/sideBar/apiActions';

const styles = {
  editingScenario: {
    clear: 'both',
    width: '100%',
    overflow: 'hidden',
    margin: '0.03em',
    backgroundColor: 'white',
    padding: '5px 10px',
    whiteSpace: 'normal',
  },
  comparisonName: {
    cursor: 'pointer',
    paddingRight: '20px',
  },
  scenarioCurrent: {
    backgroundColor: 'rgb(0, 204, 0)',
    color: 'white',
    maxWidth: '5em',
    margin: '0px',
    padding: '0.3em 0.5em',
    borderRadius: '5px',
  },
};

const EditingScenario = ({ scenarioLabel }) => (
  <div style={styles.editingScenario}>
    <span className="comparisonName" style={styles.comparisonName}>
      <span className="scenario-current" style={styles.scenarioCurrent}>
        {scenarioLabel}
      </span>
    </span>
  </div>
);

const EditScenario = props => {
  const dispatch = useDispatch();
  const scenarioInfo = useSelector(state => getEditingScenarioInfo(state));
  const workspaceInfo = useSelector(state => state.workspace.info);

  if (!scenarioInfo) return <div />;

  const onClickCancel = () => {
    dispatch(unsetEditingScenario());
  };

  const onClickUpdate = () => {
    dispatch(updateSavedScenario(scenarioInfo));
  };

  const updateSavedScenario = scenarioInfo => {
    return dispatch => {
      const { scenarioId, hasBeenSaved } = scenarioInfo;
      dispatch(updateScenario(scenarioId));
      dispatch(checkGetMetrics({ workspaceInfo, scenarioId, purpose: 'Update Scenario', newOrUpdatedScenario: true })).then(() => {
        if (hasBeenSaved) {
          dispatch(saveScenario2(workspaceInfo, scenarioId));
        }
      });
    }
  }

  const { scenarioLabel } = scenarioInfo;
  return (
    <div>
      <Button className="pull-left" style={{ marginBottom: '1em' }} onClick={onClickCancel} bsStyle="danger">
        Cancel Edit
      </Button>
      <Button
        className="pull-right"
        bsStyle="success"
        style={{ width: '60%', marginBottom: '1em' }}
        onClick={onClickUpdate}
        disabled={!scenarioInfo.anyDeviateFromScenario}
      >
        Update Scenario
      </Button>
      <EditingScenario {...{ scenarioLabel }} />
    </div>
  );
};

export default withRouter(EditScenario);
