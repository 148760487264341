// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import { Constants } from 'common/constants.js';
import { FISCALYEARPERIODS, VALUATIONYEARBALANCES, VALUATIONYEARPERIODS } from 'lib/defaults.js';

const { DOLLARS, PERCENT /*, INTEGER*/ } = Constants.formats;
const cashflows = 'cashflows';
const balanceSheet = 'balanceSheet';
const recommendedContributions = 'recommendedContributions';
const derived = 'derived'; // populated by populateDerivedSeries in lib.
const sideFund = 'sideFund';

const employeeContributions = {
  position: cashflows,
  title: 'Employee Contributions',
  color: 'navy',
};
const employerNormalCostDollars = {
  position: recommendedContributions,
  title: 'Employer Normal Cost',
  color: 'blue',
};
const employerNormalCostRate = {
  title: 'Employer Normal Cost',
  position: recommendedContributions,
  color: 'blue',
};
const amortizationPaymentDollars = {
  position: recommendedContributions,
  title: 'Annual Amortization Payment',
  color: 'red',
};
const amortizationContributionRate = {
  position: recommendedContributions,
  title: 'Annual Amortization Payment',
  color: 'red',
};
const benefitsPayments = {
  title: 'Benefit Payments',
  position: derived,
  color: 'red',
};
const expenses = {
  chartRules: { hideExpenses: false },
  title: 'Expenses',
  position: derived,
  color: 'turquoise',
};
const assetReturns = {
  title: 'Investment Return',
  position: cashflows,
  color: 'orange',
};
const pensionAssetReturns = {
  title: 'Investment Return',
  position: cashflows,
  color: 'orange',
};
const amortPayment = {
  position: derived,
  title: 'Annual Amortization Payment',
  color: 'blue',
};
const amortBalance = {
  position: derived,
  title: 'Outstanding Balance',
  color: 'red',
};
const fundedRatio = {
  title: 'Funded Percentage (Valuation Assets)',
  position: balanceSheet,
  color: 'blue',
};
const marketFundedRatio = {
  title: 'Funded Percentage (Market Value of Assets)',
  position: balanceSheet,
  color: 'red',
};
const percentOfARCDollars = {
  title: 'Percent ADC',
  position: 'employerContributions',
  color: 'orange',
};
const percentOfPayDollars = {
  title: 'Percent Pay',
  position: 'employerContributions',
  color: 'navy',
};
const percentOfBenefitsDollars = {
  title: 'Percent Benefits',
  position: 'employerContributions',
  color: 'lightPurple',
};
const additionalDollarDollars = {
  title: 'Additional Dollar',
  position: 'employerContributions',
  color: 'red',
};
const percentOfARCRate = {
  title: 'Percent ADC',
  position: 'employerContributions',
  color: 'orange',
};
const percentOfPayRate = {
  title: 'Percent Pay',
  position: 'employerContributions',
  color: 'navy',
};
const percentOfBenefitsRate = {
  title: 'Percent Benefits',
  position: 'employerContributions',
  color: 'lightPurple',
};
const additionalDollarRate = {
  title: 'Additional Dollar',
  position: 'employerContributions',
  color: 'red',
};
const amortizationDetail = {
  title: 'Amortization Details',
  position: derived,
  color: 'turquoise',
};

const unfundedLiabilities = {
  title: 'Unfunded Accrued Liability',
  stacked: false,
  position: balanceSheet,
  color: 'red',
};
const totalEmployerCosts = {
  position: cashflows,
  title: 'Employer Contributions',
  color: 'green',
};

const employerContributionDollars = {
  position: recommendedContributions,
  title: 'Total Actuarial Determined Contribution',
  color: 'orange',
};

const employerContributionRate = {
  position: recommendedContributions,
  title: 'Total Actuarial Determined Contribution',
  color: 'orange',
};
const pensionAssetsBegin = {
  title: 'Valuation Assets',
  position: balanceSheet,
  color: 'blue',
};
const pensionOverviewSeries = {
  employerContributionDollars,
  employerContributionRate,
  unfundedLiabilities,
  fundedRatio,
};

const opebCharts = {
  Dashboard: {
    'Overview Metrics': {
      format: 'text',
      defaultSeries: pensionOverviewSeries,
      views: {
        overview: {},
      },
    },
  },
  Cost: {
    'Contribution Comparison': {
      chartRules: { excludeAssets: false, hideComparison: false },
      format: DOLLARS,
      xType: FISCALYEARPERIODS,
      defaultSeries: {
        totalContributions: {
          title: 'Contributions',
          position: derived,
          color: 'green',
        },
        employerContributionDollars: {
          ...employerContributionDollars,
          title: 'ADC',
        },
        benefitsPayments: {
          title: 'Benefit Payments',
          position: cashflows,
          color: 'navy',
          view: 'line',
          stacked: false,
        },
      },
      views: {
        bar: {},
        line: {},
        table: {},
      },
    },
    'Contribution Comparison - Percent of Pay': {
      chartRules: { excludeAssets: false, hideComparison: false },
      format: PERCENT,
      xType: FISCALYEARPERIODS,
      defaultSeries: {
        totalContributionsRate: {
          title: 'Contributions',
          position: derived,
          color: 'green',
        },
        employerContributionRate: {
          ...employerContributionRate,
          title: 'ADC'
        },
        benefitsPaymentsRate: {
          title: 'PayGo',
          position: derived,
          color: 'navy',
          view: 'line',
          stacked: false,
        },
      },
      views: {
        bar: {},
        line: {},
        table: {},
      },
    },
    'Actuarial Determined Contribution': {
      chartRules: { excludeAssets: false },
      format: DOLLARS,
      xType: FISCALYEARPERIODS,
      defaultSeries: {
        employerContributionDollars: {
          ...employerContributionDollars,
          view: 'line',
          stacked: false,
          color: 'red',
        },
        amortizationPaymentDollars: {
          ...amortizationPaymentDollars,
          color: 'purple',
        },
        employerNormalCostDollars: {
          ...employerNormalCostDollars,
          color: 'orange',
        },
      },
      views: {
        area: {},
        stackedBar: {},
        line: {},
        table: {},
      },
    },
    'Actuarial Determined Contribution - Percent of Pay': {
      chartRules: { excludeAssets: false },
      format: PERCENT,
      xType: FISCALYEARPERIODS,
      defaultSeries: {
        employerContributionRate: {
          ...employerContributionRate,
          view: 'line',
          stacked: false,
          color: 'red',
        },
        amortizationContributionRate: {
          ...amortizationContributionRate,
          color: 'purple',
        },
        employerNormalCostRate: {
          ...employerNormalCostRate,
          color: 'orange',
        },
      },
      views: {
        area: {},
        stackedBar: {},
        line: {},
        table: {},
      },
    },
    'Employer Contribution': {
      chartRules: { excludeAssets: false },
      format: DOLLARS,
      xType: FISCALYEARPERIODS,
      defaultSeries: {
        percentOfARCDollars,
        percentOfPayDollars,
        percentOfBenefitsDollars,
        additionalDollarDollars,
        payGoShortfallDollars: {
          title: 'PayGo Shortfall',
          position: 'employerContributions',
          color: 'turquoise',
        },
        totalContributions: {
          title: 'Total Contributions',
          view: 'line',
          stacked: false,
          position: derived,
          color: 'green',
        },
      },
      views: {
        stackedBar: {},
        line: {},
        table: {},
      },
    },
    'Employer Contribution - Percent of Pay': {
      chartRules: { excludeAssets: false },
      format: PERCENT,
      xType: FISCALYEARPERIODS,
      defaultSeries: {
        percentOfARCRate,
        percentOfPayRate,
        percentOfBenefitsRate,
        additionalDollarRate,
        payGoShortfallRate: {
          title: 'PayGo Shortfall',
          position: 'employerContributions',
          color: 'turquoise',
        },
        totalContributionsRate: {
          title: 'Total Contributions',
          view: 'line',
          stacked: false,
          position: derived,
          color: 'green',
        },
      },
      views: {
        stackedBar: {},
        line: {},
        table: {},
      },
    },
    'Normal Cost': {
      format: DOLLARS,
      xType: FISCALYEARPERIODS,
      defaultSeries: {
        employerNormalCostDollars: {
          ...employerNormalCostDollars,
          color: 'orange',
        },
      },
      views: {
        stackedBar: {},
        line: {},
        table: {},
      },
    },
    'Normal Cost - Percent of Pay': {
      format: PERCENT,
      xType: FISCALYEARPERIODS,
      defaultSeries: {
        employerNormalCostRate: {
          ...employerNormalCostRate,
          color: 'orange',
        },
      },
      views: {
        stackedBar: {},
        line: {},
        table: {},
      },
    },
  },
  Liability: {
    'Unfunded Accrued Liability': {
      chartRules: { excludeAssets: false },
      format: DOLLARS,
      xType: VALUATIONYEARBALANCES,
      defaultSeries: {
        pensionLiabilities: {
          title: 'Accrued Liability',
          stacked: false,
          view: 'line',
          position: balanceSheet,
          color: 'navy',
          diffBorder: true,
        },
        pensionAssetsBegin: {
          ...pensionAssetsBegin,
          stacked: false,
          diffCurve: true,
          aboveColor: 'red',
          belowColor: 'green',
        },
        unfundedLiabilities,
      },
      views: {
        diffBar: {},
        diffView: {},
        line: {},
        table: {},
      },
    },
    'Funded Percentage': {
      chartRules: { excludeAssets: false },
      format: PERCENT,
      xType: VALUATIONYEARBALANCES,
      defaultSeries: {
        fundedRatio: {
          ...fundedRatio,
          chartRules: { hideValuation: false },
          color: 'red',
        },
        marketFundedRatio: {
          ...marketFundedRatio,
          color: 'blue',
        },
      },
      views: {
        bar: {},
        line: {},
        table: {},
      },
    },
    'Accrued Liability': {
      format: DOLLARS,
      xType: VALUATIONYEARBALANCES,
      defaultSeries: {
        totalAL: {
          title: 'Total',
          view: 'line',
          stacked: false,
          position: derived,
          color: 'orange',
        },
        retiredAL: {
          title: 'Annuitants',
          position: derived,
          color: 'blue',
        },
        termAL: {
          title: 'Terminated Members',
          position: derived,
          color: 'purple',
        },
        activeAL: {
          title: 'Active Members',
          position: derived,
          color: 'red',
        },
      },
      views: {
        stackedBar: {},
        line: {},
        table: {},
      },
    },
    'Present Value of Projected Benefits': {
      format: DOLLARS,
      xType: VALUATIONYEARBALANCES,
      defaultSeries: {
        totalPVB: {
          title: 'Total',
          view: 'line',
          stacked: false,
          position: derived,
          color: 'orange',
        },
        retiredAL: {
          title: 'Annuitants',
          position: derived,
          color: 'blue',
        },
        termAL: {
          title: 'Terminated Members',
          position: derived,
          color: 'purple',
        },
        activePVB: {
          title: 'Active Members',
          position: derived,
          color: 'red',
        },
      },
      views: {
        stackedBar: {},
        line: {},
        table: {},
      },
    },
  },
  Assets: {
    'Market Value of Assets': {
      chartRules: { excludeAssets: false },
      format: DOLLARS,
      xType: VALUATIONYEARPERIODS,
      defaultSeries: {
        marketAssetsBegin: {
          title: 'Assets - Beginning of Year',
          position: balanceSheet,
          color: 'blue',
        },
        employeeContributions,
        totalEmployerCosts,
        benefitsPayments,
        expenses,
        assetReturns,
      },
      views: {
        stackedBar: {},
        table: {},
      },
    },
    'Valuation Assets': {
      chartRules: { excludeAssets: false, hideValuation: false },
      format: DOLLARS,
      xType: VALUATIONYEARPERIODS,
      defaultSeries: {
        pensionAssetsBegin: {
          ...pensionAssetsBegin,
          title: 'Assets - Beginning of Year',
        },
        employeeContributions,
        totalEmployerCosts,
        benefitsPayments,
        expenses,
        pensionAssetReturns,
      },
      views: {
        stackedBar: {},
        table: {},
      },
    },
    'Cash Flow - Market Assets': {
      chartRules: { excludeAssets: false },
      format: DOLLARS,
      xType: VALUATIONYEARPERIODS,
      defaultSeries: {
        employeeContributions: {
          ...employeeContributions,
          color: 'blue',
        },
        totalEmployerCosts,
        benefitsPayments,
        expenses,
        assetReturns,
      },
      views: {
        stackedBar: {},
        bar: {},
        line: {},
        table: {},
      },
    },
    'Cash Flow - Valuation Assets': {
      chartRules: { excludeAssets: false, hideValuation: false },
      format: DOLLARS,
      xType: VALUATIONYEARPERIODS,
      defaultSeries: {
        employeeContributions,
        totalEmployerCosts,
        benefitsPayments,
        expenses,
        pensionAssetReturns,
      },
      views: {
        stackedBar: {},
        bar: {},
        line: {},
        table: {},
      },
    },
  },
  'Side Fund': {
    'Side Fund Calculations': {
      chartRules: { showSideFund: true },
      format: DOLLARS,
      xType: VALUATIONYEARPERIODS,
      defaultSeries: {
        balanceBegin: {
          title: 'Side Fund - Beginning of Year',
          position: sideFund,
          color: 'orange',
        },
        normalCostDollars: {
          title: 'Pool Normal Cost',
          position: sideFund,
          color: 'navy',
        },
        benefitPayments: {
          title: 'Benefit Payments',
          position: sideFund,
          color: 'lightPurple',
        },
        interest: {
          title: 'Interest',
          position: sideFund,
          color: 'red',
        },
        gainLoss: {
          title: 'Gain / Loss',
          position: sideFund,
          color: 'turquoise',
        },
        balanceEnd: {
          title: 'Side Fund EOY',
          position: sideFund,
          color: 'green',
        },
      },
      views: {
        stackedBar: {},
        table: {},
      },
    },
    'Side Fund UAL': {
      chartRules: { showSideFund: true },
      format: DOLLARS,
      xType: VALUATIONYEARBALANCES,
      defaultSeries: {
        balanceBegin: {
          title: 'Side Fund Accrued Liability',
          stacked: false,
          view: 'line',
          position: sideFund,
          color: 'orange',
        },
        pensionAssets: {
          title: 'Valuation Assets',
          stacked: false,
          position: sideFund,
          color: 'blue',
        },
        unfundedLiability: {
          title: 'Side Fund Unfunded Accrued Liability',
          position: sideFund,
          color: 'red',
        }
      },
      views: {
        stackedBar: {},
        area: {},
        line: {},
        table: {},
      },
    },
    'Side Fund ADC': {
      chartRules: { showSideFund: true },
      format: DOLLARS,
      xType: FISCALYEARPERIODS,
      defaultSeries: {
        totalADCDollars: {
          title: 'Side Fund Total Actuarially Determined Contribution',
          view: 'line',
          stacked: false,
          position: sideFund,
          color: 'green',
        },
        normalCostDollars: {
          title: 'Side Fund Employer Normal Cost',
          position: sideFund,
          color: 'navy',
        },
        amortizationPaymentDollars: {
          title: 'Side Fund Annual Amortization Payment',
          position: sideFund,
          color: 'red',
        }
      },
      views: {
        area: {},
        stackedBar: {},
        line: {},
        table: {},
      },
    },
    'Side Fund ADC % of Pay': {
      chartRules: { showSideFund: true },
      format: PERCENT,
      xType: FISCALYEARPERIODS,
      defaultSeries: {
        totalADCRate: {
          title: 'Side Fund Total Actuarially Determined Contribution',
          view: 'line',
          stacked: false,
          position: sideFund,
          color: 'green',
        },
        normalCostRate: {
          title: 'Side Fund Employer Normal Cost',
          position: sideFund,
          color: 'navy',
        },
        amortizationPaymentRate: {
          title: 'Side Fund Annual Amortization Payment',
          position: sideFund,
          color: 'red',
        }
      },
      views: {
        area: {},
        stackedBar: {},
        line: {},
        table: {},
      },
    },
  },
  'Amortization Bases': {
    'Outstanding Balance': {
      chartRules: { excludeAssets: false },
      xType: VALUATIONYEARBALANCES,
      format: DOLLARS,
      defaultSeries: {
        amortBalance,
      },
      views: {
        bar: {},
        line: {},
        table: {},
      },
    },
    'Annual Amortization Payment': {
      chartRules: { excludeAssets: false },
      xType: VALUATIONYEARPERIODS,
      format: DOLLARS,
      defaultSeries: {
        amortPayment: {
          ...amortPayment,
          color: 'purple',
        },
      },
      views: {
        bar: {},
        line: {},
        table: {},
      },
    },
    Details: {
      chartRules: { excludeAssets: false },
      xType: VALUATIONYEARPERIODS,
      format: DOLLARS,
      defaultSeries: {
        amortizationDetail,
      },
      views: {
        table: {},
      },
    },
  },
};
export default opebCharts;
