// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import { SIDEBAR_EXPANDED, SIDEBAR_MIN, PLAN_TYPES } from 'lib/defaults.js';

export function getSidebarWidth(state) {
  const showSideBar = state.ui.showSideBar;
  const marginLeft = showSideBar ? SIDEBAR_EXPANDED : SIDEBAR_MIN;
  return marginLeft;
}

export function getPlanType(state, props) {
  return state.workspace.info.planType;
}

export function getShowSideBar(state) {
  if(state.ui.showSideBar)
    return true;

  const project = state.user.project;
  if(!!project.generalError)
    return true;

  return false;
}

export function inPlan(state, props) {
  return PLAN_TYPES.includes(getPlanType(state, props));
}
