import { cloneDeep } from 'lodash';
import { CALC_STATUS_RECEIVED, DATA_REQUEST_STATUS, } from '../sideBar/actionTypes';

export const defaultState = {
  calculations: {},
  activeCalculations: false,
};

export function calcStatusReducer(state = defaultState, action) {
  switch (action.type) {
    case CALC_STATUS_RECEIVED: {
      const result = action.payload.result;
      if(result.status === 'NotFound')
        return {...state};
      const newState = cloneDeep(state);
      const activeCalculation = newState.calculations[action.payload.key];
      if(activeCalculation === undefined)
        return {...state};
      if(activeCalculation.status === 'Complete')
        return {...state};
      activeCalculation.message = result.message;
      activeCalculation.status = result.status;
      return newState;
    }
    case DATA_REQUEST_STATUS: {
      const newState = cloneDeep(state);
      const anyActiveCalcs = newState.calculations && !!Object.values(newState.calculations).find(x => x.isComplete === false);
      if(action.step === 'pending' && !anyActiveCalcs) {
        newState.activeCalculations = false;
        newState.calculations = {};
      }
      if(action.step === 'pending' && !newState.calculations[action.key]) {
        newState.activeCalculations = true;
        newState.calculations[action.key] = {
          status: 'Starting',
          message: 'Starting calculation...',
          scenario: action.purpose,
          scenarioKey: action.key,
          isComplete: false,
        };
      } else if(action.step === 'done') {
        const activeCalculation = newState.calculations[action.key];
        if(!activeCalculation)
          return newState;
        activeCalculation.status = 'Complete';
        activeCalculation.message = 'Calculation complete';
        activeCalculation.isComplete = true;
        if(Object.values(newState.calculations).filter(x => !x.isComplete).length === 0) {
          newState.activeCalculations = false;
        }
      }
      return newState;
    }
    default: {
      return state;
    }
  }
}