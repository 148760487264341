// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
/*
# defaults for pension adjustments
*/

// use this to minimize features for debugging
export const MIN_DEBUGGING = false;

export const DETAILS_VERSION = 2018010900;

export const ADJUSTMENT_YEARS = 30;

export const PLAN_PENSION = 'pension';
export const PLAN_OPEB = 'opeb';
export const PLAN_TYPES = [PLAN_PENSION, PLAN_OPEB];
export const PLAN_LABELS = {
  pension: 'Pension',
  opeb: 'OPEB',
};

export const PDF = 'pdf';
export const PNG = 'png';

export const SPECIFIED = 'specified';
export const FISCALYEARPERIODS = 'fiscalYearPeriods';
export const VALUATIONYEARBALANCES = 'valuationYearBalances';
export const VALUATIONYEARPERIODS = 'valuationYearPeriods';

export const DISTRIBUTION = 'distribution';
export const YEARS = 'years';
export const SIDEBAR_EXPANDED = '24rem';
export const SIDEBAR_MIN = '60px';

export const DEFAULT_FOLDER = 'General';
// deliberately increase max_additional to a very high value. See https://govinvest.myjetbrains.com/youtrack/issue/dev-250
export const MAX_ADDITIONAL_CONTRIB = 99999;
export const DEFAULT_PY = 5;

export const MAX_COMPARISONS = 7;
export const DEFAULT_CLASSIFICATION = 'All';

export const WARNING_DEFAULT_EMPLOYER_CONTRIBUTION_RAISED = `The sum of the Additional Contributions entered at the Classification level are greater than the amount entered for ${DEFAULT_CLASSIFICATION} Classifications. The amount at the ${DEFAULT_CLASSIFICATION} level has been modified to equal the sum of the amounts entered in the individual Classifications.`;

export const sectionLabels = {
  generalAssumptions: 'Assumptions and Methods',
  actualExperience: 'Actual Experience',
  planAdjustments: 'Member Experience',
  employerContributions: 'Employer Contributions',
  opeb_generalAssumptions: 'Assumptions and Methods',
  opeb_actualExperience: 'Actual Experience',
  opeb_planAdjustments: 'Member Experience',
  opeb_employerContributions: 'Employer Contributions',
};

export const typesMap = {
  slider: {
    label: 'Slider Input',
    years: 5,
  },
  yearRates: {
    label: '5 years of Input',
    years: 5,
  },
  yearRates10: {
    label: '10 years of Input',
    years: 10,
  },
  yearRates30: {
    label: '30 years of Input',
    years: 30,
  },
};
