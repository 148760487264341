// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { checkGetMetrics } from 'client/sideBar/apiActions.js';
import { isRequestingData } from 'client/lib/app-general.js';
import { YEARS } from 'lib/defaults.js';
import ChartTable from './ChartTable.jsx';
import Graphs from './graphs/Graphs.jsx';
import { setTab } from "../actions/chartsActions";
import { setYearParams } from '../actions';

// Callback for React.memo used to mirror functionality of old 'shouldComponentUpdate' lifecycle hook
const preventRender = (prevProps, nextProps) => !(!!nextProps.currentData && !!nextProps.availCharts);

const SectionGraph2 = React.memo(props => {
  const dispatch = useDispatch();
  const {
    viewingScenario,
    baselineScenario,
    isComparing,
    currentScenarios,
    currentComparison,
    baselineComparison,
    selectedView,
    availCharts,
    yearParams,
    valuationYear,
    endYear,
    projectionStart,
    marginLeft,
    fiscalStartDate,
    xAxisLabels,
    valuationStartDate,
    fiscalEndDate,
    valuationEndDate,
    fiscalYear1StartDifferentYearThanEnd,
    valuationYear1StartDifferentYearThanEnd,
    noScenarioLabel,
    currentData,
    scenarioMap,
    compareWithBaseline,
    requestingData,
    hiddenTopicChartSeries,
    workspaceInfo,
    showMiniDashboard,
    renderType,
  } = props;
  const { planType, topic, chart, view, tab } = selectedView;
  const chartSpec = availCharts[planType][topic][chart];

  const checkOrGetValues = scenarioId => {
    const scenario = scenarioMap[scenarioId];
    const values = scenario.values;
    if (!values && !isRequestingData(scenarioId, requestingData)) {
      dispatch(checkGetMetrics({ workspaceInfo, purpose: scenario.label, scenarioId: scenarioId, newOrUpdatedScenario: false }));
    }
  }

  useEffect(() => {
    if (isComparing) {
      currentScenarios.filter(scenario => scenario !== viewingScenario).forEach(scenario => {
        checkOrGetValues(scenario);
      })
    } else {
      checkOrGetValues(viewingScenario)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isComparing, currentScenarios, viewingScenario]);

  if (!currentData) {
    return null;
  }

  const doSetTab = tab => dispatch(setTab(tab));
  const doSetYearParams = (name, value) => dispatch(setYearParams(name, value));

  // events passed from the child graph component..
  const callbacks = {
    // change the selected year
    changeYear: y => {
      doSetYearParams('y', y);
    },
    changePinned: v => {
      doSetYearParams('pinned', v);
    },
    selectTab: tab => {
      doSetTab(tab);
    },
  };

  const removeNonDataElements = scenarioChartData => {
    const result = Object.keys(scenarioChartData).reduce(
      (a, s) => {
        const { chartData } = a;
        if (!Array.isArray(scenarioChartData[s])) {
          return { ...a };
        }
        return { ...a, chartData: { ...chartData, [s]: [...scenarioChartData[s]] } };
      },
      { chartData: {} },
    );
    return result;
  };

  const mapChartSeries = scenarioChartSeries => {
    return scenarioChartSeries.map(s => {
      //should this series combine on-top of the others?
      let isStacked = false;
      if (view === 'area' || view === 'stackedBar') {
        isStacked = true;
      }
      //use chart-spec overr-ride
      const chartSeries = chartSpec.views[view].series[s];
      if (chartSeries.stacked !== undefined) {
        isStacked = chartSeries.stacked;
      }
      //determine if series is hidden
      const currentTopic = selectedView.topic;
      const currentChart = selectedView.chart;
      const currentSeries = s;
      const seriesIsHidden = !!hiddenTopicChartSeries.find(hiddenSeries => {
        const topicSelected = hiddenSeries.topic === currentTopic;
        const chartSelected = hiddenSeries.chart === currentChart;
        const seriesSelected = hiddenSeries.series === currentSeries;
        return (topicSelected && chartSelected && seriesSelected);
      });

      return {
        id: s,
        view: chartSeries.view || view,
        lineType: chartSeries.lineType,
        stacked: isStacked,
        format: chartSeries.format || chartSpec.format,
        title: chartSeries.title,
        adjustments: [],
        diffCurve: chartSeries.diffCurve,
        diffBorder: chartSeries.diffBorder,
        color: chartSeries.color,
        aboveColor: chartSeries.aboveColor,
        belowColor: chartSeries.belowColor,
        hidden: seriesIsHidden,
      };
    })
  };

  // remove any non-data elements
  const { chartData } = removeNonDataElements(currentData);
  const chartSeries = Object.keys(chartData);

  const info = {
    id: chart,
    tab,
    isBaseline: baselineScenario === viewingScenario,
    xType: chartSpec.xType || YEARS,
    xAxisLabel: chartSpec.xAxisLabel,
    xAxisLabels,
    xAxisRotate: chartSpec.xAxisRotate,
    xAxisAdditionalMargin: chartSpec.xAxisAdditionalMargin,
    showMiniDashboard,
    renderType,
    //year info
    valuationYear,
    endYear,
    fiscalStartDate,
    valuationStartDate,
    fiscalEndDate,
    valuationEndDate,
    fiscalYear1StartDifferentYearThanEnd,
    valuationYear1StartDifferentYearThanEnd,
    endIndex: endYear - valuationYear,
    projectionStart,
    onYear: yearParams.y,
    yearIsPinned: yearParams.pinned,
    view,
    isComparing,
    compareWithBaseline,
    selectedView,
    hiddenTopicChartSeries,
    chartRules: {},
    //adjustment info
    onAdjustment: null,
    series: mapChartSeries(chartSeries),
  };

  // add adjustments
  currentScenarios.filter(c => isComparing || c === viewingScenario).forEach(c => {
    const scenario = scenarioMap[c];
    const values = scenario.values;
    if (values) {
      chartSeries.forEach(m => {
        info.series.find(s => s.id === m).adjustments.push({
          values: values[m],
          id: scenario.colorKey,
          label: scenario.label,
          isBaseline: baselineScenario === c,
          isCurrent: viewingScenario === c,
        });
      });
    }
  });

  const determineChartRules = info => {
    const diffBorder = info.series.find(series => series.diffBorder);
    const diffCurve = info.series.find(series => series.diffCurve);
    const diffArea = info.series.find(series => !series.diffCurve && !series.diffBorder);
    const diffCurveIsHidden = diffCurve && diffCurve.hidden;
    const diffBorderIsHidden = diffBorder && diffBorder.hidden;
    const diffAreaIsHidden = diffArea && diffArea.hidden;
    const viewIsDiffView = info.selectedView.view === 'diffView';
    const viewIsDiffBar = info.selectedView.view === 'diffBar';

    if (viewIsDiffView || viewIsDiffBar) {
      if (diffCurveIsHidden && !diffAreaIsHidden) {
        return {
          useDiffAreaCurveHidden: viewIsDiffView,
          useDiffBarCurveHidden: viewIsDiffBar,
          clampScaleMinToZero: false,
        };
      }
      if (diffBorderIsHidden && !diffAreaIsHidden && diffCurve) {
        return {
          useDiffView: viewIsDiffView,
          useDiffBar: viewIsDiffBar,
          clampScaleMinToZero: viewIsDiffView ? true : false,
          useAllSeriesForYScaling: true,
        }
      }
      return {
        useDiffView: viewIsDiffView,
        useDiffBar: viewIsDiffBar,
        clampScaleMinToZero: true,
      };
    }
  }

  info.chartRules = { ...determineChartRules(info) };

  if (view === 'json') {
    return <JsonView currentData={currentData} />;
  }

  if (!info.series.find(i => i.adjustments.find(a => a.isCurrent))) {
    console.error('no current', info);
  }

  const Control = view === 'table' ? ChartTable : Graphs;

  return <Control
    currentComparison={currentComparison}
    baselineComparison={baselineComparison}
    callbacks={callbacks}
    info={info}
    marginLeft={marginLeft}
    noScenarioLabel={noScenarioLabel}
    />;
}, preventRender);

export default SectionGraph2;

const JsonView = ({ currentData }) => {
  return <pre>{JSON.stringify(currentData, null, 2)}</pre>;
};
