// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************

import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ConfirmationModal = ({ onConfirm, onClose, message = 'Delete this saved comparison?', show }) => {
  return (
    <Modal show={show} onHide={onClose} bsSize="small" aria-labelledby="contained-modal-title-sm">
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onConfirm}>Ok</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmationModal;
