// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { connect } from 'react-redux';
import FakeGauge from './gauges/FakeGauge';
import { setTopicAndChart } from '../actions/chartsActions';
import YearSelector from './YearSelector';
import { getFiscalYear1StartLabel2, getValuationYear1StartLabel2 } from '../lib/planDefaultsSelectors';
import {getGaugeMetrics} from 'client/selectors/graphSelectors';
import { isRequestingData } from 'client/lib/app-general.js';
import { checkGetMetrics } from 'client/sideBar/apiActions.js';
import { getScenarioMap, } from 'client/sideBar/selectors';
import styled, { keyframes } from 'styled-components';

const barPulse = keyframes`
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
`;

const SSContainer = styled.div`
  display: grid;
  height: 56px;
  align-items: center;
  justify-items: center;

  margin: 8px;
`;

const SSTitle = styled.div`
  height: 20px;
  width: ${props => props.width}px;
  background: rgba(165, 165, 165, 0.1);
  animation: ${barPulse} 1s infinite ease-in-out;
`;
const SSDate = styled(SSTitle)`
  width: 130px;
`;

const GaugeTitle = styled.div`
  color: ${props => props.color};
  border-bottom: 1px solid ${props => props.color};
  margin: 8px;
  white-space: nowrap;
`;

const css = {
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 900,
    width: '100%',
    margin: '0 auto',
    justifyContent: 'space-around',
    flex: 1,
  },
  part: {
    width: 300,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    margin: 25,
  },
  description: {
    color: 'grey',
    marginTop: 30,
    textAlign: 'left',
  },
};

class Dashboard extends React.Component {
  componentDidUpdate(prevProps) {
    this.checkOrGetValues(this.props.viewingScenario);
    this.checkOrGetValues(this.props.baselineScenario);
  }

  checkOrGetValues(scenarioId) {
    const { workspaceInfo, requestingData, scenarioMap } = this.props;
    const scenario = scenarioMap[scenarioId];
    const values = scenario.values;
    if (!values && !isRequestingData(scenarioId, requestingData)) {
      this.props.dispatch(checkGetMetrics({ workspaceInfo, purpose: scenario.label, scenarioId: scenarioId, newOrUpdatedScenario: false }));
    }
  }

  makeGauge = (metric, number, numberSecondary, i, fetchingData) => {
    const { fiscalStartLabel, valuationStartLabel } = this.props;
    const metricTopic = metric.link[0];
    const metricChart = metric.link[1];
    const metricTitle = !!metric.title ? metric.title : '';
    const metricSubtitle = !!metric.subtitle ? metric.subtitle : '';
    const color =
      metric.color === 'red'
        ? '#D66D6A'
        : metric.color === 'blue' ? '#63B4D5' : metric.color === 'green' ? '#5BB372' : metric.color === 'orange' ? '#f0A965' : metric.color;
    return (
      <div
        key={i}
        style={css.part}
        onClick={() => {
          this.props.dispatch(setTopicAndChart({ topic: metricTopic, chart: metricChart }));
        }}
      >
        {metricTitle === '' || Object.keys(this.props.calcRequests).length < 1 ? (
          <SSContainer>
            <SSTitle width={Math.floor(Math.random() * (270 - 155 + 1) + 155)} />
            <SSDate width={Math.floor(Math.random() * (125 - 90 + 1) + 90)} />
          </SSContainer>
        ) : (
          <React.Fragment>
            <GaugeTitle color={color}>
              {metricTitle}
              <br />
              {metric.scaleType === 'fiscal' ? `${metricSubtitle} ${fiscalStartLabel}` : `${metricSubtitle} ${valuationStartLabel}`}
            </GaugeTitle>
          </React.Fragment>
        )}
        <FakeGauge color={color} number={number} numberSecondary={numberSecondary} fetchingData={fetchingData} />
      </div>
    );
  };

  loadingGauges() {
    const loadingGauge = i => this.makeGauge({ link: ['fake', 'fake'] }, null, null, i, true);
    return [loadingGauge(0), loadingGauge(1), loadingGauge(2), loadingGauge(3)];
  }

  render() {
    const { gaugeMetrics } = this.props;
    return (
      <div>
        <div className="dashboard-inner">
          <div>Click on a circle to view the corresponding chart</div>
          <div>
            <YearSelector />
          </div>
        </div>
        <div style={css.flex}>
          {gaugeMetrics
            ? gaugeMetrics.map((metric, metricIndex) => {
                let metricData = {
                  link: metric.link,
                  title: metric.label,
                  subtitle: metric.subtitle,
                  scaleType: metric.scaleType,
                  color: metric.color,
                };
                return this.makeGauge(metricData, metric.primary, metric.secondary, metricIndex);
              })
            : this.loadingGauges()}
        </div>
      </div>
    );
  }
}

export function stateToProps(state) {
  const gaugeMetrics = getGaugeMetrics(state);
  return {
    gaugeMetrics: gaugeMetrics,
    calcRequests: state.sideBar.calcRequests,
    fiscalStartLabel: getFiscalYear1StartLabel2(state),
    valuationStartLabel: getValuationYear1StartLabel2(state),
    selectedView: state.charts.selectedView,
    viewingScenario: state.sideBar.viewingScenario,
    baselineScenario: state.sideBar.baselineScenario,
    requestingData: state.viewSummary.requestingData,
    scenarioMap: getScenarioMap(state),
    workspaceInfo: state.workspace.info,
  };
}


export default connect(stateToProps)(Dashboard);
