// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { runScenarioButtonInfo, getNextScenario } from 'client/sideBar/selectors';
import { runScenario } from '../actionCreators';
import { setFiltersApplied } from '../actionCreators';
import { checkGetMetrics } from 'client/sideBar/apiActions';

// NB this component is exported for use in RunScenario.test.js
export const RunScenario = props => {
  const dispatch = useDispatch();
  const hasDeviated = useSelector(state => runScenarioButtonInfo(state).anyDeviateFromScenario);
  const nextScenario = useSelector(state => getNextScenario(state));
  const workspaceInfo = useSelector(state => state.workspace.info);
  const filters = useSelector(state => state.sideBar.filters);

  const handleClick = () => {
    const { scenarioId, scenarioLabel } = nextScenario;
    dispatch(setFiltersApplied(filters));
    dispatch(runScenario(scenarioId, scenarioLabel));
    dispatch(checkGetMetrics({
        workspaceInfo,
        purpose: scenarioLabel,
        scenarioId: scenarioId,
        newOrUpdatedScenario: true,
      }));
  };
  const buttonClass = hasDeviated ? 'success' : 'default';
  return (
    <Button bsStyle={buttonClass} block disabled={!hasDeviated} onClick={handleClick}>
      Run Scenario
    </Button>
  );
};

export default withRouter(RunScenario);
