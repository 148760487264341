// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************

const defaultState = {
  showTour: false,
  showSideBar: true,
  showMiniDashboard: true,
  scenariosSelectDownloadDialogShow: false,
};

const ui = (state = defaultState, action) => {
  switch(action.type) {
    case 'TOGGLE_SHOW_TOUR': {
      return {
        ...state,
        showTour: !state.showTour,
      };
    }
    case 'TOGGLE_SHOW_SIDEBAR': {
      return {
        ...state,
        showSideBar: !state.showSideBar,
      }
    }
    case 'TOGGLE_SHOW_MINI_DASHBOARD': {
      return {
        ...state,
        showMiniDashboard: !state.showMiniDashboard,
      }
    }
    case 'SCENARIOS_SELECT_DOWNLOAD_DIALOG_SHOW': {
      return {
        ...state,
        scenariosSelectDownloadDialogShow: action.payload,
      };
    }
    default: return state;
  }
}

export default ui;