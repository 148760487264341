// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { useDispatch } from 'react-redux';
import uuid4 from 'uuid/v4';
import { toggleFilterCategoryCollapsed } from '../actionCreators';
import FilterItem from './FilterItem';

const css = {
  filterItemsContainer: {
    paddingLeft: '40px',
    margin: '5px 0',
  },
  filterCategoryHeader: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    margin: '0 0 2.5px 5px',
  },
  filterCategoryLabel: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '5px',
  },
  filterCategoryTitle: {
    fontSize: '16px',
    lineHeight: '16px',
    marginRight: '10px',
  },
};

const FilterCategory = ({ filterCategory, filtersSelected, deviates }) => {
  const dispatch = useDispatch();

  const handleCollapseToggle = () => {
    dispatch(toggleFilterCategoryCollapsed({ filterCategoryName: filterCategory.name }));
  };

  return (
    <div>
      <div className="" style={css.filterCategoryHeader} onClick={() => handleCollapseToggle()}>
        <span className="caret" style={{ display: 'inline-block', transform: filterCategory.collapsed ? 'rotate(-90deg)' : '' }} />
        <div className="sideBar-filter-category-label" style={css.filterCategoryLabel}>
          <h1 className={`sideBar-filter-category-title ${deviates ? 'changed' : ''}`} style={css.filterCategoryTitle}>
            {filterCategory.label}
          </h1>
        </div>
      </div>
      {!filterCategory.collapsed && filterCategory.items && filterCategory.items.length > 1 && (
        <ul style={css.filterItemsContainer}>
          {filterCategory.items.map((filterItem, i) => (
            <FilterItem filterItem={filterItem} filterCategory={filterCategory} selected={filtersSelected.find(x => x === filterItem.name)} key={uuid4()} />
          ))}
        </ul>
      )}
    </div>
  );
};

export default FilterCategory;
