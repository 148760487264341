// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { line, area, curveMonotoneX } from 'd3-shape';
import tinycolor from 'tinycolor2';

export default class Line extends React.Component {
  render() {
    const { chart, renderCurrentComparison, renderBaselineComparison, isSingleComparisonMode } = this.props;

    const linePath = line()
      .x(d => d.x)
      .y(d => d.y)
      .curve(curveMonotoneX)(chart.points);
    const baselineLinePath = line()
      .x(d => d.x)
      .y(d => d.baseY)
      .curve(curveMonotoneX)(chart.points);
    const baselineAreaPath = area()
      .x(d => d.x)
      .y0(d => d.y)
      .y1(d => d.baseY)
      .curve(curveMonotoneX)(chart.points);

    if (renderCurrentComparison && !isSingleComparisonMode) {
      return <path d={linePath} stroke={tinycolor(chart.color).darken(20)} strokeWidth={4} fill="none" opacity={0.9} shapeRendering="auto" />;
    }

    if (renderBaselineComparison) {
      return (
        <React.Fragment>
          <path d={baselineLinePath} stroke={tinycolor(chart.color).darken(15)} strokeWidth={2} fill="none" opacity={0.9} shapeRendering="auto" />
          <path d={baselineAreaPath} strokeWidth={0} fill={chart.color} opacity={0.2} shapeRendering="geometricPrecision" />
        </React.Fragment>
      );
    }

    return <path d={linePath} stroke={chart.color} strokeWidth={4} fill="none" opacity={0.9} shapeRendering="auto" />;
  }
}
