// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import './ScenarioSummary.css';

const ScenarioSummary = ({ summaryData }) => {
  if (!summaryData.length) {
    return <strong>Compare Baseline</strong>;
  }
  return (
    <div className="scenarioSummaryContainer">
      <ul>
        {summaryData.map((adjustment, key) => {
          if (adjustment.heading) {
            return (
              <strong key={key} className="summaryHeading">
                {adjustment.label}
              </strong>
            );
          }

          let values = '';
          if(!Array.isArray(adjustment.values)) {
            values = adjustment.values;
          }
          else if (adjustment.values.length > 1) {
            adjustment.values.forEach((currentValue, key) => {
              if (currentValue[1] !== '') values += `${currentValue[0]}:${currentValue[1]}, `;
            });
          } else {
            values = adjustment.values[0];
          }

          return (
            <li key={key}>
              {adjustment.label} : {values}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ScenarioSummary;
