// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { DEFAULT_PY } from 'lib/defaults.js';
import { SET_PROJECTED_YEARS } from 'client/sideBar/actionTypes';

import {
  NAV,
  DATA_REQUEST_STATUS,
  SET_YEAR_PARAMS,
  DOWNLOAD_SELECTION,
} from '../actions';
import { PLAN_GROUPS_RECEIVED } from 'client/sideBar/actionTypes.js';
import { CHART_SET } from '../actions/chartsActions';

const START_YEAR = 2016; // this is updated when details are retrieved

export const defaultViewSummary = {
  requestingData: {},
  calcStatus: {},
  yearParams: { y: START_YEAR, PY: DEFAULT_PY, pinned: false },
  compareHistory: [],
  downloadUnchecked: {},
};

export const BAD_REQUEST = 'Selected groups and members do not intersect. A request cannot be made until this is corrected';

function viewSummary(state = defaultViewSummary, action) {
  switch (action.type) {
    case PLAN_GROUPS_RECEIVED: {
      const { payload } = action;
      return {
        ...state,
        yearParams: {
          ...state.yearParams,
          y: moment(payload.planGroupsInfo.valuationDate).year()
        },
      };
    }
    case CHART_SET: {
      const viewSummary = action.payload;
      if(viewSummary.chartXType === 'specified') {
        const yearParams = { ...state.yearParams, pinned: false };
        return {
          ...state,
          yearParams
        }
      }
      return state;
    }
    case SET_YEAR_PARAMS: {
      const { name, value } = action.result;

      const newYearParams = Object.assign({}, state.yearParams, { [name]: value });
      return {
        ...state,
        // force check for data for PY
        yearParams: newYearParams,
      };
    }
    case DATA_REQUEST_STATUS: {
      const { step, key, purpose } = action;
      const requestingData = { ...state.requestingData };
      let adjusting = cloneDeep(state.adjusting);
      const requestError = step === 'failed' ? BAD_REQUEST : null;
      if (step === 'done') {
        delete requestingData[key];
      } else {
        if (!requestingData[key]) {
          requestingData[key] = {
            date: Date.now(),
            purpose,
          };
        }
      }

      const result = {
        ...state,
        requestError,
        requestingData,
        adjusting,
      };
      return result;
    }

    case NAV: {
      return {
        ...state,
      };
    }
    case DOWNLOAD_SELECTION:
      return {
        ...state,
        downloadUnchecked: action.result,
      };

    case SET_PROJECTED_YEARS: {
      const { value, startYear } = action.payload;
      const PY = parseInt(value, 10);
      const yearParams = {
        ...state.yearParams,
        PY,
        y: Math.min(state.yearParams.y, startYear + PY - 1)
      }
      return {
        ...state,
        yearParams
      };
    }
    default:
      return state;
  }
}

export default viewSummary;
