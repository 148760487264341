// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import lineChart from './lineChart';
import { findByName } from 'common/colorMachine';

const diffViewCurveHidden = (info, scales, cmp, order) => {
  let diffCurve = info.series.find(s => s.diffCurve === true);
  let diffBorder = info.series.find(s => s.diffBorder === true);
  let diffArea = info.series.find(s => (!s.diffCurve && !s.diffBorder));

  let y = scales.y(0);
  let xMin = scales.x.range()[0];
  let xMax = scales.x.range()[1];

  let zeroPoints = [
    {
      x: xMin,
      y,
    },
    {
      x: xMax,
      y,
    }
  ]
 
  let top = scales.y.range()[1];
  let bottom = scales.y.range()[0];

  // 1. make the clip points
  let diffBorderPoints = lineChart(diffBorder, info, [], [], scales, cmp, order);
  let yearPoint = diffBorderPoints.find(point => point.year === cmp.state.onYear);
  if (yearPoint.order) {
    order += 1;
  }

  // 2. make diff area points
  let diffAreaPoints = lineChart(diffArea, info, [], [], scales, cmp, order);

  // 3. make the clip areas
  let aboveClip = { id: 'aboveClip', clipName: 'aboveClip', title: 'aboveClip', type: 'clipArea', above: true, points: zeroPoints, top, bottom };
  let belowClip = { id: 'belowClip', clipName: 'belowClip', title: 'belowClip', type: 'clipArea', above: false, points: zeroPoints, top, bottom };

  // 4. make the diff areas
  let belowBorder = { id: 'belowBorder', clipName: 'belowClip', title: 'belowBorder', type: 'diffArea', above: true, points: diffAreaPoints, top, bottom, color: findByName(diffCurve.belowColor || 'grey'), compareWithBaseline: info.compareWithBaseline };
  let aboveBorder = { id: 'aboveBorder', clipName: 'aboveClip', title: 'aboveBorder', type: 'diffArea', above: false, points: diffAreaPoints, top, bottom, color: findByName(diffCurve.aboveColor || 'grey'), compareWithBaseline: info.compareWithBaseline };

  // 5. make the diffBorder line
  let diffBorderLine = { id: diffBorder.id, title: diffBorder.title, type: 'line', points: diffBorderPoints, color: findByName(diffBorder.color || 'blue'), compareWithBaseline: info.compareWithBaseline };

  let result = [];

  if (!diffBorder.hidden) {
    result.push(diffBorderLine);
  }

  if (!diffArea.hidden) {
    result.push(aboveBorder, belowBorder, belowClip, aboveClip);
  }

  return result;
}

export default diffViewCurveHidden;