import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { getTenant } from '../../lib/sharedActions';
import { getProjects } from './projectListActions.js';
import * as routeBuilder from '../../lib/routeBuilder';
import SearchBox from '../SearchBox';
import { fontSizes } from '../../style/vars'
import { sortAlphabeticallyByField } from '../../lib/util';
import TenantImage from '../tenantImage/TenantImage';
import Spinner from '../Spinner';
import { colors } from '../../style/colors';
import IconLinkButton from '../IconLinkButton';
import ConfirmDialog from 'components/ConfirmDialog';
import { postCommand } from 'lib/commandClient';
import ErrorDialog from 'components/ErrorDialog';
import { filterSearch } from 'lib/filterUtils';

function ProjectsList() {
  const dispatch = useDispatch();
  const prms = useParams();
  const tenantId = prms.tenantId;
  const [filterString, setFilterString] = useState('');
  const [errorDialogState, setErrorDialogState] = useState({ visible: false });
  const currentUser = useSelector(rs => rs.currentUser);
  const projectsList = useSelector(rs => rs.projectsList);
  const projects = projectsList.projects;
  const currentTenant = useSelector(rs => rs.shared.currentTenant);
  const currentTenantId = !!currentTenant ? currentTenant.id : null;
  const [confirmState, setConfirmState] = useState({ visible: false });
  const [saving, setSaving] = useState(false);
  const isAdmin = !!currentUser && currentUser.isAdmin;

  useEffect(() => {
    document.title = 'GovInvest - Projects';
    dispatch(getTenant(tenantId));
    dispatch(getProjects(tenantId));
  }, [dispatch, tenantId]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setFilterString(value);
  };

  const debouncedHandleSearchChange = debounce(handleSearchChange, 300);

  const filterSearchBox = (obj) => {
    return filterSearch(obj, filterString, ['name', 'pensionPlanGroup', 'opebPlanGroup', 'mersVersion', 'mersEmployerId', 'projectId']);
  };

  const handleDisable = async (project) => {
    await postProjectCommand(project, 'DISABLE_PROJECT', 'Confirm Disable Project', 'Are you sure you want to disable this project?');
  };

  const handleEnable = async (project) => {
    await postProjectCommand(project, 'ENABLE_PROJECT', 'Confirm Enable Project', 'Are you sure you want to enable this project?');
  };

  const handleDelete = async (project) => {
    await postProjectCommand(project, 'DELETE_PROJECT', 'Confirm Delete Project', 'Are you sure you want to delete this project?');
  }

  const postProjectCommand = async (project, cmdType, title, message) => {
    const handleConfirmed = async (project) => {
      setSaving(true);
      const cmd = { type: cmdType, payload: { projectId: project.projectId } };
      const result = await postCommand(cmd);
      if (result.error) {
        console.error(result);
        setErrorDialogState({ visible: true, error: result.error, errorDescription: result.errorDescription });
        setSaving(false);
      } else {
        dispatch(getProjects(tenantId)).then(() => setSaving(false));
      }
    };
    setConfirmState({
      visible: true,
      title: title,
      message: message,
      onConfirm: () => handleConfirmed(project),
    });
  };

  const handleCloseErrorDialog = () => { setErrorDialogState({ visible: false }) };

  const filterEnabledProjects = (p) => {
    if (p.disabled && !isAdmin) //hide disabled projects from users
      return false;
    return true;
  };

  const planGroupLabel = (project) => {
    let label = '';
    if (!!project.pensionPlanGroup)
      label += project.pensionPlanGroup
    if (!!project.pensionPlanGroup && !!project.opebPlanGroup)
      label += ', ';
    if (!!project.opebPlanGroup)
      label += project.opebPlanGroup;
    if (!!project.mersVersion)
      label += project.mersVersion;
    if (!!project.mersEmployerId)
      label += ', ' + project.mersEmployerId;
    return label;
  };

  const defaultWorkspaceLink = (project) => {
    if (!!project.pensionPlanGroup)
      return routeBuilder.buildProjectPensionWorkspace(project.projectId);
    if (!!project.opebPlanGroup)
      return routeBuilder.buildProjectOpebWorkspace(project.projectId);
    if (!!project.mersVersion)
      return routeBuilder.buildMersPensionWorkspace(project.projectId);
    return '#';
  };

  if (!projects)
    return <Spinner />;

  if (tenantId !== projectsList.tenantId)
    return <Spinner />;

  if (tenantId !== currentTenantId)
    return <Spinner />;

  const enabledProjects = !!projects && projects.filter(filterEnabledProjects);
  const sortedFilteredProjects = sortAlphabeticallyByField(enabledProjects, 'name').filter(filterSearchBox);
  const disableButtons = saving;
  const showAdminButtons = isAdmin;

  return (
    <div>
      <div className="row" style={{ display: 'flex', flexDirection: 'row', padding: '10px', paddingLeft: '15px' }}>
        <div style={{ width: '32px', display: 'flex', alignItems: 'center' }}>
          <TenantImage tenantId={tenantId} width='32px' />
        </div>
        <div style={{ fontSize: fontSizes.l, padding: '15px' }}>{currentTenant.name}</div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px', marginBottom: '20px' }}>
        <div>
          <div style={{ fontSize: fontSizes.l, padding: '5px', paddingTop: '2px', marginLeft: '5px', marginRight: '20px', marginBottom: '5px' }}>Projects</div>
        </div>
        {
          showAdminButtons &&
          <div style={{ display: 'flex', alignItems: 'center', padding: '5px', color: colors.green, marginRight: '20px' }}>
            {
              <Link to={routeBuilder.buildAddProject(tenantId)}>
                <IconLinkButton text="Add" icon="plus" />
              </Link>
            }
          </div>
        }
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SearchBox onChange={debouncedHandleSearchChange} />
        </div>
      </div>

      <div className="row table highlight" style={{ fontSize: fontSizes.r, margin: '0 auto' }}>
        {
          (enabledProjects && enabledProjects.length === 0) && <div className="col-12" style={{ height: '100%', display: 'flex', alignItems: 'center' }}>No projects</div>
        }
        {
          sortedFilteredProjects.map(project => {
            const name = !!project.disabled ? (project.name + ' (Disabled)') : project.name;
            const defaultLink = defaultWorkspaceLink(project);
            return (
              <div className="table-row" key={project._id}>
                <div className="col-6" style={{ display: 'flex', flexDirection: 'column' }}>
                  <Link to={defaultLink}>
                    <div className="grey-link" style={{ display: 'flex', alignItems: 'center', fontStyle: (project.disabled ? 'italic' : '') }}>
                      {name}
                    </div>
                    {
                      isAdmin &&
                      <div style={{ fontSize: fontSizes.s, wordBreak: 'break-all', color:colors.darkGrey }}>
                        {planGroupLabel(project)}
                      </div>
                    }
                  </Link>
                </div>
                <div className="col-2" style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', padding: '8px', fontSize: fontSizes.m, paddingLeft: '30px' }}>
                  {
                    !!project.pensionPlanGroup &&
                    <div style={{ marginRight: '12px' }} title="Go to project workspace">
                      <Link to={routeBuilder.buildProjectPensionWorkspace(project.projectId)} disabled={disableButtons}>
                        <IconLinkButton text="Pension" icon="chart-bar" disabled={disableButtons} />
                      </Link>
                    </div>
                  }
                  {
                    !!project.opebPlanGroup &&
                    <div style={{ marginRight: '12px' }} title="Go to project workspace">
                      <Link to={routeBuilder.buildProjectOpebWorkspace(project.projectId)} disabled={disableButtons}>
                        <IconLinkButton text="OPEB" icon="chart-bar" disabled={disableButtons} />
                      </Link>
                    </div>
                  }
                  {
                    !!project.mersVersion &&
                    <>
                    <div style={{ marginRight: '12px' }} title="Go to project workspace">
                      <Link to={routeBuilder.buildMersPensionWorkspace(project.projectId)} disabled={disableButtons}>
                        <IconLinkButton text="MERS Pension" icon="chart-bar" disabled={disableButtons} />
                      </Link>
                    </div>
                    <div style={{ marginRight: '12px' }} title="Go to project workspace">
                      <Link to={routeBuilder.buildMersPeerComparisonWorkspace(project.projectId)} disabled={disableButtons}>
                        <IconLinkButton text="MERS Peer Comparison" icon="chart-bar" disabled={disableButtons} />
                      </Link>
                    </div>
                    </>
                  }
                </div>
                <div className="col-4" style={{ display: 'flex', alignItems: 'center', padding: '8px', fontSize: fontSizes.m }}>
                  {
                    showAdminButtons &&
                    <div style={{ marginRight: '12px' }} title="Edit the project">
                      {
                        <Link disabled={disableButtons} to={routeBuilder.buildEditProject(tenantId, project.projectId)}>
                          <IconLinkButton disabled={disableButtons} text="Edit" icon="edit" />
                        </Link>
                      }
                    </div>
                  }
                  {
                    (showAdminButtons && !project.disabled) &&
                    <div style={{ marginRight: '12px' }} title="Disable the project">
                      {<IconLinkButton text="Disable" icon="ban" disabled={disableButtons} onClick={() => handleDisable(project)} />}
                    </div>
                  }
                  {
                    (showAdminButtons && project.disabled) &&
                    <div style={{ marginRight: '12px' }} title="Enable the project">
                      {<IconLinkButton text="Enable" icon="plus-square" disabled={disableButtons} onClick={() => handleEnable(project)} />}
                    </div>
                  }
                  {
                    showAdminButtons &&
                    <div style={{ marginRight: '12px' }} title="Delete the project">
                      {<IconLinkButton text="Delete" icon="trash" disabled={disableButtons} onClick={() => handleDelete(project)} />}
                    </div>
                  }
                </div>
              </div>
            )
          })
        }
      </div>
      {confirmState.visible && <ConfirmDialog confirmState={confirmState}
        open={confirmState.visible} onConfirm={confirmState.onConfirm}
        onClose={() => setConfirmState({ ...confirmState, visible: false })} />}
      {errorDialogState.visible &&
        <ErrorDialog dialogState={errorDialogState} open={errorDialogState.visible} onClose={handleCloseErrorDialog} />}
    </div>

  )
}

export default ProjectsList;