import { homeRedirect } from './redirects';

export const errorOccurred = (error, errorDescription) => async dispatch => {
  console.error('Error occurred', { error, errorDescription });
  dispatch({ type: 'ERROR_OCCURRED', payload: { error, errorDescription }});
};

export const clearError = () => async dispatch => {
  dispatch({ type: 'CLEAR_ERROR' });
};

export const getAppEnvironments = () => async dispatch => {
    const result = await fetch(`/webapi/appenvironments`, {
      method: 'GET',
    });
    if(result.status === 401) {
        console.log('401 - redirecting home');
        homeRedirect();
        return;
    }
    if(result.status !== 200) {
        dispatch(errorOccurred('Error Getting App Environments', 'Unexpected status code: ' + result.status));
        return;
    }
    let items = [];
    try {
        items = await result.json();
        dispatch({ type: 'GET_APP_ENVIRONMENTS_SUCCESS', payload: { appEnvironments: items } });
    }
    catch(e) {
        console.error(e);
        dispatch(errorOccurred('Error Getting App Environments', 'Unable to read response'));
    }
};

export const getTenant = (tenantId) => async dispatch => {
    const result = await fetch(`/webapi/tenants/${tenantId}`, {
      method: 'GET',
    });
    if(result.status === 401) {
        console.log('401 - redirecting home');
        homeRedirect();
        return;
    }
    if(result.status !== 200) {
        dispatch(errorOccurred('Error Getting Tenant', 'Unexpected status code: ' + result.status));
        return;
    }
    let item = [];
    try {
        item = await result.json();
        dispatch({ type: 'GET_TENANT_SUCCESS', payload: { tenant: item } });
    }
    catch(e) {
        console.error(e);
        dispatch(errorOccurred('Error Getting Tenant', 'Unable to read response'));
    }
};

export const getProjects = (tenantId) => async dispatch => {
    const result = await fetch(`/webapi/projects?tenantId=${tenantId}`, {
        method: 'GET',
    });
    if(result.status === 401) {
        console.log('401 - redirecting home');
        homeRedirect();
        return;
    }
    if(result.status !== 200) {
        dispatch(errorOccurred('Error Getting Projects', 'Unexpected status code: ' + result.status));
        return;
    }
    let items = [];
    try {
        items = await result.json();
        dispatch({ type: 'GET_PROJECTS_SUCCESS', payload: { projects: items, tenantId: tenantId } });
    }
    catch(e) {
        console.error(e);
        dispatch(errorOccurred('Error Getting Projects', 'Unable to read response'));
    }
};

export const getProject = (projectId) => async dispatch => {
    const result = await fetch(`/webapi/projects/${projectId}`, {
        method: 'GET',
    });
    if(result.status === 401) {
        console.log('401 - redirecting home');
        homeRedirect();
        return;
    }
    if(result.status !== 200) {
        dispatch(errorOccurred('Error Getting Project', 'Unexpected status code: ' + result.status));
        return;
    }
    let item = null;
    try {
        item = await result.json();
        dispatch({ type: 'GET_PROJECT_SUCCESS', payload: { project: item } });
    }
    catch(e) {
        console.error(e);
        dispatch(errorOccurred('Error Getting Project', 'Unable to read response'));
    }
};

export const getProjectSimple = (projectId) => async dispatch => {
    const result = await fetch(`/webapi/projects/${projectId}`, {
        method: 'GET',
    });
    if(result.status === 401) {
        console.log('401 - redirecting home');
        homeRedirect();
        return;
    }
    if(result.status !== 200) {
        dispatch(errorOccurred('Error Getting Project', 'Unexpected status code: ' + result.status));
        return;
    }
    let item = null;
    try {
        item = await result.json();
        dispatch({ type: 'GET_PROJECT_SUCCESS', payload: { project: item } });
    }
    catch(e) {
        console.error(e);
        dispatch(errorOccurred('Error Getting Project', 'Unable to read response'));
    }
};