// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setScenarioLabelEditModal, setScenarioAsCompared, setViewingScenario, setViewingScenarioToDefault } from '../actionCreators';
import { getSavedScenarioInfo, summarizeViewParamsV2, getAdjustmentStructures } from '../selectors';
import { checkGetMetrics } from 'client/sideBar/apiActions';
import { Popover, OverlayTrigger, MenuItem } from 'react-bootstrap';
import ScenarioSummary from './ScenarioSummary';
import SendScenarioModal from './SendScenarioModal';
import HamburgerDropdown from './HamburgerDropdown';
import styled from 'styled-components';
import { SCENARIO_DEFAULT_ID } from '../constants';

const StyledSavedScenario = styled.div`
  clear: both;
  padding: 0.3em 0.5em;
  margin-top: 0.1em;
  margin-bottom: 0.2em;
  color: rgb(37, 56, 70);
  background-color: rgb(135, 148, 161);
`;

const ScenarioLabelWrapper = styled.span`
  cursor: pointer;
`;

const ScenarioButtons = styled.span`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 7px;
  align-items: center;
  justify-content: center;
`;

const popOver = (scenarioId, scenarioLabel, summarizeViewParams) => {
  return (
    <Popover placement="top" id={scenarioId} title={scenarioLabel}>
      <ScenarioSummary summaryData={summarizeViewParams} />
    </Popover>
  );
};

const SavedScenario = props => {
  const [sendScenarioModalShow, setSendScenarioModalShow] = useState(false);
  const scenarioInfo = useSelector(state => getSavedScenarioInfo(state, props));
  const adjustmentStructures = useSelector(state => getAdjustmentStructures(state));
  const summarizeViewParams = useSelector(state => summarizeViewParamsV2(state, props));
  const workspaceInfo = useSelector(state => state.workspace.info);
  const profile = useSelector(state => state.currentUser.profile);
  const userId = profile.userId;
  const { scenarioId } = props;
  const { scenarioLabel } = scenarioInfo;
  const dispatch = useDispatch();

  const onClickName = (e) => {
    e.preventDefault();
    dispatch(setScenarioLabelEditModal(scenarioId));
  };

  const onClickScenarioText = (e) => {
    e.preventDefault();
    dispatch(setScenarioAsCompared(scenarioId));
    if (scenarioId === SCENARIO_DEFAULT_ID) {
      dispatch(setViewingScenarioToDefault(adjustmentStructures));
    } else {
      dispatch(setViewingScenario(scenarioId));
    }
    dispatch(checkGetMetrics({ workspaceInfo, purpose: 'Set scenario', scenarioId: scenarioId, newOrUpdatedScenario: true }))
  };

  const onClickDelete = (e) => {
    e.preventDefault();
    props.confirmDeleteScenario(scenarioId);
  };

  const onClickSend = (e) => {
    e.preventDefault();
    setSendScenarioModalShow(true);
  };

  const handleClose = (e) => {
    if(e) {
      e.preventDefault();
    }
    setSendScenarioModalShow(false);
  };

  return (
    <React.Fragment>
      <StyledSavedScenario>
        <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={popOver(scenarioId, scenarioLabel, summarizeViewParams)}>
          <ScenarioLabelWrapper>
            <span className="savedScenarioName" onClick={onClickScenarioText}>
              {scenarioLabel}
            </span>
          </ScenarioLabelWrapper>
        </OverlayTrigger>
        <ScenarioButtons className="pull-right">
          {scenarioId !== SCENARIO_DEFAULT_ID &&
            <HamburgerDropdown dark dropup onSelect={() => { }}>
              <MenuItem href="http://" onClick={onClickSend}>Send</MenuItem>
              <MenuItem href="http://" onClick={onClickName}>Name</MenuItem>
              <MenuItem href="http://" onClick={onClickDelete}>Delete</MenuItem>
            </HamburgerDropdown>
          }
        </ScenarioButtons>
      </StyledSavedScenario>

      {sendScenarioModalShow && (
        <SendScenarioModal
          show={sendScenarioModalShow}
          handleClose={handleClose}
          scenarioId={scenarioId}
          workspaceInfo={workspaceInfo}
          userId={userId}
        />
      )}
    </React.Fragment>
  );
}

export default withRouter(SavedScenario);
