// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import pensionCharts from './pension-charts.js';
import opebCharts from './opeb-charts.js';
import mersPeerComparisonCharts from './mers-peer-comparison-charts';
import mersPensionCharts from './mers-pension-charts';

/**
 *
 * All the charts, in this structure:
 *
 * {
 *   **topic**1: {                                    // types of charts
 *     **chart**1: {                                  // the charts within a topic
 *       defaultSeries,                           // optional collection of series that will apply to views with no series
 *       **breakout**s,                               // optional collection of sub-charts. either breakouts OR views should be present
 *       format: 'dollars',                       // default format for series without one
 *       **view**s: {                                 // the available view types
 *         line: {                                // the name of a view
 *           **series**1: {                           // the series associated with it
 *             format: 'dollars',                 // format the values should be rendered with (eg dollars)
 *             title: 'Some series',              // the full title of the series
 *             position: cashflows,               // its position within the data
 *             derive: (resolved, defaults) => {} // optional function to derive data from resolved data. either position OR derive should be present
 *         },
 *         ...series2
 *       }
 *     },
 *     ...chart2
 *   },
 *   ...topic2
 * }
 *
 */
export const baseCharts = {
  pension: pensionCharts,
  opeb: opebCharts,
  'mers-peer-comparison': mersPeerComparisonCharts,
  'mers-pension': mersPensionCharts,
};
