export const roles = {
  SysAdmin: 'SysAdmin',
  Operator: 'Operator',
  TenantAdmin: 'TenantAdmin',
  User: 'User',
  CorpAdmin: 'CorpAdmin',
};
export const tenantAdminRoles = [
  roles.SysAdmin, roles.CorpAdmin, roles.Operator
];
export const roleNamesById = {
  SysAdmin: 'Sys Admin',
  Operator: 'Operator',
  TenantAdmin: 'Tenant Admin',
  User: 'User',
  CorpAdmin: 'Corp Admin',
};
export const guestRoles = [ roles.User, roles.TenantAdmin, ];