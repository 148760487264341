import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';

export function DisclaimerDialog() {
  const dispatch = useDispatch();
  const disclaimerState = useSelector(rs => rs.disclaimer)

  const disclaimerP1 = `The results reflected in this software are mathematical estimates of future events based on information provided/available and assumptions that may not materialize. Subsequent measurements may differ, possibly materially, from these estimates due to differences in assumptions, methods, plan demographics, provisions and assets/returns, or other new information.`;
  const disclaimerP2 = `Future costs and those associated with potential plan changes will change depending on actual experience that emerges. Results shown in the software are not considered a final actuarial report and are for illustrative purposes only. Neither the employer, nor any other party receiving or reviewing the data may rely on these calculations as indicative of future results.`;
  const disclaimerP3 = `By accessing the system, the employer agrees that MERS and GovInvest shall have no liability arising out of the employer’s use of the system or the provision of these amounts and rates, and agrees to indemnify MERS and GovInvest for any liability arising from employer’s use of the system and/or the provision of any information from this software to the employer or any other party or individual.`;

  return (
      <Modal
        show={!disclaimerState.acknowledged}
        className="static-modal"
        centered="true"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header>
          <Modal.Title>MERS Disclaimer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{disclaimerP1}</p>
          <p>{disclaimerP2}</p>
          <p>{disclaimerP3}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={() => dispatch({ type: 'ACKNOWLEDGE_DISCLAIMER' })}>
            Acknowledge
          </Button>
        </Modal.Footer>
      </Modal>
  );
}