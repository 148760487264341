// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { connect } from 'react-redux';
import { setAdjustmentDropdown } from '../actionCreators';
import './AdjustmentDropdown.css';

const AdjustmentDropdown = ({ adjustmentName, adjustmentDropdownData, setAdjustmentDropdown }) => {
  const handleSelect = (eventKey) => {
    setAdjustmentDropdown(eventKey, adjustmentName);
  }

  return (
    <table style={{ tableLayout: 'fixed', width: '100%' }} className="adjustment-drop-down">
      <tbody>
      <tr>
        <td>
          <DropdownButton id={`${adjustmentName}-dropdown`} title={adjustmentDropdownData.dropdownTitle} onSelect={handleSelect}>
            {adjustmentDropdownData.items.map(i => (
              <MenuItem key={i.name} eventKey={i.name} active={i.active}>{i.label}</MenuItem>
            ))}
          </DropdownButton>
        </td>
      </tr>
      </tbody>
    </table>
  );
};

export default connect(null, {setAdjustmentDropdown})(AdjustmentDropdown);