// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MenuItem } from 'react-bootstrap';
import * as routeBuilder from 'lib/routeBuilder';

export const PlanTypeSelect = () => {
  const history = useHistory();

  const planTypeInfo = useSelector(rs => rs.planTypeInfo);
  const availablePlanTypes = planTypeInfo.availablePlanTypes;
  const project = planTypeInfo.project;

  const planType = planTypeInfo.planType;

  const selectedStyle = {
    borderBottom: '3px solid #00bbe6',
  };
  const notSelectedStyle = {
    opacity: 0.3
  };

  return (
    <div className='plan-type-select'>
      {
        availablePlanTypes.map(availablePlanType => (
          <MenuItem
            style={(availablePlanType.id === planType) ? selectedStyle : notSelectedStyle}
            href="http://"
            onClick={e => e.preventDefault()}
            eventKey={availablePlanType.id}
            key={availablePlanType.id}
            onSelect={() => history.push(routeBuilder.buildWorkspaceUrl(project.projectId, availablePlanType.id))}
          >{availablePlanType.label}</MenuItem>
        ))
      }
    </div>
  );

};
export default PlanTypeSelect;