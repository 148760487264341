// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';

export default class CenteredPage extends React.Component {
  render() {
    const css = {
      body: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#F2F2F2',
      },
      center: {
        backgroundColor: 'white',
        textAlign: 'center',
        margin: 20,
        width: 600,
        minHeight: 200,
        padding: 20,
        marginBottom: 200,
        border: '1px solid lightgrey',
      },
    };
    return (
      <div style={css.body}>
        <div style={css.center}>{this.props.children}</div>
      </div>
    );
  }
}
