// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import _isEmpty from 'lodash/isEmpty';
import _fill from 'lodash/fill';
import _cloneDeep from 'lodash/cloneDeep';
import * as constants from './constants';

export const calculateDefaultValues = (props, sideBar) => {
  const { choices, defaults, history, variableType, type } = props;

  // Amortization Bases Fresh Start adjustment has a default of an empty array
  if (type === constants.TYPE_FRESH_START) {
    return [];
  }

  //if (type === constants.TYPE_CUSTOM_ADP_TIMING) {
    //return {};
  //}

  // Amortization Bases Payoff Priority adjustment has a default specific to its respective plan
  if (type === constants.TYPE_PAYOFF_PRIORITY) {
    const { extended: { planName } } = props;
    const { payoffPriorityDefault } = sideBar;
    return _cloneDeep(payoffPriorityDefault[planName]);
  }

  // dropdown only has choices, and not other properties
  let input;
  if (choices && !_isEmpty(choices)) {
    input = choices.filter(e => e.isDefault === true).map(i => i.name);
    return input;
  }

  // Adjustments of type assumption will always have default values
  if (variableType === constants.VARIABLE_TYPE_ASSUMPTION) {
    const defaultLastValueIndex = defaults.length - 1;
    const defaultLastValue = defaults[defaultLastValueIndex];
    input = _fill(Array(30), convertToNumberIfRequired(defaultLastValue));
    defaults.forEach((currentValue, index) => (input[index] = convertToNumberIfRequired(currentValue)));
  }

  //Adjustments of type experience can have a history, but its not required
  if (variableType === constants.VARIABLE_TYPE_EXPERIENCE) {
    input = _fill(Array(30), null);
    if (history && !_isEmpty(history)) {
      history.forEach((currentValue, index) => (input[index] = convertToNumberIfRequired(currentValue)));
    }
  }

  return input;
};

const convertToNumberIfRequired = input => input;
