// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';

export default function giStackedGraph(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 128 128"
      style={{ enableBackground: "new 0 0 128 128" }}
      width={128}
      height={128}
      {...props}
    >
      <metadata id="metadata23" />
      <defs id="defs21" />
      <g id="g5">
        <rect x="91.4" className="st0" width="36.6" height="49.4" id="rect7" />
        <rect y={75} className="st0" width="36.6" height={53} id="rect9" />
        <rect y="13.5" className="st0" width="36.6" height="54.9" id="rect11" />
        <rect x="45.7" className="st0" width="36.6" height="31.1" id="rect13" />
        <rect x="45.7" y="38.4" className="st0" width="36.6" height="89.6" id="rect15" />
        <rect x="91.4" y="56.4" className="st0" width="36.6" height="71.6" id="rect17" />
      </g>
      <path d="m 0.32542373,40.949153 0,-27.389831 18.16949127,0 18.169492,0 0,27.389831 0,27.38983 -18.169492,0 -18.16949127,0 0,-27.38983 z" id="path3351" />
      <path
        d="m 0.32542373,101.41642 0,-26.583585 18.03389827,0.142904 18.033898,0.142905 0.140753,26.440676 0.140752,26.44068 -18.17465,0 -18.17465127,0 0,-26.58358 z"
        id="path3363"
      />
      <path d="m 0.32542373,40.949153 0,-27.389831 18.16949127,0 18.169492,0 0,27.389831 0,27.38983 -18.169492,0 -18.16949127,0 0,-27.38983 z" id="path3365" />
      <path d="m 45.884746,15.457627 0,-15.457627 18.169491,0 18.169492,0 0,15.457627 0,15.457627 -18.169492,0 -18.169491,0 0,-15.457627 z" id="path3367" />
      <path d="m 45.884746,83.254237 0,-44.745762 18.169491,0 18.169492,0 0,44.745762 0,44.745763 -18.169492,0 -18.169491,0 0,-44.745763 z" id="path3369" />
      <path d="m 91.444068,24.677966 0,-24.677966 18.169492,0 18.16949,0 0,24.677966 0,24.677966 -18.16949,0 -18.169492,0 0,-24.677966 z" id="path3371" />
      <path d="m 91.444068,92.20339 0,-35.79661 18.169492,0 18.16949,0 0,35.79661 0,35.79661 -18.16949,0 -18.169492,0 0,-35.79661 z" id="path3373" />
    </svg>
  );
}
