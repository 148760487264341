// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { Glyphicon } from 'react-bootstrap';
import GlyphDropdown from './GlyphDropdown';

const HamburgerDropdown = ({ onSelect, children, dropup, dark }) => {
  return (
    <GlyphDropdown pullRight dropup={dropup} onSelect={onSelect} dark={dark} Glyph={<Glyphicon glyph="option-vertical" />}>
      {children}
    </GlyphDropdown>
  );
};

export default HamburgerDropdown;
