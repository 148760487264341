// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import prettyPrint from 'common/pretty_print';
import { getValues, getBaselineValues } from './getValues';
import { findWidth as barWidth } from './barChart';

const findWidth = function(scales) {
  return barWidth(scales, 1);
};

// A total line across a stacked bar chart that doesn't affect the stacking of the bars
const stackedBarLine = (obj, info, already, negAlready, scales) => {
  let width = findWidth(scales);
  let values = getValues(obj, info);
  let baselineValues = getBaselineValues(obj, info);
  return values.map(function(val, i) {
    let baselineValue = baselineValues[i];
    let year = info.valuationYear + i;
    let r = {
      value: val,
      baselineValue,
      x: scales.x(year),
      y: scales.y(val) || 0,
      baseY: scales.y(baselineValue) || 0,
      width: width,
      year: year,
      label: prettyPrint(val, obj.format)
    };
    return r;
  });
}

export default stackedBarLine;
