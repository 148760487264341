export const app = '/app';
export const appInfo = '/app/appinfo';
export const tenants = '/app/tenants';
export const tenantDetail = '/app/tenants/:tenantId';
export const addProject = '/app/tenants/:tenantId/addproject';
export const editProject = '/app/tenants/:tenantId/editproject/:projectId';
export const projectWorkspace = '/app/workspace/:projectId/:planType';

export const buildTenantDetail = (tenantId) => {
  return `/app/tenants/${tenantId}`;
};

export const buildAddProject = (tenantId) => {
  return `/app/tenants/${tenantId}/addproject`;
};

export const buildEditProject = (tenantId, projectId) => {
  return `/app/tenants/${tenantId}/editproject/${projectId}`;
};

export const buildProjectPensionWorkspace = (projectId) => {
  return `/app/workspace/${projectId}/pension`;
};

export const buildProjectOpebWorkspace = (projectId) => {
  return `/app/workspace/${projectId}/opeb`;
};

export const buildMersPensionWorkspace = (projectId) => {
  return `/app/workspace/${projectId}/mers-pension`;
};

export const buildMersPeerComparisonWorkspace = (projectId) => {
  return `/app/workspace/${projectId}/mers-peer-comparison`;
};

export const buildWorkspaceUrl = (projectId, planType) => {
  return `/app/workspace/${projectId}/${planType}`;
};