// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import { PROJECT_DETAILS, SET_ERROR_MESSAGE, PROJECT_PLAN_TYPE_DETAILS } from '../actions';

function user(
  state = {
    project: {},
    generalError: null,
  },
  action,
) {
  switch (action.type) {
    case PROJECT_PLAN_TYPE_DETAILS: {
      const { project } = action.result;
      return {
        ...state,
        project,
      };
    }
    case PROJECT_DETAILS: {
      const { project } = action.result;
      return {
        ...state,
        project,
      };
    }
    case SET_ERROR_MESSAGE: {
      return {
        ...state,
        generalError: action.result,
      };
    }
    default:
      return state;
  }
}

export default user;
