// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { useSelector } from 'react-redux';

import ChartView from './ChartView.jsx';

const SelectorBar = props => {
  const isServerSide = useSelector(state => state.isServerSide);
  let extraStyles = props.extraStyles || {};
  return (
    <div
      className="selector-bar"
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        ...extraStyles
      }}
    >
      &nbsp;
      {!isServerSide && <ChartView />}
    </div>
  );
}

export default SelectorBar;
