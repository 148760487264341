// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Table } from 'react-bootstrap';
import { find } from 'common/colorMachine';
import { format } from 'common/utils.js';
import prettyPrint from 'common/pretty_print';
import LegendCircle from './LegendCircle';
import AmortizationDetailsTable from './AmortizationDetailsTable';
import { FISCALYEARPERIODS, SPECIFIED, VALUATIONYEARPERIODS } from 'lib/defaults.js';
import { setChartSize } from '../actions/chartsActions';
import ReactResizeDetector from 'react-resize-detector';

const getYearColumnLabel = info => {
  let columnLabelPrefix;
  let columnLabelDate;

  if (info.xType === SPECIFIED) {
    return info.xAxisLabel;
  }
  else if (info.xType === FISCALYEARPERIODS) {
    columnLabelPrefix = 'Fiscal Year Ending';
    columnLabelDate = info.fiscalEndDate;
  } else if (info.xType === VALUATIONYEARPERIODS) {
    columnLabelPrefix = 'Year Ending';
    columnLabelDate = info.valuationEndDate;
  } else {
    columnLabelPrefix = 'As of';
    // special business rule. See dev-68 ticket
    const valuationStartDate = moment(info.valuationStartDate, 'MMMM D');
    // in moment month() is zero base, 11 representing December
    if (valuationStartDate.month() === 11 && valuationStartDate.date() === 31) {
      columnLabelDate = 'January 1';
    } else {
      columnLabelDate = info.valuationStartDate;
    }
  }

  return `${columnLabelPrefix} ${columnLabelDate}`;
};

class ChartTable extends React.Component {
  componentDidMount() {
    this.findWidth();
  }

  findWidth() {
    let { refs } = this;
    if (refs['graphs-table']) {
      this.props.setChartSize({
        width: refs['graphs-table'].offsetWidth,
        height: refs['graphs-table'].offsetHeight,
      });
    }
  };

  render() {
    const { callbacks: { changeYear }, info } = this.props;
    const { valuationYear, endIndex, onYear, series, isComparing, xAxisLabels } = info;

    const chartTable = (id, headings, rows) => {
      return (
        <Table key={id} className="metrics-data-table table tablecontent table-striped">
          <thead>
            <tr>{headings}</tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      );
    };

    const amortizationDetails = series.find(s => s.id === 'amortizationDetail');
    let tableSeries = series;
    if (amortizationDetails) {
      tableSeries = series.filter(s => s !== amortizationDetails);
    }

    const headingStyle = item => {
      return item.isCurrent
        ? {
            fontWeight: 'bold',
            color: find(item.id),
            borderLeft: '1px dotted ' + find(item.id),
          }
        : { color: find(item.id), borderLeft: '1px dotted transparent' };
    };
    let output = [];
    // how we are establishing what everything is compared to (the base)
    const isBase = 'isBaseline';
    const yearStr = year => {
      let y = '' + year;
      if (
        (info.xType === FISCALYEARPERIODS && info.fiscalYear1StartDifferentYearThanEnd) ||
        (info.xType === VALUATIONYEARPERIODS && info.valuationYear1StartDifferentYearThanEnd)
      ) {
        y = y.substr(2, 2) + '-' + ('' + (year + 1)).substr(2, 2);
      }
      return y;
    };

    if (isComparing) {
      // one table for each series. in each table, each row contains the x value (date) and the series value for each comparison
      tableSeries.forEach(series => {
        const base = series.adjustments.find(c => c[isBase]) || {};
        const others = series.adjustments.filter(c => !c[isBase]);
        const headings = [
          <th className="col-year centerValue" style={{ color: 'grey' }} key="y">
            {getYearColumnLabel(info)}
          </th>,
          <th className="compareHeader centerValue" style={headingStyle(base)} key="base">
            {base.label}
          </th>,
        ].concat(
          others.map(other => (
            <th className="compareHeader centerValue" style={headingStyle(other)} key={other.label}>
              {other.label}
            </th>
          )),
        );
        let rows = [];
        for (let i = 0; i <= endIndex; i++) {
          const year = valuationYear + i;
          // const isProjection = year > projectionStart;
          const isHighlighted = year === onYear;
          const isSpecified = info.xType === SPECIFIED;
          let classNames = 'dataTableRow ';
          // classNames += isProjection ? 'projection ' : '';
          classNames += isHighlighted ? 'highlighted ' : '';
          const baseValues = base.values || Array(endIndex + 1).fill('');
          rows.push(
            <tr key={i} onClick={() => changeYear(year)} className={classNames}>
              <td className="col-year centerValue" key="y">
                {!isSpecified && yearStr(valuationYear + i)}
                {isSpecified && xAxisLabels[i]}
              </td>
              <td className="centerValue" style={headingStyle(base)}>
                {prettyPrint(baseValues[i], series.format + 'Table')}
              </td>
              {others.map(other => {
                const otherValues = other.values || Array(endIndex + 1).fill('');
                const baseValue = baseValues[i];
                const seriesValue = otherValues[i];
                const title =
                  baseValue !== undefined && seriesValue !== undefined
                    ? base.label + ' is ' + format(series.format, Math.abs(baseValue - seriesValue)) + (baseValue > seriesValue ? ' more' : ' less')
                    : '';
                return (
                  <td className="centerValue" style={headingStyle(other)} key={other.label} title={title}>
                    {prettyPrint(otherValues[i], series.format + 'Table')}
                  </td>
                );
              })}
            </tr>,
          );
        }
        output.push(
          <div style={{ marginBottom: '2rem' }} key={series.id}>
            <span
              style={{
                marginTop: '1rem',
                marginBottom: '0.5rem',
                textAlign: 'center',
              }}
            >
              <LegendCircle series={series} />
            </span>
            {chartTable(series.id, headings, rows)}
          </div>,
        );
      });
    } else {
      // one table. each row contains the x value (date) and the series
      const headings = [
        <th className="col-year centerValue" key="y">
          {getYearColumnLabel(info)}
        </th>,
        ...tableSeries.map(series => {
          return (
            <th key={series.id}>
              <LegendCircle series={series} />
            </th>
          );
        }),
      ];
      let rows = [];
      const isSpecified = info.xType === SPECIFIED;
      for (let i = 0; i <= endIndex; i++) {
        const year = valuationYear + i;
        const isHighlighted = year === onYear;
        let classNames = 'dataTableRow ';
        classNames += isHighlighted ? 'highlighted ' : '';
        rows.push(
          <tr key={i} onClick={() => changeYear(year)} className={classNames}>
            <td className="col-year centerValue" key="year">
              {!isSpecified && yearStr(valuationYear + i)}
              {isSpecified && xAxisLabels[i]}
            </td>
            {tableSeries.map(aSeries => (
              <td className="centerValue" key={aSeries.id}>
                {prettyPrint(aSeries.adjustments[0].values[i], aSeries.format + 'Table')}
              </td>
            ))}
          </tr>,
        );
      }
      output.push(chartTable('t', headings, rows));
    }

    return (
      <section id="graphs-table" ref="graphs-table" className="bot-table-container container-fluid" style={{ marginTop: 10, overflowY:'auto', width:'100%', minWidth:'1000px' }}>
        <ReactResizeDetector handleWidth handleHeight onResize={this.findWidth.bind(this)} />
        {!amortizationDetails && <div className="table-responsive btc-table-bot">{output}</div>}
        {amortizationDetails && <AmortizationDetailsTable info={info} />}
      </section>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setChartSize: chartSize => dispatch(setChartSize(chartSize)),
});

export default connect(null, mapDispatchToProps)(ChartTable);
