// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { range } from 'lodash';

import AdjustmentYearRate from './AdjustmentYearRate.jsx';

const AdjustmentYearRates5 = ({ adjustmentName, yearLabels, adjustmentYearData, adjustmentStructure }) => {
  return (
    <table style={{ tableLayout: 'fixed', width: '100%' }} className="adjustment-year-rates-5">
      <tbody>
        <tr>
          {range(0, 5).map(i => (
            <td key={i}>
              <div>{yearLabels[i]}</div>
              <AdjustmentYearRate index={i} adjustmentName={adjustmentName} yearData={adjustmentYearData.values[i]} type={adjustmentStructure.type} unit={adjustmentYearData.unit} />
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};

export default AdjustmentYearRates5;
