import reducers from './rootReducer';
import thunk from './lib/redux-thunk.js';
import reporter from './lib/redux-reporter.js';
import { createStore, applyMiddleware } from 'redux';

const setupStore = () => {
  const store = createStore(
    reducers,
    applyMiddleware(thunk, reporter)
  );
  return store;
};
export default setupStore();