// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import uuid4 from 'uuid/v4';
import { Button, Glyphicon } from 'react-bootstrap';
import { isEqual } from 'lodash';
import { setFiltersApplied, resetFilterItemsSelected } from '../actionCreators';
import { checkGetMetrics } from '../apiActions';
import { getFilterCategories, getFilterApplyEnabled } from '../selectorsFilters';
import { getScenarioMap } from '../selectors';
import * as constants from '../constants';
import FilterCategory from './FilterCategory';

const css = {
  container: {
    padding: '7px',
    margin: '7px',
    background: '#3c4255',
  },
  filterHeader: {
    display: 'flex',
    alignItems: 'center',
    height: '58px',
  },
  filterHeaderTitle: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  },
  title: {
    fontSize: '16px',
    lineHeight: '16px',
    marginLeft: '10px',
  },
  filterCategories: {
    display: 'grid',
    gridRowGap: '10px',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '7px',
  },
  button: {
    fontSize: '12px',
    padding: '3px 6px',
  },
};

const Filter = props => {
  const dispatch = useDispatch();
  const filterCategories = useSelector(state => getFilterCategories(state));
  const filterApplyEnabled = useSelector(state => getFilterApplyEnabled(state));
  const filters = useSelector(state => state.sideBar.filters);
  const filtersSelected = useSelector(state => state.sideBar && state.sideBar.filters);
  const filtersApplied = useSelector(state => state.sideBar.filtersApplied);
  const comparedScenarios = useSelector(state => state.sideBar.comparedScenarios);
  const scenarioMap = useSelector(state => getScenarioMap(state, { includeDefault: true }));
  const workspaceInfo = useSelector(state => state.workspace.info);
  const [collapsed, setCollapsed] = useState(true);
  const [filterCategoriesDeviate, setFilterCategoriesDeviate] = useState({});

  const getFilteredCategories = () => {
    return filterCategories && filterCategories.length > 0
      ? filterCategories.filter(category => category.items.length > 1)
      : []
  }
  
  const handleApply = () => {
    dispatch(setFiltersApplied(filters));
    let scenariosToRun = [...comparedScenarios];
    // ensure that Default scenario is calculated whether it is compared or uncompared
    if (!scenariosToRun.includes(constants.SCENARIO_DEFAULT_ID)) {
      scenariosToRun.unshift(constants.SCENARIO_DEFAULT_ID);
    }
    scenariosToRun.forEach(scenarioId => {
      const scenario = scenarioMap[scenarioId];
      dispatch(checkGetMetrics({ workspaceInfo, purpose: scenario.label, scenarioId: scenario.name, newOrUpdatedScenario: false }));
    })
  };

  const handleCollapseToggle = () => {
    setCollapsed(!collapsed);
  };

  // On filter state change, determines the deviated state for all filter categories
  useEffect(() => {
    const filteredCategories = getFilteredCategories();
    let updatedFilterCategoriesDeviate = {
      ...filterCategoriesDeviate,
    }

    for (const category of filteredCategories) {
      const selected = filtersSelected[category.name];
      const applied = filtersApplied[category.name];
      const filtersDeviate = !isEqual(selected.sort(), applied.sort());
      updatedFilterCategoriesDeviate[category.name] = filtersDeviate;
    }

    setFilterCategoriesDeviate(updatedFilterCategoriesDeviate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterCategories, filtersApplied, filtersSelected]);

  const filtersDeviate = Object.keys(filterCategoriesDeviate).some(category => filterCategoriesDeviate[category]);
  const filterCategoriesToRender = getFilteredCategories();
  return (
    <div style={css.container}>
      <div className="sideBar-filter-header" style={css.filterHeader}>
        <div style={css.filterHeaderTitle} onClick={() => handleCollapseToggle()}>
          <span className="toggle sideBar-toggle">
            <Glyphicon glyph={!collapsed ? 'menu-down' : 'menu-right'} style={{ fontSize: 11, fontWeight: 900, color: '#a4aabb' }} />
          </span>
          <h1 className={filtersDeviate ? 'changed' : ''} style={css.title}>
            Filter
          </h1>
        </div>
        {!collapsed && (
          <span style={{ cursor: 'pointer' }} onClick={() => dispatch(resetFilterItemsSelected())}>
            <Glyphicon glyph={'repeat'} style={{ display: 'inline-block', color: '#a4aabb', transform: 'scaleX(-1)' }} />
          </span>
        )}
      </div>
      {!collapsed && filterCategories && filterCategories.length > 0 && (
        <div>
          <div style={css.filterCategories}>
            {filterCategoriesToRender
              .map(filterCategory => (
                <FilterCategory
                  key={uuid4()}
                  filterCategory={filterCategory}
                  filtersSelected={filtersSelected[filterCategory.name]}
                  deviates={filterCategoriesDeviate[filterCategory.name]}/>
              ))}
          </div>
          <div style={css.buttonWrapper}>
            <Button
              bsStyle={filterApplyEnabled ? 'success' : 'default'}
              style={css.button}
              size="sm"
              disabled={!filterApplyEnabled}
              onClick={handleApply}
            >
              Apply
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default withRouter(Filter);
