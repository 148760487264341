// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import SavedScenario from './SavedScenario';
import { Glyphicon } from 'react-bootstrap';
import Box from 'img/giBox.js';
import { getSavedScenarioList } from '../selectors';

// NOTE for folders can use import TreeView from 'react-treeview';
const SavedScenarioList = props => {
  const [collapsed, setCollapsed] = useState(true);
  const scenarios = useSelector(state => getSavedScenarioList(state).scenarioIds);
  const { confirmDeleteScenario } = props;

  const onClickHeading = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className="sideBar-adjustment-group" style={{ margin: '7px', padding: '8px' }}>
      <div className="sideBar-adjustment-group-header" onClick={onClickHeading}>
        <span>
          <Box />{' '}
        </span>
        <div className="adjustment-group-label">
          <h1>Saved Scenarios</h1>
          <span className="toggle sideBar-toggle">
            <Glyphicon glyph={!collapsed ? 'menu-down' : 'menu-right'} style={{ fontSize: 11, fontWeight: 900, color: '#a4aabb' }} />
          </span>
        </div>
      </div>
      {!collapsed ? (
        <div>
          {scenarios.length ? (
            scenarios.map(scenarioId => (
              <SavedScenario key={scenarioId} scenarioId={scenarioId} confirmDeleteScenario={confirmDeleteScenario} />
            ))
          ) : (
              <i> Saved scenarios not in current comparisons will appear here </i>
            )}
        </div>
      ) : null}
    </div>
  );
}

export default SavedScenarioList;
