// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import _fill from 'lodash/fill';
import _keyBy from 'lodash/keyBy';
import _omit from 'lodash/omit';
import _cloneDeep from 'lodash/cloneDeep';
import _forOwn from 'lodash/forOwn';
import _uniq from 'lodash/uniq';
import _find from 'lodash/find';
import _mapValues from 'lodash/mapValues';
import * as actionTypes from './actionTypes';
import units from './units';
import * as constants from './constants';
import { calculateDefaultValues } from './adjustmentDefaults';

export const setAdjustment1Year = ({ adjustmentName, index, value, unit, adjustmentYearRateOptions }) => {
  const unitDetails = units[unit];
  const meta = { adjustmentName, index, value, unit };
  if (!value || isNaN(value)) {
    return {
      type: unitDetails.actionType,
      payload: { adjustmentName, adjustmentYearRateOptions, index, value: value || null },
      meta,
    };
  }

  const calculatedValue = unitDetails.displayToData(value);
  return {
    type: unitDetails.actionType,
    payload: { adjustmentName, adjustmentYearRateOptions, index, value: calculatedValue },
    meta,
  };
};

export const setAdjustmentSlider = ({ adjustmentName, value }) => ({
  type: actionTypes.SET_ADJUSTMENT_SLIDER,
  payload: { adjustmentName, values: _fill(Array(30), value) },
});

export const setPercentAdjustmentSlider = ({ adjustmentName, value }) => ({
  type: actionTypes.SET_PERCENT_ADJUSTMENT_SLIDER,
  payload: { adjustmentName, values: _fill(Array(30), units['%'].displayToData(value)) },
});

export const setControlToSlider = ({ adjustmentName }) => ({
  type: actionTypes.SET_CONTROL_SLIDER,
  payload: { adjustmentName },
});

export const setControlTo5Year = ({ adjustmentName }) => ({
  type: actionTypes.SET_CONTROL_5YEAR,
  payload: { adjustmentName },
});

export const setControlTo10Year = ({ adjustmentName }) => ({
  type: actionTypes.SET_CONTROL_10YEAR,
  payload: { adjustmentName },
});

export const setControlTo30Year = ({ adjustmentName, values }) => ({
  type: actionTypes.SET_CONTROL_30YEAR,
  payload: { adjustmentName, values },
});

export const toggleAdjustmentGroupCollapsed = ({ adjustmentGroupName }) => ({
  type: actionTypes.TOGGLE_ADJUSTMENTGROUP_COLLAPSED,
  payload: { adjustmentGroupName },
});

export const toggleFilterCategoryCollapsed = ({ filterCategoryName }) => ({
  type: actionTypes.TOGGLE_FILTERCATEGORY_COLLAPSED,
  payload: { filterCategoryName },
});

export const resetFilterItemsSelected = () => ({
  type: actionTypes.RESET_FILTERITEMS_SELECTED,
});

export const toggle30YearModal = adjustmentName => ({
  type: actionTypes.TOGGLE_30YEAR_MODAL,
  payload: adjustmentName,
});

export const setErrorMessage = result => {
  return { type: actionTypes.SET_ERROR_MESSAGE, result };
};

export const openWillLoseDataModal = ({ adjustmentName, userSelectedControl }) => ({
  type: actionTypes.OPEN_WILL_LOSE_DATA_MODAL,
  payload: { adjustmentName, userSelectedControl },
});

export const cancelControlChange = () => ({
  type: actionTypes.CANCEL_CONTROL_CHANGE,
});

export const proceedControlChange = adjustmentName => ({
  type: actionTypes.PROCEED_CONTROL_CHANGE,
  payload: adjustmentName,
});

export const planGroupsReceived = apiResponse => {
  const result = {};
  result.planGroupsInfo = _omit(apiResponse, ['categories', 'adjustmentGroups', 'adjustments']);
  result.adjustmentStructure = constructAdjustmentStructure(apiResponse);
  result.adjustmentGroups = constructAdjustmentGroups(apiResponse);
  result.categories = constructCategories(apiResponse);
  result.adjustmentsMadeByUser = constructAdjustmentsMadeByUser(apiResponse);
  result.adjustmentControls = constructAdjustmentControls(apiResponse);
  result.filterCategories = constructFilterCategories(apiResponse);

  return {
    type: actionTypes.PLAN_GROUPS_RECEIVED,
    payload: result,
    meta: { apiResponse },
  };
};

export const setAdjustmentDropdown = (selectedChoiceName, adjustmentName) => ({
  type: actionTypes.SET_ADJUSTMENT_DROPDOWN,
  payload: { selectedChoiceName, adjustmentName },
});

export const setProjectedYears = (value, startYear) => {
  if (new RegExp('^\\d+$').test(value)) {
    return {
      type: actionTypes.SET_PROJECTED_YEARS,
      payload: { value: Number(value), startYear },
    };
  }
  return {
    type: actionTypes.SET_PROJECTED_YEARS,
    payload: { value, startYear },
  };
};

export const overrideAdjustment = (adjustmentItem, sideBar) => ({
  type: actionTypes.OVERRIDE_ADJUSTMENT,
  payload: {
    adjustmentName: adjustmentItem.name,
    values: calculateDefaultValues(adjustmentItem, sideBar),
  },
  meta: adjustmentItem,
});

export const removeOverride = adjustmentName => ({
  type: actionTypes.REMOVE_OVERRIDE,
  payload: {
    adjustmentName,
  },
});

const constructAdjustmentControls = apiResponse => {
  const result = _mapValues(_keyBy(_cloneDeep(apiResponse.adjustments), 'name'), e => ({
    name: e.name,
    control: 'default',
    collapsed: false,
  }));
  return result;
};

const _globalCategoryNames = categories => {
  return categories.filter(x => !x.categoryItems || x.categoryItems.length === 0)
    .map(x => x.name);
};

const _isGlobalCategory = (categories, category) => {
  return _globalCategoryNames(categories).indexOf(category) !== -1;
};

const constructAdjustmentsMadeByUser = apiResponse => {
  if(!apiResponse.adjustments)
    return {};
  const globalCategoryNames = _globalCategoryNames(apiResponse.categories);
  const adjustmentsToAdd = _cloneDeep(apiResponse.adjustments)
    .filter(e => globalCategoryNames.indexOf(e.category) !== -1 || e.addInitially);

  const result = _mapValues(_keyBy(adjustmentsToAdd, 'name'), () => []);
  adjustmentsToAdd.forEach(adjustment => {
    result[adjustment.name] = calculateDefaultValues(adjustment);
  });

  return result;
};

const constructCategories = apiResponse => {
  if(!apiResponse.categories)
    return {};
  const result = _cloneDeep(apiResponse.categories);
  result.forEach(e => {
    if (!e.categoryItems.length) {
      e.adjustmentGroupNames = [];
    } else {
      e.categoryItems.forEach(i => {
        i.adjustmentGroupNames = [];
      });
    }
  });

  apiResponse.adjustments.forEach(adjustment => {
    const category = _find(result, e => e.name === adjustment.category);
    console.assert(category, `API data error: Adjustment has reference to nonexistent category. ${JSON.stringify(adjustment)}`);
    if (!category) {
      return;
    }

    if (!category.categoryItems.length) {
      if (!category.adjustmentGroupNames.some(i => i === adjustment.groupName)) {
        category.adjustmentGroupNames.push(adjustment.groupName);
        category.hasAdjustment = true;
      }
    }

    if (category.categoryItems) {
      const uiGroupName = getUiGroupName(adjustment);
      const massagedAdjustmentCategoryItem = massageCategoryItem(adjustment.categoryItem);
      const categoryItem = _find(category.categoryItems, e => e.name === massagedAdjustmentCategoryItem);
      if (categoryItem && categoryItem.adjustmentGroupNames && !categoryItem.adjustmentGroupNames.some(i => i === uiGroupName)) {
        categoryItem.adjustmentGroupNames.push(uiGroupName);
        category.hasAdjustment = true;
      }
    }
  });

  return result;
};

// allows override in case backend is failing or providing bad info
const massageCategoryItem = adjustmentCategoryItem => {
  return adjustmentCategoryItem;
};

const constructAdjustmentStructure = apiResponse => {
  const result = _keyBy(_cloneDeep(apiResponse.adjustments), 'name');
  _forOwn(result, value => {
    delete value.groupName;
    value.isGlobal = _isGlobalCategory(apiResponse.categories, value.category);
    delete value.category;
    delete value.categoryItem;
    if (value.min !== undefined)
      value.min = Math.round(value.min * 1e7) / 1e7;
    if (value.max !== undefined)
      value.max = Math.round(value.max * 1e7) / 1e7;
  });
  return result;
};

const constructAdjustmentGroups = apiResponse => {
  if(!apiResponse.adjustments)
    return {};
  let data = _keyBy(_cloneDeep(apiResponse.adjustmentGroups), 'name');
  _forOwn(data, value => {
    value.adjustmentNames = [];
  });
  let groupsToDelete = [];
  // create groups if needed, add adjustmentNames information to capture group collection
  apiResponse.adjustments.forEach(adjustment => {
    if (!adjustment.categoryItem) {
      data[adjustment.groupName].adjustmentNames.push(adjustment.name);
      return;
    } else {
      groupsToDelete.push(adjustment.groupName);
      const uiGroupName = getUiGroupName(adjustment);
      if (!data[uiGroupName]) {
        data[uiGroupName] = {
          name: uiGroupName,
          label: data[adjustment.groupName].label,
          collapsed: false,
          adjustmentNames: [],
        };
      }

      data[uiGroupName].adjustmentNames.push(adjustment.name);
    }
  });

  groupsToDelete = _uniq(groupsToDelete);
  return _omit(data, groupsToDelete);
};

const constructFilterCategories = apiResponse => {
  if (apiResponse.filters && apiResponse.filters.length > 0) {
    return apiResponse.filters.map(filterCategory => {
      return {
        ...filterCategory,
        items: filterCategory.items,
        collapsed: true,
      };
    });
  } else {
    return [];
  }
};

const getUiGroupName = adjustment => {
  return `${adjustment.category}.${adjustment.categoryItem}.${adjustment.groupName}`;
};

export const resetUserAdjustmentToDefaultValues = (adjustmentStructure, sideBar) => {
  const defaultValues = calculateDefaultValues(adjustmentStructure, sideBar);
  return {
    type: actionTypes.RESET_ADJUSTMENT_TO_DEFAULT,
    payload: {
      adjustmentName: adjustmentStructure.name,
      values: defaultValues,
    },
  };
};

export const resetUserAdjustmentToScenario = ({ viewingScenarioId, adjustmentStructure }, sideBar) => {
  if (viewingScenarioId === constants.SCENARIO_DEFAULT_ID) {
    return resetUserAdjustmentToDefaultValues(adjustmentStructure, sideBar);
  }

  return {
    type: actionTypes.RESET_ADJUSTMENT_TO_SCENARIO,
    payload: {
      adjustmentName: adjustmentStructure.name,
      viewingScenarioId,
    },
  };
};

export const deleteScenario = scenarioId => ({
  type: actionTypes.DELETE_SCENARIO,
  payload: { scenarioId },
});

export const runScenario = (scenarioId, scenarioLabel) => {
  return {
    type: actionTypes.RUN_SCENARIO,
    payload: {
      scenarioId,
      scenarioLabel,
      hasBeenSaved: false,
    },
  };
};

export const saveScenario = scenarioId => ({
  type: actionTypes.SAVE_SCENARIO,
  payload: { scenarioId },
});

export const setScenarioLabelEditModal = scenarioId => ({
  type: actionTypes.SET_SCENARIO_LABEL_EDIT_MODAL,
  payload: { scenarioId },
});

export const unsetScenarioLabelEditModal = () => ({
  type: actionTypes.UNSET_SCENARIO_LABEL_EDIT_MODAL,
});

export const setViewingScenario = scenarioId => ({
  type: actionTypes.SET_VIEWING_SCENARIO,
  payload: { scenarioId },
});

export const setEditingScenario = scenarioId => ({
  type: actionTypes.SET_EDITING_SCENARIO,
  payload: { scenarioId },
});

export const unsetEditingScenario = () => ({
  type: actionTypes.UNSET_EDITING_SCENARIO,
});

export const setScenarioAsUncompared = scenarioId => ({
  type: actionTypes.SET_SCENARIO_AS_UNCOMPARED,
  payload: { scenarioId },
});

export const removeFromCompared = scenarioId => ({
  type: actionTypes.REMOVE_FROM_COMPARED,
  payload: { scenarioId },
});

export const setScenarioAsCompared = scenarioId => ({
  type: actionTypes.SET_SCENARIO_AS_COMPARED,
  payload: { scenarioId },
});

export const setBaselineScenario = scenarioId => ({
  type: actionTypes.SET_BASELINE_SCENARIO,
  payload: { scenarioId },
});

export const updateScenarioLabel = (scenarioId, label) => ({
  type: actionTypes.UPDATE_SCENARIO_LABEL,
  payload: { scenarioId, label },
});

export const updateScenario = scenarioId => ({
  type: actionTypes.UPDATE_SCENARIO,
  payload: { scenarioId },
});

export const setViewingScenarioToDefault = adjustmentStructures => {
  const globalAdjustments = Object.values(adjustmentStructures).filter(a => a.isGlobal);
  const adjustmentsMadeByUser = {};
  globalAdjustments.forEach(adjustment => {
    adjustmentsMadeByUser[adjustment.name] = calculateDefaultValues(adjustment);
  });
  return {
    type: actionTypes.SET_VIEWING_SCENARIO_TO_DEFAULT,
    payload: {
      adjustmentsMadeByUser: adjustmentsMadeByUser,
      scenarioId: constants.SCENARIO_DEFAULT_ID,
    },
  };
};

export const scenarios2Received = apiResponse => {
  return {
    type: actionTypes.SCENARIOS2_RECEIVED,
    payload: apiResponse,
  };
};

export const updateCurrentScenario2FromAdjustments = () => ({
  type: actionTypes.UPDATE_SCENARIO2_FROM_ADJUSTMENTS,
});

export const selectCategory = selected => ({
  type: actionTypes.SELECT_CATEGORY,
  payload: selected === '_all' ? '' : selected,
});

export const selectFilter = (categoryName, filterName) => {
  return {
    type: actionTypes.SELECT_FILTER,
    payload: { categoryName, filterName },
  };
};

export const setFiltersApplied = filters => {
  return {
    type: actionTypes.SET_FILTERS_APPLIED,
    payload: filters,
  };
};

export const setFreshStartsApplied = freshStarts => ({
  type: actionTypes.SET_FRESH_STARTS_APPLIED,
  payload: freshStarts,
});

export const setPayoffPriorityApplied = payoffPriority => {
  return {
    type: actionTypes.SET_PAYOFF_PRIORITY_APPLIED,
    payload: payoffPriority,
  };
};
