// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import { scaleLinear } from 'd3-scale';
import { min, max } from 'd3-array';

//list all the years
const xValues = function(s, info) {
  return [info.valuationYear, info.endYear];
};

//list all the dollar/whatever values
const yValues = function(s) {
  let arr = [0];
  s.adjustments.forEach(function(a) {
    arr = arr.concat(a.values);
  });
  return arr;
};

const makeScales = (s, info, size) => {
  let arr = xValues(s, info);
  let leftMargin = 50;
  let rightMargin = 30;
  let x = scaleLinear()
    .domain([min(arr), max(arr)])
    .range([leftMargin, size.width - rightMargin])
    .clamp(false);

  arr = yValues(s);
  let bottomMargin = 60 + (info.xAxisAdditionalMargin ?? 0);
  let topMargin = 30;
  let y = scaleLinear()
    .domain([min(arr), max(arr)])
    .range([size.height - bottomMargin, topMargin])
    .clamp(false);

  return { x, y };
}

export default makeScales;
