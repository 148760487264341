// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Glyphicon, Button } from 'react-bootstrap';
import { bootstrapUtils } from 'react-bootstrap/lib/utils';
import { getShowSideBar, getSidebarWidth } from 'client/selectors/page.js';
import Filter from './Filter.jsx';
import GlobalCategory from './GlobalCategory.jsx';
import NonGlobalCategorry from './NonGlobalCategory';
import AreaGraph from 'img/giAreaGraph.js';
import ScenarioComparison from './ScenarioComparison';
import YearRates30Modal from './YearRates30Modal';
import WillLoseDataModal from './WillLoseDataModal';
import YearsProjected from './YearsProjected';
import { getGlobalCategories, getNonGlobalCategories } from '../selectorsUI';
import { getShowFilter } from '../selectorsFilters.js';
import { toggleShowSideBar } from '../../actions/uiActions';
import('./SideBar.css');

// apply custom button styles to bsStyle
bootstrapUtils.addStyle(Button, 'prom_apply');

class Sidebar extends React.Component {
  handleSidebarToggle = () => {
    this.props.toggleShowSideBar();
  };

  render() {
    const { showSideBar, marginLeft, showFilter } = this.props;
    if (!showSideBar) {
      return (
        <aside id="left-menu" className="sideBar" style={{ width: '60px', marginBottom: '0', overflowY: 'visible' }}>
          <a href="#/" className="sideBar-toggle" style={{ marginTop: '32px' }} onClick={this.handleSidebarToggle}>
            <Glyphicon glyph={'menu-right'} style={{ fontSize: 11, fontWeight: 900, color: '#a4aabb', lineHeight: 0 }} />
          </a>
        </aside>
      );
    }

    return (
      <aside id="left-menu" className="sideBar" style={{ width: marginLeft }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            color: '#a4aabb',
            minHeight: '74px',
            marginTop: '7px',
            cursor: 'auto',
          }}
          className="sideBar-header"
        >
          <span className="sideBar-group-icon">
            <AreaGraph />
          </span>
          <span className="nav-header" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={this.handleSidebarToggle}>
            <span className="sideBar-menu-section-title" style={{ color: 'white' }}>
              Sidebar
            </span>
            <span className="toggle sideBar-toggle">
              <Glyphicon glyph={'menu-left'} style={{ fontSize: 11, fontWeight: 900, color: '#a4aabb' }} />
            </span>
          </span>
        </div>

        <div style={{ flex: 1, marginBottom: '7px' }}>
          { this.props.features.changeYearsProjected && <YearsProjected /> }

          {showFilter && <Filter />}

          {
            this.props.features.adjustments && (
              <>
                {this.props.globalCategories.map(c => 
                  <GlobalCategory key={c.name} name={c.name} label={c.label} />
                )}

                {this.props.nonGlobalCategories.filter(c => c.hasAdjustment).map(c => 
                  <NonGlobalCategorry key={c.name} name={c.name} label={c.label} />
                )}
              </>
            )
          }
          

        </div>

        {
          this.props.features.scenarios && (
            <div style={{ position: 'sticky', bottom: '0px' }}>
              <ScenarioComparison />
            </div>
          )
        }
        <YearRates30Modal />
        <WillLoseDataModal />
      </aside>
    );
  }
}

const stateToProps = (state, ownProps) => ({
  showSideBar: getShowSideBar(state),
  marginLeft: getSidebarWidth(state),
  showFilter: getShowFilter(state),
  globalCategories: getGlobalCategories(state),
  nonGlobalCategories: getNonGlobalCategories(state),
  workspaceInfo: state.workspace.info,
  fullState: state,
  features: state.planTypeInfo.features,
});

const dispatchToProps = dispatch => ({
  toggleShowSideBar: () => dispatch(toggleShowSideBar()),
})

export default withRouter(connect(stateToProps, dispatchToProps)(Sidebar));
