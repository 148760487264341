import { combineReducers } from 'redux';
import * as currentUserReducers from './components/currentUser/currentUserReducer';
import * as tenantsListReducers from './components/tenantsList/tenantsListReducer';
import * as sharedReducers from './lib/sharedReducer';
import * as projectsListReducers from './components/projectsList/projectsListReducer';
import * as workspaceReducers from './components/workspace/workspaceReducer';
import * as disclaimerReducers from './components/disclaimerDialog/disclaimerReducer';
import pensionOpebWorkspaceReducers from './client/reducers';

let allReducers = Object.assign(
  {},
  currentUserReducers,
  tenantsListReducers,
  sharedReducers,
  projectsListReducers,
  workspaceReducers,
  disclaimerReducers,
);

let allReducer = combineReducers(allReducers);

const buildReducers = () => {
  return Object.assign(
    {},
    currentUserReducers,
    tenantsListReducers,
    sharedReducers,
    projectsListReducers,
    workspaceReducers,
    disclaimerReducers,
    { ...pensionOpebWorkspaceReducers },
  );
};

const rootReducer = (state, action) => {
  if(action.type === 'RESET_WORKSPACE') {
    const newState = {
      currentUser: state.currentUser,
      projectsList: state.projectsList,
      tenantsList: state.tenantsList,
      shared: state.shared,
      workspace: state.workspace,
      disclaimer: state.disclaimer,
    };

    const resetReducers = buildReducers();
    allReducer = combineReducers(resetReducers);
    return allReducer(newState, action);
  }

  return allReducer(state, action);
};
export default rootReducer;
