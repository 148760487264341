import React from 'react';
import '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '../style/colors';
import { fontSizes } from '../style/vars';

function IconLinkButton(props) {
  const { text, icon, onClick, disabled} = props;
  let colorToUse = colors.green;
  if(disabled) {
    colorToUse = colors.grey;
  }
  const handleClick = () => {
    if(disabled) {
      return;
    }
    if(onClick)
      onClick();
  };
  return (
      <div style={{display:'flex', padding:'3px', cursor:'pointer', alignItems:'bottom', color:colorToUse}} onClick={handleClick} disabled={disabled}>
        <div style={{display:'flex', alignItems:'bottom', fontSize:fontSizes.m}} disabled={disabled}>
          <FontAwesomeIcon icon={icon} disabled={disabled} />
        </div>
        <div style={{marginLeft:'3px', fontSize:fontSizes.r}}>{text}</div>
      </div>
  );
}

export default IconLinkButton;