import React from 'react';
import Button from 'components/Button';

function CustomError({error, errorDescription}) {
  function convertErrorToTitle(str) {
    return str.replaceAll('_', ' ').replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  function redirectHome() {
    window.location.replace('/');
  }

  return (
    <>
    <div style={{margin:'30px', display:'flex', flexDirection:'column', alignItems:'center'}}>
      <div><img style={{height:'50px', marginBottom:'20px'}} src="/govinvest_logo_no_subtitle_small.png" alt="GovInvest Logo"/></div>
      <div style={{fontSize:'2em', marginBottom:'20px'}}>An Error Has Occurred</div>
      <div style={{marginBottom:'10px'}}><label style={{fontWeight:'bold'}}>Error: </label>{convertErrorToTitle(error)}</div>
      <div style={{marginBottom: '20px'}}><label style={{fontWeight:'bold'}}>Description: </label>{errorDescription}</div>
      <div onClick={redirectHome} >
        <Button>Back</Button>
      </div>
    </div>
    </>
  );
}

export default CustomError;