import urlJoin from 'url-join';
import config from './client-config';

export const errorRedirect = (error, description) => {
  const baseUrl = config.baseUrl;
  const uri = urlJoin(baseUrl, '/error')
    + '?error=' + encodeURI(error)
    + '&error_description=' + encodeURI(description);
  window.location.replace(uri);
};

export const homeRedirect = () => {
  const baseUrl = config.baseUrl;
  window.location.replace(baseUrl);
};