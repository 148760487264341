import React from 'react';
import { chartSummarySelector } from './chartSummarySelector';
import { connect } from 'react-redux';
import { c } from 'common/colorMachine';

class ChartSummary extends React.Component {
  constructor(props) {
    super(props);
    this.css = this.makeCss();
  }

  makeCss() {
    return {
      sentence: {
        fontSize: '16px',
        textAlign: 'center',
        margin: '8px 15px 0px 15px',
      },
      red: {
        color: 'red',
      },
      green: {
        color: 'green',
      },
    };
  }

  render() {
    const model = this.props.model;
    if(!model.shouldShowSummary) {
      return ( <div style={this.css.sentence}>{'\u00a0'}</div> );
    }
    let sentence = model.sentence;
    sentence = sentence.replace('<red>', `<div style='color:${c.red}; display: inline; font-weight: bold'>`)
    sentence = sentence.replace('</red>', '</div>');
    sentence = sentence.replace('<green>', `<div style='color:${c.green}; display:inline; font-weight: bold'>`)
    sentence = sentence.replace('</green>', '</div>');
    return (
      <div style={this.css.sentence} dangerouslySetInnerHTML={{__html: sentence}}/>
    );
  }

}

const mapStateToProps = state => ({
  model: chartSummarySelector(state)
});

export default connect(mapStateToProps)(ChartSummary);
