// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
function doJSON(url, json, method, type = 'json') {
  let request = {
    method,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (json) {
    request.body = JSON.stringify(json);
  }
  return fetch(url, request)
    .then(response => {
      if (!response.status >= 300) {
        console.error('Bad response:' + url + ", Status:" + response.status);
        return Promise.reject(response);
      }
      return Promise.resolve(response[type]());
    })
    .catch(error => {
      console.error('Error fetching:' + url, error);
      throw error;
    });
}

export function postJSON(url, json, accepts) {
  return doJSON(url, json, 'POST', accepts);
}

export function getJSON(url, accepts) {
  return doJSON(url, null, 'GET', accepts);
}

export function deleteJSON(url, json) {
  return doJSON(url, json, 'DELETE');
}