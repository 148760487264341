// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _round from 'lodash/round';
import Slider from 'react-rangeslider';
import { FormControl, Alert } from 'react-bootstrap';
import { checkGetMetrics } from 'client/sideBar/apiActions.js';
import { setProjectedYears } from '../actionCreators';
import { getValuationYear2 } from '../../lib/planDefaultsSelectors';
import { runScenarioButtonInfo, projectedNumYears, getFilterApplyEnabled, getScenarioMap } from '../selectors';
import * as constants from '../constants';
import './YearsProjected.css';

const YearsProjectedComponent = props => {
  const dispatch = useDispatch();
  const startYear = useSelector(state => getValuationYear2(state));
  const projectedNumberOfYears = useSelector(state => projectedNumYears(state, props));
  const hasDeviated = useSelector(state => runScenarioButtonInfo(state).anyDeviateFromScenario);
  const filterApplyEnabled = useSelector(state => getFilterApplyEnabled(state));
  const comparedScenarios = useSelector(state => state.sideBar.comparedScenarios);
  const scenarioMap = useSelector(state => getScenarioMap(state, { includeDefault: true }));
  const workspaceInfo = useSelector(state => state.workspace.info);
  const [yearsProjectedState, setYearsProjectedState] = useState({
    sliderValue: projectedNumberOfYears.value,
    inputValue: projectedNumberOfYears.value.toString(),
    errorMessage: `Input must be in rage ${constants.PROJECTED_NUMBER_YEAR.min} to ${constants.PROJECTED_NUMBER_YEAR.max}`,
  })

  const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevProjectedNumberOfYears = usePrevious(projectedNumberOfYears);

  useEffect(() => {
    if (prevProjectedNumberOfYears && prevProjectedNumberOfYears.value !== projectedNumberOfYears.value && !projectedNumberOfYears.hasErrors) {
      let scenariosToRun = [...comparedScenarios];
      // ensure that Default scenario is calculated whether it is compared or uncompared
      if (!scenariosToRun.includes(constants.SCENARIO_DEFAULT_ID)) {
        scenariosToRun.unshift(constants.SCENARIO_DEFAULT_ID)
      }
      scenariosToRun.forEach(scenarioId => {
        const scenario = scenarioMap[scenarioId];
        dispatch(checkGetMetrics({ workspaceInfo, purpose: scenario.label, scenarioId: scenario.name, newOrUpdatedScenario: false }));
      })
    }
  })

  const handleSliderChange = value => {
    if (hasDeviated || filterApplyEnabled) return;
    setYearsProjectedState({
      ...yearsProjectedState,
      sliderValue: value,
      inputValue: value,
    })
  };

  const limit = value => {
    if (value > constants.PROJECTED_NUMBER_YEAR.max) value = constants.PROJECTED_NUMBER_YEAR.max;
    else if (value < constants.PROJECTED_NUMBER_YEAR.min) value = constants.PROJECTED_NUMBER_YEAR.min;
    return value;
  };

  const handleInputChange = ev => {
    if (hasDeviated || filterApplyEnabled) return;

    const value = ev.target.value;
    const numericValue = parseFloat(value);
    if (new RegExp('^\\d+$').test(value)) {
      setYearsProjectedState({
        ...yearsProjectedState,
        inputValue: value,
        sliderValue: Number.isFinite(numericValue) ? _round(value, 3) : -99999,
      });
    }
  };

  const handleProjectedYearsChange = () => {
    const value = yearsProjectedState.inputValue;
    const numericValue = parseFloat(value);
    if (new RegExp('^\\d+$').test(value)) {
      let inputValue = limit(value);
      dispatch(setProjectedYears(inputValue, startYear));
      setYearsProjectedState({
        ...yearsProjectedState,
        inputValue: inputValue,
        sliderValue: Number.isFinite(numericValue) ? _round(value, 3) : -99999,
      });
    }
  };

  const titleMessage = `Please ${hasDeviated ? 'run scenario' : filterApplyEnabled ? 'apply filters' : undefined} before changing `;
  return (
    <div className="years-projected">
      <div className="years-projected-header">
        <label>Years Projected</label>
      </div>
      {projectedNumberOfYears.hasErrors && (
        <Alert id={'error-years-projected'} bsStyle="danger">
          {yearsProjectedState.errorMessage}
        </Alert>
      )}
      <div
        className={'adjustment-slider' + (hasDeviated || filterApplyEnabled ? ' disabled' : '')}
        title={hasDeviated || filterApplyEnabled ? titleMessage : undefined}
      >
        <Slider
          min={constants.PROJECTED_NUMBER_YEAR.min}
          max={constants.PROJECTED_NUMBER_YEAR.max}
          value={yearsProjectedState.sliderValue}
          step={1}
          tooltip={!hasDeviated || !filterApplyEnabled}
          onChange={handleSliderChange}
          onChangeComplete={handleProjectedYearsChange}
        />
        <FormControl
          id={'projected_input_slider'}
          type="text"
          value={yearsProjectedState.inputValue}
          onChange={handleInputChange}
          onBlur={handleProjectedYearsChange}
          disabled={hasDeviated || filterApplyEnabled}
        />
      </div>
    </div>
  );
}

export default withRouter(YearsProjectedComponent);
