// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { connect } from 'react-redux';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { setTopic } from '../actions/chartsActions';
import styled from 'styled-components';

const DropdownSelect = styled.div`
  padding: 0 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  user-select: none;
  cursor: pointer;
  &:hover {
    border-color: #b5bbcc;
  }
`;

class TopicSelect extends React.Component {
  componentDidUpdate() {
    const { chartState } = this.props;
    const { topics, selectedView } = chartState;
    const defaultTopic = topics.includes('Liability') ? 'Liability' : topics.length > 1 ? topics[1] : topics[0];

    if (!topics.includes(selectedView.topic)) {
      this.props.dispatch(setTopic(defaultTopic));
    }

  }
  render() {
    const { chartState } = this.props;
    const { selectedView } = chartState;
    const chooseTopics = [
      ...chartState.topics.map(topic => this.menuItem(topic))
    ];

    if(chooseTopics.length === 1) {
      return <></>
    }

    return (
      <div className="dropdown-parent" id="select-topic" style={{ verticalAlign: 'middle' }}>
        <Dropdown id="topic-select">
          <DropdownSelect bsRole="toggle">
            {selectedView.topic} <span className="caret" />
          </DropdownSelect>
          <Dropdown.Menu>{chooseTopics}</Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
  menuItem(topic, action) {
    const { dispatch } = this.props;
    return (
      <MenuItem
        href="http://"
        onClick={e => e.preventDefault()}
        eventKey={topic}
        key={topic}
        onSelect={topic => {
          if (action) {
            action();
            return;
          }
          dispatch(setTopic(topic));
        }}
      >
        {topic}
      </MenuItem>
    );
  }
}

export function stateToProps(state, ownProps) {
  return {
    selectedView: state.charts.selectedView,
    chartState: state.charts,
  };
}

export default withRouter(connect(stateToProps)(TopicSelect));
