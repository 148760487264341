// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { connect } from 'react-redux';
import { selectFilter } from '../actionCreators';

const FilterItem = ({ selected, filterItem, filterCategory, selectFilter }) => {
  const handleFilterSelection = () => {
    selectFilter(filterCategory.name, filterItem.name);
  };
  return (
    <li className={selected ? 'control-label changed' : 'control-label'} style={{ fontSize: '14px', cursor: 'pointer' }} onClick={handleFilterSelection}>
      {filterItem.label}
    </li>
  );
};

const mapDispatchToProps = {
  selectFilter
};

export default connect(null, mapDispatchToProps)(FilterItem);
