// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { Glyphicon } from 'react-bootstrap';
import { connect } from 'react-redux';
import classNames from 'classnames';
import SideBarAdjustment from './SideBarAdjustment.jsx';
import { getAdjustmentGroupInfo } from '../selectors';
import { toggleAdjustmentGroupCollapsed } from '../actionCreators';

const getAdjustmentGroupHeaderCssClass = anyDeviateFromScenario => {
  let result = 'category-header';
  if (anyDeviateFromScenario) {
    result = classNames(result, 'changed');
  }
  return result;
};

const SideBarAdjustmentGroup = ({ adjustmentGroup, adjustmentGroupName, toggleAdjustmentGroupCollapsed, adjustmentGroupInfo, Icon }) => {
  const { adjustmentNames, label, collapsed } = adjustmentGroup;
  const { anyDeviateFromScenario } = adjustmentGroupInfo;
  const handleGroupTitleClick = () => {
    toggleAdjustmentGroupCollapsed({ adjustmentGroupName });
  };

  return (
    <div className="sideBar-adjustment-group">
      <div className="sideBar-adjustment-group-header" onClick={handleGroupTitleClick}>
        <span>{Icon}</span>
        <div className="adjustment-group-label">
          <h1 className={getAdjustmentGroupHeaderCssClass(anyDeviateFromScenario)}>{label}</h1>
          <span className="toggle sideBar-toggle">
            <Glyphicon glyph={!collapsed ? 'menu-down' : 'menu-right'} style={{ fontSize: 11, fontWeight: 900, color: '#a4aabb' }} />
          </span>
        </div>
      </div>
      {!collapsed ? (
        <div className="sideBar-adjustment-subgroup">
          {adjustmentNames.map((adjustmentName, i) => <SideBarAdjustment key={i} adjustmentName={adjustmentName} />)}
        </div>
      ) : null}
    </div>
  );
};

const stateToProps = (state, ownProps) => ({
  adjustmentGroupInfo: getAdjustmentGroupInfo(state, ownProps),
});

const dispatchToProps = {
  toggleAdjustmentGroupCollapsed,
};

export default connect(stateToProps, dispatchToProps)(SideBarAdjustmentGroup);
