// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import ReactResizeDetector from 'react-resize-detector';
import ChooseTab from './ChooseTab';
import { setChartSize, setLegendSize, toggleCompareWithBaseline } from '../../actions/chartsActions';
import Legend from './Legend';
import { default as generalMakeScales } from './general/makeScales';
import { default as generalCompute } from './general/compute';
import { default as compareMakeScales } from './compare/makeScales';
import { default as compareCompute } from './compare/compute';
import Graph from './graph/Graph';
import { PDF, SPECIFIED } from 'lib/defaults';

//<Graph/>'s css styles
const makeCss = function (info) {
  const ret = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'hidden',
      height: '100%',
      marginLeft: '30px',
      marginRight: '10px',
      minWidth: '700px',
      minHeight: '250px',
    },
  };
  if(info.renderType === PDF) {
    ret.container.minWidth = '1000px';
  }
  return ret;
};

function Graphs(props) {
  const dispatch = useDispatch();
  const graphsRef = useRef(null);
  const legendRef = useRef(null);
  const chartSize = useSelector(rs => rs.charts.chartSize);
  const initialChartSize = (!chartSize || _.isEmpty(chartSize)) ? { height: 400, width: 700 } : chartSize;
  const [size, setSize] = useState(initialChartSize);
  const pinYear = props.info.yearIsPinned;
  const [onYear, setOnYear] = useState(props.info.onYear || null);
  const css = makeCss(props.info);
  const [currentChart, setCurrentChart] = useState(props.info.id);
  const viewTransitioning = currentChart !== props.info.id;

  useEffect(() => {
    if(viewTransitioning && props.info.xType === SPECIFIED) {
      setOnYear(props.info.valuationYear)
      setCurrentChart(props.info.id);
    }
  }, [viewTransitioning, props.info.xType, props.info.id, props.info.valuationYear]);

  useEffect(() => {
    if (props.info.onYear !== onYear) {
      setOnYear(props.info.onYear);
    }
  // eslint-disable-next-line
  }, [props.info.onYear]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.onresize = _.debounce(() => {
        onResize();
      }, 200);
    }
  // eslint-disable-next-line
  }, []);

  const hoverCallback = (year) => {
    if (pinYear)
      return;

    setOnYear(year || onYear);
  }

  const clickCallback = (year) => {
    setOnYear(year);
    props.callbacks.changePinned(!pinYear);
  };

  const getCallbacks = () => {
    return {
      ...props.callbacks,
      hoverYear: hoverCallback,
      clickYear: clickCallback,
    };
  };

  const onResize = () => {
    if (!graphsRef || !graphsRef.current)
      return;
    const decreaseHeightBy = graphsRef.current.offsetHeight > 650 ? 60 : 0; //If it's very big add some bottom margin
    const newGraphsSize = {
      width: graphsRef.current.offsetWidth,
      height: graphsRef.current.offsetHeight - decreaseHeightBy,
    };
    setSize(newGraphsSize);
    dispatch(setChartSize(newGraphsSize));

    if (!legendRef || !legendRef.current)
      return;
    dispatch(setLegendSize({
      width: legendRef.current.offsetWidth,
      height: legendRef.current.offsetHeight,
    }));
  };

  const getOnYear = () => {
    let oy = onYear;
    const { info } = props;
    //the following is to fix an issue where if you have a year selected and switch to a chart with less years
    if (info.xType === SPECIFIED && oy >= info.valuationYear + info.xAxisLabels.length - 1)
      oy = info.valuationYear + props.info.xAxisLabels.length - 1;
    return oy;
  }

  const renderNormal = () => {
    let { info, currentComparison, baselineComparison } = props;
    const tab = info.tab || 'current';
    const callbacks = getCallbacks();
    const oy = getOnYear();
    const cmp = { state: { onYear: oy, pinYear } };
    const scales = generalMakeScales(info, size);
    const charts = generalCompute(info, scales, cmp);

    return (
      <>
        <div ref={legendRef}>
          <ChooseTab
            currentComparison={currentComparison}
            baselineComparison={baselineComparison}
            callbacks={callbacks}
            tab={tab}
            cmp={cmp}
            info={info}
            onYear={oy}
            toggleCompareWithBaseline={() => dispatch(toggleCompareWithBaseline())}
          />
        </div>
        <div id="graphs" ref={graphsRef} style={css.container}>
          <ReactResizeDetector handleWidth onResize={onResize} />
          <Graph cmp={cmp} scales={scales} charts={charts} info={info} callbacks={callbacks} size={size} />
        </div>
      </>
    );
  }

  const renderScenarioComparison = () => {
    const { info, noScenarioLabel, currentComparison, baselineComparison } = props;
    const oy = getOnYear();
    const cmp = { state: { onYear: oy, pinYear } };
    const tab = info.tab;
    const callbacks = getCallbacks();
    let s = info.series.find(o => o.id === tab);
    if (!s) {
      return null;
    }
    const scales = compareMakeScales(s, info, size);
    const charts = compareCompute(s, info, scales);

    return (
      <>
        <div ref={legendRef}>
          <ChooseTab
            currentComparison={currentComparison}
            baselineComparison={baselineComparison}
            callbacks={callbacks}
            tab={tab}
            cmp={cmp}
            info={info}
            onYear={oy}
            noScenarioLabel={noScenarioLabel}
            toggleCompareWithBaseline={() => dispatch(toggleCompareWithBaseline())}
          />
          <Legend cmp={cmp} info={info} charts={charts} showCircles={false} />
        </div>
        <div id="graphs" ref={graphsRef} style={css.container}>
          <ReactResizeDetector handleWidth onResize={onResize} />
          <Graph cmp={cmp} scales={scales} charts={charts} info={info} callbacks={callbacks} size={size} />
        </div>
      </>
    );
  };

  const tab = props.info.tab || 'current';

  if (props.info.isComparing && tab !== 'current')
    return renderScenarioComparison();

  return renderNormal();
}
export default Graphs;