// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React, {Component} from 'react';
import Slider from 'react-rangeslider'
import { connect } from 'react-redux';
import './RangeSlider.css';
import { FormControl } from 'react-bootstrap';
import _round from 'lodash/round';
import { getSliderInfo } from '../selectors';
import { setAdjustmentSlider, setPercentAdjustmentSlider } from '../actionCreators';
import * as constants from '../constants';
import './AdjustmentSlider.css';

class AdjustmentSlider extends Component {
  constructor(props) {
    super(props);

    const value = props.sliderInfo ? props.sliderInfo.value : 0;
    this.state = {
      sliderValue: _round(value, 3),
      inputValue: _round(value, 3).toString()
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.sliderInfo) {
      if (this.props.sliderInfo.value === prevProps.sliderInfo.value) {
        return;
      }
    }
    const value = this.props.sliderInfo ? this.props.sliderInfo.value : 0;
    this.setState({
      sliderValue: _round(value, 3),
      inputValue: _round(value, 3).toString()
    });
  }

  handleSliderChange = (value) => {
    this.setState({
      sliderValue: _round(value, 3),
      inputValue: _round(value, 3).toString()
    });
  }

  handleInputChange = ev => {
    const value = ev.target.value;
    const numericValue = parseFloat(value);
    this.setState({
      inputValue: value,
      sliderValue: Number.isFinite(numericValue) ? _round(value, 3): -99999
    });
  };

  handleAdjustmentChange = () => {
    const { doSetAdjustmentSlider, adjustmentName } = this.props;
    const input = {
      adjustmentName,
      value: this.state.sliderValue
    };
      doSetAdjustmentSlider(input);
  }

  render() {
    const { sliderInfo } = this.props;
    const { min, max, step } = sliderInfo;
    const { sliderValue, inputValue } = this.state;
    return (
      <div className="adjustment-slider">
        <Slider
          min={min}
          max={max}
          value={sliderValue}
          step={step}
          onChange={this.handleSliderChange}
          onChangeComplete={this.handleAdjustmentChange}
        />
        <FormControl
          type="text"
          value={inputValue}
          onChange={this.handleInputChange}
          onBlur={this.handleAdjustmentChange}
        />
      </div>
    );
  }
}

const stateToProps = (state, ownProps) => ({
  sliderInfo: getSliderInfo(state, ownProps)
});

const dispatchToProps = (dispatch, ownProps) => ({
  doSetAdjustmentSlider: ({ adjustmentName, value}) => {
    const {type} = ownProps.adjustmentStructure;
    if (type === constants.TYPE_YEAR_PERCENT) {
      dispatch(setPercentAdjustmentSlider({ adjustmentName, value }))
    } else {
      dispatch(setAdjustmentSlider({ adjustmentName, value }));
    }
  }
});

export default connect(stateToProps, dispatchToProps)(AdjustmentSlider);
