// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import { Constants } from 'common/constants.js';
import { FISCALYEARPERIODS, VALUATIONYEARBALANCES, VALUATIONYEARPERIODS } from 'lib/defaults.js';

const { DOLLARS, PERCENT, COMMA, NUMBER } = Constants.formats;
const cashflows = 'cashflows';
const balanceSheet = 'balanceSheet';
const recommendedContributions = 'recommendedContributions';
const calPERS = 'calPERS';
const derived = 'derived'; // populated by populateDerivedSeries in lib.
const MIMERS = 'MIMERS';
const SC = 'SC';

const employeeContributions = {
  chartRules: { inactiveOnly: false },
  position: cashflows,
  title: 'Employee Contributions',
  color: 'navy',
};
const employerNormalCostDollars = {
  chartRules: { inactiveOnly: false },
  position: recommendedContributions,
  title: 'Employer Normal Cost',
  color: 'blue',
};
const employerNormalCostRate = {
  chartRules: { inactiveOnly: false },
  title: 'Employer Normal Cost',
  position: recommendedContributions,
  color: 'blue',
};
const amortizationPaymentDollars = {
  position: recommendedContributions,
  title: 'Annual Amortization Payment',
  color: 'red',
};
const amortizationContributionRate = {
  position: recommendedContributions,
  title: 'Annual Amortization Payment',
  color: 'red',
};
const benefitsPayments = {
  title: 'Benefit Payments',
  position: derived,
  color: 'red',
};
const expenses = {
  chartRules: { hideExpenses: false },
  title: 'Expenses',
  position: derived,
  color: 'turquoise',
};
const assetReturns = {
  title: 'Investment Return',
  position: cashflows,
  color: 'orange',
};
const pensionAssetReturns = {
  title: 'Investment Return',
  position: cashflows,
  color: 'orange',
};
const amortPayment = {
  position: derived,
  title: 'Annual Amortization Payment',
  color: 'blue',
};
const amortBalance = {
  position: derived,
  title: 'Outstanding Balance',
  color: 'red',
};
const fundedRatio = {
  title: 'Funded Percentage (Valuation Assets)',
  position: balanceSheet,
  color: 'blue',
};
const marketFundedRatio = {
  title: 'Funded Percentage (Market Value of Assets)',
  position: balanceSheet,
  color: 'red',
};
const percentOfARCDollars = {
  title: 'Percent ARC',
  position: 'employerContributions',
  color: 'orange',
};
const percentOfPayDollars = {
  chartRules: { inactiveOnly: false },
  title: 'Percent Pay',
  position: 'employerContributions',
  color: 'navy',
};
const percentOfBenefitsDollars = {
  title: 'Percent Benefits',
  position: 'employerContributions',
  color: 'lightPurple',
};
const additionalDollarDollars = {
  title: 'Additional Dollar',
  position: 'employerContributions',
  color: 'red',
};
const percentOfARCRate = {
  title: 'Percent ARC',
  position: 'employerContributions',
  color: 'orange',
};
const percentOfPayRate = {
  chartRules: { inactiveOnly: false },
  title: 'Percent Pay',
  position: 'employerContributions',
  color: 'navy',
};
const percentOfBenefitsRate = {
  title: 'Percent Benefits',
  position: 'employerContributions',
  color: 'lightPurple',
};
const additionalDollarRate = {
  title: 'Additional Dollar',
  position: 'employerContributions',
  color: 'red',
};
const amortizationDetail = {
  title: 'Amortization Details',
  position: derived,
  color: 'turquoise',
};

const unfundedLiabilities = {
  title: 'Unfunded Accrued Liability',
  stacked: false,
  position: balanceSheet,
  color: 'red',
};
const totalEmployerCosts = {
  position: cashflows,
  title: 'Employer Contributions',
  color: 'green',
};

const employerContributionDollars = {
  position: recommendedContributions,
  title: 'Total Employer Recommended Contribution',
  color: 'orange',
};

const employerContributionRate = {
  chartRules: { inactiveOnly: false },
  position: recommendedContributions,
  title: 'Total Employer Recommended Contribution',
  color: 'orange',
};
const pensionAssetsBegin = {
  title: 'Valuation Assets',
  position: balanceSheet,
  color: 'blue',
};
const pensionOverviewSeries = {
  employerContributionDollars,
  employerContributionRate,
  unfundedLiabilities,
  fundedRatio,
};

const pensionCharts = {
  Dashboard: {
    'Overview Metrics': {
      format: 'text',
      defaultSeries: pensionOverviewSeries,
      views: {
        overview: {},
      },
    },
  },
  Cost: {
    'Contribution Comparison': {
      chartRules: { excludeAssets: false, hideComparison: false, trustOnly: false, isSC: false },
      format: DOLLARS,
      xType: FISCALYEARPERIODS,
      defaultSeries: {
        totalContributions: {
          title: 'Contributions',
          position: derived,
          color: 'green',
        },
        benefitsPayments: {
          title: 'PayGo',
          position: cashflows,
          color: 'navy',
        },
        employerContributionDollars: {
          ...employerContributionDollars,
          title: 'ARC',
        },
      },
      views: {
        bar: {},
        line: {},
        table: {},
      },
    },
    'Contribution Comparison - Percent of Pay': {
      chartRules: { excludeAssets: false, inactiveOnly: false, hideComparison: false, trustOnly: false, isSC: false },
      format: PERCENT,
      xType: FISCALYEARPERIODS,
      defaultSeries: {
        totalContributionsRate: {
          title: 'Contributions',
          position: derived,
          color: 'green',
        },
        benefitsPaymentsRate: {
          title: 'PayGo',
          position: derived,
          color: 'navy',
        },
        employerContributionRate: {
          ...employerContributionRate,
          title: 'ARC',
        },
      },
      views: {
        bar: {},
        line: {},
        table: {},
      },
    },
    'CalPERS Contribution': {
      chartRules: { excludeAssets: false, isCalPERS: true, trustOnly: false },
      format: DOLLARS,
      xType: FISCALYEARPERIODS,
      defaultSeries: {
        netUALPmt: {
          position: calPERS,
          title: 'ER Req UAL',
          color: 'purple',
        },
        netERNC: {
          position: calPERS,
          title: 'ER Req NC',
          color: 'orange',
        },
        trust115DistribARCPositive: {
          title: '115 ER Req',
          position: calPERS,
          color: 'green'
        },
        trust115DistribAddPositive: {
          title: '115 UAL Addl',
          position: calPERS,
          color: 'brown'
        },
        additionalER: {
          title: 'Addl ER',
          position: calPERS,
          color: 'blue'
        },
        addlOffsetEEC: {
          title: 'EE Pick Up',
          position: calPERS,
          color: 'lightPurple'
        },
        adpAddlEEC: {
          title: 'EE Addl Cont',
          position: calPERS,
          color: 'navy'
        },
        employerContributionDollars: {
          position: recommendedContributions,
          title: 'Total ER Req Cont',
          view: 'line',
          lineType: 'curved',
          stacked: false,
          color: 'black'
        }
      },
      views: {
        stackedBar: {},
        line: {},
        table: {}
      }
    },
    'Actuarial Recommended Contribution': {
      chartRules: { excludeAssets: false, showARC: { value: true, isOverride: true}, trustOnly: false, isSC: false },
      chartSummary: 'actuarialRecommendedContribution',
      format: DOLLARS,
      xType: FISCALYEARPERIODS,
      defaultSeries: {
        employerContributionDollars: {
          ...employerContributionDollars,
          view: 'line',
          stacked: false,
          color: 'red',
        },
        amortizationPaymentDollars: {
          ...amortizationPaymentDollars,
          color: 'purple',
        },
        employerNormalCostDollars: {
          ...employerNormalCostDollars,
          color: 'orange',
        },
        expenseContribDollars: {
          chartRules: { showExpenseLoad: true },
          position: recommendedContributions,
          title: 'Expense Contribution',
          color: 'green',
        },
        employeeContributionDollars: {
          chartRules: { inactiveOnly: false },
          position: recommendedContributions,
          title: 'Employee Contribution',
          color: 'blue',
        },
      },
      views: {
        area: {},
        stackedBar: {},
        line: {},
        table: {},
      },
    },
    'Actuarial Recommended Contribution - Percent of Pay': {
      chartRules: { excludeAssets: false, inactiveOnly: false, showARC: { value: true, isOverride: true }, trustOnly: false, isSC: false },
      format: PERCENT,
      xType: FISCALYEARPERIODS,
      defaultSeries: {
        employerContributionRate: {
          ...employerContributionRate,
          view: 'line',
          stacked: false,
          color: 'red',
        },
        amortizationContributionRate: {
          ...amortizationContributionRate,
          color: 'purple',
        },
        employerNormalCostRate: {
          ...employerNormalCostRate,
          color: 'orange',
        },
        expenseContribRate: {
          chartRules: { showExpenseLoad: true },
          position: recommendedContributions,
          title: 'Expense Contribution',
          color: 'green',
        },
        employeeContributionRate: {
          position: recommendedContributions,
          title: 'Employee Contribution',
          color: 'blue',
        }
      },
      views: {
        area: {},
        stackedBar: {},
        line: {},
        table: {},
      },
    },
    'Employer Contribution': {
      chartRules: { excludeAssets: false, isCalPERS: false, hideERC: false, trustOnly: false, isSC: false },
      format: DOLLARS,
      chartSummary: 'actuarialRecommendedContribution',
      xType: FISCALYEARPERIODS,
      defaultSeries: {
        percentOfARCDollars,
        percentOfPayDollars,
        percentOfBenefitsDollars,
        additionalDollarDollars,
        payGoShortfallDollars: {
          title: 'PayGo Shortfall',
          position: 'employerContributions',
          color: 'turquoise',
        },
        expenseContribDollars: {
          chartRules: { showExpenseLoad: true },
          position: 'employerContributions',
          title: 'Expense Contrib',
          color: 'purple',
        },
        totalContributions: {
          title: 'Total Contributions',
          view: 'line',
          stacked: false,
          position: derived,
          color: 'green',
        },
      },
      views: {
        stackedBar: {},
        line: {},
        table: {},
      },
    },
    'Employer Contribution - Percent of Pay': {
      chartRules: { excludeAssets: false, inactiveOnly: false, isCalPERS: false, hideERC: false, trustOnly: false, isSC: false },
      format: PERCENT,
      xType: FISCALYEARPERIODS,
      defaultSeries: {
        percentOfARCRate,
        percentOfPayRate,
        percentOfBenefitsRate,
        additionalDollarRate,
        payGoShortfallRate: {
          title: 'PayGo Shortfall',
          position: 'employerContributions',
          color: 'turquoise',
        },
        expenseContribRate: {
          chartRules: { showExpenseLoad: true },
          position: 'employerContributions',
          title: 'Expense Contrib',
          color: 'purple',
        },
        totalContributionsRate: {
          title: 'Total Contributions',
          view: 'line',
          stacked: false,
          position: derived,
          color: 'green',
        },
      },
      views: {
        stackedBar: {},
        line: {},
        table: {},
      },
    },
    'Normal Cost': {
      chartRules: { inactiveOnly: false, trustOnly: false },
      format: DOLLARS,
      xType: FISCALYEARPERIODS,
      defaultSeries: {
        totalNormalCost: {
          title: 'Total Normal Cost',
          stacked: false,
          view: 'line',
          position: derived,
          color: 'green',
        },
        employeeContributionDollars: {
          position: recommendedContributions,
          title: 'Employee Contributions',
          color: 'blue',
        },
        employerNormalCostDollars: {
          ...employerNormalCostDollars,
          color: 'orange',
        },
      },
      views: {
        stackedBar: {},
        line: {},
        table: {},
      },
    },
    'Normal Cost - Percent of Pay': {
      chartRules: { inactiveOnly: false, trustOnly: false },
      format: PERCENT,
      xType: FISCALYEARPERIODS,
      defaultSeries: {
        totalNormalCostRate: {
          title: 'Total Normal Cost',
          stacked: false,
          view: 'line',
          position: derived,
          color: 'green',
        },
        employeeContributionRate: {
          title: 'Employee Contributions',
          position: recommendedContributions,
          color: 'blue',
        },
        employerNormalCostRate: {
          ...employerNormalCostRate,
          color: 'orange',
        },
      },
      views: {
        stackedBar: {},
        line: {},
        table: {},
      },
    },
    'Valuation Payroll': {
      chartRules: { inactiveOnly: false, isCalPERS: true, trustOnly: false },
      format: DOLLARS,
      xType: VALUATIONYEARPERIODS,
      defaultSeries: {
        payroll: {
          title: 'Payroll',
          stacked: false,
          position: cashflows,
          color: 'navy',
        },
      },
      views: {
        area: {},
        stackedBar: {},
        line: {},
        table: {},
      },
    },
    'SC Valuation Payroll': {
      chartRules: { isSC: true },
      format: DOLLARS,
      xType: VALUATIONYEARBALANCES,
      defaultSeries: {
        totalPay: {
          title: 'Total',
          view: 'line',
          stacked: false,
          position: SC,
          color: 'black',
        },
        actPay: {
          title: 'Actives',
          position: SC,
          color: 'green',
        },
        retStatePay: {
          title: 'RehRet - State',
          position: SC,
          color: 'purple',
        },
        retSchoolsPay: {
          title: 'RehRet - Schools',
          position: SC,
          color: 'blue',
        },
        retOtherPay: {
          title: 'RehRet - Other',
          position: SC,
          color: 'lightPurple'
        },
        othStatePay: {
          chartRules: { scORP: true },
          title: 'ORP - State',
          position: SC,
          color: 'red',
        },
        othSchoolsPay: {
          chartRules: { scORP: true },
          title: 'ORP - Schools',
          position: SC,
          color: 'turquoise',
        }
      },
      views: {
        stackedBar: {},
        line: {},
        table: {},
      },
    },
    'SC Employer Contribution Breakdown': {
      chartRules: { isSC: true },
      format: DOLLARS,
      xType: VALUATIONYEARBALANCES,
      defaultSeries: {
        totalERC: {
          title: 'Total',
          view: 'line',
          stacked: false,
          position: SC,
          color: 'black',
        },
        actERC: {
          title: 'Actives',
          position: SC,
          color: 'green',
        },
        retStateERC: {
          title: 'RehRet - State',
          position: SC,
          color: 'purple',
        },
        retSchoolsERC: {
          title: 'RehRet - Schools',
          position: SC,
          color: 'blue',
        },
        retOtherERC: {
          title: 'RehRet - Other',
          position: SC,
          color: 'lightPurple'
        },
        othStateERC: {
          chartRules: { scORP: true },
          title: 'ORP - State',
          position: SC,
          color: 'red',
        },
        othSchoolsERC: {
          chartRules: { scORP: true },
          title: 'ORP - Schools',
          position: SC,
          color: 'turquoise',
        }
      },
      views: {
        stackedBar: {},
        line: {},
        table: {},
      },
    },
    'SC Employer Contribution Breakdown (% of Pay)': {
      chartRules: { isSC: true },
      format: PERCENT,
      xType: VALUATIONYEARBALANCES,
      defaultSeries: {
        avgERCPct: {
          title: 'Average %',
          view: 'line',
          position: SC,
          color: 'black',
        },
        actERCPct: {
          title: 'Actives',
          position: SC,
          color: 'green',
        },
        retStateERCPct: {
          title: 'RehRet - State',
          position: SC,
          color: 'purple',
        },
        retSchoolsERCPct: {
          title: 'RehRet - Schools',
          position: SC,
          color: 'blue',
        },
        retOtherERCPct: {
          title: 'RehRet - Other',
          position: SC,
          color: 'lightPurple'
        },
        othStateERCPct: {
          chartRules: { scORP: true },
          title: 'ORP - State',
          position: SC,
          color: 'red',
        },
        othSchoolsERCPct: {
          chartRules: { scORP: true },
          title: 'ORP - Schools',
          position: SC,
          color: 'orange',
        }
      },
      views: {
        bar: {},
        line: {},
        table: {},
      },
    },
  },
  Liability: {
    'Unfunded Accrued Liability': {
      chartRules: { excludeAssets: false, trustOnly: false },
      format: DOLLARS,
      xType: VALUATIONYEARBALANCES,
      defaultSeries: {
        pensionLiabilities: {
          title: 'Accrued Liability',
          stacked: false,
          view: 'line',
          position: balanceSheet,
          color: 'navy',
          diffBorder: true,
        },
        pensionAssetsBegin: {
          ...pensionAssetsBegin,
          stacked: false,
          diffCurve: true,
          aboveColor: 'red',
          belowColor: 'green',
        },
        unfundedLiabilities,
      },
      views: {
        diffBar: {},
        diffView: {},
        line: {},
        table: {},
      },
    },
    'Funded Percentage': {
      chartRules: { excludeAssets: false, trustOnly: false },
      format: PERCENT,
      xType: VALUATIONYEARBALANCES,
      defaultSeries: {
        fundedRatio: {
          ...fundedRatio,
          chartRules: { hideValuation: false },
          color: 'red',
        },
        marketFundedRatio: {
          ...marketFundedRatio,
          color: 'blue',
        },
      },
      views: {
        bar: {},
        line: {},
        table: {},
      },
    },
    'Accrued Liability': {
      chartRules: { trustOnly: false, isSC: false },
      format: DOLLARS,
      xType: VALUATIONYEARBALANCES,
      defaultSeries: {
        totalAL: {
          title: 'Total',
          view: 'line',
          stacked: false,
          position: derived,
          color: 'orange',
        },
        retiredAL: {
          title: 'Annuitants',
          position: derived,
          color: 'blue',
        },
        termAL: {
          title: 'Terminated Members',
          position: derived,
          color: 'purple',
        },
        activeAL: {
          chartRules: { inactiveOnly: false },
          title: 'Active Members',
          position: derived,
          color: 'red',
        },
        otherAL: {
          chartRules: { isSC: true },
          title: 'Other Members',
          position: 'SC',
          color: 'navy',
        }
      },
      views: {
        stackedBar: {},
        line: {},
        table: {},
      },
    },
    'Present Value of Projected Benefits': {
      chartRules: { trustOnly: false, isSC: false },
      format: DOLLARS,
      xType: VALUATIONYEARBALANCES,
      defaultSeries: {
        totalPVB: {
          title: 'Total',
          view: 'line',
          stacked: false,
          position: derived,
          color: 'orange',
        },
        retiredAL: {
          title: 'Annuitants',
          position: derived,
          color: 'blue',
        },
        termAL: {
          title: 'Terminated Members',
          position: derived,
          color: 'purple',
        },
        activePVB: {
          chartRules: { inactiveOnly: false },
          title: 'Active Members',
          position: derived,
          color: 'red',
        },
      },
      views: {
        stackedBar: {},
        line: {},
        table: {},
      },
    },
    'SC Present Value of Benefits': {
      chartRules: { isSC: true },
      format: DOLLARS,
      xType: VALUATIONYEARBALANCES,
      defaultSeries: {
        totalPVB: {
          title: 'Total',
          view: 'line',
          stacked: false,
          position: SC,
          color: 'orange',
        },
        activePVB: {
          title: 'Active Members',
          position: SC,
          color: 'red',
        },
        inactAL: {
          title: 'Inactive Members',
          position: SC,
          color: 'blue',
        },
        rehRetAL: {
          title: 'Rehired Retirees',
          position: SC,
          color: 'purple',
        },
        othAL: {
          chartRules: { scORP: true },
          title: 'Optional Retirement Plan',
          position: SC,
          color: 'green',
        },
      },
      views: {
        stackedBar: {},
        line: {},
        table: {},
      },
    },
    'SC Accrued Liability': {
      chartRules: { isSC: true },
      format: DOLLARS,
      xType: VALUATIONYEARBALANCES,
      defaultSeries: {
        totalAL: {
          title: 'Total',
          view: 'line',
          stacked: false,
          position: SC,
          color: 'orange',
        },
        activeAL: {
          title: 'Active Members',
          position: SC,
          color: 'red',
        },
        inactAL: {
          title: 'Inactive Members',
          position: SC,
          color: 'blue',
        },
        rehRetAL: {
          title: 'Rehired Retirees',
          position: SC,
          color: 'purple',
        },
        othAL: {
          chartRules: { scORP: true },
          title: 'Optional Retirement Plan',
          position: SC,
          color: 'green',
        },
      },
      views: {
        stackedBar: {},
        line: {},
        table: {},
      },
    },
    'SC Payoff Period': {
      chartRules: { isSC: true },
      format: NUMBER,
      xType: VALUATIONYEARBALANCES,
      defaultSeries: {
        payoffPeriod: {
          title: 'Payoff Period',
          position: SC,
          color: 'orange',
        },
      },
      views: {
        bar: {},
        line: {},
        table: {},
      },
    },
    'SC Participant Counts': {
      chartRules: { isSC: true },
      format: COMMA,
      xType: VALUATIONYEARBALANCES,
      defaultSeries: {
        totalCnt: {
          title: 'Total',
          view: 'line',
          stacked: false,
          position: SC,
          color: 'black',
        },
        actCnt: {
          title: 'Actives',
          position: SC,
          color: 'green',
        },
        retStateCnt: {
          title: 'RehRet - State',
          position: SC,
          color: 'purple',
        },
        retSchoolsCnt: {
          title: 'RehRet - Schools',
          position: SC,
          color: 'blue',
        },
        retOtherCnt: {
          title: 'RehRet - Other',
          position: SC,
          color: 'lightPurple'
        },
        othStateCnt: {
          chartRules: { scORP: true },
          title: 'ORP - State',
          position: SC,
          color: 'red',
        },
        othSchoolsCnt: {
          chartRules: { scORP: true },
          title: 'ORP - Schools',
          position: SC,
          color: 'turquoise',
        }
      },
      views: {
        stackedBar: {},
        line: {},
        table: {},
      },
    },
    'SC Active Liability': {
      chartRules: { isSC: true },
      format: DOLLARS,
      xType: VALUATIONYEARBALANCES,
      defaultSeries: {
        activePVB: {
          title: 'Present Value of Benefits',
          position: SC,
          color: 'blue',
        },
        activeAL: {
          title: 'Accrued Liability',
          position: SC,
          color: 'red',
        }
      },
      views: {
        bar: {},
        line: {},
        table: {},
      },
    },
    'Participant Counts': {
      chartRules: { isCalPERS: true },
      format: COMMA,
      xType: VALUATIONYEARBALANCES,
      defaultSeries: {
        totalLives: {
          title: 'Total',
          view: 'line',
          stacked: false,
          position: calPERS,
          color: 'orange',
        },
        inactNonTermLives: {
          title: 'Annuitants',
          position: calPERS,
          color: 'blue',
        },
        inactTermLives: {
          title: 'Terminated Members',
          position: calPERS,
          color: 'purple',
        },
        activeLives: {
          chartRules: { inactiveOnly: false },
          title: 'Active Members',
          position: calPERS,
          color: 'red',
        },
      },
      views: {
        stackedBar: {},
        line: {},
        table: {},
      }
    }
  },
  POB: {
    'Bond Balance': {
      chartRules: { showPOB: true, trustOnly: false },
      format: DOLLARS,
      xType: FISCALYEARPERIODS,
      defaultSeries: {
        balancePOB: {
          title: 'Bond Balance',
          position: calPERS,
          color: 'black',
        }
      },
      views: {
        bar: {},
        line: {},
        table: {}
      }
    },
    'Bond Payment': {
      chartRules: { showPOB: true, trustOnly: false },
      format: DOLLARS,
      xType: FISCALYEARPERIODS,
      defaultSeries: {
        principalPOB: {
          title: 'Principal',
          position: calPERS,
          color: 'blue'
        },
        interestPOB: {
          title: 'Interest',
          position: calPERS,
          color: 'navy'
        },
        totalPaymentPOB: {
          title: 'Total',
          position: calPERS,
          color: 'black',
          view: 'line',
          stacked: false
        }
      },
      views: {
        stackedBar: {},
        area: {},
        table: {},
      }
    },
    'Payments to Pension Debt by Source': {
      chartRules: { showPOB: true, trustOnly: false },
      format: DOLLARS,
      xType: FISCALYEARPERIODS,
      defaultSeries: {
        netUALPmt: {
          title: 'Required UAL Payment',
          position: calPERS,
          color: 'purple'
        },
        additionalER: {
          title: 'Additional UAL Payment',
          position: calPERS,
          color: 'blue'
        },
        adpAddlEEC: {
          title: 'Additional EE UAL Payment',
          position: calPERS,
          color: 'navy'
        },
        trust115DistribARCAmortOnly: {
          title: '115 Trust Dist Req UAL',
          position: calPERS,
          color: 'green'
        },
        trust115DistribAddPositive: {
          title: '115 Trust Dist Additional UAL',
          position: calPERS,
          color: 'brown'
        },
        proceedsPOB: {
          title: 'Bond Proceeds',
          position: calPERS,
          color: 'black'
        },
        totalPensionPaymentsPOB: {
          title: 'Total',
          position: calPERS,
          color: 'black',
          view: 'line',
          stacked: false
        }
      },
      views: {
        stackedBar: {},
        line: {},
        table: {},
      }
    },
    'Total Employer Cost': {
      chartRules: { showPOB: true, trustOnly: false },
      chartSummary: 'pobTotalCost',
      format: DOLLARS,
      xType: FISCALYEARPERIODS,
      defaultSeries: {
        netUALPmt: {
          title: 'Required UAL Payment',
          position: calPERS,
          color: 'purple'
        },
        netERNC: {
          title: 'ER Req Normal Cost',
          position: calPERS,
          color: 'orange',
        },
        additionalER: {
          title: 'Additional UAL Payment',
          position: calPERS,
          color: 'blue'
        },
        totalPaymentPOB: {
          title: 'Bond Payment',
          position: calPERS,
          color: 'lime'
        },
        trust115Contrib: {
          title: '115 Trust Contribution',
          position: calPERS,
          color: 'navy'
        },
        totalEmployerCostPOB: {
          title: 'Total',
          position: calPERS,
          color: 'black',
          view: 'line',
          stacked: false
        }
      },
      views: {
        stackedBar: {},
        line: {},
        table: {},
      }
    },
    'Required Employer Contribution with POB': {
      chartRules: { showPOB: true, trustOnly: false },
      format: DOLLARS,
      xType: FISCALYEARPERIODS,
      defaultSeries: {
        erContribNoPOB: {
          title: 'Without POB',
          position: calPERS,
          color: 'black',
          view: 'line',
          lineType: 'curved',
          stacked: false
        },
        erContribNoPOBAmort: {
          title: 'Without POB (UAL)',
          position: calPERS,
          color: 'navy',
          view: 'line',
          lineType: 'curved',
          stacked: false
        },
        amortizationPaymentDollars: {
          title: 'Required UAL Payment',
          position: recommendedContributions,
          color: 'purple'
        },
        employerNormalCostDollars: {
          title: 'Required ER Normal Cost',
          position: recommendedContributions,
          color: 'blue'
        }
      },
      views: {
        stackedBar: {},
        area: {},
        line: {},
        table: {},
      }
    }
  },
  Assets: {
    'Market Value of Assets': {
      chartRules: { excludeAssets: false, trustOnly: false },
      format: DOLLARS,
      xType: VALUATIONYEARPERIODS,
      defaultSeries: {
        marketAssetsBegin: {
          title: 'Assets - Beginning of Year',
          position: balanceSheet,
          color: 'blue',
        },
        employeeContributions,
        totalEmployerCosts,
        benefitsPayments,
        expenses,
        assetReturns,
      },
      views: {
        stackedBar: {},
        table: {},
      },
    },
    'Valuation Assets': {
      chartRules: { excludeAssets: false, hideValuation: false, trustOnly: false },
      format: DOLLARS,
      xType: VALUATIONYEARPERIODS,
      defaultSeries: {
        pensionAssetsBegin: {
          ...pensionAssetsBegin,
          title: 'Assets - Beginning of Year',
        },
        employeeContributions,
        totalEmployerCosts,
        benefitsPayments,
        expenses,
        pensionAssetReturns,
      },
      views: {
        stackedBar: {},
        table: {},
      },
    },
    '115 Trust Assets': {
      chartRules: { excludeAssets: false, isCalPERS: true },
      format: DOLLARS,
      xType: VALUATIONYEARPERIODS,
      defaultSeries: {
        trust115Balance: {
          title: '115 Trust - Beginning of Year',
          position: calPERS,
          color: 'blue'
        },
        trust115Contrib: {
          title: 'Contributions',
          position: calPERS,
          color: 'navy'
        },
        trust115DistribARC: {
          title: 'Distributions Req ER',
          position: calPERS,
          color: 'green'
        },
        trust115DistribAdd: {
          title: 'Distributions Addl Pmt',
          position: calPERS,
          color: 'red'
        },
        trust115AssetReturns: {
          title: 'Investment Returns',
          position: calPERS,
          color: 'orange',
        }
      },
      views: {
        stackedBar: {},
        table: {},
      }
    },
    'MERS 115 Trust': {
      chartRules: { excludeAssets: false, showMERSv1Trust: true },
      format: DOLLARS,
      xType: VALUATIONYEARPERIODS,
      defaultSeries: {
        trust115Balance: {
          title: 'Trust Balance - Beginning of Year',
          position: MIMERS,
          color: 'blue'
        },
        trust115Contrib: {
          title: 'Contributions to Trust',
          position: MIMERS,
          color: 'navy'
        },
        trust115Distrib: {
          title: 'Distributions from Trust',
          position: MIMERS,
          color: 'green'
        },
        trust115AssetReturns: {
          title: 'Trust Returns',
          position: MIMERS,
          color: 'orange',
        }
      },
      views: {
        stackedBar: {},
        table: {},
      }
    },
    'Cash Flow - Market Assets': {
      chartRules: { excludeAssets: false, trustOnly: false },
      format: DOLLARS,
      xType: VALUATIONYEARPERIODS,
      defaultSeries: {
        employeeContributions: {
          ...employeeContributions,
          color: 'blue',
        },
        totalEmployerCosts,
        benefitsPayments,
        expenses,
        assetReturns,
        riskPoolAdjustment: {
          title: 'Risk Pool Adjustment',
          position: calPERS,
          color: 'purple',
          chartRules: { showRiskPoolAdj: true }
        },
      },
      views: {
        stackedBar: {},
        bar: {},
        line: {},
        table: {},
      },
    },
    'Cash Flow - Valuation Assets': {
      chartRules: { excludeAssets: false, hideValuation: false, trustOnly: false },
      format: DOLLARS,
      xType: VALUATIONYEARPERIODS,
      defaultSeries: {
        employeeContributions: {
          ...employeeContributions,
          color: 'blue',
        },
        totalEmployerCosts,
        benefitsPayments,
        expenses,
        pensionAssetReturns,
        riskPoolAdjustment: {
          title: 'Risk Pool Adjustment',
          position: calPERS,
          color: 'purple',
          chartRules: { showRiskPoolAdj: true }
        },
      },
      views: {
        stackedBar: {},
        bar: {},
        line: {},
        table: {},
      },
    },
    'Cash Flow - 115 Trust': {
      chartRules: { excludeAssets: false, isCalPERS: true },
      format: DOLLARS,
      xType: VALUATIONYEARPERIODS,
      defaultSeries: {
        trust115Contrib: {
          title: 'Contributions',
          position: calPERS,
          color: 'navy'
        },
        trust115DistribARC: {
          title: 'Distributions Req ER',
          position: calPERS,
          color: 'green'
        },
        trust115DistribAdd: {
          title: 'Distributions Addl Pmt',
          position: calPERS,
          color: 'red'
        },
        trust115AssetReturns: {
          title: 'Investment Returns',
          position: calPERS,
          color: 'orange',
        }
      },
      views: {
        stackedBar: {},
        bar: {},
        line: {},
        table: {},
      }
    },
  },
  'Amortization Bases': {
    'Outstanding Balance': {
      chartRules: { excludeAssets: false, trustOnly: false, isSC: false },
      xType: VALUATIONYEARBALANCES,
      format: DOLLARS,
      defaultSeries: {
        amortBalance,
      },
      views: {
        bar: {},
        line: {},
        table: {},
      },
    },
    'Annual Amortization Payment': {
      chartRules: { excludeAssets: false, trustOnly: false, isSC: false },
      xType: FISCALYEARPERIODS,
      format: DOLLARS,
      defaultSeries: {
        amortPayment: {
          ...amortPayment,
          color: 'purple',
        },
      },
      views: {
        bar: {},
        line: {},
        table: {},
      },
    },
    Details: {
      chartRules: { excludeAssets: false, trustOnly: false, isSC: false },
      xType: VALUATIONYEARPERIODS,
      format: DOLLARS,
      defaultSeries: {
        amortizationDetail,
      },
      views: {
        table: {},
      },
    },
  },
};
export default pensionCharts;
