// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React, { Component } from 'react';
import { connect } from 'react-redux';
import giLogo from 'img/gi-logo.svg';
import { Button } from 'react-bootstrap';
import { postJSON } from 'lib/network';
import CenteredPage from 'components/CenteredPage.jsx';
import { requestProjectPlanTypeDetails } from 'client/actions';
import { checkGetMetrics, requestPlanGroups, requestSavedScenarios2 } from 'client/sideBar/apiActions';
import styled from 'styled-components';

const StatusContainer = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  align-items: center;
  justify-content: center;
  height: 160px;
`;

const StyledLogo = styled.img`
  align-self: flex-start;
  justify-self: center;
`;

const ConfirmButton = styled(Button)`
  width: 70px;
  justify-self: flex-end;
  align-self: flex-end;
`;

class CopySentScenario extends Component {
  state = {
    status: 'Copying Scenario...',
    savingScenario: true,
  };

  componentDidMount() {
    this.copyScenario();
  }

  copyScenario = () => {
    const { projectId, planType } = this.props.workspaceInfo;
    const scenarioId = window.location.href.split('/').pop();
    const url = `/webapi/workspace/${projectId}/${planType}/copyScenario/${scenarioId}`;

    return postJSON(url).then(apiResponse => {
      if (apiResponse.error === 'scenario_not_found') {
        this.setState({
          status: 'Failed to copy scenario. The scenario no longer exists.',
          savingScenario: false,
        });
      } else if (!!apiResponse.error) {
        this.setState({
          status: 'Error copying scenario: ' + apiResponse.error,
          savingScenario: false,
        });
      } else {
        this.setState({
          status: 'Scenario copied',
          savingScenario: false,
        });
      }
    });
  };

  confirm = () => {
    const { projectId, planType } = this.props.workspaceInfo;
    const uri = `/app/workspace/${projectId}/${planType}`;
    window.location.replace(uri);
  };

  render() {
    return (
      <CenteredPage>
        <StatusContainer>
          <StyledLogo alt="logo" src={giLogo} style={{ height: 40 }} />
          <div>{this.state.status}</div>
          <ConfirmButton bsStyle={this.state.savingScenario ? 'default' : 'success'} disabled={this.state.savingScenario} onClick={this.confirm}>
            OK
          </ConfirmButton>
        </StatusContainer>
      </CenteredPage>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.currentUser.profile,
  workspaceInfo: state.workspace.info,
});

const mapDispatchToProps = { checkGetMetrics, requestProjectPlanTypeDetails, requestPlanGroups, requestSavedScenarios2 };

export default connect(mapStateToProps, mapDispatchToProps)(CopySentScenario);
