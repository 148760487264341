// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';

export default function giBarGraph(props) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 128 128"
      style={{ enableBackground: 'new 0 0 128 128' }}
      width={128}
      height={128}
      {...props}
    >
      <g>
        <rect x="91.4" className="st0" width="36.6" height={128} />
        <rect y="73.1" className="st0" width="36.6" height="54.9" />
        <rect x="45.7" y="36.6" className="st0" width="36.6" height="91.4" />
      </g>
    </svg>
  );
}
