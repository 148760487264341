// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import _cloneDeep from 'lodash/cloneDeep';
import _without from 'lodash/without';
import _pull from 'lodash/pull';
import _zip from 'lodash/zip';
import _toPairs from 'lodash/toPairs';
import moment from 'moment';
import * as constants from './constants';
import units from './units';
import { calculateDefaultValues } from './adjustmentDefaults';
import _difference from 'lodash/difference';
import _intersection from 'lodash/intersection';
import _concat from 'lodash/concat';
import _isEqual from 'lodash/isEqual';
import _remove from 'lodash/remove';

//SHARED HELPER FUNCTIONS SHARED BETWEEN SELECTORS
import {
  getViewingScenarioValues,
  isAdjustmentAssumption,
  getGlobalCategories,
  constructValidationFunc,
  determineAdjustmentYearDataUnit,
  getControlValue,
  getGlobalAdjustmentNames,
  getCategoryItemAdjustmentNames,
  getCategoryAdjustmentNames,
} from './selectorsSharedHelpers';
import {getFiscalYear1StartDifferentYearThanEnd2} from "../lib/planDefaultsSelectors";

export const getAdjustmentYearData = ({ sideBar }, { adjustmentName }) => {
  const data = sideBar.adjustmentsMadeByUser[adjustmentName];
  const structure = sideBar.adjustmentStructure[adjustmentName];
  if (
    data === undefined ||
    structure.type === constants.TYPE_DROPDOWN ||
    structure.type === constants.TYPE_FRESH_START ||
    structure.type === constants.TYPE_PAYOFF_PRIORITY ||
    structure.type === constants.TYPE_CUSTOM_ADP_TIMING
  ) {
    return {};
  }

  const unit = determineAdjustmentYearDataUnit(sideBar, adjustmentName);

  const values = data.map((currentValue, index, array) =>
    convertToYearData(
      sideBar,
      adjustmentName,
      {
        currentValue,
        index,
        array,
      },
      unit.dataToDisplay,
    ),
  );

  const structureDefaults = {
    ...structure,
  };

  const defaultValues = calculateDefaultValues(structureDefaults);
  if (!defaultValues) {
    return {
      anyDeviateFromScenario: false,
      hasErrors: values.some(e => e.userInputValueError),
      unit: unit.unit,
      values,
    };
  }

  return {
    anyDeviateFromScenario: values.some(e => e.deviateFromDefaultAndScenario),
    hasErrors: values.some(e => e.userInputValueError),
    unit: unit.unit,
    values,
  };
};

export const getAdjustmentDropdownData = ({ sideBar }, { adjustmentName }) => {
  const data = sideBar.adjustmentsMadeByUser[adjustmentName];
  const structure = sideBar.adjustmentStructure[adjustmentName];

  if (data === undefined || structure.type !== constants.TYPE_DROPDOWN) {
    return {};
  }

  let deviatedFromViewingScenario = false;
  if (!_isEqual(data, getViewingScenarioValues(sideBar, adjustmentName))) {
    deviatedFromViewingScenario = true;
  }

  let result = {};
  result.anyDeviateFromScenario = deviatedFromViewingScenario;
  result.hasErrors = false;
  result.multiSelectEnabled = structure.multiSelectEnabled;
  result.items = structure.choices.map(e => {
    const item = { ...e };
    item.active = data.some(f => f === item.name);
    delete item.isDefault;
    return item;
  });
  result.dropdownTitle = result.items
    .filter(e => e.active)
    .map(i => i.label)
    .join(', ');
  return result;
};

export const getAdjustmentStructure = ({ sideBar }, { adjustmentName }) => {
  const dataToDisplay = determineAdjustmentYearDataUnit(sideBar, adjustmentName).dataToDisplay;
  const result = constructAdjustmentStructure(sideBar.adjustmentStructure[adjustmentName], dataToDisplay);
  return result;
};

export const getAdjustmentOptions = ({ sideBar }, { adjustmentName }) => {
  const currentControl = getControlValue(sideBar, adjustmentName);

  return {
    collapsed: getCollapsedValue(sideBar, adjustmentName),
    control: getControlValue(sideBar, adjustmentName),
    selectableControls: getSelectableControls(sideBar, adjustmentName, currentControl),
    canRemoveOverride: canRemoveOverride(sideBar, adjustmentName),
  };
};

export const getGlobalAdjustmentGroupsForCategory = ({ sideBar }, { name }) => {
  const categoryName = name;
  const globalCategories = getGlobalCategories(sideBar).filter(x => x.name === categoryName);
  const adjustmentGroups = [];
  for(let globalCategory of globalCategories) {
    const categoryAdjustmentGroupNames = globalCategory.adjustmentGroupNames;
    const mappedAdjGroups = categoryAdjustmentGroupNames.map(e => sideBar.adjustmentGroups[e]);
    adjustmentGroups.push(...mappedAdjGroups);
  }
  return adjustmentGroups;
};

export const getSliderInfo = ({ sideBar }, { adjustmentName }) => {
  const data = sideBar.adjustmentsMadeByUser[adjustmentName];
  // sliders are never used for dollar adjustments - assume percent
  const dataToDisplay = units['%'].dataToDisplay;

  if (data === undefined) {
    return null;
  }

  const result = {};
  const structure = sideBar.adjustmentStructure[adjustmentName];
  result.min = dataToDisplay(structure.min);
  result.max = dataToDisplay(structure.max);
  if (structure.step) {
    result.step = dataToDisplay(structure.step);
  } else {
    result.step = 0.1;
  }

  result.value = dataToDisplay(data[0]);

  return result;
};

export const get30YearModalAdjustmentStructure = ({ sideBar }) => {
  const rawStructure = sideBar.adjustmentStructure[sideBar.year30ModalAdjustmentName];

  if (!rawStructure) {
    return {
      label: 'empty label',
      errorMessage: ' empty error message',
    };
  }
  const dataToDisplay = determineAdjustmentYearDataUnit(sideBar, sideBar.year30ModalAdjustmentName).dataToDisplay;
  return constructAdjustmentStructure(rawStructure, dataToDisplay);
};

export const projectedNumYears = ({ sideBar }, ownProps) => {
  return { value: sideBar.projectedNumYears, hasErrors: invalidProjectedNumberOfYears(sideBar.projectedNumYears) };
};

export const getOverrideAdjustmentItems = ({ sideBar }, { itemName, categoryName }) => {
  const planAdjustmentsNotOverridden = _difference(Object.keys(sideBar.adjustmentStructure), Object.keys(sideBar.adjustmentsMadeByUser));
  const category = sideBar.categories.find(e => e.name === categoryName);
  const adjustmentGroupNamesInNonGlobalCategoryItem = category.categoryItems.find(e => e.name === itemName).adjustmentGroupNames;
  let result = [];
  adjustmentGroupNamesInNonGlobalCategoryItem.forEach(adjustmentGroupName => {
    const adjustmentNamesInGroup = sideBar.adjustmentGroups[adjustmentGroupName].adjustmentNames;
    const adjustmentsCanBeOveridden = _intersection(adjustmentNamesInGroup, planAdjustmentsNotOverridden);
    if (adjustmentsCanBeOveridden.length) {
      result.push({
        name: `${adjustmentGroupName}`,
        label: sideBar.adjustmentGroups[adjustmentGroupName].label,
        isAdjustmentGroup: true,
      });
      result = _concat(
        result,
        adjustmentsCanBeOveridden.map(e => ({
          name: e,
          label: sideBar.adjustmentStructure[e].label,
          isAdjustmentGroup: false,
          choices: sideBar.adjustmentStructure[e].choices,
          defaults: sideBar.adjustmentStructure[e].defaults,
          history: sideBar.adjustmentStructure[e].history,
          variableType: sideBar.adjustmentStructure[e].variableType,
          type: sideBar.adjustmentStructure[e].type,
          extended: sideBar.adjustmentStructure[e].extended,
        })),
      );
    }
  });

  return result;
};

export const getOveriddenAdjustmentGroupsInfo = ({ sideBar }, { item, categoryName }) => {
  const category = sideBar.categories.find(e => e.name === categoryName);
  const adjustmentGroupNamesInNonGlobalCategoryItem = category.categoryItems.find(e => e.name === item.name).adjustmentGroupNames;
  let result = adjustmentGroupNamesInNonGlobalCategoryItem.map(adjustmentGroupName => {
    let data = {};
    data.adjustmentGroupName = adjustmentGroupName;
    data.adjustmentGroup = {
      label: sideBar.adjustmentGroups[adjustmentGroupName].label,
      adjustmentNames: _intersection(sideBar.adjustmentGroups[adjustmentGroupName].adjustmentNames, Object.keys(sideBar.adjustmentsMadeByUser)),
      collapsed: sideBar.adjustmentGroups[adjustmentGroupName].collapsed,
    };
    return data;
  });

  return result.filter(e => e.adjustmentGroup.adjustmentNames.length);
};

export const getAdjustmentYearRateOptions = ({ sideBar }, { adjustmentName, index }) => {
  const currentControl = getControlValue(sideBar, adjustmentName);
  // this only applies to 5 year and 10 year controls. Not applicable for slider or 30 year inputs. Slider / 30 year would not call this selector or rely on the output.
  const lastIndexInControl = currentControl === constants.CONTROL_10YEAR ? 9 : 4;
  const isLastIndexInControl = index === lastIndexInControl;
  const adjustmentAssumption = isAdjustmentAssumption(sideBar, adjustmentName);

  return {
    valueShouldRepeatFromIndexToEnd: isLastIndexInControl && adjustmentAssumption,
  };
};

export function getPlanGroupsLoaded(state) {
  return state.sideBar.planGroupsLoaded;
}

export const getYearLabels = (state, { adjustmentName }, hidePlusSymbol) => {
  const { sideBar } = state;
  const adjustmentStructure = sideBar.adjustmentStructure[adjustmentName];
  const valuationYear = getValuationYear(sideBar);
  const result = [];
  for (let i = 0; i < constants.YEARSOFINPUT; ++i) {
    let yearLabel = '';
    const currentYear = valuationYear + i;
    if (adjustmentStructure.indexType === constants.INDEX_TYPE_FISCALYEAR
      && getFiscalYear1StartDifferentYearThanEnd2(state)) {
      yearLabel = getFiscalYearLabel(currentYear);
    } else {
      yearLabel = currentYear.toString();
    }
    result.push(yearLabel);
  }

  // defaults should have + sign at last label (only applies 5 year and 10 year inputs)
  if (sideBar.adjustmentControls[adjustmentName] && isAdjustmentAssumption(sideBar, adjustmentName) && !hidePlusSymbol) {
    const control = getControlValue(sideBar, adjustmentName);
    if (control && control === constants.CONTROL_5YEAR) {
      result[4] = result[4] + '+';
    } else if (control && control === constants.CONTROL_10YEAR) {
      result[9] = result[9] + '+';
    }
  }
  return result;
};

export const getCalcRequest = ({ sideBar }) => {
  const result = {};

  result.planGroupName = sideBar.planGroupsInfo.name;
  result.numberOfYears = sideBar.projectedNumYears;

  const adjustmentValues = [];
  _toPairs(sideBar.adjustmentsMadeByUser).forEach(e => {
    if (sideBar.adjustmentStructure[e[0]]) {
      if (sideBar.adjustmentStructure[e[0]].type === constants.TYPE_DROPDOWN) {
        adjustmentValues.push({
          name: e[0], // object key
          choices: e[1],
        });
      } else if (sideBar.adjustmentStructure[e[0]].type === constants.TYPE_PAYOFF_PRIORITY) {
        adjustmentValues.push({
          name: e[0],
          payoffPriority: {
            bases: e[1] && e[1].bases,
            manual: e[1] && e[1].manual,
            strategy: e[1] && e[1].strategy,
          }
        })
      } else if (sideBar.adjustmentStructure[e[0]].type === constants.TYPE_FRESH_START) {
        adjustmentValues.push({
          name: e[0],
          freshStarts: e[1],
        });
      } else if (sideBar.adjustmentStructure[e[0]].type === constants.TYPE_CUSTOM_ADP_TIMING) {
        adjustmentValues.push({
          name: e[0],
          adpTimings: e[1],
        });
      } else {
        adjustmentValues.push({
          name: e[0], // object key
          values: e[1],
        });
      }
    }
  });

  result.adjustmentValues = adjustmentValues;
  return result;
};

export const getModalRows = (state) => {
  if (!state.sideBar.year30ModalAdjustmentName) return null;
  const props = { adjustmentName: state.sideBar.year30ModalAdjustmentName };
  const hidePlusSymbol = true;
  const yearLabels = getYearLabels(state, props, hidePlusSymbol);
  const yearDataValues = getAdjustmentYearData(state, props).values;
  // combine into array of arrays and return
  const result = yearLabels.map((e, index) => [
    e,
    {
      displayValue: yearDataValues[index].displayValue,
      dataValue: yearDataValues[index].dataValue,
    },
  ]);
  return result;
};

// PRIVATE FUNCTIONS
const invalidProjectedNumberOfYears = value => {
  if (typeof value !== 'number' || value > constants.PROJECTED_NUMBER_YEAR.max || value < constants.PROJECTED_NUMBER_YEAR.min) {
    return true;
  }
  return false;
};

const canRemoveOverride = (sideBar, adjustmentName) => {
  // all non-global adjustments by known business rule can be overridden, so override can be removed as well - EL
  const globalAdjustmentNames = getGlobalAdjustmentNames(sideBar);
  return globalAdjustmentNames.every(e => e !== adjustmentName);
};

const constructAdjustmentStructure = (rawStructure, dataToDisplay = value => value) => {
  const result = _cloneDeep(rawStructure);
  const min = dataToDisplay(rawStructure.min);
  const max = dataToDisplay(rawStructure.max);

  // allows backend to define custom error message
  if (!result.errorMessage) {
    result.errorMessage = `All inputs must be in range ${min} to ${max}${rawStructure.type === constants.TYPE_YEAR_PERCENT ? '%' : ''}`;
  }
  delete result.min;
  delete result.max;

  return result;
};

const getCollapsedValue = (sideBar, adjustmentName) => {
  if (!sideBar.adjustmentControls[adjustmentName] || !sideBar.adjustmentControls[adjustmentName].collapsed) {
    return false;
  }

  return sideBar.adjustmentControls[adjustmentName].collapsed;
};

const convertToYearData = (sideBar, adjustmentName, { currentValue, index, array }, dataToDisplay) => {
  const adjustmentStructure = sideBar.adjustmentStructure[adjustmentName];

  const hasError = constructValidationFunc(sideBar, adjustmentName)(currentValue) === false;

  let displayValue = currentValue === null ? '' : currentValue;
  // do the conversion if a number
  if (Number.isFinite(displayValue)) {
    displayValue = dataToDisplay(displayValue);
  }

  const structureDefaults = {
    ...adjustmentStructure,
  };

  const defaultValues = calculateDefaultValues(structureDefaults);
  if (!defaultValues) {
    return {
      dataValue: currentValue,
      displayValue,
      deviateFromDefaultAndScenario: false,
      userInputValueError: hasError,
    };
  }

  const deviated = currentValue !== getViewingScenarioValues(sideBar, adjustmentName)[index];

  return {
    dataValue: currentValue,
    displayValue,
    deviateFromDefaultAndScenario: deviated,
    userInputValueError: hasError,
  };
};

const getSelectableControls = (sideBar, adjustmentName, currentControl) => {
  const result = _without(constants.ALL_CONTROLS, currentControl);
  const structure = sideBar.adjustmentStructure[adjustmentName];
  if (!structure.showSlider) {
    _pull(result, constants.CONTROL_SLIDER);
  }
  return result;
};

const getValuationYear = sideBar => moment(sideBar.planGroupsInfo.valuationDate).year();

const getFiscalYearLabel = year => {
  const nextYear = year + 1;
  const yearString = year.toString();
  const nextYearString = nextYear.toString();

  return `${yearString.substr(2, 2)}-${nextYearString.substr(2, 2)}`;
};

/**
 ** Create an array of text that summarizes the argument viewParams
 **/

export function summarizeViewParamsV2(state, { scenarioId }) {
  const displays = [];

  const { sideBar } = state;
  const { adjustmentStructure, scenarios } = sideBar;

  let defaultAdjustments = {};
  if (state.sideBar.calcRequests[constants.SCENARIO_DEFAULT_ID]) {
    state.sideBar.calcRequests[constants.SCENARIO_DEFAULT_ID].adjustmentValues.forEach(adjustment => {
      defaultAdjustments[adjustment.name] = adjustment.values || adjustment.choices
    });
  };

  const scenarioAdjustments = scenarioId === constants.SCENARIO_DEFAULT_ID ? defaultAdjustments : scenarios[scenarioId].adjustments;
  // Create an array with the yearLabels
  const valuationYear = getValuationYear(sideBar);
  const yearLables = [];
  for (let i = 0; i < constants.YEARSOFINPUT; ++i) {
    let yearLabel = '';
    const currentYear = valuationYear + i;
    yearLabel = currentYear.toString();
    yearLables.push(yearLabel);
  }
  if (scenarioAdjustments) {
    Object.keys(scenarioAdjustments).forEach(adjustment => {
      const unit = determineAdjustmentYearDataUnit(sideBar, adjustment);
      let values = [];
      if (!adjustmentStructure[adjustment]) return;
      if (
        adjustmentStructure[adjustment].type !== constants.TYPE_DROPDOWN &&
        adjustmentStructure[adjustment].type !== constants.TYPE_FRESH_START &&
        adjustmentStructure[adjustment].type !== constants.TYPE_PAYOFF_PRIORITY &&
        adjustmentStructure[adjustment].type !== constants.TYPE_CUSTOM_ADP_TIMING
      ) {
        // If adjustment is of type dropdown, the returned result will be different.
        const adjustments = scenarioAdjustments[adjustment].map(currentValue => {
          let displayValue = currentValue === null ? '' : currentValue;
          // do the conversion if a number
          if (Number.isFinite(displayValue)) {
            displayValue = unit.dataToDisplay(displayValue);
          }
          return displayValue;
        });
        values = _zip(yearLables, adjustments);
      } else {
        if (adjustmentStructure[adjustment].type === constants.TYPE_FRESH_START
          || adjustmentStructure[adjustment].type === constants.TYPE_PAYOFF_PRIORITY
          || adjustmentStructure[adjustment].type === constants.TYPE_CUSTOM_ADP_TIMING
        ) {
          values = '(summary not supported)'
        } else {
          values = scenarioAdjustments[adjustment];
        }
      }

      displays.push({
        name: adjustmentStructure[adjustment].name,
        label: adjustmentStructure[adjustment].label,
        values: values,
        heading: false,
      });
    });
  }

  const modifiedDisplays = applyAdjustmentCategoryToSummary(sideBar, displays, scenarioId);

  return modifiedDisplays;
}

export function summarizeViewParamsXLS(state, { scenarioId }) {
  const summary = summarizeViewParamsV2(state, { scenarioId });
  const summaryString = summary.map(item => {
    if (!item.heading) {
      let valueString = [];
      if(typeof item.values === 'string' || item.values instanceof String) {
        valueString = item.values;
      }
      // If size = 1, type is of dropdown so no need to loop through all values
      else if (item.values.length === 1) {
        valueString = item.values;
      } else {
        // Loop through each value to create a string for each item
        item.values.forEach(item => {
          item[1] && valueString.push(`${item[0]}:${item[1]}`);
        });
      }

      return `${item.label} : ${valueString.toString()}`;
    }
    return item.label;
  });

  return summaryString;
}

// PRIVATE FUNCTIONS
const applyAdjustmentCategoryToSummary = (sideBar, originalSummary, scenarioId) => {
  let summary = _cloneDeep(originalSummary);

  if (summary.length) {
    let modifiedSummary = [];

    //Find all adjustments that belong to global categories
    const globalCategories = sideBar.categories.filter(x => !x.categoryItems || x.categoryItems.length === 0);
    for(let globalCategory of globalCategories) {
      let modifiedSummaryInner = modifiedSummary;
      let pushHeading = true;
      let globalAdjustments = getCategoryAdjustmentNames(sideBar, globalCategory.name);

      // Remove all global adjustment from summary and push them into modified summary
      summary = _remove(summary, adjustment => {
        let filtered = globalAdjustments.filter(x => x === adjustment.name);

        // Due to global adjustments always appearing in scenario adjustments, its import to check and see if it has actually deviated before adding to the summary
        if (filtered.length && deviatedGlobalAdjustment(sideBar, scenarioId, adjustment.name)) {
          if (pushHeading) {
            modifiedSummaryInner.push({
              name: globalCategory.name,
              label: globalCategory.label,
              values: [],
              heading: true,
            });
            pushHeading = false;
          }

          modifiedSummaryInner.push(adjustment);
          return false;
        } else {
          return true;
        }
      });
      modifiedSummary = modifiedSummaryInner;
    }

    // Get All Non-Global Categories
    const nonGlobalCategories = sideBar.categories.filter(x => x.categoryItems && x.categoryItems.length > 0);
    const categoryAdjustments = [];

    for(let nonGlobalCategory of nonGlobalCategories) {
      const categoryItems = nonGlobalCategory.categoryItems;
      // Construct Array with Category name and all adjustments belonging to it.
      categoryItems.forEach(categoryItem => {
        categoryAdjustments.push({
          name: categoryItem.name,
          label: categoryItem.label,
          categoryLabel: nonGlobalCategory.label,
          adjustments: getCategoryItemAdjustmentNames(sideBar, nonGlobalCategory.name, categoryItem.name),
        });
      });
    }

    // Loop through each category and if adjustment exist then add category
    categoryAdjustments.forEach(adj => {
      let pushHeading = true;

      summary = _remove(summary, adjustment => {
        let filtered = adj.adjustments.filter(x => x === adjustment.name);

        if (filtered.length) {
          if (pushHeading) {
            modifiedSummary.push({
              name: adj.name,
              label: adj.categoryLabel + ': ' + adj.label,
              values: [],
              heading: true,
            });
            pushHeading = false;
          }
          modifiedSummary.push(adjustment);
          return false;
        } else {
          return true;
        }
      });
    });
    modifiedSummary = modifiedSummary.filter(
      summary => !summary.name.includes('amortizationBasesFreshStart') && !summary.name.includes('amortizationBasesPayoffPriority'),
    );
    return modifiedSummary;
  }

  return summary;
};

const deviatedGlobalAdjustment = (sideBar, scenarioId, adjustmentName) => {
  const adjustmentStructure = sideBar.adjustmentStructure[adjustmentName];
  const defaultValues = calculateDefaultValues(adjustmentStructure);
  const userValues = scenarioId === constants.SCENARIO_DEFAULT_ID ? defaultValues : sideBar.scenarios[scenarioId].adjustments[adjustmentName];

  return !_isEqual(userValues, defaultValues);
};
