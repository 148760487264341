// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { connect } from 'react-redux';
import { FormControl } from 'react-bootstrap';
import { getAdjustmentYearRateOptions } from '../selectors';
import { setAdjustment1Year } from 'client/sideBar/actionCreators.js';

class AdjustmentYearRate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editedValue: props.yearData.displayValue,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { yearData } = nextProps;
    if (yearData && this.props.yearData) {
      if (yearData.displayValue === this.props.yearData.displayValue) {
        return;
      }
    }
    this.setState({
      editedValue: yearData.displayValue,
    });
  }

  getInputCssClass = ({ userInputValueError, deviateFromDefaultAndScenario }) => {
    let result = 'inputDigits';
    if (userInputValueError) {
      result += ' error';
    } else if (deviateFromDefaultAndScenario) {
      result += ' changed';
    }
    return result;
  };

  render() {
    const { index, yearData, adjustmentName } = this.props;
    const { editedValue } = this.state;
    return (
      <FormControl
        className={this.getInputCssClass(yearData)}
        id={['input', adjustmentName, index].join('-')}
        type="text"
        value={editedValue}
        onBlur={this.update}
        onChange={this.onChange}
      />
    );
  }
  onChange = ev => {
    const { value: editedValue } = ev.target;
    this.setState({ editedValue });
  };
  update = () => {
    const { setAdjustment1Year, adjustmentName, index, yearData, unit, adjustmentYearRateOptions } = this.props;
    const { editedValue: value } = this.state;
    if (String(value) !== String(yearData.displayValue)) {
      setAdjustment1Year({ adjustmentName, index, value, unit, adjustmentYearRateOptions });
    }
  };
}
const stateToProps = (state, ownProps) => ({
  adjustmentYearRateOptions: getAdjustmentYearRateOptions(state, ownProps),
});
const dispatchToProps = { setAdjustment1Year };
export default connect(stateToProps, dispatchToProps)(AdjustmentYearRate);
