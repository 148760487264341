// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import { scaleLinear } from 'd3-scale';
import { min, max } from 'd3-array';

//return new array with min and max values in given arrays
const minMaxValues = (values) => {
  let result = [];
  result.push(Math.min(...values));
  result.push(Math.max(...values));

  return result;
};

//list all the years
const xValues = function(info) {
  return [info.valuationYear, info.endYear];
};

//list all the values in .value (not include adjustments)
const yValues = function(info, series) {
  let already = [];
  let negAlready = [];
  return series.reduce(
    (arr, s) => {
      let current = s.adjustments.find(a => a.isCurrent === true) || {};
      let baseline = s.adjustments.find(a => a.isBaseline === true) || {};
      let values = [...current.values];
      const comparingWithBaseline = baseline && baseline.values && info.compareWithBaseline && !info.isBaseline;
      if (comparingWithBaseline) {
        values = [...current.values, ...baseline.values];
      }

      if (s.stacked === true) {
        //stacking-logic scale
        for (let i = 0; i < values.length; i++) {
          already[i] = already[i] || 0;
          negAlready[i] = negAlready[i] || 0;
          if (values[i] < 0) {
            negAlready[i] += values[i];
            arr.push(negAlready[i]);
          } else {
            already[i] += values[i];
            arr.push(already[i]);
          }
        }
      } else {
        //non-stacking scale
        arr = arr.concat(minMaxValues(values));
      }
      return arr;
    },
    [0]
  );
};

export const LEFT_CHART_MARGIN = 50;
export const RIGHT_CHART_MARGIN = 30;

const makeScales = (info, size) => {
  let arr = xValues(info);
  let barMargin = 0;
  const leftMargin = LEFT_CHART_MARGIN;
  const rightMargin = RIGHT_CHART_MARGIN;
  const viewsToCheck = ['bar', 'stackedBar', 'diffBar'];
  const isBar = info.series.some(series => viewsToCheck.includes(series.view));
  const seriesToUseForYScaling = info.chartRules.useAllSeriesForYScaling
    ? info.series
    : info.series.filter(series => !series.hidden);

  if (isBar) {
    let numVals = arr[1] - arr[0] + 1;
    barMargin = size.width * 0.5 / numVals;
  }
  let x = scaleLinear()
    .domain([min(arr), max(arr)])
    .range([leftMargin + barMargin, size.width - rightMargin - barMargin])
    .clamp(false);

  arr = yValues(info, seriesToUseForYScaling);
  let bottomMargin = 60 + (info.xAxisAdditionalMargin ?? 0);
  let topMargin = 30;
  let y = scaleLinear()
    .domain([min(arr), max(arr)])
    .range([size.height - bottomMargin, topMargin])
    .clamp(false);

  if (info.chartRules.clampScaleMinToZero) {
    y = y.domain([0, max(arr)]);
  }

  return {
    x,
    y
  };
}

export default makeScales;
