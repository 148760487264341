// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { Modal, Button, Glyphicon, OverlayTrigger, Tooltip } from 'react-bootstrap';
import styled from 'styled-components';
import './SendScenarioModal.css';

const ModalTitleStyled = styled(Modal.Title)`
  text-align: left;
`;

const Description = styled.div`
  margin-bottom: 15px;
`;

const URLContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  grid-column-gap: 15px;
  margin-right: 20px;
`;

const URLStyled = styled.textarea`
  height: 27px
  padding: 2.5px;
  white-space: nowrap;
  overflow: hidden;
  resize: none;
`;

const CopyIconStyled = styled(Glyphicon)`
  justify-self: center;
  font-size: 20px;
  margin-left: 15px;
  cursor: pointer;
  &:hover {
    color: #12b9e5;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;
`;

const SendScenarioModal = props => {
  const { show, handleClose, scenarioId, workspaceInfo } = props;
  const { planType, projectId } = workspaceInfo;

  const generateScenarioURL = (projectId, planType, scenarioId) => {
    const url = `${window.location.origin}/app/workspace/${projectId}/${planType}/copy-sent-scenario/${scenarioId}`;
    return url;
  };

  const selectAndCopy = () => {
    const textArea = document.getElementById('send-scenario-url');
    textArea.select();
    document.execCommand('copy');
  };

  const url = generateScenarioURL(projectId, planType, scenarioId);

  return (
    <Modal
      className="send-scenario-modal"
      show={show}
      onHide={handleClose}
      centered="true"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <ModalTitleStyled>Send Scenario</ModalTitleStyled>
      </Modal.Header>
      <Modal.Body>
        <Description>Send this scenario to another user by sending them the following link:</Description>
        <URLContainer>
          <URLStyled id="send-scenario-url" defaultValue={url} readOnly/>
          <OverlayTrigger rootClose placement="top" trigger="click" overlay={<Tooltip id={url + 'top'}>Copied!</Tooltip>}>
            <CopyIconStyled glyph="copy" onClick={selectAndCopy} />
          </OverlayTrigger>
        </URLContainer>
        <ButtonWrapper>
          <Button onClick={handleClose}>Close</Button>
        </ButtonWrapper>
      </Modal.Body>
    </Modal>
  );
};

export default SendScenarioModal;
