// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import { Constants } from 'common/constants.js';
import { SPECIFIED } from 'lib/defaults.js';

const { INTEGER, PERCENT, NUMBER, COMMA } = Constants.formats;

const peerComparisonCharts = {
  'Peer Comparison': {
    'Distribution of Employers by Funding Level': {
      chartRules: { },
      format: INTEGER,
      xType: SPECIFIED,
      xAxisLabels: 'fundingRangeXAxis',
      xAxisLabelsPosition: 'distribution',
      xAxisLabel: 'Funding Level Range',
      yAxisLabel: '# of Employees',
      defaultSeries: {
        fundingLevelEmployerCount: {
          title: 'Number of Employers',
          position: 'distribution',
          color: 'mersBlue',
        },
      },
      views: {
        bar: {},
        line: {},
        table: {},
      },
    },
    'Distribution of Divisions by Funding Level': {
      chartRules: { },
      format: PERCENT,
      xType: SPECIFIED,
      xAxisLabels: 'fundingRangeXAxis',
      xAxisLabelsPosition: 'distribution',
      xAxisLabel: 'Funding Level Range',
      yAxisLabel: '% of Divisions',
      defaultPosition: 'distribution',
      defaultSeries: {
        fundingLevelDivisionPercent: { title: 'My Employer', color: 'mersGreen', },
        fundingLevelPeerPercent: { title: 'Peer Group', color: 'mersBlue', },
      },
      views: {
        bar: {},
        line: {},
        table: {},
      },
    },
    'Distribution of Divisions by Multiplier Range': {
      format: PERCENT,
      xType: SPECIFIED,
      xAxisLabels: 'multiplierXAxis',
      xAxisLabelsPosition: 'distribution',
      xAxisLabel: 'Multiplier Range',
      yAxisLabel: '% of Divisions',
      defaultPosition: 'distribution',
      defaultSeries: {
        multiplierDivisionPercent: { title: 'My Employer', color: 'mersGreen', },
        multiplierPeerPercent: { title: 'Peer Group', color: 'mersBlue', },
      },
      views: {
        bar: {},
        line: {},
        table: {},
      },
    },
    'Average Participant Contribution Rates by Multiplier Range': {
      format: PERCENT,
      xType: SPECIFIED,
      xAxisLabels: 'multiplierXAxis',
      xAxisLabelsPosition: 'distribution',
      xAxisLabel: 'Multiplier Range',
      yAxisLabel: 'Average Participant Contribution Rate',
      defaultPosition: 'distribution',
      defaultSeries: {
        avgEEContByMultDivision: { title: 'My Employer', color: 'mersGreen', },
        avgEEContByMultPeer: { title: 'Peer Group', color: 'mersBlue', },
      },
      views: {
        bar: {},
        line: {},
        table: {},
      },
    },
    'Distribution of Divisions by Status': {
      format: PERCENT,
      xType: SPECIFIED,
      xAxisLabels: 'statusXAxis',
      xAxisLabelsPosition: 'distribution',
      xAxisLabel: ' ',
      yAxisLabel: '',
      defaultPosition: 'distribution',
      defaultSeries: {
        statusOpenPercent: { title: 'Open Divisions', color: 'mersBlue', },
        statusClosedPercent: { title: 'Closed Divisions', color: 'mersGreen', },
      },
      views: {
        stackedBar: {},
        table: {},
      },
    },
    'Peer Group: Distribution of Divisions with/without COLA by Multiplier Range': {
      format: INTEGER,
      xType: SPECIFIED,
      xAxisLabels: 'multiplierXAxis',
      xAxisLabelsPosition: 'distribution',
      xAxisLabel: 'Multiplier Range',
      yAxisLabel: '# of Divisions',
      defaultPosition: 'distribution',
      defaultSeries: {
        colaCountByMultPeer: { title: 'Peer Group: Divisions with COLA', color: 'mersGreen', },
        noCOLACountByMultPeer: { title: 'Peer Group: Divisions without COLA', color: 'mersBlue', },
      },
      views: {
        bar: {},
        table: {},
      },
    },
    'My Employer: Distribution of Divisions with/without COLA by Multiplier Range': {
      format: INTEGER,
      xType: SPECIFIED,
      xAxisLabels: 'multiplierXAxis',
      xAxisLabelsPosition: 'distribution',
      xAxisLabel: 'Multiplier Range',
      yAxisLabel: '# of Divisions',
      defaultPosition: 'distribution',
      defaultSeries: {
        colaCountByMultEmployer: { title: 'My Employer: Divisions with COLA', color: 'mersGreen', },
        noCOLACountByMultEmployer: { title: 'My Employer: Divisions without COLA', color: 'mersBlue', },
      },
      views: {
        bar: {},
        table: {},
      },
    },
    'Distribution of Divisions with COLA by Multiplier Range and Participant Contribution Rates': {
      format: PERCENT,
      xType: SPECIFIED,
      xAxisLabels: 'multiplierXAxis',
      xAxisLabelsPosition: 'distribution',
      xAxisLabel: 'Multiplier Range',
      yAxisLabel: '% of Divisions',
      defaultPosition: 'distribution',
      defaultSeries: {
        percentByMultEmployerCOLA: { title: 'My Employer: Divisions with COLA', color: 'mersGreen', },
        percentByMultPeerCOLA: { title: 'Peer Group: Divisions with COLA', color: 'mersBlue', },
        avgEECByMultEmployerCOLA: { title: 'My Employer: Average Participant Contribution Rates of Divisions with COLA', view: 'line', color: 'mersLightGreen', },
        avgEECByMultPeerCOLA: { title: 'Peer Group: Average Participant Contribution Rates of Divisions with COLA', view: 'line', color: 'mersLightBlue', },
      },
      views: {
        bar: {},
        table: {},
      },
    },
    'Distribution of Divisions without COLA by Multiplier Range and Participant Contribution Rates': {
      format: PERCENT,
      xType: SPECIFIED,
      xAxisLabels: 'multiplierXAxis',
      xAxisLabelsPosition: 'distribution',
      xAxisLabel: 'Multiplier Range',
      yAxisLabel: '% of Divisions',
      defaultPosition: 'distribution',
      defaultSeries: {
        percentByMultEmployerNoCOLA: { title: 'My Employer: Divisions without COLA', color: 'mersGreen', },
        percentByMultPeerNoCOLA: { title: 'Peer Group: Divisions without COLA', color: 'mersBlue', },
        avgEECByMultEmployerNoCOLA: { title: 'My Employer: Average Participant Contribution Rates of Divisions without COLA', view: 'line', color: 'mersLightGreen', },
        avgEECByMultPeerNoCOLA: { title: 'Peer Group: Average Participant Contribution Rates of Divisions without COLA', view: 'line', color: 'mersLightBlue', },
      },
      views: {
        bar: {},
        table: {},
      },
    },
    'Distribution of Divisions by Unreduced Retirement Rider': {
      format: PERCENT,
      xType: SPECIFIED,
      xAxisLabels: 'unredRiderXAxis',
      xAxisLabelsPosition: 'distribution',
      xAxisRotate: true,
      xAxisAdditionalMargin: 90,
      xAxisLabel: 'Unreduced Retirement Rider Present',
      yAxisLabel: '% of Divisions',
      defaultPosition: 'distribution',
      defaultSeries: {
        unredRiderPctEmployer: { title: 'My Employer', color: 'mersGreen', },
        unredRiderPctPeer: { title: 'Peer Group', color: 'mersBlue', },
      },
      views: {
        bar: {},
        table: {},
      },
    },
    'Distribution of Divisions by Vesting Period': {
      format: PERCENT,
      xType: SPECIFIED,
      xAxisLabels: 'vestPeriodXAxis',
      xAxisLabelsPosition: 'distribution',
      xAxisLabel: 'Vesting Period',
      yAxisLabel: '% of Divisions',
      defaultPosition: 'distribution',
      defaultSeries: {
        vestPeriodPctEmployer: { title: 'My Employer', color: 'mersGreen', },
        vestPeriodPctPeer: { title: 'Peer Group', color: 'mersBlue', },
      },
      views: {
        bar: {},
        table: {},
      },
    },
    'Distribution of Divisions by FAC Period': {
      format: PERCENT,
      xType: SPECIFIED,
      xAxisLabels: 'facPeriodXAxis',
      xAxisLabelsPosition: 'distribution',
      xAxisLabel: 'FAC Period',
      xAxisRotate: true,
      xAxisAdditionalMargin: 115,
      yAxisLabel: '% of Divisions',
      defaultPosition: 'distribution',
      defaultSeries: {
        facPeriodPctEmployer: { title: 'My Employer', color: 'mersGreen', },
        facPeriodPctPeer: { title: 'Peer Group', color: 'mersBlue', },
      },
      views: {
        bar: {},
        table: {},
      },
    },
    'Distribution of Divisions by Service Credit Purchase': {
      format: PERCENT,
      xType: SPECIFIED,
      xAxisLabels: 'svcCreditPurchAllowedXAxis',
      xAxisLabelsPosition: 'distribution',
      xAxisLabel: 'Service Credit Purchase Option',
      yAxisLabel: '% of Divisions',
      defaultPosition: 'distribution',
      defaultSeries: {
        svcCreditPurchAllowedPctEmployer: { title: 'My Employer', color: 'mersGreen', },
        svcCreditPurchAllowedPctPeer: { title: 'Peer Group', color: 'mersBlue', },
      },
      views: {
        bar: {},
        table: {},
      },
    },
    'Peer Group: Distribution of Participant Counts by Source': {
      format: COMMA,
      xType: SPECIFIED,
      xAxisLabels: 'yearsXAxis',
      xAxisLabelsPosition: 'years',
      xAxisLabel: 'Valuation Year',
      yAxisLabel: '# of Members',
      defaultPosition: 'years',
      defaultSeries: {
        payeeCountPeer: { title: 'Retirees', color: 'blue', },
        vestCountPeer: { title: 'Vested Terminated', color: 'navy' },
        refundCountPeer: { title: 'Pending Refunds', color: 'orange', },
        actCountPeer: { title: 'Actives', color: 'red', },
        totalCountPeer: { title: 'Total', color: 'black', view: 'line', stacked: false },
      },
      views: {
        area: {},
        stackedBar: {},
        table: {},
      },
    },
    'My Employer: Distribution of Participant Counts by Source': {
      format: COMMA,
      xType: SPECIFIED,
      xAxisLabels: 'yearsXAxis',
      xAxisLabelsPosition: 'years',
      xAxisLabel: 'Valuation Year',
      yAxisLabel: '# of Members',
      defaultPosition: 'years',
      defaultSeries: {
        payeeCountEmployer: { title: 'Retirees', color: 'blue', },
        vestCountEmployer: { title: 'Vested Terminated', color: 'navy' },
        refundCountEmployer: { title: 'Pending Refunds', color: 'orange', },
        actCountEmployer: { title: 'Actives', color: 'red', },
        totalCountEmployer: { title: 'Total', color: 'black', view: 'line', stacked: false },
      },
      views: {
        area: {},
        stackedBar: {},
        table: {},
      },
    },
    'Maturity Metric - MVA/Payroll': {
      format: NUMBER,
      xType: SPECIFIED,
      xAxisLabels: 'yearsXAxis',
      xAxisLabelsPosition: 'years',
      xAxisLabel: 'Valuation Year',
      defaultPosition: 'years',
      defaultSeries: {
        assetsToPayrollEmployer: { title: 'My Employer', color: 'mersGreen', },
        assetsToPayrollPeer: { title: 'Peer Group', color: 'mersBlue', },
      },
      views: {
        bar: {},
        line: {},
        table: {},
      },
    },
    'Maturity Metric - AAL/Payroll': {
      format: NUMBER,
      xType: SPECIFIED,
      xAxisLabels: 'yearsXAxis',
      xAxisLabelsPosition: 'years',
      xAxisLabel: 'Valuation Year',
      defaultPosition: 'years',
      defaultSeries: {
        liabToPayrollEmployer: { title: 'My Employer', color: 'mersGreen', },
        liabToPayrollPeer: { title: 'Peer Group', color: 'mersBlue', },
      },
      views: {
        bar: {},
        line: {},
        table: {},
      },
    },
    'Maturity Metric - Actives/Retirees': {
      format: NUMBER,
      xType: SPECIFIED,
      xAxisLabels: 'yearsXAxis',
      xAxisLabelsPosition: 'years',
      xAxisLabel: 'Valuation Year',
      defaultPosition: 'years',
      defaultSeries: {
        activesToPayeesEmployer: { title: 'My Employer', color: 'mersGreen', },
        activesToPayeesPeer: { title: 'Peer Group', color: 'mersBlue', },
      },
      views: {
        bar: {},
        line: {},
        table: {},
      },
    },
    'Maturity Metric - MVA/Benefit Payments': {
      format: NUMBER,
      xType: SPECIFIED,
      xAxisLabels: 'yearsXAxis',
      xAxisLabelsPosition: 'years',
      xAxisLabel: 'Valuation Year',
      defaultPosition: 'years',
      defaultSeries: {
        assetsToBenefitsEmployer: { title: 'My Employer', color: 'mersGreen', },
        assetsToBenefitsPeer: { title: 'Peer Group', color: 'mersBlue', },
      },
      views: {
        bar: {},
        line: {},
        table: {},
      },
    },
    'Maturity Metric - Net Cash Flow to MVA (BOY)': {
      format: PERCENT,
      xType: SPECIFIED,
      xAxisLabels: 'yearsXAxis',
      xAxisLabelsPosition: 'years',
      xAxisLabel: 'Valuation Year',
      defaultPosition: 'years',
      defaultSeries: {
        cashflowToAssetsEmployer: { title: 'My Employer', color: 'mersGreen', },
        cashflowToAssetsPeer: { title: 'Peer Group', color: 'mersBlue', },
      },
      views: {
        bar: {},
        line: {},
        table: {},
      },
    },
  },
};
export default peerComparisonCharts;
