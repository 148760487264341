// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { connect } from 'react-redux';
import { Glyphicon } from 'react-bootstrap';
import { getFiscalYear1StartLabel2, getValuationYear1StartLabel2 } from '../lib/planDefaultsSelectors';
import { setTopicAndChart } from '../actions/chartsActions';
import { toggleShowMiniDashboard } from 'client/actions/uiActions';
import GaugeHeader from './GaugeHeader.jsx';
import { getGaugeMetrics } from 'client/selectors/graphSelectors';
import styled from 'styled-components';
import './MiniDashboard.css';

const LeftControlWrapper = styled.div`
  display: flex;
  position: ${props => props.collapsed ? '' : 'absolute'};
  margin-bottom: ${props => props.collapsed ? '10px' : ''};
  z-index:100;
`;

const CollapseContent = styled.span`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-column-gap: 5px;
  font-size: 16px;
  user-select: none;
`;

const GlyphiconStyled = styled(Glyphicon)`
  color: lightgrey;
  cursor: pointer;
  &:hover {
    color: black;
  }
`;

class MiniDashboard extends React.Component {
  handleSetTopicAndChart = (topic, chart) => {
    this.props.setTopicAndChart({ topic, chart });
  }
  render() {
    const { selectedView, showMiniDashboard, fiscalStartLabel, valuationStartLabel, gaugeMetrics, toggleShowMiniDashboard } = this.props;
    const leftControl = (
      <CollapseContent>
        <GlyphiconStyled onClick={() => toggleShowMiniDashboard()} glyph={!showMiniDashboard ? 'menu-right' : 'menu-down'} />{' '}
        {!showMiniDashboard}
      </CollapseContent>
    );

    const style = {
      height: '275px',
      transition: 'height .75s ease, opacity .75s ease',
    };
    if(!showMiniDashboard) {
      style.height = '0px';
      style.overflow = 'hidden';
      style.opacity = 0;
    }

    return (
      <div className="mini-dashboard" style={{ padding: '0px', paddingLeft: '10px' }}>
        <LeftControlWrapper collapsed={!showMiniDashboard}>
          {leftControl}
        </LeftControlWrapper>
        <div aria-expanded={showMiniDashboard} style={style}>
          <GaugeHeader
            gaugeMetrics={gaugeMetrics}
            selectedView={selectedView}
            fiscalStartLabel={fiscalStartLabel}
            valuationStartLabel={valuationStartLabel}
            handleSetTopicAndChart={this.handleSetTopicAndChart}
          />
        </div>
      </div>
    );
  }
}

const stateToProps = state => {
  const gaugeMetrics = getGaugeMetrics(state);
  return {
    gaugeMetrics: gaugeMetrics,
    showMiniDashboard: state.ui.showMiniDashboard,
    fiscalStartLabel: getFiscalYear1StartLabel2(state),
    valuationStartLabel: getValuationYear1StartLabel2(state),
    selectedView: state.charts.selectedView,
  };
}

const dispatchToProps = {
  setTopicAndChart,
  toggleShowMiniDashboard,
}

export default connect(stateToProps, dispatchToProps)(MiniDashboard);
