// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import { findByName } from 'common/colorMachine';
import linechart from './lineChart';
import areachart from './areaChart';
import stackedBar from './stackedBar';
import stackedBarLine from './stackedBarLine';
import barChart from './barChart';
import diffView from './diffView';
import diffBarCurveHidden from './diffBarCurveHidden';
import diffViewCurveHidden from './diffViewCurveHidden';
import diffBar from './diffBar';

//add a 'points' field, with the calculated x & y's
const compute = (info, scales, cmp) => {
  let already = [];
  let baseAlready = [];
  let negAlready = [];
  let baseNegAlready = [];
  let order = 1;

  if (info.chartRules.useDiffView) {
    return diffView(info, scales, cmp, order);
  }
  if (info.chartRules.useDiffAreaCurveHidden) {
    return diffViewCurveHidden(info, scales, cmp, order);
  }
  if (info.chartRules.useDiffBar) {
    return diffBar(info, scales);
  }
  if (info.chartRules.useDiffBarCurveHidden) {
    return diffBarCurveHidden(info, scales);
  }

  const visibleSeries = info.series.filter(series => !series.hidden);

  const charts = visibleSeries.map(function(s, i) {
    let points = [];
    if (s.view === 'area') {
      points = areachart(s, info, already, negAlready, baseAlready, baseNegAlready, scales, cmp, order);
    } else if (s.view === 'bar') {
      points = barChart(s, info, already, negAlready, scales, i);
    } else if (s.view === 'line' && info.view === 'stackedBar' && !s.stacked) {
      points = stackedBarLine(s, info, already, negAlready, scales);
    } else if (s.view === 'stackedBar') {
      points = stackedBar(s, info, already, negAlready, baseAlready, baseNegAlready, scales);
    } else {
      points = linechart(s, info, already, negAlready, scales, cmp, order);
    }
    const yearPoint = points.filter(point => point.year === cmp.state.onYear)[0]
    if (yearPoint.order) {
      order += 1;
    }
    return {
      id: s.id,
      title: s.title,
      type: s.view,
      points: points,
      color: findByName(s.color) || 'lightgrey',
      view: info.view,
      lineType: s.lineType,
      compareWithBaseline: info.compareWithBaseline,
    };
  });
  return charts;
}

export default compute;
