import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DropdownButton, Glyphicon } from 'react-bootstrap';
import styled from 'styled-components';

const ToggleSeriesDropdown = props => {
  const dispatch = useDispatch();
  const selectedView = useSelector(state => state.charts.selectedView);
  const { series } = props.info;


  const handleSelectSeries = series => {
    const payload = {
      topic: selectedView.topic,
      chart: selectedView.chart,
      series: series.id,
    }
    dispatch({ type: 'TOGGLE_DATA_SERIES', payload: payload });
  }

  const resetHiddenDataSeries = () => {
    dispatch({ type: 'RESET_HIDDEN_DATA_SERIES' });
  }

  const determineEnabled = (series, visibleSeriesAmount) => {
    return visibleSeriesAmount > 1 || series.hidden;
  }

  const renderSeries = series => {
    const visibleSeriesAmount = series.filter(series => !series.hidden).length;
    const seriesJsx = series.map(series => {
      const enabled = determineEnabled(series, visibleSeriesAmount);
      return (
        <Series
          key={series.id}
          disabled={!enabled}
          onClick={enabled ? () => handleSelectSeries(series) : null}>
          <input type="checkbox" checked={!series.hidden} onChange={() => {}} disabled={!enabled}/>
          {series.title}
        </Series>
      );
    });
    return seriesJsx;
  }

  return (
    <ToggleSeriesMain>
      <DropdownStyled id="series-visibility-dropdown" title="Series" noCaret>
        {renderSeries(series)}
      </DropdownStyled>
      <IconWrapper onClick={resetHiddenDataSeries}>
        <ResetVisibilityIcon glyph={'repeat'} />
      </IconWrapper>
    </ToggleSeriesMain>
  )
}

export default ToggleSeriesDropdown;

const ToggleSeriesMain = styled.div`
  display: inline-flex;
  align-self: center;
`;

const DropdownStyled = styled(DropdownButton)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  background: white !important;
  &:first-child {
    box-shadow: none !important;
    color: black !important;
  }
`;

const GridRow = styled.div`
  display: grid;
  grid-auto-flow: column;
`;

const Series = styled(GridRow)`
  grid-column-gap: 5px;
  font-weight: 400;
  justify-content: flex-start;
  padding: 2.5px 10px;
  color: ${props => (props.disabled ? '#808797' : '')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  white-space: nowrap;
  user-select: none;
  & input {
    cursor: pointer;
  }
`;

const IconWrapper = styled.div`
  display: grid;
  padding: 5px;
  border: 1px solid #ccc;
  border-top-right-radius: 2.5px;
  border-bottom-right-radius: 2.5px;
  cursor: pointer;
  &:hover {
    border-color: #b5bbcd;
    & span {
      color: #12B9E5;
    }
  }
`;

const ResetVisibilityIcon = styled(Glyphicon)`
  align-self: center;
  font-size: 14px;
  color: #808797;

  transform: scaleX(-1);
`;
