import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Switch,
  Route,
  useHistory
} from "react-router-dom";
import './App.css';
import Auth from './lib/auth';
import { getTenants } from 'components/tenantsList/tenantsListActions';
import ErrorDialog from './components/ErrorDialog';
import Spinner from './components/Spinner';
import MainPage from './components/mainPage/MainPage';
import * as routeBuilder from 'lib/routeBuilder';
import Workspace from 'components/workspace/Workspace';
import { clearError, getProjects } from 'lib/sharedActions';
import { onlyOneProject, selectFirstProject } from 'lib/sharedSelectors';

function App() {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector(rs => rs.currentUser);
  const profile = currentUser.profile;
  const error = useSelector(rs => rs.shared.error);
  const tenants = useSelector(rs => rs.tenantsList.tenants);
  const projects = useSelector(rs => rs.projectsList.projects);
  const oneProject = useSelector(onlyOneProject);
  const firstProject = useSelector(selectFirstProject);
  const auth = new Auth();
  const authProfile = auth.getProfile();
  const tenantsCount = !!tenants ? tenants.length : null;
  const [loaded, setLoaded] = useState(false);
  const userId = !!profile ? profile.userId : null;

  if(!profile && authProfile) {
    dispatch({ type: 'PROFILE_AVAILABLE', payload: { profile: authProfile, isAdmin: auth.isAdmin() } });
  }

  useEffect(() => {
    if(!!userId)
      dispatch(getTenants());
  }, [userId, dispatch]);

  useEffect(() => {
    if(tenantsCount === null)
      return;
    if(tenantsCount === 1) { //if only one tenant, load the projects to determine if there is more than one
      dispatch(getProjects(tenants[0].id));
      return;
    }
    setLoaded(true); //if more than one tenant, just show tenants list page
  }, [tenantsCount, dispatch, tenants]);

  useEffect(() => {
    if(oneProject === null) //check if its loaded
      return;
    if(new Auth().isAdmin()) { //don't redirect if admin
      setLoaded(true);
      return;
    }
    if(oneProject) { //if only one tenant and one project, and not admin, jump to the workspace for that project
      const proj = firstProject

      if(window.location.pathname.indexOf('copy-sent-scenario') === -1) {

        if (!!proj.pensionPlanGroup) {
          history.replace(routeBuilder.buildProjectPensionWorkspace(proj.projectId));
        } else if (!!proj.opebPlanGroup) {
          history.replace(routeBuilder.buildProjectOpebWorkspace(proj.projectId));
        }

      }
    }
    setLoaded(true);
  }, [oneProject, history, projects, firstProject]);

  useEffect(() => {
    if(profile) {
      const auth = new Auth();
      const renewScheduled = auth.getRenewScheduled();
      if(!renewScheduled) {
        auth.scheduleRenewalWithRandom();
      } else {
        console.log('Renew already scheduled');
      }
      if(profile.requiresPasswordReset) {
        auth.changePassword(true);
      }
    }
  }, [profile]);

  useEffect(() => {
    if(profile) {
      const requestedUrl = auth.getRequestedUrl();
      if(!!requestedUrl) {
        auth.clearRequestedUrl();
        if(requestedUrl.indexOf('logout_callback') === -1)
          history.push(requestedUrl);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const handleErrorClose = () => {
    dispatch(clearError());
  };

  if(!authProfile)
    return <Spinner />;

  if(!loaded)
    return <Spinner/>;

  const showAlert = !!error;

  return (
      <Switch>
        <Route path={routeBuilder.projectWorkspace}>
          <Workspace />
        </Route>
        <Route path={routeBuilder.app}>
          <MainPage />
        </Route>
      { error && <ErrorDialog open={showAlert} dialogState={error} onClose={handleErrorClose} /> }
      </Switch>
  );
}

export default App;