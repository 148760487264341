// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
export const TYPE_YEAR_PERCENT = 'YearlyPercent';
export const TYPE_DROPDOWN = 'DropDown';
export const TYPE_YEAR_DOLLAR = 'YearlyDollar';
export const TYPE_FRESH_START = 'AmortizationBasesFreshStart';
export const TYPE_PAYOFF_PRIORITY = 'AmortizationBasesPayoffPriority';
export const TYPE_CUSTOM_ADP_TIMING = 'CustomAdpTiming';
export const YEARSOFINPUT = 30;
export const CONTROL_SLIDER = 'slider';
export const CONTROL_5YEAR = '5year';
export const CONTROL_10YEAR = '10year';
export const CONTROL_30YEAR = '30year';
export const CONTROL_DEFAULT = 'default';
export const ALL_CONTROLS = [CONTROL_SLIDER, CONTROL_5YEAR, CONTROL_10YEAR, CONTROL_30YEAR];
export const PRECISION_DECIMAL = 5;
export const PRECISION_PERCENT = 3;
export const PRECISION_DOLLAR = 5;
export const INDEX_TYPE_VALUATIONYEAR = 'VY';
export const INDEX_TYPE_FISCALYEAR = 'FY';
export const PROJECTED_NUMBER_YEAR = { min: 5, max: 30 };
export const SCENARIO_DEFAULT_ID = 'default';
export const YEAR_30_MODAL_ADJUSTMENT_NAME = undefined;
export const VARIABLE_TYPE_ASSUMPTION = 'Assumption';
export const VARIABLE_TYPE_EXPERIENCE = 'Experience';
export const SCENARIO_ID = 'scenario';
