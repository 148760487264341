import _ from 'lodash';
import { v4 as uuid } from 'uuid';

const initialState = {
  mode: 'none',
  payments: [],
  currentPayment: null,
  adjustmentStructure: null,
  adjustmentName: null,
  isDirty: false,
  plans: [],
};
function customAdp(state = initialState, action) {
  switch (action.type) {
    case 'CUSTOM_ADP_ADD_PAYMENT': {
      const newState = _.cloneDeep(state);
      newState.mode = 'add';
      newState.currentPayment = {
        id: uuid(),
        date: '',
        planPaymentAmounts: {},
        planPaymentAmountsParsed: {},
      };
      for(let plan of newState.plans) {
        newState.currentPayment.planPaymentAmounts[plan.id] = '0';
        newState.currentPayment.planPaymentAmountsParsed[plan.id] = 0;
      }
      return newState;
    }
    case 'CUSTOM_ADP_EDIT_PAYMENT': {
      const { id } = action.payload;
      const newState = _.cloneDeep(state);
      newState.currentPayment = newState.payments.find(x => x.id === id);
      populateCurrentPaymentStringFields(newState);
      newState.mode = 'edit';
      return newState;
    }
    case 'CUSTOM_ADP_PAYMENT_ADDED': {
      const newState = _.cloneDeep(state);
      populateCurrentPaymentStringFields(newState);
      newState.payments.push(newState.currentPayment);
      sortPayments(newState);
      newState.mode = 'list';
      newState.isDirty = true;
      return newState;
    }
    case 'CUSTOM_ADP_PAYMENT_EDITED': {
      const newState = _.cloneDeep(state);
      populateCurrentPaymentStringFields(newState);
      newState.payments = newState.payments.filter(x => x.id !== newState.currentPayment.id);
      newState.payments.push(newState.currentPayment);
      sortPayments(newState);
      newState.mode = 'list';
      newState.isDirty = true;
      return newState;
    }
    case 'CUSTOM_ADP_ADD_EDIT_INPUT_UPDATED': {
      const { planId, amount } = action.payload;
      const newState = _.cloneDeep(state);
      newState.currentPayment.planPaymentAmounts[planId] = amount;
      newState.currentPayment.planPaymentAmountsParsed[planId] = Number.parseFloat(amount.replaceAll(',', ''));
      return newState;
    }
    case 'CUSTOM_ADP_ADD_EDIT_DATE_UPDATED': {
      const { date } = action.payload;
      const newState = _.cloneDeep(state);
      newState.currentPayment.date = date;
      return newState;
    }
    case 'CUSTOM_ADP_DELETE_PAYMENT': {
      const { id } = action.payload;
      const newState = _.cloneDeep(state);
      newState.payments = newState.payments.filter(x => x.id !== id);
      newState.isDirty = true;
      return newState;
    }
    case 'CUSTOM_ADP_CANCEL_ADD_EDIT': {
      const newState = _.cloneDeep(state);
      newState.mode = 'list';
      newState.currentPayment = null;
      return newState;
    }
    case 'CUSTOM_ADP_SETUP_CANCELLED': {
      const newState = _.cloneDeep(state);
      newState.mode = 'none';
      newState.currentPayment = null;
      newState.payments = [];
      newState.isDirty = false;
      return newState;
    }
    case 'CUSTOM_ADP_SETUP_PAYMENTS_CLICKED': {
      const { adjustmentStructure, adjustmentName, payments } = action.payload;
      const newState = _.cloneDeep(state);
      newState.mode = 'list';
      newState.adjustmentStructure = adjustmentStructure;
      newState.adjustmentName = adjustmentName;
      newState.isDirty = false;
      newState.plans = adjustmentStructure.extended.plans;
      newState.payments = payments;
      return newState;
    }
    case 'CUSTOM_ADP_SETUP_SAVE_REQUESTED': {
      const newState = _.cloneDeep(state);
      newState.isDirty = false;
      return newState;
    }
    case 'SET_CUSTOM_ADP_PAYMENTS_APPLIED': {
      const newState = _.cloneDeep(state);
      newState.isDirty = false;
      newState.mode = 'none';
      return newState;
    }
    case 'CUSTOM_ADP_COPY_PASTE_FROM_SPREADSHEET_CLICKED': {
      const newState = _.cloneDeep(state);
      newState.mode = 'copy_paste';
      return newState;
    }
    case 'CUSTOM_ADP_CANCEL_COPY_PASTE': {
      const newState = _.cloneDeep(state);
      newState.mode = 'list';
      return newState;
    }
    case 'CUSTOM_ADP_COPY_PASTE_OK_CLICKED': {
      const newState = _.cloneDeep(state);
      newState.mode = 'list';
      const { payments } = action.payload;
      newState.payments = payments;
      for(let pmt of newState.payments)
        populateStringInputValuesFromParsed(pmt, newState.plans);
      newState.isDirty = true;
      sortPayments(newState);
      return newState;
    }
    case 'RESET_ADJUSTMENT_TO_DEFAULT': {
      const newState = _.cloneDeep(state);
      newState.payments = [];
      return newState;
    }
    default:
      return state;
  }
}

const populateCurrentPaymentStringFields = (state) => {
  populateStringInputValuesFromParsed(state.currentPayment, state.plans);
};
const populateStringInputValuesFromParsed = (pmt, plans) => {
  pmt.planPaymentAmounts = {};
  for(let p of plans) {
    if(!pmt.planPaymentAmountsParsed[p.id]) {
      pmt.planPaymentAmounts[p.id] = '0';
      pmt.planPaymentAmountsParsed[p.id] = 0;
      continue;
    }
    pmt.planPaymentAmounts[p.id] = pmt.planPaymentAmountsParsed[p.id].toString();
  }
};
const sortPayments = (state) => {
  state.payments = state.payments.sort((a, b) => a.date < b.date ? -1 : 1);
};

export default customAdp;
