// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PlanInteraction from './PlanInteraction.jsx';
import DownloadSelector2 from './DownloadSelector2';
import TopicSelect from './TopicSelect.jsx';
import ChartSelect from './ChartSelect.jsx';
import PlanTypeSelect from './PlanTypeSelect.jsx';
import { checkGetMetrics, requestPlanGroups, requestSavedScenarios2 } from '../sideBar/apiActions';
import { setPlanType } from '../actions/chartsActions';
import { setViewingScenario, setScenarioAsCompared } from '../sideBar/actionCreators';
import YearSelector from './YearSelector.jsx';
import { requestProjectPlanTypeDetails } from '../actions';
import CalcStatusModal from './calcStatus/CalcStatusModal';
import CopySentScenario from './CopySentScenario.jsx';
import Spinner from 'components/Spinner.jsx';

const PlanPage = props => {
  const dispatch = useDispatch();
  const scenarios = useSelector(state => state.sideBar.scenarios);
  const workspaceInfo = useSelector(state => state.workspace.info);
  const initialized = useSelector(state => state.charts.initialized);
  const hasComparedScenarios = useSelector(state => state.sideBar.comparedScenarios.legnth > 0);
  const planGroupsLoaded = useSelector(state => state.sideBar.planGroupsLoaded);
  const planTypeInfo = useSelector(state => state.planTypeInfo);

  const planType = !!workspaceInfo ? workspaceInfo.planType : null;
  const projectId = !!workspaceInfo ? workspaceInfo.projectId : null;
  const features = planTypeInfo.features;

  useEffect(() => {
    if(features && !!planType && !!projectId) {
      dispatch(setPlanType(planType));
      dispatch(requestProjectPlanTypeDetails(planType));
      if(features.scenarios) {
        dispatch(requestSavedScenarios2());
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, planType, projectId, features]);

  useEffect(() => {
    if(planTypeInfo.planGroupKeys) {
      dispatch(requestPlanGroups());
    }
  }, [dispatch, planTypeInfo]);

  useEffect(() => {
    if(planGroupsLoaded && !hasComparedScenarios) {
      dispatch(checkGetMetrics({ workspaceInfo, purpose: 'Default', scenarioId: 'default', newOrUpdatedScenario: true }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, hasComparedScenarios, planGroupsLoaded]);

  const didUpdateCallback = () => {
    // if scenario copied via send link
    // add it to compared and viewed and calculate it
    if(!features || !features.scenarios)
      return;
    const scenarioCopyToCompare = localStorage.getItem('scenarioToCompare');
    if (scenarioCopyToCompare && Object.keys(scenarios).length > 0) {
      localStorage.removeItem('scenarioToCompare');
      dispatch(setScenarioAsCompared(scenarioCopyToCompare));
      dispatch(setViewingScenario(scenarioCopyToCompare));
      dispatch(checkGetMetrics({ workspaceInfo, purpose: 'Set scenario', scenarioId: scenarioCopyToCompare, newOrUpdatedScenario: true }));
    }
  }

  useEffect(() => {
   didUpdateCallback();
  });

  if (!initialized || !planGroupsLoaded) {
    return <Spinner />;
  }

  const wrapperProps = {
    leftItems: (
      <div id="navmenus" className="menus">
        <TopicSelect />
        <ChartSelect />
        { features.yearSelector && <YearSelector /> }
      </div>
    ),
    middleItems: <PlanTypeSelect />,
    alert: <CalcStatusModal />,
    workspaceInfo: workspaceInfo,
  };

  return (
    <Switch>
      { features.scenarios && <Route path="*copy-sent-scenario/:scenarioId" component={CopySentScenario} /> }
      { features.downloadSelector && <Route path="*download" render={() => <DownloadSelector2 wrapperProps={wrapperProps} />} /> }
      <Route path="/" render={() => <PlanInteraction wrapperProps={wrapperProps} />} />
    </Switch>
  );
}

export default withRouter(PlanPage);
