const initialState = {
  profile: null,
  isAdmin: false,
};

export const currentUser = (state = initialState, action) => {
  switch (action.type) {
    case 'PROFILE_AVAILABLE':
      const newState = {
        profile: action.payload.profile,
        isAdmin: action.payload.isAdmin,
      };
      return newState;
    default:
      return state;
  }
};