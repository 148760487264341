// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************

const SCENARIOS_SELECT_DOWNLOAD_DIALOG_SHOW = 'SCENARIOS_SELECT_DOWNLOAD_DIALOG_SHOW';

export const toggleShowSideBar = () => ({
  type: 'TOGGLE_SHOW_SIDEBAR',
});

export const toggleShowMiniDashboard = () => ({
  type: 'TOGGLE_SHOW_MINI_DASHBOARD',
});

export const scenariosSelectDownloadDialogShow = bool => ({
  type: SCENARIOS_SELECT_DOWNLOAD_DIALOG_SHOW,
  payload: bool,
});