// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
/*

# ChartView

*/

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import area from 'img/giAreaGraph.js';
import bar from 'img/giBarGraph.js';
import line from 'img/giLineGraph.js';
import stackedBar from 'img/giStackedGraph.js';
import table from 'img/giTable.js';
import DownloadData2 from './DownloadData2';
import { setView } from "../actions/chartsActions";

const icons = {
  area,
  bar,
  line,
  stackedBar,
  table,
  diffView: area,
  diffBar: stackedBar,
};

const ChartView = props => {
  const dispatch = useDispatch();
  const selectedView = useSelector(state => state.charts.selectedView.view);
  const viewButtons = useSelector(state => state.charts.viewButtons);
  const views = viewButtons.map(v =>
    <ChartViewLink dispatch={dispatch} key={v.view} icon={v.iconToShow} view={selectedView} new_view={v.view} />);

  views.push(<DownloadData2 key="download data 2" />);

  return (
    <div
      id="select-view"
      aria-label=""
      style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center', zIndex: null }} //z-index null to stop button from showing up over profile dropdown
      className="btn-group btn-group-rounded view-btn-set"
      role="group"
    >
      {views}
    </div>
  );

}

const ChartViewLink = props => {
  const { dispatch, new_view, icon, view } = props;
  const classes = 'btn gi-btn-gr-default-o' + (new_view === view ? ' active' : '');
  const Icon = icons[icon];
  return (
    <button
      onClick={() => dispatch(setView(new_view))}
      className={classes}
      style={{ marginTop: 5, marginBottom: 5 }}
      type="button"
    >
      <div style={{display:'flex', alignItems:'center', height:'34px', justifyContent:'center'}}>
        <Icon />
      </div>
    </button>
  );
}

export default ChartView;
