// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import _cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import prettyPrint from 'common/pretty_print';
import { cachedAvailable, parameterizedFilterCategories } from 'client/lib/app-general';
import { SPECIFIED } from '../../lib/defaults';

export function getChartDataFromProps(state, scenarioId) {
  return bundledData(state, state.selectedView || state.charts.selectedView, scenarioId);
}

export function getChartCustomXAxisFromProps(state, scenarioId) {
  return customXAxisLabels(state, state.selectedView || state.charts.selectedView, scenarioId);
}

function customXAxisLabels(state, selectedView, scenarioId) {
  const { projectedNumYears: PY } = state.sideBar;
  const { requestedMetricsData } = state.cache;
  const cached = requestedMetricsData[scenarioId];
  const { availCharts } = state.charts;
  const { planType, topic, chart } = selectedView;
  const { sideBar } = state;
  const filters = parameterizedFilterCategories(sideBar);

  if (cachedAvailable(cached, PY, filters)) {
    const chartSpec = availCharts[planType][topic][chart];
    if (chartSpec.xType === SPECIFIED) {
      const { xAxisLabelsPosition, xAxisLabels } = chartSpec;
      return cached.data[xAxisLabelsPosition][xAxisLabels];
    }
  }
  return null;
}

/**
 *
 *
 * @param {Object} state
 * @param {string} scenarioId
 * @returns
 */
// TODO fix other usages of bundledData for the fact we deleted viewParams from it.
function bundledData(state, selectedView, scenarioId) {
  const { projectedNumYears: PY } = state.sideBar;
  const { requestedMetricsData } = state.cache;
  const cached = requestedMetricsData[scenarioId];
  const { availCharts } = state.charts;
  const { planType, topic, chart, view: viewName } = selectedView;
  const { sideBar } = state;
  const filters = parameterizedFilterCategories(sideBar);

  if (cachedAvailable(cached, PY, filters)) {
    const chartSpec = availCharts[planType][topic][chart];
    const view = chartSpec.views[viewName];
    const series = view.series || {};
    const ret = {};
    const seriesNames = Object.keys(series);
    for(let n = 0; n < seriesNames.length; n++) {
      const currentSeriesName = seriesNames[n];
      const currentSeries = series[currentSeriesName];
      const position = currentSeries.position || chartSpec.defaultPosition;
      const data = cached.data[position][currentSeriesName];
      if (data === undefined) {
        console.error('missing', currentSeriesName, 'from', {
          series,
          data: cached.data,
          position,
        });
      }
      ret[currentSeriesName] = _cloneDeep(data);
    }
    return ret;
  }
  return null;
}

export const getGaugeMetrics = state => {
  let gaugeMetrics = state.sideBar.gaugeMetrics[state.sideBar.viewingScenario];
  if (!gaugeMetrics) {
    if(!state.sideBar.gaugeMetrics)
      return;
    if(Object.values(state.sideBar.gaugeMetrics).length === 0)
      return;
    return Object.values(state.sideBar.gaugeMetrics)[0].metrics; //i only expect this to occur during calculations.  this is done to stop the mini dashboard disappearing during calcs
  }
  const { baselineScenario, viewingScenario } = state.sideBar;
  const baselineMetrics = state.sideBar.gaugeMetrics[baselineScenario].metrics;
  const valuationYear = moment(state.sideBar.planGroupsInfo.valuationDate).year();
  const { y } = state.viewSummary.yearParams;
  const index = y - valuationYear;

  let viewingMetrics = _cloneDeep(gaugeMetrics.metrics);

  if (viewingScenario === baselineScenario) {
    viewingMetrics.forEach((metric, metricIndex) => {
      viewingMetrics[metricIndex].primary = prettyPrint(metric.values[index], metric.valueType);
    });
  }

  if (viewingScenario !== baselineScenario) {
    viewingMetrics.forEach((viewingMetric, metricIndex) => {
      const baselineMetric = baselineMetrics.filter(baselineMetric => baselineMetric.label === viewingMetric.label)[0];
      if (!baselineMetric || !baselineMetric.values)
        return;
      viewingMetrics[metricIndex].primary = prettyPrint(viewingMetric.values[index], viewingMetric.valueType);
      viewingMetrics[metricIndex].secondary = formatChange(subtractValues(viewingMetric.values[index], baselineMetric.values[index]), viewingMetric.valueType);
    });
  }

  return viewingMetrics;
};

const subtractValues = (a, b) => a - b;
const formatChange = (value, valueType) =>
  (value.toFixed(4) > 0 ? '+' : value.toFixed(4) < 0 ? '-' : '') + prettyPrint(value, valueType || 'percentage').replace(/[()]/g, '');

export function getXAxisLabels(state, scenarioId) {
  const { requestedMetricsData } = state.cache;
  const scenarioCalcResult = requestedMetricsData[scenarioId];
  const { charts } = state;
  const { selectedView, availCharts } = charts;
  const { planType, topic, chart } = selectedView;
  const chartSpec = availCharts[planType][topic][chart];
  if(!scenarioCalcResult) {
    return [];
  }
  if(!chartSpec.xAxisLabelsPosition)
    return [];
  const resultsPosition = scenarioCalcResult.data[chartSpec.xAxisLabelsPosition];
  if(!resultsPosition) {
    console.error('No position found for XAxis labels');
    return [];
  }
  const labels = resultsPosition[chartSpec.xAxisLabels];
  return labels;
}