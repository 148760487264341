// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { connect } from 'react-redux';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { setYearParams } from 'client/actions';
import { range } from 'lodash';
import { getValuationYear } from '../selectors/dashboardSelectors.js'
import styled from 'styled-components';

const DropdownSelect = styled.div`
  font-size: 16px;
  color: #333333;
  padding: 0 10px;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  user-select: none;
  cursor: pointer;
  &:hover {
    border-color: #B5BBCC;
  }
`;

class YearSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      y: props.y,
      PY: props.PY,
    };
  }

  render() {
    const { valuationYear, y: valy, PY: valPY } = this.props;

    return (
      <div className="dropdown-parent" id="year-select-parent">
        <Dropdown id="year-select" pullRight>
          <DropdownSelect bsRole="toggle">
            {valy} <span className="caret" />
          </DropdownSelect>
          <Dropdown.Menu>
            {range(valuationYear, valuationYear + valPY).map(y => (
              <MenuItem key={y} onSelect={() => this.update(y)} href="http://" onClick={e => e.preventDefault()}>
                {y}
              </MenuItem>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }

  update(v) {
    const { dispatch } = this.props;
    const value = v;
    this.setState({ y: value });
    dispatch(setYearParams('y', value));
  }
}

export default connect(state => ({
  y: state.viewSummary.yearParams.y,
  PY: state.viewSummary.yearParams.PY,
  valuationYear: getValuationYear(state)
}))(YearSelector);
