// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import DifferenceArrow from '../misc/DifferenceArrow';
import tinycolor from 'tinycolor2';

export default class DrawArrows extends React.Component {
  render() {
    const { chart, cmp, year } = this.props;
    const { onYear } = cmp.state;
    const renderBaselineBarArrows = chart.type === 'bar';
    const renderBaselineArrows = chart.type === 'area' || chart.type === 'fillArea' || chart.type === 'diffArea' || 'line';

    const yearPoint = chart.points.filter(point => point.year === onYear)[0];

    let arrows;
    if (renderBaselineBarArrows) {
      arrows = buildBaselineBarArrows(chart, yearPoint, year, cmp);
    } else if (renderBaselineArrows) {
      arrows = buildBaselineArrows(chart, yearPoint, year, cmp);
    }
    return arrows ? arrows : <g />
  }
}

const buildBaselineBarArrows = (chart, yearPoint, year, cmp) => {
  const result = chart.points.map(function(c, i) {

    const valueIsNegative = c.value < 0;
    const baseValueIsNegative = c.baseValue < 0;
    const yearIsInFocus = c.year === yearPoint.year;

    let differenceIsBigEnough = Math.abs(yearPoint.y - yearPoint.baseY) > 7;
    if (baseValueIsNegative) {
      differenceIsBigEnough = Math.abs(yearPoint.bottom - yearPoint.baseBottom) > 7;
    }

    const renderDifferenceArrow = yearIsInFocus && differenceIsBigEnough;

    const halfWidth = c.width * 0.5;
    let opacity = 1;
    let color = tinycolor(chart.color).darken(30);

    if (c.reversed) {
      color = tinycolor('#5AB573').darken(20);
    }
    if (cmp.state.pinYear && cmp.state.onYear !== c.year) {
      opacity = 0.4;
    }

    let top = c.baseY;
    if (baseValueIsNegative) {
      top = c.baseBottom;
    }

    let to = valueIsNegative ? c.bottom : c.y;
    let from = baseValueIsNegative ? c.baseBottom : c.baseY;

    return (
      <React.Fragment key={`baseline-bar-arrows-${i}`}>
        <line
          x1={c.x - halfWidth}
          y1={top}
          x2={c.x + halfWidth}
          y2={top}
          stroke={color}
          strokeWidth={4}
          fill="none"
          opacity={opacity}
          shapeRendering="auto"
        />
        {renderDifferenceArrow &&  (
            <DifferenceArrow x={c.x - halfWidth} to={to} from={from} color={color} year={year} />
          )}
      </React.Fragment>
    );
  });

  return result;
};

const buildBaselineArrows = (chart, yearPoint, year, cmp) => {
  const result = chart.points.map((c, i) => {
    const darken = chart.type !== 'comparison';
    const color = darken ? tinycolor(chart.color).darken(30) : tinycolor(chart.color);
    const yearIsInFocus = c.year === yearPoint.year;
    const differenceIsBigEnough = Math.abs(yearPoint.y - yearPoint.baseY) > 7;
    const renderDifferenceArrow = yearIsInFocus && differenceIsBigEnough;

    return (
      <React.Fragment key={`baseline-area-arrows-${i}`}>
        {renderDifferenceArrow &&
          <DifferenceArrow x={c.x} to={c.y} from={c.baseY} color={color} year={year} order={yearPoint.order}  />
        }
      </React.Fragment>
    );
  });

  return result;
};
