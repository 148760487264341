// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import * as actionTypes from './actionTypes';
import * as constants from './constants';
import _round from 'lodash/round';

const units = {
  B: {
    unit: 'B',
    dataToDisplay: data => _round(data, constants.PRECISION_DOLLAR),
    displayToData: display => _round(display, constants.PRECISION_DOLLAR),
    actionType: actionTypes.SET_DOLLAR_ADJUSTMENT_1YEAR,
  },
  M: {
    unit: 'M',
    dataToDisplay: data => _round(data, constants.PRECISION_DOLLAR),
    displayToData: display => _round(display, constants.PRECISION_DOLLAR),
    actionType: actionTypes.SET_DOLLAR_ADJUSTMENT_1YEAR,
  },
  K: {
    unit: 'K',
    dataToDisplay: data => _round(data, constants.PRECISION_DOLLAR),
    displayToData: display => _round(display, constants.PRECISION_DOLLAR),
    actionType: actionTypes.SET_DOLLAR_ADJUSTMENT_1YEAR,
  },
  '%': {
    unit: '%',
    dataToDisplay: data => _round(data * 100, constants.PRECISION_PERCENT),
    displayToData: display => _round(display / 100, constants.PRECISION_DECIMAL),
    actionType: actionTypes.SET_PERCENT_ADJUSTMENT_1YEAR,
  },
};

export default units;