// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';

export default function giAreaGraph(props) {
  return (
    <svg
      version="1.1"
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 150 150"
      style={{ enableBackground: 'new 0 0 150 150' }}
      width={150}
      height={150}
      {...props}
    >
      <path className="st0"
        d="M111.5,111.5H16.5V86.2l22,9.7l29.2-36.5h33.8l10-7.8V111.5z M128,0L94.2,32H58.9L33.3,64L0,48v80h128V0L128,0z"
      />
    </svg>
  );
}
