// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { line, area, curveMonotoneX } from 'd3-shape';

export default class Comparison extends React.Component {
  drawLine() {
    const { chart } = this.props;
    const path = line()
      .x(d => d.x)
      .y(d => d.y)
      .curve(curveMonotoneX)(chart.points);
    return (
      <path
        d={path}
        stroke={chart.color}
        strokeDasharray={'7 4'}
        strokeWidth={3}
        fill="none"
        opacity={1}
        shapeRendering="auto"
      />
    );
  }
  drawShade() {
    const { chart } = this.props;
    if (!chart.isCurrent) {
      return null;
    }
    const path = area()
      .x(d => d.x)
      .y0(d => d.y)
      .y1(d => d.bottom) // <-- Doesn't exist
      .curve(curveMonotoneX)(chart.points);
    return (
      <path d={path} stroke={chart.color} strokeWidth={2} fill={chart.color} shapeRendering="auto" opacity={0.5} />
    );
  }
  render() {
    return (
      <g>
        {this.drawLine()}
        {/* {this.drawShade()} */}
      </g>
    );
  }
}
