// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';

export default function giLineGraph(props) {
  return (
    <svg
      version="1.1"
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 128 128"
      style={{ enableBackground: "new 0 0 128 128" }}
      width={128}
      height={128}
      {...props}
    >
      <g>
        <path
          className="st0"
          d="M116.9,52.4c-6.1,0-11.1,5-11.1,11.1c0,0.7,0.1,1.4,0.2,2.1L91.4,83c-0.3,0-0.6,0-0.9,0
        		c-1.1,0-2.3,0.2-3.3,0.5l-12-9.3c0-0.2,0-0.3,0-0.5c0-6.1-5-11.1-11.1-11.1s-11.1,5-11.1,11.1c0,1,0.1,1.9,0.4,2.8L39.2,93.2
        		c-0.3,0-0.6,0-1,0c-1.2,0-2.3,0.2-3.4,0.5l-12.6-9.5c0-0.1,0-0.2,0-0.4c0-6.1-5-11.1-11.1-11.1C5,72.8,0,77.8,0,83.9S5,95,11.1,95
        		c1.2,0,2.3-0.2,3.4-0.5l12.6,9.5c0,0.1,0,0.2,0,0.4c0,6.1,5,11.1,11.1,11.1c6.1,0,11.1-5,11.1-11.1c0-1-0.1-1.9-0.4-2.8L63,84.8
        		c0.3,0,0.6,0,1,0c1.1,0,2.3-0.2,3.3-0.5l12,9.3c0,0.2,0,0.3,0,0.5c0,6.1,5,11.1,11.1,11.1c6.1,0,11.1-5,11.1-11.1
        		c0-1-0.1-1.9-0.4-2.9l14-16.8c0.5,0.1,1.1,0.1,1.6,0.1c6.1,0,11.1-5,11.1-11.1S123,52.4,116.9,52.4z"
        />
        <path
          className="st0"
          d="M31.6,75.6c6.1,0,11.1-5,11.1-11.1c0-1-0.1-1.9-0.4-2.8L56.4,45c0.3,0,0.6,0,1,0c1.1,0,2.3-0.2,3.3-0.5l12,9.3
        		c0,0.2,0,0.3,0,0.5c0,6.1,5,11.1,11.1,11.1c6.1,0,11.1-5,11.1-11.1c0-1-0.1-1.9-0.4-2.9l14-16.8c0.5,0.1,1.1,0.1,1.6,0.1
        		c6.1,0,11.1-5,11.1-11.1c0-6.1-5-11.1-11.1-11.1c-6.1,0-11.1,5-11.1,11.1c0,0.7,0.1,1.4,0.2,2.1L84.7,43.2c-0.3,0-0.6,0-0.9,0
        		c-1.1,0-2.3,0.2-3.3,0.5l-12-9.3c0-0.2,0-0.3,0-0.5c0-6.1-5-11.1-11.1-11.1s-11.1,5-11.1,11.1c0,1,0.1,1.9,0.4,2.8L32.5,53.4
        		c-0.3,0-0.6,0-1,0c-6.1,0-11.1,5-11.1,11.1C20.4,70.6,25.4,75.6,31.6,75.6z"
        />
      </g>
    </svg>
  );
}
