import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { getTenants } from './tenantsListActions.js';
import * as routeBuilder from '../../lib/routeBuilder';
import SearchBox from '../SearchBox';
import { fontSizes } from '../../style/vars'
import { sortAlphabeticallyByField } from '../../lib/util';
import TenantImage from '../tenantImage/TenantImage';
import Spinner from '../Spinner';

function TenantsList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [filterString, setFilterString] = useState('');
  const tenants = useSelector(rs => rs.tenantsList.tenants);

  useEffect(() => {
    document.title = 'GovInvest - Tenants';
    dispatch(getTenants());
  }, [dispatch]);

  useEffect(() => {
    if (!!tenants && tenants.length === 1) {
      history.replace(routeBuilder.buildTenantDetail(tenants[0].id));
    }
  });

  if (tenants == null || tenants.length === 1) {
    return <Spinner />;
  }

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setFilterString(value);
  };

  const debouncedHandleSearchChange = debounce(handleSearchChange, 300);

  const orderedTenants = sortAlphabeticallyByField(tenants, 'name');

  return (
    <div style={{ margin: '0 auto', width: '600px', display: 'flex', flexDirection: 'column' }}>
      <div style={{ padding: '15px', display: 'flex', alignItems: 'center' }}>
        <div style={{ fontSize: fontSizes.xl, fontWeight: '300', marginRight: '30px', display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
          Tenants
        </div>

        <div style={{ display: 'flex' }}>
          <div style={{ padding: '3px' }}>
            <SearchBox onChange={debouncedHandleSearchChange} />
          </div>
        </div>

      </div>
      <div className="table" style={{ fontSize: fontSizes.r }}>
        {
          (!orderedTenants || orderedTenants.length === 0) && <div>No tenants</div>
        }
        {
          orderedTenants.filter(x => x.name.toLowerCase().indexOf(filterString.toLowerCase()) !== -1).map(tenant => {
            return (
              <div className="table-row" key={tenant.id} style={{ height: '60px' }}>
                <div className="col-12" style={{ height: '100%', display: 'flex', alignItems: 'center', cursor: 'pointer', width:'100%' }}>
                  <Link to={routeBuilder.buildTenantDetail(tenant.id)} style={{ marginLeft: '15px', width:'100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <div style={{ width: '32px', marginRight: '15px' }}>
                        <TenantImage tenantId={tenant.id} width='32px' />
                      </div>
                      {tenant.name}
                    </div>
                  </Link>
                </div>
              </div>
            );
          })
        }
      </div>
    </div>
  )
}

export default TenantsList;