// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { connect } from 'react-redux';
import { Alert, MenuItem } from 'react-bootstrap';
import classNames from 'classnames';
import {
  getYearLabels,
  getAdjustmentOptions,
  getAdjustmentStructure,
  getAdjustmentYearData,
  willLoseDataOnControlChange,
  getAdjustmentDropdownData,
} from 'client/sideBar/selectors.js';
import AdjustmentYearRates5 from './AdjustmentYearRates5';
import AdjustmentYearRates10 from './AdjustmentYearRates10';
import AdjustmentYearRates30 from './AdjustmentYearRates30';
import AdjustmentSlider from './AdjustmentSlider';
import AmortizationBases from './AmortizationBases';
import { CONTROL_5YEAR, CONTROL_10YEAR, CONTROL_30YEAR, CONTROL_SLIDER } from '../constants';
import { setControlTo5Year, setControlTo10Year, setControlToSlider, toggle30YearModal, openWillLoseDataModal } from '../actionCreators';
import * as constants from '../constants';
import HamburgerDropdown from './HamburgerDropdown';
import AdjustmentDropdown from './AdjustmentDropdown';
import ResetDropdown from './ResetDropdown';
import RemoveOverrideButton from './RemoveOverrideButton';
import './SideBar.css';
import CustomAdpTimingAdjustment from './customAdp/CustomAdpTimingAdjustment';

const getAdjustmentLabelCssClass = anyDeviateFromScenario => {
  let result = 'control-label';
  if (anyDeviateFromScenario) {
    result = classNames(result, 'changed');
  }
  return result;
};

const getControlLabel = controlType => {
  switch (controlType) {
    case CONTROL_5YEAR: {
      return '5 years of Input';
    }
    case CONTROL_10YEAR: {
      return '10 years of Input';
    }
    case CONTROL_30YEAR: {
      return '30 years of Input';
    }
    case CONTROL_SLIDER: {
      return 'Slider Input';
    }
    default: {
      return ' unknown ';
    }
  }
};

const getControlMenuItems = selectableControls => {
  return selectableControls.map(e => (
    <MenuItem key={e} eventKey={e} href="http://" onClick={e => e.preventDefault()}>
      {getControlLabel(e)}
    </MenuItem>
  ));
};

const Container = ({
  adjustmentName,
  adjustmentYearData,
  adjustmentOptions,
  adjustmentStructure,
  adjustmentDropdownData,
  children,
  willLoseData,
  setControlTo5Year,
  setControlTo10Year,
  setControlToSlider,
  openWillLoseDataModal,
  toggle30YearModal,
  features,
}) => {
  const selectableControls = features.adjustments30year
                              ? adjustmentOptions.selectableControls
                              : adjustmentOptions.selectableControls.filter(x => x !== '30year');
  const { label, errorMessage } = adjustmentStructure;
  let hasErrors = false;
  if (adjustmentYearData.hasErrors || adjustmentDropdownData.hasErrors) {
    hasErrors = true;
  }
  const handleControlSelect = userSelectedControl => {
    if (willLoseData(userSelectedControl)) {
      openWillLoseDataModal({ adjustmentName, userSelectedControl });
      return;
    }
    if (userSelectedControl === CONTROL_5YEAR) {
      setControlTo5Year({ adjustmentName });
    } else if (userSelectedControl === CONTROL_10YEAR) {
      setControlTo10Year({ adjustmentName });
    } else if (userSelectedControl === CONTROL_30YEAR) {
      // 30 year should only trigger modal to appear - modal sets control to 30 year
      toggle30YearModal(adjustmentName);
    } else if (userSelectedControl === CONTROL_SLIDER) {
      setControlToSlider({ adjustmentName });
    }
  };
  const anyDeviateFromScenario = adjustmentYearData.anyDeviateFromScenario || adjustmentDropdownData.anyDeviateFromScenario;
  return (
    <div className="sideBar-adjustment">
      <div className="sideBar-adjustment-header">
        <label className={getAdjustmentLabelCssClass(anyDeviateFromScenario)}>{label}</label>
        <div className="sideBar-adjustment-header-actions">
          {adjustmentOptions.canRemoveOverride && <RemoveOverrideButton {...{ adjustmentName }} />}
          <ResetDropdown {...{ adjustmentName, adjustmentStructure }} />
          {adjustmentStructure.type !== constants.TYPE_DROPDOWN &&
            adjustmentStructure.type !== constants.TYPE_FRESH_START &&
            adjustmentStructure.type !== constants.TYPE_PAYOFF_PRIORITY &&
            adjustmentStructure.type !== constants.TYPE_CUSTOM_ADP_TIMING && (
              <HamburgerDropdown onSelect={handleControlSelect}>{getControlMenuItems(selectableControls)}</HamburgerDropdown>
            )}
        </div>
      </div>
      {hasErrors && (
        <Alert id={'error-' + adjustmentName} bsStyle="danger">
          {errorMessage}
        </Alert>
      )}
      {children}
    </div>
  );
};

const getAdjustmentControl = adjustmentOptions => {
  const { control } = adjustmentOptions;
  let AdjustmentControl;
  if (control === CONTROL_5YEAR) {
    AdjustmentControl = AdjustmentYearRates5;
  } else if (control === CONTROL_SLIDER) {
    AdjustmentControl = AdjustmentSlider;
  } else if (control === CONTROL_10YEAR) {
    AdjustmentControl = AdjustmentYearRates10;
  } else if (control === CONTROL_30YEAR) {
    AdjustmentControl = AdjustmentYearRates30;
  }
  return AdjustmentControl;
};

const SideBarAdjustment = props => {
  const { adjustmentStructure, adjustmentName, adjustmentYearData, yearLabels, adjustmentDropdownData, adjustmentOptions } = props;
  const AdjustmentControl = getAdjustmentControl(adjustmentOptions);
  return (
    <Container {...props}>
      {adjustmentStructure.type === constants.TYPE_FRESH_START ? (
        <AmortizationBases freshStart />
      ) : adjustmentStructure.type === constants.TYPE_PAYOFF_PRIORITY ? (
        <AmortizationBases payoffPriority adjustmentName={adjustmentName} adjustmentStructure={adjustmentStructure} />
      ) : adjustmentStructure.type === constants.TYPE_CUSTOM_ADP_TIMING ? (
        <CustomAdpTimingAdjustment { ...{ adjustmentName }} />
      ) : adjustmentStructure.type === constants.TYPE_DROPDOWN ? (
        <AdjustmentDropdown {...{ adjustmentName, adjustmentDropdownData }} />
      ) : AdjustmentControl ? (
        <AdjustmentControl {...{ adjustmentName, adjustmentYearData, yearLabels, adjustmentStructure }} />
      ) : (
        <span>Error: Unknown adjustmentControl.</span>
      )}
    </Container>
  );
};

const stateToProps = (state, ownProps) => ({
  adjustmentStructure: getAdjustmentStructure(state, ownProps),
  adjustmentYearData: getAdjustmentYearData(state, ownProps),
  yearLabels: getYearLabels(state, ownProps),
  adjustmentOptions: getAdjustmentOptions(state, ownProps),
  willLoseData: willLoseDataOnControlChange(state, ownProps),
  adjustmentDropdownData: getAdjustmentDropdownData(state, ownProps),
  features: state.planTypeInfo.features,
});
const dispatchToProps = {
  setControlTo5Year,
  setControlTo10Year,
  setControlToSlider,
  toggle30YearModal,
  openWillLoseDataModal,
};

export default connect(stateToProps, dispatchToProps)(SideBarAdjustment);
