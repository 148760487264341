// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
/* access application data and general functions */

import 'whatwg-fetch';
import { getSidebarWidth, getPlanType } from 'client/selectors/page.js';

import {
  getFiscalYear1StartLabel2,
  getValuationYear1StartLabel2,
  getFiscalYear1EndLabel2,
  getValuationYear1EndLabel2,
  getFiscalYear1StartDifferentYearThanEnd2,
  getValuationYear1StartDifferentYearThanEnd2,
} from './planDefaultsSelectors';
import { getChartDataFromProps, getXAxisLabels } from 'client/selectors/graphSelectors';
import { getScenarioMap } from 'client/sideBar/selectors';
import { getValuationYear } from 'client/selectors/dashboardSelectors';
import _isEqual from 'lodash/isEqual';

// sequence used for scenarios
let sequence = 1;

// get the next sequence
export function getSequence() {
  return sequence++;
}

export function isToggled(position, toggles) {
  let search = { ...toggles };
  for (let p of position) {
    search = search[p];
    if (!search) {
      break;
    }
  }
  return search;
}

/**
 * Bundle all required properties for chart rendering
 * @param {*} state
 */
export function getDisplayDataPropsFromState2(state, props) {
  const { yearParams, toggle } = state.viewSummary;
  const { charts } = state;
  const { selectedView, availCharts, chartSize, legendSize } = charts;
  const { comparedScenarios, baselineScenario } = state.sideBar;
  const isComparing = comparedScenarios.length > 1;
  const miniDashboardHidden = toggle && toggle.hidden && toggle.hidden.dashboard;
  const currentData = getChartDataFromProps(state, state.sideBar.viewingScenario);
  let numYearsOfCurrentChart = 5;
  if(currentData && Object.keys(currentData).length > 0)
    numYearsOfCurrentChart = currentData[Object.keys(currentData)[0]].length;
  
  const ret = {
    valuationYear: getValuationYear(state),
    endYear: getValuationYear(state) + numYearsOfCurrentChart - 1,
    baselineScenario,
    requestingData: state.viewSummary.requestingData,
    requestedMetricsData: state.cache.requestedMetricsData,
    miniDashboardHidden,
    yearParams,
    selectedView,
    availCharts,
    charts,
    chartSize,
    legendSize,
    projectionStart: getValuationYear(state),
    toggled: state.viewSummary.toggle,
    isComparing,
    currentScenarios: [...comparedScenarios],
    marginLeft: getSidebarWidth(state),
    project: state.user.project,
    fiscalStartDate: getFiscalYear1StartLabel2(state),
    valuationStartDate: getValuationYear1StartLabel2(state),
    fiscalEndDate: getFiscalYear1EndLabel2(state),
    valuationEndDate: getValuationYear1EndLabel2(state),
    fiscalYear1StartDifferentYearThanEnd: getFiscalYear1StartDifferentYearThanEnd2(state),
    valuationYear1StartDifferentYearThanEnd: getValuationYear1StartDifferentYearThanEnd2(state),
    currentData,
    xAxisLabels: getXAxisLabels(state, state.sideBar.viewingScenario),
    currentComparison: state.sideBar.viewingScenario === 'default' ? { label: 'Default', colorKey: 0 } : state.sideBar.scenarios[state.sideBar.viewingScenario],
    baselineComparison: state.sideBar.baselineScenario === 'default' ? { label: 'Default', colorKey: 0 } : state.sideBar.scenarios[state.sideBar.baselineScenario],
    viewingScenario: state.sideBar.viewingScenario,
    scenarioMap: getScenarioMap(state),
    planType: getPlanType(state, props),
    planGroupKeys: state.planTypeInfo.planGroupKeys,
    compareWithBaseline: state.charts.compareWithBaseline,
    hiddenTopicChartSeries: state.charts.hiddenTopicChartSeries,
    workspaceInfo: state.workspace.info,
    showMiniDashboard: state.ui.showMiniDashboard && state.planTypeInfo.features.miniDashboard,
  };
  return ret;
}

export function isRequestingData(key, requestingData) {
  return !!requestingData[key];
}

export function inDevelopment() {
  return process.env.NODE_ENV === 'development';
}

export function devMessage(o) {
  if (inDevelopment()) {
    return JSON.stringify(o);
  }
  return '';
}

/**
 * consistently make keys out of arrays
 */

export function keyFromArray(arr) {
  return arr.join('');
}

// get either table or the first view
export function getXlsView(chart) {
  return chart.views.table ? 'table' : Object.keys(chart.views)[0];
}

export function cachedAvailable(cached, PY, filters) {
  return cached && cached.PY >= PY && cached.data && _isEqual(cached.filters, filters);
}

export function parameterizedFilterCategories(sideBar) {
  const result = [];
  for(let appliedFilterKey of Object.keys(sideBar.filtersApplied)) {
    const filterItems = sideBar.filtersApplied[appliedFilterKey];
    if(filterItems.length > 0) {
      result.push({
        name: appliedFilterKey,
        items: [...filterItems],
      })
    }
  }
  return result;
}
