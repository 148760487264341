import React from 'react';
import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import TenantsList from '../tenantsList/TenantsList';
import ProjectsList from 'components/projectsList/ProjectsList';
import AddEditProject from 'components/addEditProject/AddEditProject';
import * as routeBuilder from 'lib/routeBuilder';
import AppInfo from 'components/appInfo/AppInfo';

function MainContent() {
  return (
    <div style={{margin:'0px 20px', padding:'10px 25px'}}>
      <Switch>
        <Route exact path={routeBuilder.app}>
          <Redirect to={routeBuilder.tenants} />
        </Route>
        <Route exact path={routeBuilder.tenants}a>
          <TenantsList />
        </Route>
        <Route exact path={routeBuilder.tenantDetail}>
          <ProjectsList />
        </Route>
        <Route exact path={routeBuilder.addProject}>
          <AddEditProject />
        </Route>
        <Route exact path={routeBuilder.editProject}>
          <AddEditProject />
        </Route>
        <Route exact path={routeBuilder.appInfo}>
          <AppInfo />
        </Route>
      </Switch>
    </div>
  );
}

export default MainContent;