// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
/*
  The circle-things on the top
*/

import React from 'react';
import FakeGauge from './gauges/FakeGauge';

export default class GaugeHeader extends React.Component {
  constructor(props) {
    super(props);
    this.makePart = this.makePart.bind(this);
  }
  goto(topic, chart) {
    this.props.handleSetTopicAndChart(topic, chart);
  }
  makePart(metric, num, numSecondary, i, activeChart) {
    const { fiscalStartLabel, valuationStartLabel } = this.props;
    const metricTopic = metric.link[0];
    const metricChart = metric.link[1];
    const isCurrent = () => {
      if (activeChart === metricChart) return true;
    };
    const color =
      metric.color === 'red'
        ? '#D66D6A'
        : metric.color === 'blue' ? '#63B4D5' : metric.color === 'green' ? '#5BB372' : metric.color === 'orange' ? '#f0A965' : metric.color;
    const titleStyle = {
      color: color,
      fontStyle: isCurrent() ? 'italic' : '',
    };

    return (
      <div key={i} onClick={() => this.goto(metricTopic, metricChart)} className="mini-dashboard-circle-container">
        <span className="mini-dashboard-title" style={titleStyle}>
          {metric.title}
          <br />
          {metric.scaleType === 'fiscal' ? `${metric.subtitle} ${fiscalStartLabel}` : `${metric.subtitle} ${valuationStartLabel}`}
        </span>
        <FakeGauge radius={95} color={color} number={num} numberSecondary={numSecondary} />
      </div>
    );
  }

  render() {
    const { gaugeMetrics, selectedView } = this.props;
    return (
      <div className="mini-dashboard-content">
        {gaugeMetrics
          ? gaugeMetrics.map((metric, metricIndex) => {
              let metricData = {
                link: metric.link,
                title: metric.label,
                subtitle: metric.subtitle,
                scaleType: metric.scaleType,
                color: metric.color,
              };
              return this.makePart(metricData, metric.primary, metric.secondary, metricIndex, selectedView.chart);
            })
          : null}
      </div>
    );
  }
}
