import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import '@fortawesome/fontawesome-free-solid';
import './headerProfile.css';
import Auth from '../../lib/auth';
import { colors } from '../../style/colors';
import TenantImage from '../../components/tenantImage/TenantImage';
import * as constants from '../../lib/constants';
import * as routeBuilder from '../../lib/routeBuilder';
import { onlyOneProject } from 'lib/sharedSelectors';
import urlJoin from 'url-join';
import config from '../../lib/client-config';

function HeaderProfile() {
  const history = useHistory();
  const onWorkspace = window.location.pathname.startsWith('/app/workspace');
  const { profile } = useSelector(rs => rs.currentUser);
  const workspaceInfo = useSelector(rs => rs.workspace.info);
  const tenants = useSelector(rs => rs.tenantsList.tenants);
  const oneProject = useSelector(onlyOneProject);
  const currentTenant = useSelector(rs => rs.shared.currentTenant);
  const tenantImageTenantId = onWorkspace ? currentTenant.id : profile.tenantId;
  const isAdmin = useSelector(rs => rs.currentUser.isAdmin);

  let name = profile ? profile.name : '';
  if(name === "__no_name__")
    name = profile.email;
  const auth = new Auth();
  let roleText = '';
  if(profile && !!profile.role) {
    if(profile.role !== constants.roles.User && profile.role !== constants.roles.TenantAdmin) {
      roleText = `(${constants.roleNamesById[profile.role]})`;
    }
  }
  const moreThanOneTenant = (!!tenants && tenants.length > 1);
  const showProjectsLink = isAdmin || !oneProject;
  const downloadPromctl = profile.email.endsWith('@govinvest.com')
    || profile.email.endsWith('@lenalysis.com');
  const downloadPromctlUri = `/webapi/download-promctl`;

  return (
    <>
    <div className="dropdown" style={{cursor:'pointer', zIndex:100}}>
      <div style={{display: 'flex'}}>
        <div style={{padding: '5px', fontSize:'small', display:'flex', alignItems:'center'}}>
          {name} {roleText}
        </div>
        <div style={{padding: '5px', fontSize:'small', color: colors.green}}>
          <TenantImage tenantId={tenantImageTenantId} width="32px" />
        </div>
      </div>
      <div className="dropdown-content">
        {
          (onWorkspace && showProjectsLink) &&
          <>
            <div onClick={() => history.push(routeBuilder.buildTenantDetail(workspaceInfo.tenantId))}>Projects</div>
          </>
        }
        {
          (onWorkspace && moreThanOneTenant) &&
          <>
            <div onClick={() => history.push(routeBuilder.tenants)}>Tenants</div>
          </>
        }
        {
          profile.role === 'SysAdmin' && <div onClick={() => history.push(routeBuilder.appInfo)}>App Info</div>
        }
        {
          downloadPromctl && <a href={downloadPromctlUri} rel="noreferrer" target="_blank"><div>Download Promctl</div></a>
        }
        { profile.userDatabaseConnection && <div onClick={() => auth.changePassword()}>Change Password</div> }
        { profile.userDatabaseConnection && ( <div onClick={() => auth.logout()}>Logout</div>)}
        { !profile.userDatabaseConnection && (
          <>
            <div onClick={() => auth.switchUser()}>Logout</div>
            { !profile.ssoLogoutDisabled && <div onClick={() => auth.logout()}>Logout SSO</div> }
          </>
        )}
      </div>
    </div>
    </>
  );
}

export default HeaderProfile;
