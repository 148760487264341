// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import { routerReducer } from 'react-router-redux';
import cache from './cache';
import user from './user';
import viewSummary from './viewSummary';
import sideBar from '../sideBar/sideBarReducer';
import charts from './chartsReducer';
import ui from './ui';
import { calcStatusReducer } from './calcStatusReducer';
import planTypeInfo from './planTypeInfoReducer';
import customAdp from '../sideBar/components/customAdp/customAdpDialogReducer';

const reducers = {
  user,
  cache,
  viewSummary,
  routing: routerReducer,
  sideBar,
  charts,
  ui,
  calcStatus: calcStatusReducer,
  planTypeInfo,
  customAdp,
};
export default reducers;