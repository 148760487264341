// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { findByName } from 'common/colorMachine';

export default class LegendCircle extends React.Component {
  makeCircle(fill) {
    let s = this.props.series;
    let opacity = 1;
    let borderColor = fill;
    //make it shaded-out
    if (s.isCurrent === false) {
      opacity = 0.4;
    }
    const style = {
      width: 18,
      minWidth: 18,
      height: 18,
      display: 'inline-block',
      marginLeft: 10,
      marginRight: 10,
      opacity: opacity,
      border: '2px solid ' + borderColor,
      backgroundColor: fill,
      borderRadius: '50%'
    };
    return <div style={style} />;
  }
  makeText(color) {
    let s = this.props.series;
    let css = {
      color: color,
      textAlign: 'center',
      userSelect: 'none',
      fontWeight: 600,
      cursor: 'pointer'
    };
    return (
      <span style={css}>
        {s.title}
      </span>
    );
  }

  render() {
    let s = this.props.series;
    let color = findByName(s.color);
    let container = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    };
    return (
      <span style={container}>
        {/* {this.makeCircle(color)} */}
        {this.makeText(color)}
      </span>
    );
  }
}
