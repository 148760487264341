import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getTenant, errorOccurred } from 'lib/sharedActions';
import NotFound from 'components/NotFound';
import Spinner from 'components/Spinner';
import { workspaceStarting } from './workspaceActions';
import AlertDialog from '../AlertDialog';
import PlanPage from 'client/components/PlanPage';

function Workspace() {
  const dispatch = useDispatch();
  const prms = useParams();
  const history = useHistory();

  const projectId = prms.projectId;
  const planType = prms.planType;
  const workspaceKey = `${projectId}/${planType}`;
  const validPlanTypes = [
    'pension', 'opeb', 'mers-peer-comparison', 'mers-pension'
  ];

  const currentTenant = useSelector(rs => rs.shared.currentTenant);
  const planTypeInfo = useSelector(rs => rs.planTypeInfo);
  const workspaceInfo = useSelector(rs => rs.workspace.info);
  const [alertState, setAlertState] = useState({ visible: false });
  const [notAuthorized, setNotAuthorized] = useState(false);
  const loadedWorkspaceKey = useSelector(rs => rs.workspace.key);

  const currentProject = planTypeInfo && planTypeInfo.project;
  const currentTenantId = !!currentTenant ? currentTenant.id : null;
  const currentProjectId = !!currentProject ? currentProject.projectId : null;
  const currentProjectTenantId = !!currentProject ? currentProject.tenantId : null;
  const projectLoaded = projectId === currentProjectId;
  const tenantLoaded = !!currentTenantId && projectId === currentProjectId;
  const readyToStart = workspaceKey === loadedWorkspaceKey;
  const projectType = currentProject && (!!currentProject.projectType ? currentProject.projectType : 'pensionOpeb');

  useEffect(() => {
    if(workspaceKey !== loadedWorkspaceKey) {
      dispatch({ type: 'RESET_WORKSPACE', payload: { workspaceKey, planType }});
    }
  }, [workspaceKey, loadedWorkspaceKey, planType, dispatch]);

  const loadProject = async (projectId) => {
    const result = await fetch(`/webapi/projects/${projectId}`, {
        method: 'GET',
    });
    if(result.status === 401) {
      console.log('no access to project');
      setNotAuthorized(true);
      setAlertState({ visible: true, title:'Not Authorized', message:'You do not have access to this project.  Please contact your administrator or GovInvest support.'});
      return;
    }
    if(result.status !== 200) {
        dispatch(errorOccurred('Error Getting Project', 'Unexpected status code: ' + result.status));
        return;
    }
    let item = null;
    try {
        item = await result.json();
        dispatch({ type: 'GET_PROJECT_SUCCESS', payload: { project: item } });
    }
    catch(e) {
        console.error(e);
        dispatch(errorOccurred('Error Getting Project', 'Unable to read response'));
    }
  };

  useEffect(() => {
    if(!readyToStart)
      return;
    document.title = 'GovInvest - Workspace';
    if(!projectLoaded) {
      loadProject(projectId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, projectLoaded, projectId, readyToStart]);

  useEffect(() => {
    if(!readyToStart)
      return;
    if(projectLoaded) {
      dispatch(getTenant(currentProjectTenantId));
    }
  }, [dispatch, currentProjectTenantId, projectLoaded, readyToStart]);

  useEffect(() => {
    if(!readyToStart)
      return;
    if(tenantLoaded && projectLoaded) {
      dispatch(workspaceStarting({
        tenantId: currentTenantId,
        projectId: currentProjectId,
        planType,
        workspaceKey,
        projectType,
      }));
    }
  }, [dispatch, currentTenantId, planType, currentProjectId, projectLoaded, tenantLoaded, readyToStart, workspaceKey, projectType]);

  const handleAlertClosed = () => {
    if(notAuthorized) {
      setNotAuthorized(false);
      history.push('/');
    }
  };

  if(validPlanTypes.indexOf(planType) === -1) {
    return <NotFound />;
  }

  if(alertState && alertState.visible) {
    return <AlertDialog alertState={alertState} open={alertState.visible} onClose={handleAlertClosed} />
  }

  if(!currentProject)
    return <Spinner />;

  if(!workspaceInfo || planType !== workspaceInfo.planType || projectId !== workspaceInfo.projectId)
    return <Spinner />;

  return <PlanPage planType={planType} />;
}

export default Workspace;