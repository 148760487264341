// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import styled from 'styled-components';
import { Glyphicon } from 'react-bootstrap';

const css = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginLeft: 30,
    marginRight: 30,
    alignItems: 'stretch'
  },
  series: {
    marginLeft: 15,
    marginRight: 15,
    maxWidth: 180,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    minHeight: '100%',
    userSelect: 'none'
  },
  words: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignContent: 'flex-end',
    height: '100%',
    maxWidth: 300,
    fontSize: '16px',
  },
  label: {
    color: 'grey',
    flexGrow: 1
  },
  circleAndNumber: {
    marginTop: '5px',
    display: 'flex',
    justifyContent: 'center',
  },
  number: {
    color: '#3a3636',
    marginBottom: 10,
    marginLeft: 10,
    fontSize: '16px',
    lineHeight: '16px',
  },
  baselineValue: {
    color: 'darkgray',
    fontSize: '12px',
    textAlign: 'center',
  },
  deltaFromBaseline: {
    color: 'darkgray',
    fontSize: '12px',
    textAlign: 'center',
  },
};

const Circle = styled.div`
  width: 16px;
  height: 16px;
  border: 2px solid ${props => props.colorCode};
  border-radius: 50%;
  top: '2px';
  margin-right: '10px';
`;

export default class Legend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverOn: null
    };
    this.drawSection = this.drawSection.bind(this);
  }
  drawCircle(c) {
    if (!this.props.showCircles) {
      return null;
    }
    let fill = c.color;
    let opacity = 1;
    const style = {
      width: 18,
      minWidth: 18,
      height: 18,
      opacity: opacity,
      border: '2px solid ' + c.color,
      backgroundColor: fill,
      borderRadius: '50%'
    };
    return <div style={style} />;
  }

  drawSection(c, key) {
    const { cmp } = this.props;
    let number = null;
    let baselineLabel = null;
    let baselineDiffLabel = null;
    let baselineDiffDirection = null;
    let wantYear = cmp.state.onYear;
    if (wantYear || wantYear === 0) {
      for (var i = 0; i < c.points.length; i++) {
        if (c.points[i].year === wantYear) {
          number = c.points[i].label;
          baselineLabel = c.points[i].baselineLabel;
          baselineDiffLabel = c.points[i].baselineDiffLabel;
          baselineDiffDirection = c.points[i].baselineDiffDirection;
        }
      }
    }
    //css styles
    let container = Object.assign({}, css.series);
    let numberStyle = Object.assign({}, css.number, {
      color: c.color,
    });
    let textStyle = {
    };
    if (this.state.hoverOn === c.id) {
      textStyle.opacity = 0.7;
      numberStyle.opacity = 0.7;
    }
    if (!this.props.showCircle) {
      textStyle.color = c.color;
    }
    textStyle = Object.assign({}, css.label, textStyle);
    const title = c.isCurrent ? c.title + ' (current)' : c.title;

    let isBaseline = c.type === 'line';

    return (
      <span style={container} key={key}>
        <span style={css.words}>
          <div style={textStyle}>
            {title}
          </div>
          <div style={css.circleAndNumber}>
            <Circle colorCode={textStyle.color} isShowingValue={false} />
            <div style={numberStyle}>{number}</div>
          </div>
          <div style={css.baselineValue}>{ isBaseline ? '\u00a0' : `from ${baselineLabel}` }</div>
          <div style={css.deltaFromBaseline}>
            {
              isBaseline
                ? '\u00a0'
                : baselineDiffDirection === 'none'
                ? 'No change'
                  : baselineDiffDirection === 'up'
                  ? <div>
                      <Glyphicon glyph='triangle-top' />
                      {baselineDiffLabel}
                    </div>
                  : <div>
                      <Glyphicon glyph='triangle-bottom' />
                      {baselineDiffLabel}
                    </div>
            }
          </div>
        </span>
      </span>
    );
  }

  render() {
    const charts = this.props.charts;
    return (
      <div style={css.container} className="legend">
        {charts.map(this.drawSection)}
      </div>
    );
  }
}
