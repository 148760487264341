// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getFreshStarts,
  getAmortizationPlans,
  getAmortizationPlansWithoutAppliedFreshStarts,
  getFreshStartAdjustmentName,
  getFreshStartYears,
} from '../selectorsAmortizationBases';
import { setFreshStartsApplied } from '../actionCreators';
import { Modal, Button, FormControl } from 'react-bootstrap';
import styled from 'styled-components';

const ModalTitleStyled = styled(Modal.Title)`
  text-align: left;
`;

const CurrentFreshStartContainer = styled.div`
  margin: 0 0 10px 0;
`;

const SectionTitle = styled.p`
  font-size: 18px;
  margin: 10px 0;
`;

const NoItemsMessage = styled.div`
  font-style: italic;
  font-size: 14px;
`;

const InlineDiv = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 10px;
  align-items: center;
  width: max-content;
  margin-bottom: 10px;
`;

const StyledSelect = styled.select`
  padding: 3.5px;
  border-radius: 2.5px;
  border-color: #ccc;
`;

const StyledFormControl = styled(FormControl)`
  margin: 0;
`;

const OKButton = styled(Button)`
  width: 65px;
`;

const AddButton = styled(Button)`
  width: 65px;
`;

const FooterButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const AddFreshStartContainer = styled.section`
  display: grid;
  grid-row-gap: 5px;
  margin-bottom: 15px;
`;

const AddFreshStartHeading = styled.p`
  font-size: 18px;
  margin: 0;
`;

const AddFreshStartFooterButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const TableGrid = styled.div`
  display: grid;
  grid-template-columns: ${props => (props.check ? 'max-content' : '')} repeat(${props => (props.numberOfColumns ? props.numberOfColumns : '5')}, auto);
  max-height: ${props => (props.maxHeight ? props.maxHeight + 'px' : '218px')};
  height: 100%;
  border: 1px solid #ddd;
  margin: 5px 0 10px 0;
  overflow: auto;
  &:after {
    content: '';
    display: block;
    height: 10px;
    width: 100%;
  }
  & > span {
    padding: 4px 4px;
    border-bottom: 1px solid #ddd;
    cursor: ${props => (props.selectable ? 'pointer' : '')};
  }
`;

const HeaderDataCell = styled.span`
  position: sticky;
  top: 0;
  font-size: 14px;
  font-weight: bold;
  background: white;
`;

const HeaderCellRight = styled(HeaderDataCell)`
  text-align: right;
`;

const DataCell = styled.span`
  font-size: 14px;
  background: ${props => (props.hoveredIndex >= 0 && props.hoveredIndex === props.ownIndex ? '#ddd' : '')};
`;

const DataCellRight = styled(DataCell)`
  text-align: right;
`;

const CheckboxStyled = styled.input`
  margin: 0;
  cursor: pointer;
`;

const GridRow = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
`;

const RowStart = styled(GridRow)`
  justify-content: flex-start;
  display: block;
`;

const RowEnd = styled(GridRow)`
  justify-content: flex-end;
`;

const EditingLabel = styled.div`
  color: #337AB7;
  font-style: italic;
`;

const FreshStartModal = props => {
  const dispatch = useDispatch();
  const freshStarts = useSelector(state => getFreshStarts(state));
  const freshStartsAdjustmentName = useSelector(state => getFreshStartAdjustmentName(state));
  const amortizationPlans = useSelector(state => getAmortizationPlans(state));
  const amortizationPlansWithoutAppliedFreshStarts = useSelector(state => getAmortizationPlansWithoutAppliedFreshStarts(state));
  const freshStartYears = useSelector(state => getFreshStartYears(state))
  const [freshStartsState, setFreshStartsState] = useState({
    freshStarts: freshStarts.applied,
    plans: amortizationPlansWithoutAppliedFreshStarts,
    planSelection:
      amortizationPlansWithoutAppliedFreshStarts &&
      amortizationPlansWithoutAppliedFreshStarts.length > 0 &&
      amortizationPlansWithoutAppliedFreshStarts[0].plan,
    numberOfYearsDefault: 20,
    numberOfYears: 20,
    year: freshStartYears[0],
    includeNewBases: true,
    hoveredRow: null,
    mode: 'add',
  });
  const header = freshStartsState.mode === 'edit'
  ? 'Edit Amortization Fresh Start'
  : 'Add Amortization Fresh Start'
  const inEditMode = freshStartsState.mode === 'edit';

  const handleChange = name => event => {
    setFreshStartsState({
      ...freshStartsState,
      [name]: event.target.value,
    });
  }

  const validateNumberInput = (name, min, max) => ({ target }) => {
    const { value } = target;
    if (value > max) {
      setFreshStartsState({
        ...freshStartsState,
        [name]: max,
      });
    }
    if (value < min) {
      setFreshStartsState({
        ...freshStartsState,
        [name]: min,
      })
    }
  }

  const handleFreshStartAdd = () => {
    const { numberOfYears, year, includeNewBases } = freshStartsState;
    const currentPlan = freshStartsState.plans.filter(amortizationPlan => amortizationPlan.plan === freshStartsState.planSelection)[0];
    const currentPlanName = currentPlan.plan;
    const basesSelected = currentPlan.details.filter(base => base.selected);

    const newFreshStart = {
      plan: currentPlanName,
      numberOfYears: numberOfYears,
      bases: basesSelected,
      year: year,
      includeNewBases: includeNewBases,
    };

    const plansWithoutFreshStarts = getPlansWithoutFreshStarts([...freshStartsState.freshStarts, newFreshStart]);

    const newPlanSelection = plansWithoutFreshStarts && plansWithoutFreshStarts.length > 0 ? plansWithoutFreshStarts[0].plan : '';

    let modifiedFreshStarts = JSON.parse(JSON.stringify(freshStartsState.freshStarts));
    modifiedFreshStarts.push(newFreshStart);
    setFreshStartsState({
      ...freshStartsState,
      plans: plansWithoutFreshStarts,
      planSelection: newPlanSelection,
      freshStarts: modifiedFreshStarts,
    });
  };

  const handleFreshStartsApply = () => {
    let freshStartsToApply = { adjustmentName: freshStartsAdjustmentName, freshStarts: [...freshStartsState.freshStarts] } ;
    freshStartsToApply.freshStarts.forEach(freshStart => {
      freshStart.bases = freshStart.bases.map(base => base.id);
    });

    dispatch(setFreshStartsApplied(freshStartsToApply));
    props.handleShow('freshStartModalShow');
  };

  const handleFreshStartsEditAccept = () => {
    const { numberOfYears, year, includeNewBases } = freshStartsState;
    const currentPlan = freshStartsState.plans.find(amortizationPlan => amortizationPlan.plan === freshStartsState.planSelection);
    const currentPlanName = currentPlan.plan;
    const basesSelected = currentPlan.details.filter(base => base.selected);
    const editedFreshStart = {
      plan: currentPlanName,
      numberOfYears: numberOfYears,
      bases: basesSelected,
      year: year,
      includeNewBases: includeNewBases,
    };
    const plansWithoutFreshStarts = getPlansWithoutFreshStarts([...freshStartsState.freshStarts, editedFreshStart]);
    const newPlanSelection = plansWithoutFreshStarts && plansWithoutFreshStarts.length > 0 ? plansWithoutFreshStarts[0].plan : '';
    const updatedFreshStarts = freshStartsState.freshStarts.map(freshStart => {
      if (freshStart.plan === currentPlanName) {
        return editedFreshStart;
      } else {
        return freshStart;
      }
    })

    setFreshStartsState({
      ...freshStartsState,
      plans: plansWithoutFreshStarts,
      planSelection: newPlanSelection,
      freshStarts: updatedFreshStarts,
      mode: 'add',
    });
  }

  const handleFreshStartsEditCancel = () => {
    const plansWithoutFreshStarts = getPlansWithoutFreshStarts(freshStartsState.freshStarts);
    const newPlanSelection = plansWithoutFreshStarts && plansWithoutFreshStarts.length > 0 ? plansWithoutFreshStarts[0].plan : '';
    setFreshStartsState({
      ...freshStartsState,
      plans: plansWithoutFreshStarts,
      planSelection: newPlanSelection,
      mode: 'add',
    });
  }

  const handleFreshStartsEdit = freshStart => {
    const planToEdit = amortizationPlans.find(amortizationPlan => amortizationPlan.plan === freshStart.plan);
    const selectedPlan = planToEdit.plan;

    // set the edited plan's chosen fresh starts to be selected in the plan being edited
    planToEdit.details = planToEdit.details.map((detail, detailIndex) => {
      for (const base of freshStart.bases) {
        if (base.id === detail.id) {
          return {
            ...detail,
            selected: true,
          };
        } 
      }
      return detail;
    });

    // build fresh start plans including the plan being edited
    const plansDuringEdit = amortizationPlans.map(amortizationPlan => {
      if (amortizationPlan.plan === planToEdit.plan) {
        return planToEdit;
      } else {
        return amortizationPlan;
      }
    })

    setFreshStartsState({
      ...freshStartsState,
      planSelection: selectedPlan,
      plans: plansDuringEdit,
      year: freshStart.year,
      numberOfYears: freshStart.numberOfYears,
      includeNewBases: freshStart.includeNewBases,
      mode: 'edit',
    });
  }

  const handleFreshStartsRemove = itemIndex => {
    const freshStarts = itemIndex >= 0 ? freshStartsState.freshStarts.filter((freshStart, freshStartIndex) => itemIndex !== freshStartIndex) : [];
    const plansWithoutFreshStarts = getPlansWithoutFreshStarts(freshStarts);
    const newPlanSelection = plansWithoutFreshStarts && plansWithoutFreshStarts.length > 0 ? plansWithoutFreshStarts[0].plan : '';
    setFreshStartsState({
      ...freshStartsState,
      freshStarts,
      plans: plansWithoutFreshStarts,
      planSelection: newPlanSelection,
      mode: 'add',
    });
  };

  const basesSelected = () => {
    const currentPlan = freshStartsState.plans.filter(amortizationPlan => amortizationPlan.plan === freshStartsState.planSelection)[0];
    return currentPlan && currentPlan.details.some(base => base.selected);
  };

  const handleRowHover = i => setFreshStartsState({
    ...freshStartsState,
    hoveredRow: i,
  });

  const handlePlanSelection = e => {
    const plansUnselectedBases = freshStartsState.plans.map(amortizationPlan => ({
      ...amortizationPlan,
      details: amortizationPlan.details.map(base => ({ ...base, selected: false })),
    }));

    setFreshStartsState({
      ...freshStartsState,
      planSelection: e.target.value,
      plans: plansUnselectedBases,
    })
  };

  const handleYearSelection = e => {
    setFreshStartsState({
      ...freshStartsState,
      year: e.target.value,
    })
  };

  const handleIncludeNewBasesChange = e => {
    setFreshStartsState({
      ...freshStartsState,
      includeNewBases: !!e.target.checked,
    })
  };

  const handleBaseSelect = i => {
    let plansModified = [...freshStartsState.plans];

    if (i === "selectAll") {
      plansModified = plansModified.map(amortizationPlan => ({
        ...amortizationPlan,
        details: amortizationPlan.details.map(base => ({ ...base, selected: true })),
      }));
    } else if (i === "deselectAll") {
      plansModified = plansModified.map(amortizationPlan => ({
        ...amortizationPlan,
        details: amortizationPlan.details.map(base => ({ ...base, selected: false })),
      }));
    }
    else if (i >= 0) {
      plansModified = plansModified.map(amortizationPlan => ({
        ...amortizationPlan,
        details: amortizationPlan.details.map((base, baseIndex) => ({
          ...base,
          selected: baseIndex === i ? !base.selected : base.selected,
        })),
      }));
    }

    setFreshStartsState({
      ...freshStartsState,
      plans: plansModified,
    })
  };

  const determineComma = (index, length) => (index === length - 1 ? '' : ', ');

  const getPlansWithoutFreshStarts = freshStarts => {
    return amortizationPlans.filter(amortizationPlan => !freshStarts.some(freshStart => freshStart.plan === amortizationPlan.plan));
  };

  const buildCurrentPlan = () => {
    return freshStartsState.plans && freshStartsState.plans.filter(amortizationPlan => amortizationPlan.plan === freshStartsState.planSelection)[0];
  };

  const getPlanLabel = (planId) => {
    const amortizationDetailForPlan = amortizationPlans.filter(x => x.plan === planId)[0];
    if(!amortizationDetailForPlan)
      return 'Not found';
    return amortizationDetailForPlan.planLabel ? amortizationDetailForPlan.planLabel : amortizationDetailForPlan.plan;
  }

  const indicateIfEditing = freshStartPlanName => {
    if (inEditMode && freshStartPlanName === freshStartsState.planSelection) {
      return <EditingLabel>Editing:</EditingLabel>
    }
  };

  const currentPlan = buildCurrentPlan();

  return (
    <Modal
      show={props.show}
      onHide={() => props.handleShowWithConfirm('freshStartModalShow', freshStartsState.freshStarts, freshStarts.applied)}
      bsSize="large"
      centered="true"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <ModalTitleStyled id="contained-modal-title-vcenter">
          Amortization Fresh Starts
        </ModalTitleStyled>
      </Modal.Header>
      <Modal.Body>

        <AddFreshStartContainer>
          <AddFreshStartHeading>{header}</AddFreshStartHeading>
          {freshStartsState.plans && freshStartsState.plans.length > 0 ? (
            <React.Fragment>
              <div style={{overflow:'auto', fontStyle:'italic', marginBottom:'10px', color: '#737373'}}>
                Select a plan to add a fresh start for that plan. Provide the number of years for the fresh start, the
                bases you would like to be included in the fresh start, then press the 'Add' button.  You can only add one
                fresh start per plan.
              </div>
              <InlineDiv>
                <div>Select Plan:</div>
                <StyledSelect value={freshStartsState.planSelection} disabled={freshStartsState.mode === 'edit'} onChange={handlePlanSelection}>
                  {freshStartsState.plans.map((amortizationPlan, i) => (
                    <option key={i} value={amortizationPlan.plan}>
                      {getPlanLabel(amortizationPlan.plan)}
                    </option>
                  ))}
                </StyledSelect>
              </InlineDiv>
              <InlineDiv>
                <div>Number of Years:</div>
                <StyledFormControl
                  bsSize="small"
                  type="number"
                  min={1}
                  max={100}
                  value={freshStartsState.numberOfYears}
                  onChange={e => handleChange('numberOfYears')(e)}
                  onBlur={e => validateNumberInput('numberOfYears', 1, 100)(e)}
                />
                <div>Year to Fresh Start:</div>
                <StyledSelect value={freshStartsState.year} onChange={handleYearSelection}>
                  {freshStartYears && (
                      <React.Fragment key={0}>
                        {freshStartYears.map(yr => (
                          <option key={yr} value={yr}>
                            {yr}
                          </option>
                        ))}
                      </React.Fragment>
                  )}
                </StyledSelect>
                <div>Include new bases?</div>
                <CheckboxStyled type="checkbox" checked={freshStartsState.includeNewBases} onChange={handleIncludeNewBasesChange} />
              </InlineDiv>
              <div>Select Bases to Combine:</div>
              {currentPlan &&
              currentPlan.details &&
              currentPlan.details.length > 0 && (
                <TableGrid check selectable>
                  <HeaderDataCell />
                  <HeaderDataCell>Bases</HeaderDataCell>
                  <HeaderCellRight>Date Established</HeaderCellRight>
                  <HeaderCellRight>Payments Remaining</HeaderCellRight>
                  <HeaderCellRight>Current Payment</HeaderCellRight>
                  <HeaderCellRight>Remaining Balance</HeaderCellRight>
                  {currentPlan.details.map((base, i) => (
                    <React.Fragment key={i}>
                      <DataCell
                        ownIndex={i}
                        hoveredIndex={freshStartsState.hoveredRow}
                        onMouseOver={() => handleRowHover(i)}
                        onMouseOut={() => handleRowHover(null)}
                        onClick={() => handleBaseSelect(i)}
                      >
                        <CheckboxStyled type="checkbox" checked={base.selected} value={''} onChange={() => handleBaseSelect(i)} />
                      </DataCell>
                      <DataCell
                        ownIndex={i}
                        hoveredIndex={freshStartsState.hoveredRow}
                        onMouseOver={() => handleRowHover(i)}
                        onMouseOut={() => handleRowHover(null)}
                        onClick={() => handleBaseSelect(i)}
                      >
                        {base.source}
                      </DataCell>
                      <DataCellRight
                        ownIndex={i}
                        hoveredIndex={freshStartsState.hoveredRow}
                        onMouseOver={() => handleRowHover(i)}
                        onMouseOut={() => handleRowHover(null)}
                        onClick={() => handleBaseSelect(i)}
                      >
                        {base.dateEstablished}
                      </DataCellRight>
                      <DataCellRight
                        ownIndex={i}
                        hoveredIndex={freshStartsState.hoveredRow}
                        onMouseOver={() => handleRowHover(i)}
                        onMouseOut={() => handleRowHover(null)}
                        onClick={() => handleBaseSelect(i)}
                      >
                        {base.yearsRemaining}
                      </DataCellRight>
                      <DataCellRight
                        ownIndex={i}
                        hoveredIndex={freshStartsState.hoveredRow}
                        onMouseOver={() => handleRowHover(i)}
                        onMouseOut={() => handleRowHover(null)}
                        onClick={() => handleBaseSelect(i)}
                      >
                        {base.payment.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                      </DataCellRight>
                      <DataCellRight
                        ownIndex={i}
                        hoveredIndex={freshStartsState.hoveredRow}
                        onMouseOver={() => handleRowHover(i)}
                        onMouseOut={() => handleRowHover(null)}
                        onClick={() => handleBaseSelect(i)}
                      >
                        {base.balanceBegin.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                      </DataCellRight>
                    </React.Fragment>
                  ))}
                </TableGrid>
              )}
              <AddFreshStartFooterButtons>
                <div>
                  <Button bsSize="small" onClick={() => handleBaseSelect("selectAll")}>
                    Select All (Full Fresh Start)
                  </Button>
                  <Button bsSize="small" style={{marginLeft: "10px"}} onClick={() => handleBaseSelect("deselectAll")}>
                    Deselect All
                  </Button>
                </div>
                {inEditMode
                  ? (
                    <RowEnd>
                      <Button bsStyle="primary" bsSize="small" onClick={handleFreshStartsEditAccept}>Accept</Button>
                      <Button bsSize="small" onClick={handleFreshStartsEditCancel}>Cancel</Button>
                    </RowEnd>
                  ) : (
                    <AddButton bsStyle="primary" bsSize="small" disabled={!basesSelected()} onClick={handleFreshStartAdd}>
                      Add
                    </AddButton>
                  )
                }
                
              </AddFreshStartFooterButtons>
            </React.Fragment>
          ) : (
            <NoItemsMessage>A fresh start has been defined for every plan</NoItemsMessage>
          )}
        </AddFreshStartContainer>

        <CurrentFreshStartContainer>
          <SectionTitle>Current Amortization Fresh Starts</SectionTitle>
          {freshStartsState.freshStarts && freshStartsState.freshStarts.length > 0 ? (
            <TableGrid maxHeight={184} numberOfColumns="4">
              <HeaderDataCell>Plan</HeaderDataCell>
              <HeaderDataCell># Years</HeaderDataCell>
              <HeaderDataCell>Bases</HeaderDataCell>
              <HeaderDataCell />
              {freshStartsState.freshStarts.map((freshStart, freshStartIndex, arr) => (
                <React.Fragment key={freshStartIndex}>
                  <DataCell>
                    <RowStart>
                      {indicateIfEditing(freshStart.plan)}
                      {getPlanLabel(freshStart.plan)} ({freshStart.year})
                    </RowStart>
                  </DataCell>
                  <DataCell>{freshStart.numberOfYears}</DataCell>
                  <DataCell>
                    {freshStart.bases.map((base, baseIndex, bases) => (
                      <span key={arr.length + baseIndex}>
                        {base.source} ({base.dateEstablished})
                        {determineComma(baseIndex, bases.length)}
                      </span>
                    ))} ({freshStart.includeNewBases ? "Including new bases" : "Excluding new bases"})
                  </DataCell>
                  <DataCellRight>
                    <RowEnd>
                      <Button bsSize="small" onClick={() => handleFreshStartsEdit(freshStart)}>Edit</Button>
                      <Button bsStyle="danger" bsSize="small" onClick={() => handleFreshStartsRemove(freshStartIndex)}>
                        Remove
                      </Button>
                    </RowEnd>
                  </DataCellRight>
                </React.Fragment>
              ))}
            </TableGrid>
          ) : (
            <NoItemsMessage>There are no current fresh starts</NoItemsMessage>
          )}
        </CurrentFreshStartContainer>
        <FooterButtons>
          <OKButton bsStyle="success" bsSize="small" disabled={inEditMode} onClick={handleFreshStartsApply}>
            OK
          </OKButton>
        </FooterButtons>
      </Modal.Body>
    </Modal>
  );
}

export default FreshStartModal;
