// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import { cloneDeep } from 'lodash';
import * as actionTypes from './actionTypes';
import {
  updateScenarioLabel,
  deleteScenario,
  planGroupsReceived,
  saveScenario,
  scenarios2Received,
} from './actionCreators';
import { calculationCompleted } from '../actions/chartsActions';
import { getJSON, postJSON, deleteJSON } from 'lib/network.js';
import { isRequestingData, cachedAvailable, parameterizedFilterCategories } from 'client/lib/app-general.js';
import { getCalcRequest } from './selectors';
import { populateDerivedSeries } from '../lib/derivedSeries.js';
import urlJoin from 'url-join';
import config from '../../lib/client-config';

export const requestPlanGroups = () => {
  return (dispatch, getState) => {
    const { workspace } = getState();
    const workspaceInfo = workspace.info;
    const uri = urlJoin(config.baseUrl, `webapi/workspace/${workspaceInfo.projectId}/${workspaceInfo.planType}/plan-groups`);
    return getJSON(uri).then(planGroupResponse => {
      dispatch(planGroupsReceived(planGroupResponse));
    });
  };
};

export const checkGetMetrics = ({ workspaceInfo, purpose, scenarioId, newOrUpdatedScenario }) => {
  return (dispatch, getState) => {
    const state = getState();
    const { viewSummary, sideBar, cache, planTypeInfo } = state;

    const existingCalcRequest = sideBar.calcRequests[scenarioId];
    const createNewCalcRequest = newOrUpdatedScenario || !existingCalcRequest;
    const calcRequest = cloneDeep(createNewCalcRequest ? getCalcRequest(state) : existingCalcRequest);

    calcRequest.filters = parameterizedFilterCategories(sideBar);
    calcRequest.numberOfYears = sideBar.projectedNumYears;
    calcRequest.type = planTypeInfo.planType;
    calcRequest.planGroupKeys = planTypeInfo.planGroupKeys;
    if (window.forceRecalc)
      calcRequest.forceRecalc = true;

    const { requestedMetricsData } = cache;
    const cached = requestedMetricsData[scenarioId];
    const PY = sideBar.projectedNumYears;
    const filters = calcRequest.filters;
    const { requestingData } = viewSummary;

    if (isRequestingData(scenarioId, requestingData)) {
      console.info('already requesting', scenarioId);
      return;
    }
    if (cachedAvailable(cached, PY, filters)) {
      if (cached.step === 'pending') {
        return;
      }
    }

    return dispatch(getMetrics(workspaceInfo, purpose, PY, calcRequest, scenarioId));
  };
};

const getMetrics = (workspaceInfo, purpose, PY, calcRequest, scenarioId) => {
  const { planType } = workspaceInfo;
  return (dispatch, getState) => {
    const cache = getState().cache.requestedMetricsData;
    dispatch(dataRequestStatus('pending', purpose, scenarioId, calcRequest));
    const onCalcStatusReceived = createCalcStatusReceived(scenarioId, dispatch);
    return getMetricRequest(workspaceInfo, calcRequest, onCalcStatusReceived)
      .then(result => {
        const step = result ? 'done' : 'failed';
        result.derived = populateDerivedSeries(result, PY, planType);
        dispatch(dataRetrieval(step, cache, result, PY, scenarioId, calcRequest));
        dispatch(dataRequestStatus(step, purpose, scenarioId, calcRequest));
        dispatch(calculationCompleted(result, planType));
      })
      .catch(e => {
        throw e;
      });
  };
};

const createCalcStatusReceived = (scenarioId, dispatch) => (calcStatus) => {
  const action = {
    type: 'CALC_STATUS_RECEIVED',
    payload: {
      result: calcStatus,
      key: scenarioId,
    }
  };
  dispatch(action);
};

const dataRequestStatus = (step, purpose, scenarioId, calcRequest) => {
  return {
    type: actionTypes.DATA_REQUEST_STATUS,
    key: scenarioId,
    step,
    purpose,
    calcRequest
  };
};

const dataRetrieval = (step, cache, result, PY, scenarioId, calcRequest) => {
  return dispatch => {
    dispatch({
      type: actionTypes.DATA_RETRIEVAL,
      step,
      cache,
      result,
      PY,
      key: scenarioId,
      calcRequest,
    });
  };
};

const getMetricRequest = async (workspaceInfo, calcRequest, onCalcStatusReceived) => {
  const { projectId } = workspaceInfo;
  const body = JSON.stringify(calcRequest);
  const uri = urlJoin(config.baseUrl, `/webapi/workspace/${projectId}/calc-request`);
  const startResult = await fetch(uri, {
    method: 'POST',
    credentials: 'include',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: body,
  });
  if (startResult.status === 200) {
    var parsedResult = await startResult.json();
    return parsedResult.payload;
  }
  if (startResult.status !== 202) {
    throw new Error('Error posting peer comparison calc request.  Status:' + startResult.status);
  }

  const parsedStartResult = await startResult.json();
  const maxTries = 300; //300 seconds-ish
  const getUri = urlJoin(config.baseUrl, `/webapi/workspace/${projectId}/calc-result?requestId=${parsedStartResult.requestId}&cacheKey=${parsedStartResult.cacheKey}`);
  for (let i = 0; i < maxTries; i++) {
    const result = await fetch(getUri, {
      method: 'GET',
      credentials: 'include',
      headers: new Headers({
        'Content-Type': 'application/json',
      })
    });
    if (result.status === 202) {
      const calcStatus = await result.json();
      onCalcStatusReceived(calcStatus);

      if (i < 10)
        await new Promise(resolve => setTimeout(resolve, 300));
      else
        await new Promise(resolve => setTimeout(resolve, 1000)); //slow down wait after a few tries
      continue;
    }
    if (result.status !== 200) {
      throw new Error('Unexpected response status getting calc response:' + result.status);
    }
    var ret = await result.json();
    if (ret.error) {
      throw new Error('Calculation error:' + ret.error + ', ' + ret.errorDescription);
    }
    return ret.payload;
  }
};

export const requestSavedScenarios2 = () => {
  return (dispatch, getState) => {
    const { sideBar, workspace } = getState();
    const { projectId, planType } = workspace.info;
    const { scenarios2Loaded } = sideBar;

    if (scenarios2Loaded) {
      return Promise.resolve();
    }

    const uri = urlJoin(config.baseUrl, `/webapi/workspace/${projectId}/${planType}/scenarios`);
    return getJSON(uri).then(apiResponse => {
      dispatch(scenarios2Received(apiResponse));
    });
  };
};

export const saveScenario2 = (workspaceInfo, scenarioId) => {
  return (dispatch, getState) => {
    const { projectId, planType } = workspaceInfo;
    const scenario = getState().sideBar.scenarios[scenarioId];
    const uri = urlJoin(config.baseUrl, `/webapi/workspace/${projectId}/${planType}/scenarios`);
    return postJSON(uri, scenario).then(_ => {
      dispatch(saveScenario(scenarioId));
    });
  };
};

export const deleteScenario2 = (workspaceInfo, scenarioId) => {
  return dispatch => {
    const { projectId, planType } = workspaceInfo;
    const uri = urlJoin(config.baseUrl, `/webapi/workspace/${projectId}/${planType}/scenarios`);
    return deleteJSON(uri, { scenarioId: scenarioId }).then(_ => {
      dispatch(deleteScenario(scenarioId));
    });
  };
};
export const updateScenario2Label = (workspaceInfo, scenarioId, label) => {
  return (dispatch, getState) => {
    const { projectId, planType } = workspaceInfo;
    const scenario = getState().sideBar.scenarios[scenarioId];
    if (scenario.hasBeenSaved) {
      const uri = urlJoin(config.baseUrl, `/webapi/workspace/${projectId}/${planType}/scenarios`);
      return postJSON(uri, { ...scenario, label }).then(_ => {
        dispatch(updateScenarioLabel(scenarioId, label));
      });
    }
    dispatch(updateScenarioLabel(scenarioId, label));
  };
};
