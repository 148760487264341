// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import { Constants } from 'common/constants.js';
import { SPECIFIED } from 'lib/defaults.js';

const { INTEGER, DOLLARS, PERCENT, NUMBER } = Constants.formats;

const pensionCharts = {
  'Historical': {
    'Liabilities by Source': {
      chartRules: { },
      format: DOLLARS,
      xType: SPECIFIED,
      xAxisLabels: 'historyYearLabels',
      xAxisLabelsPosition: 'history',
      xAxisLabel: ' ',
      defaultPosition: 'history',
      defaultSeries: {
        liabilitiesRetiree: { title: 'Retirees', color: 'blue', },
        liabilitiesVestedTerm: { title: 'Vested Terminated', color: 'navy', },
        liabilitiesPendingRefund: { title: 'Pending Refunds', color: 'orange', },
        liabilitiesActive: { title: 'Actives', color: 'red', },
        liabilitiesTotal: { title: 'Total', color: 'black', view: 'line', stacked: false, }
      },
      views: {
        stackedBar:{},
        area: {},
        line: {},
        table: {},
      },
    },
    'Layered Bases by Year': {
      chartRules: { },
      format: DOLLARS,
      xType: SPECIFIED,
      xAxisLabels: 'historyYearLabels',
      xAxisLabelsPosition: 'history',
      xAxisLabel: ' ',
      defaultPosition: 'history',
      defaultSeries: {
        priorUAL: { title: 'Prior UAL', color: 'red', },
        allUAL: { title: 'Pre-2015 UAL', color: 'purple', },
        initialBase: { title: 'New Initial Base', color: 'orange', },
        gainLossBase: { title: 'New (Gain)/Loss Base', color: 'navy', },
        assumptionBase: { title: 'New Assumption Base', color: 'green' },
        amendmentBase: { title: 'New Amendment Base', color: 'blue', },
        experienceBase: { title: 'New Experience Base', color: 'mersLightGreen', },
        mergerBase: { title: 'New Merger Base', color: 'brown', },
        ualBalance: { title: 'UAL Balance', view: 'line', color: 'black', stacked: false, },
      },
      views: {
        stackedBar:{},
        area: {},
        line: {},
        table: {},
      },
    },
    'Participant Counts by Source': {
      chartRules: { },
      format: INTEGER,
      xType: SPECIFIED,
      xAxisLabels: 'historyYearLabels',
      xAxisLabelsPosition: 'history',
      xAxisLabel: ' ',
      defaultPosition: 'history',
      defaultSeries: {
        retireeCount: { title: 'Retirees', color: 'blue', },
        vestedTermCount: { title: 'Vested Terminated', color: 'navy', },
        refundCount: { title: 'Pending Refunds', color: 'orange', },
        activeCount: { title: 'Actives', color: 'red', },
        totalCount: { title: 'Total', view: 'line', color: 'black', stacked: false, },
      },
      views: {
        stackedBar:{},
        area: {},
        line: {},
        table: {},
      },
    },
    'Contributions Paid': {
      chartRules: { },
      format: DOLLARS,
      xType: SPECIFIED,
      xAxisLabels: 'historyYearLabels',
      xAxisLabelsPosition: 'history',
      xAxisLabel: ' ',
      defaultPosition: 'history',
      defaultSeries: {
        erContribPaid: { title: 'Employer Contributions', color: 'purple', },
        eeContribPaid: { title: 'Employee Contributions', color: 'orange', },
        erAdditionalPaid: { title: 'Additional Voluntary Contributions', color: 'blue', },
      },
      views: {
        stackedBar:{},
        area: {},
        line: {},
        table: {},
      },
    },
    'Funding Level': {
      chartRules: { },
      format: PERCENT,
      xType: SPECIFIED,
      xAxisLabels: 'historyYearLabels',
      xAxisLabelsPosition: 'history',
      xAxisLabel: ' ',
      defaultPosition: 'history',
      defaultSeries: {
        fundedRatio: { title: 'Funding Level', color: 'blue', },
      },
      views: {
        bar:{},
        line: {},
        table: {},
      },
    },
    'Asset Values': {
      chartRules: { },
      format: DOLLARS,
      xType: SPECIFIED,
      xAxisLabels: 'historyYearLabels',
      xAxisLabelsPosition: 'history',
      xAxisLabel: ' ',
      defaultPosition: 'history',
      defaultSeries: {
        ava: { title: 'Actuarial Value of Assets', color: 'navy', },
        mva: { title: 'Market Value of Assets', color: 'red', },
      },
      views: {
        bar:{},
        table: {},
      },
    },
    'Asset Rates of Return': {
      chartRules: { },
      format: PERCENT,
      xType: SPECIFIED,
      xAxisLabels: 'historyYearLabels',
      xAxisLabelsPosition: 'history',
      xAxisLabel: ' ',
      defaultPosition: 'history',
      defaultSeries: {
        avaReturnRate: { title: 'Actuarial Value RoR', color: 'navy', },
        marketReturnRate: { title: 'Market Value RoR', color: 'red', },
        assumedReturnRate: { title: 'Assumed RoR', color: 'green', },
      },
      views: {
        line: {},
        table: {},
      },
    },
  },
  'Projection': {
    'Funding Level': {
      chartRules: { },
      format: PERCENT,
      xType: SPECIFIED,
      xAxisLabels: 'nonContribXAxis',
      xAxisLabelsPosition: 'projection',
      xAxisLabel: ' ',
      defaultPosition: 'projection',
      defaultSeries: {
        fundingLevel: { title: 'Funding Level', color: 'blue', },
      },
      views: {
        bar:{},
        line: {},
        table: {},
      },
    },
    'Required Contributions': {
      chartRules: {},
      format: DOLLARS,
      xType: SPECIFIED,
      xAxisLabels: 'contribXAxis',
      xAxisLabelsPosition: 'projection',
      xAxisLabel: ' ',
      defaultPosition: 'projection',
      defaultSeries: {
        ualPayment: { title: 'Payment of Unfunded Accrued Liability', color: 'purple', },
        erncDollar: { title: 'Employer Normal Cost', color: 'orange', },
        eeContrib: { title: 'Employee Contributions', color: 'blue', },
        reqContDollar: { title: 'Total Employer Required Contributions', view: 'line', stacked: false, color: 'red', },
        addlERContrib: { title: 'Additional Voluntary Contributions', color: 'green', chartRules: { mersShowAddlER: true }},
      },
      views: {
        stackedBar: {},
        area: {},
        line: {},
        table: {},
      },
    },
    'Maturity Metrics': {
      chartRules: {},
      format: NUMBER,
      xType: SPECIFIED,
      xAxisLabels: 'nonContribXAxis',
      xAxisLabelsPosition: 'projection',
      xAxisLabel: ' ',
      defaultPosition: 'projection',
      defaultSeries: {
        metric1: { title: 'MVA/Payroll', color: 'red', },
        metric2: { title: 'AAL/Payroll', color: 'purple', },
        metric4: { title: 'MVA/Benefit Payments', color: 'blue', },
      },
      views: {
        bar: {},
        line: {},
        table: {},
      },
    },
    'Maturity Metric - Net Cash Flow to MVA (BOY)': {
      chartRules: {},
      format: PERCENT,
      xType: SPECIFIED,
      xAxisLabels: 'nonContribXAxis',
      xAxisLabelsPosition: 'projection',
      xAxisLabel: ' ',
      defaultPosition: 'projection',
      defaultSeries: {
        metric5: { title: 'Net Cash Flow/MVA (BOY)', color: 'red', },
      },
      views: {
        bar: {},
        line: {},
        table: {},
      },
    },
  }
};
export default pensionCharts;
