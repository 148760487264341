import _ from 'lodash';

const initialState = {
  info: null,
  key: null,
};

export const workspace = (state = initialState, action) => {
  switch (action.type) {
    case 'RESET_WORKSPACE': {
      const newState = _.cloneDeep(state);
      newState.info = null;
      newState.key = action.payload.workspaceKey;
      return newState;
    }
    case 'WORKSPACE_STARTING': {
      const newState = _.cloneDeep(state);
      newState.info = _.cloneDeep(action.payload);
      return newState;
    }
    default:
      return state;
  }
};