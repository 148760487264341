// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import moment from 'moment';
import AxisCss from './axisCss';
import { uniq } from 'lodash';
import { FISCALYEARPERIODS, SPECIFIED, VALUATIONYEARPERIODS } from 'lib/defaults.js';

export default class XAxis extends React.Component {
  // draw each year
  ticks(y) {
    let { scales, info, cmp } = this.props;
    const onYear = cmp.state.onYear;
    let yPosition = y;

    let years = 0;

    if (info.xType === SPECIFIED && info.xAxisLabels) {
      if(!info.xAxisLabels)
        throw new Error('XAxis: Missing info.xAxisLabels');
      years = scales.x.ticks(info.xAxisLabels.length); //never hide ticks for specified charts
    } else {
      years = scales.x.ticks(8); //default to 8 ticks
    }

    //filter to unique whole numbers
    years = years.filter(y => parseInt(y, 10) === y);
    years = uniq(years);

    const yearStr = (year, n) => {

      if(info.xType === SPECIFIED ) {
        if(!info.xAxisLabels)
          return '';
        return info.xAxisLabels[n];
      }

      let y = '' + year;

      if (
        (info.xType === FISCALYEARPERIODS && info.fiscalYear1StartDifferentYearThanEnd) ||
        (info.xType === VALUATIONYEARPERIODS && info.valuationYear1StartDifferentYearThanEnd)
      ) {
        y = y.substr(2, 2) + '-' + ('' + (year + 1)).substr(2, 2);
      }

      return y;
    };

    return years.map((year, i) => {
      let color = 'grey';
      let weight = 'normal';
      if (onYear === year) {
        color = 'darkslategrey';
        weight = 'bold';
      } else if (cmp.state.pinYear === true) {
        color = 'lightgrey';
      }
      let tickStyle = {
        stroke: 'none',
        fill: color,
        fontWeight: weight,
        opacity: 1,
        shapeRendering: 'crispEdges',
        fontSize: 14,
        textAnchor: info.xAxisRotate ? null : 'middle',
      };
      let x = scales.x(year);

      const transform = info.xAxisRotate ? `rotate(60 ${x} ${yPosition})` : null;

      return (
        <g key={i}>
          <text x={x} y={yPosition} style={tickStyle} transform={transform}>
            {yearStr(year, i)}
          </text>
        </g>
      );
    });
  }
  label(y) {
    let { info, scales } = this.props;
    if (!info || !info.xType) {
      return null;
    }
    let arr = scales.x.range();
    let middle = arr[0] + (arr[1] - arr[0]) / 2;
    const getAxisLabel = () => {
      if(info.xAxisLabel)
        return info.xAxisLabel;

      let axisLabelPrefix;
      let axisLabelDate;

      if (info.xType === FISCALYEARPERIODS) {
        axisLabelPrefix = 'Fiscal Year Ending';
        axisLabelDate = info.fiscalEndDate;
      } else if (info.xType === VALUATIONYEARPERIODS) {
        axisLabelPrefix = 'Year Ending';
        axisLabelDate = info.valuationEndDate;
      } else {
        axisLabelPrefix = 'As of';
        // special business rule. See dev-68 ticket
        const valuationStartDate = moment(info.valuationStartDate, 'MMMM D');
        // in moment month() is zero base, 11 representing December
        if (valuationStartDate.month() === 11 && valuationStartDate.date() === 31) {
          axisLabelDate = 'January 1';
        } else {
          axisLabelDate = info.valuationStartDate;
        }
      }

      return `${axisLabelPrefix} ${axisLabelDate}`;
    };

    return (
      <g key={'label'}>
        <text x={middle} y={y} style={ {...AxisCss.labelY, fontSize: 16 }}>
          {getAxisLabel()}
        </text>
      </g>
    );
  }

  render() {
    let { info, scales } = this.props;
    let bottom = scales.y.range()[0];
    let tickMarginY = 20;
    let labelMarginY = 40;
    if(info.xAxisAdditionalMargin)
      labelMarginY = labelMarginY + info.xAxisAdditionalMargin;

    if (info.isComparing && info.compareWithBaseline && (info.view === 'diffBar' || info.view === 'stackedBar')) {
      tickMarginY = 32;
      labelMarginY = 52;
    }

    return (
      <g key="xaxis">
        {this.ticks(bottom + tickMarginY)}
        {this.label(bottom + labelMarginY)}
      </g>
    );
  }
}
