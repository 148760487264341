// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Popover, MenuItem, OverlayTrigger } from 'react-bootstrap';
import { getPlanType } from 'client/selectors/page.js';
import { getComparedScenarioInfo, getAdjustmentStructures, summarizeViewParamsV2 } from '../selectors';
import { find } from 'common/colorMachine';
import { saveScenario2 } from '../apiActions.js';
import {
  setScenarioLabelEditModal,
  setViewingScenario,
  setViewingScenarioToDefault,
  setEditingScenario,
  setScenarioAsUncompared,
  setBaselineScenario,
  removeFromCompared,
} from '../actionCreators';
import SendScenarioModal from './SendScenarioModal';
import { SCENARIO_DEFAULT_ID } from '../constants';
import ScenarioSummary from './ScenarioSummary';
import HamburgerDropdown from './HamburgerDropdown';
import { getComparedScenarioList, getUserInfo } from '../selectorsScenarios';
import styled from 'styled-components';
import './Scenario.css';
import { setTab } from 'client/actions/chartsActions';

const styles = {
  savedScenario: {
    paddingLeft: '0.5em',
    cursor: 'default',
    fontSize: '13px',
    color: 'black',
    opacity: '0.5',
  },
  clickable: {
    color: '#000000',
    fontSize: '0.8em',
    paddingLeft: '0.5em',
    cursor: 'pointer',
  },
};

const ClickableWrapper = styled.a`
  display: flex;
`;

const RightItems = styled.span`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
  align-items: center;
`;

const ClickableItem = ({ onClick, text }) => (
  <ClickableWrapper>
    <span style={styles.clickable} onClick={onClick}>
      {text}
    </span>
  </ClickableWrapper>
);

const popOver = (scenarioId, scenarioLabel, summarizeViewParams) => {
  return (
    <Popover id={scenarioId} title={scenarioLabel}>
      <ScenarioSummary summaryData={summarizeViewParams} />
    </Popover>
  );
};

const ComparedScenario = props => {
  const [ sendScenarioModalShow, setSendScenarioModalShow ] = useState(false);
  const dispatch = useDispatch();
  const comparedScenarioInfo = useSelector(state => getComparedScenarioInfo(state.sideBar, props.scenarioId));
  const adjustmentStructures = useSelector(state => getAdjustmentStructures(state));
  const summarizeViewParams = useSelector(state => summarizeViewParamsV2(state, props));
  const planType = useSelector(state => getPlanType(state, props));
  const userInfo = useSelector(state => getUserInfo(state));
  const comparedScenarioList = useSelector(state => getComparedScenarioList(state));
  const selectedView = useSelector(state => state.charts.selectedView);
  const workspaceInfo = useSelector(state => state.workspace.info);

  const onClickScenarioLabel = (e) => {
    e.preventDefault();
    comparedScenarioInfo.scenarioId === SCENARIO_DEFAULT_ID
      ? dispatch(setViewingScenarioToDefault(adjustmentStructures))
      : dispatch(setViewingScenario(comparedScenarioInfo.scenarioId));
  };

  const onClickName = (e) => {
    e.preventDefault();
    dispatch(setScenarioLabelEditModal(comparedScenarioInfo.scenarioId));
  };

  const onClickEdit = (e) => {
    e.preventDefault();
    dispatch(setEditingScenario(comparedScenarioInfo.scenarioId));
  };

  const onClickRemove = (e) => {
    e.preventDefault();
    const scenarioIdToRemove = comparedScenarioInfo.scenarioId;
    const isSaved = comparedScenarioInfo.hasBeenSaved;
    const comparedScenariosFiltered = comparedScenarioList.scenarioIds.filter(scenarioId => scenarioId !== scenarioIdToRemove);

    if (comparedScenarioInfo.isBaselineScenario) {
      dispatch(setBaselineScenario(comparedScenariosFiltered[0]));
    }

    if (comparedScenarioInfo.isViewedScenario) {
      if (comparedScenariosFiltered.includes(SCENARIO_DEFAULT_ID)) {
        dispatch(setViewingScenarioToDefault(adjustmentStructures));
      } else {
        dispatch(setViewingScenario(comparedScenariosFiltered[0]));
      }
    }

    if (comparedScenariosFiltered.length === 1 && selectedView.tab !== 'current') {
      dispatch(setTab('current'));
    }

    if (isSaved) {
      dispatch(setScenarioAsUncompared(scenarioIdToRemove));
    } else {
      dispatch(removeFromCompared(scenarioIdToRemove));
    }
  };

  const onClickSave = (e) => {
    e.preventDefault();
    dispatch(saveScenario2(workspaceInfo, comparedScenarioInfo.scenarioId));
  };

  const onClickSend = (e) => {
    e.preventDefault();
    setSendScenarioModalShow(true);
  };

  const handleClose = (e) => {
    if(e)
      e.preventDefault();
    setSendScenarioModalShow(false);
  };

  const onClickUseAsBaseline = (e) => {
    e.preventDefault();
    dispatch(setBaselineScenario(comparedScenarioInfo.scenarioId));
  };

  const isOnlyComparedScenario = comparedScenarioList.scenarioIds.length === 1;
  const { scenarioId, scenarioLabel, isViewedScenario, isBaselineScenario, hasBeenSaved, canEdit, colorKey } = comparedScenarioInfo;
  const { clientId, username } = userInfo;
  const isDefaultScenario = scenarioId === 'default';
  const style = {
    maxWidth: '15em',
    fontSize: '16px',
    border: 0,
    margin: '0px',
    padding: '0.3em 0.5em',
    borderRadius: '5px',
    backgroundColor: (isViewedScenario && colorKey !== undefined) ? find(colorKey) : 'white',
    color: (!isViewedScenario && colorKey !== undefined) ? find(colorKey) : 'white',
  };

  return (
    <div className="scenario">
      <span>
        <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={popOver(scenarioId, scenarioLabel, summarizeViewParams)}>
          <Button style={style} bsStyle={isViewedScenario ? 'primary' : 'link'} bsSize="xsmall" onClick={onClickScenarioLabel}>
            {scenarioLabel}
          </Button>
        </OverlayTrigger>
      </span>
      <RightItems>
        {!hasBeenSaved && <ClickableItem text={'save'} onClick={onClickSave} />}
        {!isBaselineScenario ? (
          <ClickableItem text={'use as baseline'} onClick={onClickUseAsBaseline} />
        ) : (
            <span style={styles.savedScenario}>baseline</span>
        )}
        {((isDefaultScenario && !isOnlyComparedScenario) || !isDefaultScenario) && (
          <HamburgerDropdown dropup={true} onSelect={() => {}}>
            {hasBeenSaved && !isDefaultScenario && <MenuItem href="http://" onClick={onClickSend}>Send</MenuItem>}
            {!isDefaultScenario && <MenuItem href="http://" onClick={onClickName}>Name</MenuItem>}
            {canEdit && !isDefaultScenario && <MenuItem href="http://" onClick={onClickEdit}>Edit</MenuItem>}
            {!isOnlyComparedScenario && <MenuItem href="http://" onClick={onClickRemove}>Remove</MenuItem>}
          </HamburgerDropdown>
        )}
      </RightItems>
      {sendScenarioModalShow && (
        <SendScenarioModal
          show={sendScenarioModalShow}
          handleClose={handleClose}
          clientId={clientId}
          username={username}
          planType={planType}
          scenarioId={scenarioId}
          workspaceInfo={workspaceInfo}
        />
      )}
    </div>
  );
}

export default withRouter(ComparedScenario);
