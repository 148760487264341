// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import * as constants from './constants';
//SHARED HELPER FUNCTIONS SHARED BETWEEN SELECTORS
import { anyDeviateFromViewingScenario } from './selectorsSharedHelpers';
import { getChartDataFromProps } from '../selectors/graphSelectors';
import { uuid } from 'lib/util';

export const getSavedScenarioList = ({ sideBar }) => ({ scenarioIds: sideBar.unComparedScenarios });

export const getSavedScenarioInfo = ({ sideBar }, { scenarioId }) => {
  if (sideBar.scenarios[scenarioId]) {
    return { scenarioId: sideBar.scenarios[scenarioId].scenarioId, scenarioLabel: sideBar.scenarios[scenarioId].label };
  } else {
    return null;
  }
};

export const getEditingScenarioInfo = state => {
  const { sideBar } = state;
  const allAdjustmentNames = Object.keys(sideBar.adjustmentStructure);
  if (sideBar.scenarios[sideBar.editingScenario]) {
    return {
      scenarioId: sideBar.editingScenario,
      scenarioLabel: sideBar.scenarios[sideBar.editingScenario].label,
      anyDeviateFromScenario: anyDeviateFromViewingScenario(sideBar, allAdjustmentNames),
      hasBeenSaved: sideBar.scenarios[sideBar.editingScenario].hasBeenSaved,
    };
  } else {
    return null;
  }
};

export const getComparedScenarioList = ({ sideBar }) => ({ scenarioIds: sideBar.comparedScenarios });

export const getComparedScenarioInfo = (sideBar, scenarioId) => {
  if (scenarioId === constants.SCENARIO_DEFAULT_ID) {
    return {
      scenarioLabel: 'Default',
      scenarioId: scenarioId,
      colorKey: 0,
      isBaselineScenario: sideBar.baselineScenario === scenarioId,
      isViewedScenario: sideBar.viewingScenario === scenarioId,
      canEdit: sideBar.baselineScenario !== scenarioId,
      hasBeenSaved: true,
    };
  }
  if (sideBar.scenarios[scenarioId]) {
    return {
      scenarioLabel: sideBar.scenarios[scenarioId].label,
      scenarioId: scenarioId,
      colorKey: sideBar.scenarios[scenarioId].colorKey,
      isBaselineScenario: sideBar.baselineScenario === scenarioId,
      isViewedScenario: sideBar.viewingScenario === scenarioId,
      canEdit: sideBar.baselineScenario !== scenarioId,
      hasBeenSaved: sideBar.scenarios[scenarioId].hasBeenSaved,
    };
  } else {
    console.error('cannot find', scenarioId, sideBar.scenarios);
    return null;
  }
};

export const getViewingScenarioLabel = ({ sideBar }) => {
  return getComparedScenarioInfo(sideBar, sideBar.viewingScenario).scenarioLabel;
};

export const getViewingScenarioId = ({ sideBar }) => sideBar.viewingScenario;

export const getScenarioCount = ({ sideBar }) => {
  if (Object.keys(sideBar.scenarios).length) {
    let number = 1;
    while (sideBar.scenarios[constants.SCENARIO_ID + number]) {
      number++;
    }
    return number;
  }
  return 1;
};

export const getIsEditingScenario = ({ sideBar }) => {
  if (sideBar.editingScenario !== constants.SCENARIO_DEFAULT_ID) {
    return true;
  }
  return false;
};

export const getAdjustmentStructures = ({ sideBar }) => sideBar.adjustmentStructure;

/**
 * Generates a unique {scenarioId, scenarioLabel} based on stored scenarios
 * @param state
 *
 */
export function getNextScenario(state) {
  const { scenarios } = state.sideBar;
  let next = 1;
  if (Object.keys(scenarios).length > 0) {
    for (; next < 100; next++) {
      const name = getNextScenarioLabel(next);
      if (!Object.keys(scenarios).find(s => scenarios[s].label === name)) break;
    }
  }
  const scenarioLabel = getNextScenarioLabel(next);
  return {
    scenarioId: uuid(),
    scenarioLabel,
  };
}

function getNextScenarioLabel(num) {
  return 'Scenario ' + num;
}

/**
 Returns a map of scenario information for compared and default scenarios
 * @param state
 */
export function getScenarioMap(state, options) {
  const { sideBar } = state;
  let scenariosToMap = [...sideBar.comparedScenarios];
  // allow getScenarioMap to optionally include Default scenario even if it is not a compared scenario
  if (options && options.includeDefault && !scenariosToMap.includes(constants.SCENARIO_DEFAULT_ID)) {
    scenariosToMap.unshift(constants.SCENARIO_DEFAULT_ID);
  }
  const a = scenariosToMap.reduce(
    (all, scenarioId) => ({ ...all, [scenarioId]: getComparedScenarioInfo(state.sideBar, scenarioId) }),
    {},
  );
  //  this is transformed to something more compatible with the current graphs / fetching
  const t = Object.keys(a).reduce((all, name) => {
    const scenario = a[name];
    const values = getChartDataFromProps(state, name);
    return {
      ...all,
      [name]: {
        name,
        label: scenario.scenarioLabel,
        colorKey: scenario.colorKey,
        values,
        calcRequest: sideBar.calcRequests[name],
      },
    };
  }, {});
  return t;
}

export function findColorKey(scenarios) {
  let next = 1;
  if (Object.keys(scenarios).length > 0) {
    const isThere = s => scenarios[s].colorKey === next;
    for (; next < 100; ++next) {
      if (!Object.keys(scenarios).find(isThere)) break;
    }
  }
  return next;
}

export const getUserInfo = state => {
  const { project } = state.user;
  return {
    projectId: !!project ? project.projectId : null,
    userId: state.currentUser.profile.userId,
  }
};
