// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************

import { cloneDeep } from 'lodash';

export function populateDerivedSeries(resolved, PY, planType) {
  // used to populate any missing series when appropriate
  const derived = {};
  const def = series => {
    if (!series || series.length < PY) {
      return Array(PY).fill(0);
    }
    return series;
  };

  if(planType === "pension" || planType === "opeb") {
    derived.benefitsPayments = resolved.cashflows.benefitsPayments.map(c => -c);
    derived.expenses = resolved.cashflows.expenses.map(c => -c);
    derived.amortPayment = resolved.amortizationSummary.payment;
    derived.amortBalance = resolved.amortizationSummary.balanceBegin;
    derived.amortizationDetail = populateAmortDetail(resolved);
    derived.totalContributions = populateTotalContribDollars(resolved);
    derived.totalContributionsRate = populateTotalContribRate(resolved);
    derived.retiredAL = populateRetiredAL(resolved, def);
    derived.termAL = populateTerminatedAL(resolved, def);
    derived.activeAL = populateActiveAL(resolved, def);
    derived.totalAL = resolved.balanceSheet.pensionLiabilities;
    derived.totalPVB = resolved.presentValues.benefitsPayments;
    derived.activePVB = populateActivePVB(resolved, def);
    derived.totalNormalCost = populateTotalNormalCost(resolved);
    derived.totalNormalCostRate = populateTotalNormalCostRate(resolved);
    derived.benefitsPaymentsRate = populateBenefitsPaymentsRate(resolved);
  }

  return derived;
}

function populateBenefitsPaymentsRate(resolved) {
  const { fiscalYearPayroll, benefitsPayments } = resolved.cashflows;
  const ret = fiscalYearPayroll.map((p, i) => p < 1e-7 ? 0.0 : benefitsPayments[i] / p);
  return ret;
}

function populateTotalNormalCost(resolved) {
  const { employeeContributionDollars, employerNormalCostDollars } = resolved.recommendedContributions;
  const ret = employeeContributionDollars.map((c, i) => c + employerNormalCostDollars[i]);
  return ret;
}

function populateTotalNormalCostRate(resolved) {
  const { employeeContributionRate, employerNormalCostRate } = resolved.recommendedContributions;
  const ret = employeeContributionRate.map((c, i) => c + employerNormalCostRate[i]);
  return ret;
}

function populateRetiredAL(resolved, defaults) {
  const annuitantFields = resolved.balanceSheet.statusDistribution.map(s => s.status).filter(status => status !== 'Vested' && status !== 'Active');
  const res = annuitantFields.reduce((res, field) => {
    const cur = defaults(resolved.balanceSheet.statusDistribution.filter(c => c.status === field)[0].pensionLiabilities);
    cur.forEach((c, i) => (res[i] = (res[i] === undefined ? 0 : res[i]) + cur[i]));
    return res;
  }, defaults([]));
  return res;
}

function populateTerminatedAL(resolved, defaults) {
  return defaults(findMetricValue(resolved.balanceSheet.statusDistribution, 'status', 'Vested', 'pensionLiabilities'));
}

function populateActiveAL(resolved, defaults) {
  return defaults(findMetricValue(resolved.balanceSheet.statusDistribution, 'status', 'Active', 'pensionLiabilities'));
}

function populateActivePVB(resolved, defaults) {
  return defaults(findMetricValue(resolved.balanceSheet.statusDistribution, 'status', 'Active', 'pvOfBenefits'));
}

function populateTotalContribDollars(resolved) {
  const { percentOfARCDollars, percentOfPayDollars, percentOfBenefitsDollars, additionalDollarDollars, payGoShortfallDollars, expenseContribDollars } = resolved.employerContributions;
  const ret = percentOfARCDollars.map(
    (c, i) => c + percentOfPayDollars[i] + percentOfBenefitsDollars[i] + additionalDollarDollars[i] + payGoShortfallDollars[i] + expenseContribDollars[i],
  );
  return ret;
}

function populateTotalContribRate(resolved) {
  const { percentOfARCRate, percentOfPayRate, percentOfBenefitsRate, additionalDollarRate, payGoShortfallRate, expenseContribRate } = resolved.employerContributions;
  const ret = percentOfARCRate.map((c, i) => c + payGoShortfallRate[i] + percentOfPayRate[i] + percentOfBenefitsRate[i] + additionalDollarRate[i] + expenseContribRate[i]);
  return ret;
}

function populateAmortDetail(resolved) {
  const data = cloneDeep(resolved.amortizationDetail);
  for (const year of data) {
    let gtBalance = 0;
    let gtPayment = 0;
    for (const plan of year.plans) {
      let tBalance = 0;
      let tPayment = 0;
      for (const base of plan.details) {
        tBalance += base.balanceBegin;
        tPayment += base.payment;
      }
      plan.totalBalanceBegin = tBalance;
      plan.totalPayment = tPayment;
      gtBalance += tBalance;
      gtPayment += tPayment;
    }
    year.totalBalanceBegin = gtBalance;
    year.totalPayment = gtPayment;
  }
  return data;
}

function findMetricValue(series, field, value, metric) {
  const source = series.filter(c => c[field] === value)[0];
  if (source) {
    return source[metric];
  }
  return null;
}
