// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { area, line, curveMonotoneX } from 'd3-shape';
import tinycolor from 'tinycolor2';

export default class ClippedArea extends React.Component {
  render() {
    const { chart, renderCurrentComparison, renderBaselineComparison } = this.props;
    const { above, top, bottom, color, clipName } = chart;

    const aboveFunc = above ? d => top : d => d.y;
    const belowFunc = above ? d => d.y : d => bottom;
    const baselineAboveFunc = above ? d => top : d => d.baseY;
    const baselineBelowFunc = above ? d => d.baseY : d => bottom;

    const areaPath = area()
      .x(d => d.x)
      .y1(aboveFunc)
      .y0(belowFunc)
      .curve(curveMonotoneX)(chart.points);
    const linePath = line()
      .x(d => d.x)
      .y(d => d.y)
      .curve(curveMonotoneX)(chart.points);
    const baselineLinePath = line()
      .x(d => d.x)
      .y(d => d.baseY)
      .curve(curveMonotoneX)(chart.points);
    const differenceAreaPath = area()
      .x(d => d.x)
      .y0(baselineBelowFunc)
      .y1(baselineAboveFunc)
      .curve(curveMonotoneX)(chart.points);

    if (renderCurrentComparison) {
      return !above
        ? <path d={linePath} clipPath={`url(#${clipName})`} stroke={tinycolor(color).darken(20)} strokeWidth={4} fill="none" opacity={0.9} shapeRendering="auto" />
        : <g />
    }

    if (renderBaselineComparison) {
      return (
        <React.Fragment>
          <path d={differenceAreaPath} clipPath={`url(#${clipName})`} strokeWidth={0} fill={color} opacity={0.2} shapeRendering="geometricPrecision" />
          {!above && <path d={baselineLinePath} clipPath={`url(#${clipName})`} stroke={tinycolor(color).darken(20)} strokeWidth={2} fill="none" opacity={0.9} shapeRendering="auto" />}
        </React.Fragment>
      );
    }

    return <path d={areaPath} clipPath={`url(#${clipName})`} strokeWidth={0} fill={color} opacity={1} shapeRendering="geometricPrecision" />;
  }
}
