// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { rect } from 'd3-shape';
import styled from 'styled-components';

const BarLabel = styled.text`
  stroke: none;
  fill: ${props => props.color};
  opacity: 1;
  font-weight: ${props => props.weight};
  shape-rendering: crispEdges;
  font-size: 12px;
  text-anchor: middle;
  text-align: center;
`;

export default class Bar extends React.Component {
  render() {
    const { chart, cmp, isComparing, renderLabels, size } = this.props;
    const renderBaselineBars = chart.view === 'stackedBar' && isComparing && chart.compareWithBaseline;

    const bars = chart.points.map(function(c, i) {
      let opacity = 1;
      let baselineOpacity = 0.7;
      let color = chart.color;
      let baseColor = chart.color;
      if (c.reversed) {
        color = '#5AB573';
      }
      if (c.baseReversed) {
        baseColor = '#5AB573';
      }
      if (cmp.state.pinYear && cmp.state.onYear !== c.year) {
        opacity = 0.4;
        baselineOpacity = 0.4;
      }

      const margin = c.width * 0.025;
      const width = renderBaselineBars ? c.width * 0.5 : c.width;
      const halfWidth = width * 0.5;

      const height = c.bottom - c.y;
      const baseHeight = c.baseBottom - c.baseY;
      const xPosition = renderBaselineBars ? c.x + margin : c.x - halfWidth;
      const baseXPosition = c.x - (c.width * 0.5 + margin);
      const labelPosition = c.x + margin + halfWidth;
      const baseLabelPosition = c.x - margin - halfWidth;
      const renderBarLabel = renderBaselineBars && renderLabels && width > 45;

      let labelColor = 'grey';
      let labelWeight = 'normal';
      if (cmp.state.onYear === c.year) {
        labelColor = 'darkslategrey';
        labelWeight = 'bold';
      } else if (cmp.state.pinYear === true) {
        labelColor = 'lightgrey';
      }

      const labelY = size.height - 45;

      return (
        <g key={i}>
          <rect
            x={xPosition}
            y={c.y}
            width={width}
            height={height}
            stroke={color}
            strokeWidth={1}
            fill={color}
            shapeRendering="auto"
            opacity={opacity}
          />
          {renderBaselineBars &&
            <rect
              x={baseXPosition}
              y={c.baseY}
              width={c.width * 0.5}
              height={baseHeight}
              stroke={baseColor}
              strokeWidth={1}
              fill={baseColor}
              shapeRendering="auto"
              opacity={baselineOpacity}
            />
          }
          {renderBarLabel &&
            <React.Fragment>
              <BarLabel
                x={labelPosition}
                y={labelY}
                color={labelColor}
                weight={labelWeight}
              >
                Current
              </BarLabel>
              <BarLabel
                x={baseLabelPosition}
                y={labelY}
                color={labelColor}
                weight={labelWeight}
              >
                Baseline
              </BarLabel>
            </React.Fragment>
          }
        </g>
      );
    });

    return (
      <g>
        {bars}
      </g>
    );
  }
}
