import React, { useState, useEffect } from 'react';
import qs from 'qs';
import { Provider } from 'react-redux';
import history from './lib/history.js';
import store from './storeSetup';
import {
  Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import LoginFailed from './components/LoginFailed';
import CustomError from './components/CustomError';
import Auth from './lib/auth';
import NotFound from './components/NotFound';
import App from './App';
import Spinner from 'components/Spinner';

function Routes() {
  const [authStartInfo, setAuthStartInfo] = useState(null);
  const [started, setStarted] = useState(false);

  useEffect(() => {
    const getAuthStartInfo = async () => {
      const si = await new Auth().handleStart(); //IUM Web client auth
      setAuthStartInfo(si);
      setStarted(true);
    };
    if (!started) {
      getAuthStartInfo();
    }
  }, [started]);

  const loading = <Spinner />;

  if(window.location.pathname === '/error') {
    const queryStrings = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    return <CustomError error={queryStrings.error} errorDescription={queryStrings.error_description} />
  }

  if(window.location.pathname === '/callback') {
    if(!authStartInfo) {
      return loading;
    }
    if(authStartInfo.error) {
      return <LoginFailed error={authStartInfo.error} errorDescription={authStartInfo.errorDescription} />
    }
  }

  if (authStartInfo && authStartInfo.mode === 'error') {
    return (<CustomError error={authStartInfo.error} errorDescription={authStartInfo.errorDescription}  />);
  }

  if(!started) {
    console.log('waiting to start')
    return loading;
  }

  console.log('started')

  const auth = new Auth();

  if(!auth.isLoggedIn()) {
    auth.initiateLogin();
    return loading;
  }

  if(!auth.hasAuthorizedScope()) {
      return <LoginFailed error="not_authorized" errorDescription="You do not have access to this module.  Please contact GovInvest support or your local administrator." />
  }

  return (
    <Provider store={store}>
      <React.Fragment>
        <Router history={history} component={App}>
          <Switch>
            <Route exact path="/callback" >
              <Redirect to="/app" />
            </Route>
            <Route exact path="/" >
              <Redirect to="/app" />
            </Route>
            <Route path="/app">
              <App />
            </Route>
            <Route path="*" component={NotFound} status={404} />
          </Switch>
        </Router>
      </React.Fragment>
    </Provider>
  );
}

export default Routes;