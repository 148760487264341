// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import urlJoin from 'url-join';
import ClientMenu from '../ClientMenu.jsx';
import { getDownloadableCharts } from 'client/selectors/downloadSelector.js';
import { downloadXLS } from 'client/lib/download.js';
import TopicRow from './TopicRow.jsx';
import { getDisplayDataPropsFromState2 } from 'client/lib/app-general.js';
import { requestProjectPlanTypeDetails } from 'client/actions';
import { getPlanType } from 'client/selectors/page.js';
import config from 'lib/client-config';

import './DownloadSelector.css';

const DownloadSelector = props => {
  const dispatch = useDispatch();
  const planType = useSelector(state => getPlanType(state, props));
  const downloadableTopicsCharts = useSelector(state => getDownloadableCharts(planType, state.charts.availCharts));
  const downloadUnchecked = useSelector(state => state.viewSummary.downloadUnchecked);
  const workspaceInfo = useSelector(state => state.workspace.info);
  const displayProps = useSelector(state => ({
    ...getDisplayDataPropsFromState2(state, props),
    sideBar: state.sideBar,
    cache: state.cache,
    user: state.user,
    workspaceInfo: state.workspaceInfo,
    currentUser: state.currentUser.profile,
  }));

  const handleBack = () => {
    props.history.goBack();
  }

  useEffect(() => {
    dispatch(requestProjectPlanTypeDetails(planType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uri = urlJoin(config.baseUrl, `webapi/workspace/${workspaceInfo.projectId}/${workspaceInfo.planType}/selectedXlsx2`);

  const { wrapperProps } = props;
  return (
    <ClientMenu {...wrapperProps}>
      <div className="download-selector" style={{overflowY:'scroll', height:'100%'}}>
        <div className="heading">
          <div>Select charts and series to include</div>
        </div>
        <div className="download-selector-content">
          {Object.keys(downloadableTopicsCharts).map(topic => (
            <TopicRow id={`cb/${topic}`} key={topic} planType={planType} topic={topic} downloadableCharts={downloadableTopicsCharts[topic]} />
          ))}
        </div>
        <div className="button-group" style={{position:"unset", paddingBottom:'40px'}}>
          <Button onClick={() => downloadXLS(uri, { displayProps, downloadUnchecked }, 'selected-charts.xlsx')}>Download</Button>
          <Button onClick={handleBack} style={{ marginLeft: 20 }}>Back</Button>
        </div>
      </div>
    </ClientMenu>
  );
}

export default withRouter(DownloadSelector);
