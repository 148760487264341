// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { area, curveMonotoneX } from 'd3-shape';

export default class ClipArea extends React.Component {
  render() {
    const { chart } = this.props;
    const { above, clipName, top, bottom, isBaseline } = chart;
    // lineStrokeWidth and clipAreaGap introduced to account for
    // line strokes whose widths exceed chart y scale and to shore up small gaps between clipped areas
    const lineStrokeWidth = 2;
    const clipAreaGap = lineStrokeWidth / 6;
    const aboveFunc = above ? d => top - lineStrokeWidth : d => isBaseline ? d.baseY : d.y;
    const belowFunc = above ? d => isBaseline ? d.baseY : d.y + clipAreaGap : d => bottom;

    const areaPath = area()
      .x(d => d.x)
      .y1(aboveFunc)
      .y0(belowFunc)
      .curve(curveMonotoneX)(chart.points);
    return (
      <g>
        <clipPath id={clipName} shapeRendering="geometricPrecision">
          <path d={areaPath} shapeRendering="geometricPrecision" />
        </clipPath>
      </g>
    );
  }
}
