// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************

import MD5 from 'md5.js';

import { DATA_RETRIEVAL } from '../actions';

function cache(state = { requestedMetricsData: {} }, action) {
  switch (action.type) {
    case DATA_RETRIEVAL:
      const { step, result, PY, key, calcRequest } = action;
      let requestedMetricsData = Object.assign({}, state.requestedMetricsData);
      delete requestedMetricsData[key];

      if (step === 'failed') {
        requestedMetricsData[key] = {
          step,
          error: result,
        };
      } else {
        requestedMetricsData[key] = {
          step,
          filters: calcRequest && calcRequest.filters,
          checksum: new MD5().update(JSON.stringify(result)).digest('hex'),
          data: result,
          PY,
        };
      }

      return {
        ...state,
        requestedMetricsData,
      };
    default:
      return state;
  }
}

export default cache;
