// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import XAxis from './axis/xAxis';
import YAxis from './axis/yAxis';
import Hovers from './misc/Hovers';
import CrossHair from './misc/CrossHair';
import Line from './charts/Line';
import Area from './charts/Area';
import BarChart from './charts/Bar';
import BarLine from './charts/BarLine';
import Comparison from './charts/Comparison';
import ClipArea from './charts/ClipArea';
import ClippedArea from './charts/ClippedArea';
import FillArea from './charts/FillArea';
import DrawArrows from './charts/DrawArrows';
import { barChartWidth } from '../general/compute/barChart';

const css = {
  container: {
    fontFamily: '"Lato", sans-serif',
    textRendering: 'geometricPrecision',
    overflow: 'hidden',
  },
};

export default class Graph extends React.Component {
  constructor(props) {
    super(props);
    this.mouseLeave = this.mouseLeave.bind(this);
  }
  mouseLeave() {
    this.props.callbacks.hoverYear(null);
  }
  render() {
    let { cmp, scales, charts, size, info, callbacks } = this.props;
    const year = {
      start: info.projectionStart,
      end: info.endYear,
      current: cmp.state.onYear,
    };
    const isSingleComparisonMode = charts.filter(x => x.type === 'comparison').length > 0;
    const renderComparisonElements = info.isComparing && info.compareWithBaseline;
    const indexOfFirstStackedBar = charts.findIndex(chart => chart.type === 'stackedBar');
    const renderStackedBarCurvedLine = (view, chart) => {
      return view !== 'stackedBar' || (view === 'stackedBar' && chart.lineType === 'curved');
    }

    let renderFirst = []
    let renderLast = [];

    charts.forEach((chart, i) => {
      if (chart.type === 'area') {
        renderFirst.push(<Area chart={chart} cmp={cmp} info={info} key={i} scales={scales} year={year} isComparing={info.isComparing} />)
        if (renderComparisonElements) {}
      } else if (chart.type === 'stackedBar' || chart.type === 'bar') {
        renderFirst.push(<BarChart chart={chart} cmp={cmp} key={i} year={year} isComparing={info.isComparing} renderLabels={indexOfFirstStackedBar === i} size={size} />)
      } else if (chart.type === 'comparison') {
        renderFirst.push(<Comparison chart={chart} cmp={cmp} key={i} />)
      } else if (chart.type === 'line' && info.view === 'stackedBar' && !renderStackedBarCurvedLine(info.view, chart) && (!info.isComparing || info.tab === 'current')) {
        renderLast.push(<BarLine chart={chart} cmp={cmp} key={i} isComparing={info.isComparing} />)
      } else if (chart.type === 'barLine' && (!info.isComparing || info.tab === 'current')) {
        renderLast.push(<BarLine chart={chart} cmp={cmp} key={i} isComparing={info.isComparing} />)
      } else if (chart.type === 'clipArea') {
        renderFirst.push(<ClipArea chart={chart} cmp={cmp} key={i} />)
      } else if (chart.type === 'diffArea') {
        renderFirst.push(<ClippedArea chart={chart} info={info} cmp={cmp} key={i} />)
      } else if (chart.type === 'fillArea') {
        renderFirst.push(<FillArea chart={chart} info={info} cmp={cmp} key={i} />)
      } else {
        renderLast.push(<Line chart={chart} key={i} isSingleComparisonMode={isSingleComparisonMode} />)
      }
    })

    const graphs = [...renderLast, ...renderFirst];

    const compareLinesCurrent = renderComparisonElements
      ? charts.map((chart, i) => {
          if (chart.type === 'area') {
            return <Area chart={chart} cmp={cmp} info={info} key={`compare-current-lines-${i}`} scales={scales} year={year} isComparing={info.isComparing} renderCurrentComparison={true} />;
          } else if (chart.type === 'diffArea') {
            return <ClippedArea chart={chart} info={info} cmp={cmp} key={`compare-current-lines-${i}`} renderCurrentComparison={true} />;
          } else if (chart.type === 'fillArea') {
            return <FillArea chart={chart} info={info} cmp={cmp} key={`compare-current-lines-${i}`} renderCurrentComparison={true} />;
          } else if (chart.type === 'line' && renderStackedBarCurvedLine(info.view, chart)) {
            return <Line chart={chart} key={`compare-current-lines-${i}`} renderCurrentComparison={true} isSingleComparisonMode={isSingleComparisonMode} />;
          }
          return <g key={`compare-current-lines-${i}`} />;
        })
      : [<g key={'compare-current-null'} />];

    const compareLinesBaseline = renderComparisonElements
      ? charts.map((chart, i) => {
          if (chart.type === 'area') {
            return <Area chart={chart} cmp={cmp} info={info} key={`compare-baseline-lines-${i}`} scales={scales} year={year} isComparing={info.isComparing} renderBaselineComparison={true} />;
          } else if (chart.type === 'line' && !renderStackedBarCurvedLine(info.view, chart) && (!info.isComparing || info.tab === 'current')) {
            return <BarLine chart={chart} cmp={cmp} key={i} isComparing={info.isComparing} renderBaselineComparison={true} />
          } else if (chart.type === 'barLine' && (!info.isComparing || info.tab === 'current')) {
            return <BarLine chart={chart} cmp={cmp} key={i} isComparing={info.isComparing} renderBaselineComparison={true} />
          } else if (chart.type === 'diffArea') {
            return <ClippedArea chart={chart} info={info} cmp={cmp} key={`compare-baseline-lines-${i}`} renderBaselineComparison={true} />;
          } else if (chart.type === 'fillArea') {
            return <FillArea chart={chart} info={info} cmp={cmp} key={`compare-baseline-lines-${i}`} renderBaselineComparison={true} />;
          } else if (chart.type === 'line') {
            return <Line chart={chart} key={`compare-baseline-lines-${i}`} renderBaselineComparison={true} />;
          }
          return <g key={`compare-baseline-lines-${i}`} />;
        })
      : [<g key={'compare-baseline-null'} />];

    const arrows = renderComparisonElements
      ? charts.map((chart, i) => {
          if (chart.type === 'bar') {
            return <DrawArrows chart={chart} cmp={cmp} key={`arrows-${i}`} year={year} isComparing={info.isComparing} />;
          } else if (chart.type === 'area') {
            return <DrawArrows chart={chart} cmp={cmp} key={`arrows-${i}`} year={year} isComparing={info.isComparing} />;
          } else if (chart.type === 'fillArea') {
            return <DrawArrows chart={chart} cmp={cmp} key={`arrows-${i}`} year={year} isComparing={info.isComparing} />;
          } else if (chart.type === 'line' && renderStackedBarCurvedLine(info.view, chart) && isSingleComparisonMode === false) {
            return <DrawArrows chart={chart} cmp={cmp} key={`arrows-${i}`} year={year} isComparing={info.isComparing} />;
          } else if (chart.type === 'comparison' && charts.length === 2) { //only show arrows if there is one scenario being compared to baseline
            return <DrawArrows chart={chart} cmp={cmp} key={`arrows-${i}`} year={year} isComparing={info.isComparing} />;
          } else if (chart.type === 'diffArea') {
            return <DrawArrows chart={chart} cmp={cmp} key={`arrows-${i}`} year={year} isComparing={info.isComparing} />;
          }
          return <g key={`arrows-${i}`} />;
        })
      : [<g key={'arrows-null'} />];

    return (
      <svg height={size.height} width={size.width} style={css.container} onMouseLeave={this.mouseLeave}>
        <XAxis scales={scales} cmp={cmp} info={info} />
        <YAxis scales={scales} cmp={cmp} info={info} barChartWidth={barChartWidth(info, scales)} />
        {graphs.reverse()}
        {compareLinesBaseline.reverse()}
        {compareLinesCurrent.reverse()}
        {arrows}
        <CrossHair cmp={cmp} scales={scales} info={info} />
        <Hovers cmp={cmp} charts={charts} scales={scales} callbacks={callbacks} />
      </svg>
    );
  }
}
