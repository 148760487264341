import pretty_print from 'common/pretty_print';

class DollarDifferenceFromBaselineCalculator {
  constructor(baselineChartSummary, currentScenarioChartSummary, commonVars) {
    this.baselineChartSummary = baselineChartSummary;
    this.scenarioChartSummary = currentScenarioChartSummary;
    this.commonVars = commonVars;
  }

  calculate() {
    const baselineAmount = this.baselineChartSummary.inputs.amount;
    const scenarioAmount = this.scenarioChartSummary.inputs.amount;
    const difference = scenarioAmount - baselineAmount;
    const sentenceName = difference > 10e-9
      ? 'increase'
      : difference < 0
        ? 'decrease'
        : 'noChange';

    let formattedSentence = this.baselineChartSummary.templateSentences[sentenceName];
    const commonVarsKeys = Object.keys(this.commonVars);
    for(let commonVarKey of commonVarsKeys) {
      formattedSentence = formattedSentence.replace(`{vars.${commonVarKey}}`, this.commonVars[commonVarKey])
    }
    const prettyDollarDifference = pretty_print(difference, 'dollar').replace(/[()]/g, '');
    formattedSentence = formattedSentence.replace('{output.difference}', prettyDollarDifference);

    return {
      sentence: formattedSentence,
    }
  }
}

function getFirstChartSummary(state, scenarioId, chartSummaryName) {
  const cache = state.cache.requestedMetricsData;
  if(!cache[scenarioId] || !cache[scenarioId].data) { return null; }
  const chartSummaries = cache[scenarioId].data.chartSummaries;
  if(chartSummaries === undefined || chartSummaries === null
    || chartSummaries[chartSummaryName] === undefined
    || chartSummaries[chartSummaryName].length === 0) { return null; }
  return chartSummaries[chartSummaryName][0];
}

export function chartSummarySelector(state) {
  const doNotShow = {
    shouldShowSummary: false,
  };

  if(state.charts.selectedView.tab !== 'current') { return doNotShow; }
  if(!state.charts.compareWithBaseline) { return doNotShow; }
  if(state.sideBar.comparedScenarios.length === 0) { return doNotShow; }

  const selectedChartName = state.charts.selectedView.chart;
  const planType = state.charts.selectedView.planType;
  const topic = state.charts.selectedView.topic;
  const chart = state.charts.availCharts[planType][topic][selectedChartName];
  if(!chart || !chart.chartSummary) return doNotShow;

  const currentScenario = state.sideBar.viewingScenario;
  const baselineScenario = state.sideBar.baselineScenario;

  const currentScenarioChartSummary = getFirstChartSummary(state, currentScenario, chart.chartSummary);
  const baselineChartSummary = getFirstChartSummary(state, baselineScenario, chart.chartSummary);
  if(currentScenarioChartSummary === null || baselineChartSummary === null) { return doNotShow; }

  const baselineResultData = state.cache.requestedMetricsData[baselineScenario].data;
  const commonVars = {
    startYear: baselineResultData.valuationYear,
    endYear: baselineResultData.valuationYear + baselineResultData.numYears - 1,
  };
  const calculator = new DollarDifferenceFromBaselineCalculator(baselineChartSummary, currentScenarioChartSummary, commonVars);
  const result = calculator.calculate();

  return {
    shouldShowSummary: true,
    sentence: result.sentence,
  };
}