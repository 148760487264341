// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import prettyPrint from 'common/pretty_print';
import { findByName, hexToRGB } from 'common/colorMachine';
import styled from 'styled-components';
import { Glyphicon } from 'react-bootstrap';
import ToggleSeriesDropdown from './ToggleSeriesDropdown';

const GridRow = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  align-content: center;
`;

const TabContainer = styled(GridRow)`
  grid-template-columns: ${props => (props.showVisibilityDropdown ? 'max-content' : 'auto')};
`;

const ToggleSeriesDropdownWrapper = styled(GridRow)`
  align-self: ${props => (props.isComparing ? 'flex-start' : 'center')};
  margin-top: ${props => (props.isComparing? '15px' : '')};
  margin-left: 30px;
`;

const TabGrid = styled.div`
  display: flex;
  justify-content: space-around;
  padding-left: 1rem;
  padding-right: 1rem;
`;

const NumberContainer = styled.div`
  position: relative;
  width: max-content;
  margin: 0 auto;
`;

const NumberRow = styled.div`
  display: flex;
  align-items: center;
`;

const Number = styled.div`
  color: ${props => props.colorCode};
  font-size: 16px;
  text-align: center;
`;

const Circle = styled.div`
  display: ${props => (props.isShowingValue && props.number ? 'inline-block' : 'none')};
  width: 16px;
  height: 16px;
  border: 2px solid ${props => (props.isHidden ? '#ccc' : props.colorCode)};
  border-radius: 50%;
  margin: 3px 10px 3px 0;
  margin: ${props => (props.isHidden ? '3px 0' : '3px 10px 3px 0')};
`;

const BaselineNumber = styled.div`
  color: darkgrey;
  font-size: 12px;
  text-align: center;
`;

const DifferenceNumber = styled(BaselineNumber)`
  color: grey;
  text-align: center;
`;

const StyledDifferenceIcon = styled(Glyphicon)`
  font-size: 9px;
`;

const Underline = styled.div`
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 2px;
  width: 0px;
  background: ${props => props.colorCode};
  transition: 0.15s ease;
`;

const Tab = styled.div`
  position: relative;
  color: ${props => (props.selected ? 'white' : props.colorCode)};
  color: ${props => (props.isHidden ? '#ccc' : '')};
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  transition: 0.15s ease;
`;

const CompareTab = styled(Tab)`
  background-color: ${props => (props.selected ? props.colorCode : hexToRGB(props.colorCode, 0.01))};
  box-shadow: ${props => (props.isHidden ? '' : '0px 0px 2px')};
  padding: 5px 10px;
  margin-bottom: 5px;
  cursor: ${props => (!props.selected ? 'pointer' : 'default')};
  &:hover {
    background-color: ${props => (props.isHidden ? '' : props.colorCode)};
    box-shadow: ${props => (props.isHidden ? '' : !props.selected ? `0px 8px 4px -4px ${hexToRGB(props.colorCode, 0.4)}` : '')};
    color: ${props => (props.isHidden ? '' : 'white')};
    transform: ${props => (props.isHidden ? '' : !props.selected ? 'translateY(-4px)' : '')};
  }
  &:after {
    position: absolute;
    content: '';
    width: 100%;
    top: 0;
    left: 0;
    bottom: -1em;
    z-index: -1;
  }
`;

const ToolTipParent = styled.div`
  position: relative;
  padding: 0 7px 5px 7px;
  margin: 15px;
  margin-bottom: 0px;
  user-select: none;
  color: ${props => (props.isHidden ? '#ccc !important' : '')};
  &:hover ${Underline} {
    width: ${props => (props.isHidden ? '' : '100%')};
  }
`;

const TabWrapper = styled.div`
  cursor: ${props => (props.toggleEnabled ? 'pointer' : '')};
`;

const CompareBaselineContainer = styled.div`
  display: flex;
  align-items: center;
  color: grey;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    color: ${props => props.color || ''};
  }
  & input {
    margin: 2px 0 0 0;
    height: 10px;
    cursor: pointer;
  }
`;

const ValuesContainer = styled.div``;

const ChooseTab = props => {
  const dispatch = useDispatch();
  const isServerSide = useSelector(state => state.isServerSide);

  const toggleDataSeries = tab => {
    const dataSeriesToHide = {
      topic: info.selectedView.topic,
      chart: info.selectedView.chart,
      series: tab.id,
    }
    dispatch({ type: 'TOGGLE_DATA_SERIES', payload: dataSeriesToHide });
  }

  const onClickToggleBaselineComparison = () => {
    props.toggleCompareWithBaseline();
  };

  const makeTab = (o, i, color, inCompareMode = false) => {
    const { tab, callbacks, cmp, info, currentComparison, baselineComparison } = props;
    const colorCode = findByName(color);
    const wantYear = cmp.state.onYear;
    const isShowingValue = tab === 'current' && i !== 'current' && !!wantYear;

    const makeNumber = (comparisonScenario, isShowingValue) => {
      let startYear = info.projectionStart;
      let wantIndex = wantYear - startYear;
      if (isShowingValue) {
        let adjustments = o.adjustments.find(a => a.label === comparisonScenario.label);
        if (adjustments) {
          let val = adjustments.values[wantIndex];
          return {
            value: val,
            label: prettyPrint(val, o.format),
          };
        }
      }
      return null;
    };

    const makeDifferenceNumber = (currentNumber, baselineNumber) => {
      if (currentNumber && baselineNumber) {
        const differenceValue = currentNumber.value - baselineNumber.value;
        if (!differenceValue) {
          return null;
        }
        return prettyPrint(differenceValue, o.format).replace(/[()]/g, '');
      }
      return null;
    };

    const comparingScenarios = currentComparison.label !== baselineComparison.label;
    const number = makeNumber(currentComparison, isShowingValue);
    const baselineNumber = makeNumber(baselineComparison, isShowingValue);
    const differenceNumber = makeDifferenceNumber(number, baselineNumber);
    const specifyDifferenceIcon = () => `triangle-${number.value > baselineNumber.value ? 'top' : 'bottom'}`;
    const selected = tab === o.id;
    const tabIsHidden = o.hidden;
    const renderComparisonNumbers = baselineNumber && comparingScenarios && info.compareWithBaseline && !tabIsHidden;
    const visibleSeries = info.series.filter(series => !series.hidden);
    const toggleEnabled = visibleSeries.length > 1 || tabIsHidden;

    return (
      <ToolTipParent key={i} className="tool-tip-parent" isHidden={tabIsHidden}>
        <TabWrapper toggleEnabled={toggleEnabled}>
          {inCompareMode ? (
            <CompareTab
              onClick={tabIsHidden && toggleEnabled
                ? () => toggleDataSeries(o)
                : !selected
                  ? () => callbacks.selectTab(o.id)
                  : null
              }
            inCompareMode={inCompareMode}
            colorCode={colorCode}
            selected={selected}
            isHidden={tabIsHidden}
            >
              {o.title}
            </CompareTab>
          ) : (
            <Tab colorCode={colorCode} selected={selected} isHidden={tabIsHidden} onClick={toggleEnabled ? () =>  toggleDataSeries(o) : null}>
              {o.title}
            </Tab>
          )}

        <ValuesContainer isHidden={tabIsHidden} onClick={toggleEnabled ? () =>  toggleDataSeries(o) : null}>
          <NumberContainer colorCode={colorCode}>
            <NumberRow>
              {i === 'current' && tab === 'current' ? (
                <CompareBaselineContainer onClick={onClickToggleBaselineComparison} color={colorCode}>
                  <input type="checkbox" checked={info.compareWithBaseline} onChange={() => {}} />
                  <span>compare baseline</span>
                </CompareBaselineContainer>
              ) : (
                <React.Fragment>
                  <Circle number={number} colorCode={colorCode} isShowingValue={isShowingValue} isHidden={tabIsHidden} />
                  {!tabIsHidden && <Number colorCode={colorCode}>{isShowingValue ? number.label : '\u00A0'}</Number>}
                </React.Fragment>
              )}
            </NumberRow>
            {renderComparisonNumbers && <BaselineNumber>from {baselineNumber.label}</BaselineNumber>}
          </NumberContainer>
          {renderComparisonNumbers && (
            <DifferenceNumber>
              {differenceNumber ? (
                <React.Fragment>
                  <StyledDifferenceIcon glyph={specifyDifferenceIcon()} />
                  {differenceNumber}
                </React.Fragment>
              ) : (
                'No change'
              )}
            </DifferenceNumber>
          )}
          {!selected && <Underline colorCode={colorCode} className="underline" />}
          </ValuesContainer>
        </TabWrapper>
      </ToolTipParent>
    );
  }

  let { info, noScenarioLabel, currentComparison } = props;
  const showVisibilityDropdown = info.series.length > 1 && info.selectedView.tab === 'current' && !isServerSide;
  return (
    <TabContainer showVisibilityDropdown={showVisibilityDropdown}>
      {showVisibilityDropdown &&
        <ToggleSeriesDropdownWrapper isComparing={info.isComparing}>
          <ToggleSeriesDropdown info={info} />
        </ToggleSeriesDropdownWrapper>
      }
      <TabGrid>
        {info.isComparing &&
          !noScenarioLabel &&
          makeTab({ id: 'current', title: currentComparison.label }, 'current', 'scenario' + currentComparison.colorKey, true)}
        {info.series.map((s, i) => makeTab(s, i, s.color, info.isComparing))}
      </TabGrid>
    </TabContainer>
  );
}

export default ChooseTab;
