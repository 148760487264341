// ********************************************************************************************
// Copyright(c) 2018 GovInvest, Inc. - All Rights Reserved
// This file is part of the Prometheus product
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// ********************************************************************************************

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, FormGroup, Checkbox } from 'react-bootstrap';
import urlJoin from 'url-join';
import { scenariosSelectDownloadDialogShow } from 'client/actions/uiActions';
import { downloadXLS } from 'client/lib/download.js';
import { SCENARIO_DEFAULT_ID } from '../sideBar/constants';
import styled from 'styled-components';
import './ScenariosSelectDownloadDialog.css';
import config from '../../lib/client-config';

const CheckboxLabel = styled.span`
  user-select: none;
`;

class ScenariosSelectDownloadDialog extends Component {
  state = {
    all: false,
    scenariosSelected: [this.props.viewingScenario],
  };

  handleSelectAll = e => {
    const selected = e.target.checked;
    let selectedScenarios = [];
    if (selected) {
      selectedScenarios = [...this.props.comparedScenarios];
    }
    if (!selected) {
      selectedScenarios = [];
    }
    this.setState({
      all: !this.state.all,
      scenariosSelected: selectedScenarios,
    });
  };

  handleChange = (scenario, e) => {
    const selected = e.target.checked;
    let selectedScenarios = [...this.state.scenariosSelected];
    if (selected) {
      scenario === SCENARIO_DEFAULT_ID ? selectedScenarios.unshift(scenario) : selectedScenarios.push(scenario);
    }
    if (!selected) selectedScenarios = selectedScenarios.filter(selectedScenario => selectedScenario !== scenario);
    this.setState({
      scenariosSelected: selectedScenarios,
    });
  };

  sanitizeFileName = (src) => {
    let dst = src.replace(':', '')
                 .replace('/', '_');
    return dst;
  }

  handleSubmit = () => {
    const downloadProps = {
      ...this.props.downloadProps,
      displayProps: {
        ...this.props.downloadProps.displayProps,
        comparedScenarios: this.state.scenariosSelected,
      },
    };
    downloadProps.displayProps.cache = JSON.parse(JSON.stringify(downloadProps.displayProps.cache));
    const comparedScenarios = downloadProps.displayProps.comparedScenarios;
    const cachedResults = Object.keys(downloadProps.displayProps.cache.requestedMetricsData);
    for(let cachedResult of cachedResults) {
      if(comparedScenarios.indexOf(cachedResult) === -1) {
        delete downloadProps.displayProps.cache.requestedMetricsData[cachedResult];
      }
    }
    const workspaceInfo = this.props.workspaceInfo;
    delete downloadProps.displayProps.requestedMetricsData;
    const uri = urlJoin(config.baseUrl, `webapi/workspace/${workspaceInfo.projectId}/${workspaceInfo.planType}/chartXlsx2`);
    const fileName = this.sanitizeFileName(this.props.topic + '-' + this.props.chart + '.xlsx');
    downloadXLS(uri, downloadProps, fileName);
    this.props.hideDialog(!this.props.showDialog);
  };

  render() {
    const { comparedScenarios, scenarios, showDialog, hideDialog } = this.props;
    const comparedScenariosFiltered = comparedScenarios.filter(comparedScenario => comparedScenario !== SCENARIO_DEFAULT_ID);
    return (
      <Modal
        show={showDialog}
        className="static-modal"
        dialogClassName="modal-xlsx-download"
        centered="true"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => hideDialog(!showDialog)}
      >
        <Modal.Header>
          <Modal.Title>Download Excel (xlsx) Scenario Result</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            {comparedScenariosFiltered.length > 0 && (
              <Checkbox checked={this.state.all} onChange={e => this.handleSelectAll(e)}>
                <CheckboxLabel>All</CheckboxLabel>
              </Checkbox>
            )}
            <Checkbox
              checked={this.state.scenariosSelected.includes(SCENARIO_DEFAULT_ID)}
              onChange={e => this.handleChange(SCENARIO_DEFAULT_ID, e)}
              disabled={this.state.all}
            >
              <CheckboxLabel>Default</CheckboxLabel>
            </Checkbox>
            {comparedScenariosFiltered.map((scenario, scenarioIndex) => (
              <Checkbox
                key={scenarioIndex}
                checked={this.state.scenariosSelected.includes(scenario)}
                onChange={e => this.handleChange(scenario, e)}
                disabled={this.state.all}
              >
                <CheckboxLabel>{scenarios[scenario].label}</CheckboxLabel>
              </Checkbox>
            ))}
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => hideDialog(!showDialog)}>Cancel</Button>
          <Button bsStyle="primary" onClick={this.handleSubmit} disabled={this.state.scenariosSelected.length < 1}>
            Download
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  scenarios: state.sideBar.scenarios,
  viewingScenario: state.sideBar.viewingScenario,
  comparedScenarios: state.sideBar.comparedScenarios,
  showDialog: state.ui.scenariosSelectDownloadDialogShow,
  workspaceInfo: state.workspace.info,
});

const mapDispatchToProps = dispatch => ({
  hideDialog: bool => dispatch(scenariosSelectDownloadDialogShow(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScenariosSelectDownloadDialog);
